<template>
  <div
    class="w-full cursor-pointer dark:bg-navy-600 bg-navy-10 rounded-2xl"
    @click="handleClick"
  >
    <input type="file" class="hidden" ref="fileInput" />
    <div
      v-if="loadingStatus === 1"
      class="flex flex-row items-center justify-center dark:text-white text-navy-800 gap-2 py-6"
    >
      <img src="@/assets/icons/spinner.svg" class="w-6 h-6" />
      <div class="text-sm font-medium">Uploading...</div>
    </div>
    <div
      v-else-if="loadingStatus === 0"
      class="flex flex-col items-center justify-center w-full py-12"
    >
      <div
        class="flex flex-row items-center justify-center text-primary-700 gap-4"
      >
        <file-icon class="w-10 h-10" />
        <div>Upload a CV</div>
      </div>
      <div
        class="flex flex-row items-center justify-center text-sm font-medium mt-3"
      >
        The file size must not exceed 10 MB
      </div>
    </div>
    <div
      v-else-if="loadingStatus == 2"
      class="flex flex-row items-center justify-center p-4"
    >
      <div class="dark:text-white text-base font-medium text-navy-800">
        {{ fileName }}
      </div>
      <div class="dark:text-navy-100 text-base font-medium text-navy-200 ml-3">
        {{ formatFileSize(fileSize) }}
      </div>
      <div class="grow" />
      <trash-icon class="w-6 h-6 text-navy-100" @click="handleClear" />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, defineEmits } from "vue";
import { formatFileSize, isEmpty } from "@/utils/utils";
import FileIcon from "@/assets/icons/common/FileIcon.vue";
import { TrashIcon } from "@heroicons/vue/24/outline";

const emit = defineEmits(["change"]);

const fileInput = ref();
const data = ref();
const loadingStatus = ref(0);
const fileName = ref("");
const fileSize = ref(0);

const handleClear = (e) => {
  data.value = null;
  loadingStatus.value = 0;
  fileName.value = "";
  fileSize.value = 0;
  e.stopPropagation();
};

const handleClick = () => {
  if (loadingStatus.value === 0) {
    fileInput.value.click();
  }
};

onMounted(() => {
  fileInput.value.addEventListener("change", () => {
    if (isEmpty(fileInput.value.files)) {
      return;
    }
    const reader = new FileReader();
    reader.onloadstart = () => {
      loadingStatus.value = 1;
    };
    reader.onload = () => {
      loadingStatus.value = 2;
      fileName.value = fileInput.value.files[0].name;
      fileSize.value = fileInput.value.files[0].size;
    };
    data.value = reader.readAsArrayBuffer(fileInput.value.files[0]);
    emit("change", data.value);
  });
});
</script>
