<template>
  <div class="w-full container mx-auto px-4 mb-52">
    <div class="mt-4 sm:mt-10">
      <breadcrumb-menu :items="breadCrumbData" />
    </div>
    <div
      class="sm:mt-6 mt-3 dark:text-white font-bold sm:text-[40px] text-[32px] text-navy-800"
    >
      Subscription History
    </div>
    <div
      class="mt-8 flex sm:flex-row flex-col sm:items-center items-start max-sm:w-full sm:justify-between gap-4"
    >
      <div>
        <radio-button-list :items="radioButtonListData" />
      </div>
      <div class="sm:w-72 w-full">
        <text-edit
          type="text"
          size="sm"
          rounded="full"
          placeholder="Search"
          :filled="true"
          v-model="searchText"
        >
          <template #prefix>
            <magnifying-glass-icon class="w-6 h-6 mr-2 text-navy-100" />
          </template>
          <template #suffix v-if="searchText.length > 0">
            <div class="w-6 h-6 ml-2 cursor-pointer" @click="searchText = ''">
              <x-circle-icon class="text-navy-200 stroke-2 w-6 h-6" />
            </div>
          </template>
        </text-edit>
      </div>
    </div>
    <div
      class="flex sm:flex-row items-center flex-col max-sm:gap-3 sm:mt-12 mt-4"
    >
      <div class="sm:mr-4 max-sm:w-full">
        <dropdown-select @select="handleCoinMenuSelected" width="full">
          <template #header="{ handleContentClick, isShowingMenu }">
            <div
              class="sm:w-32 flex flex-row items-center justify-between px-3 border dark:border-navy-400 border-navy-50 rounded-xl py-2.5 w-full"
            >
              <div class="text-sm font-medium text-navy-100">Coin</div>
              <div
                class="dark:text-white text-navy-800"
                @click="handleContentClick"
              >
                <chevron-up-icon
                  v-if="isShowingMenu"
                  class="w-4 h-4 stroke-2"
                />
                <chevron-down-icon v-else class="w-4 h-4 stroke-2" />
              </div>
            </div>
          </template>
          <template #menu="{ handleMenuItemClick }">
            <div
              class="flex flex-col px-2 py-1.5 w-full dark:text-navy-200 text-navy-700 dark:bg-navy-700 bg-navy-50 rounded-lg gap-1"
            >
              <div
                v-for="item in coinSelectMenuData"
                :key="item.id"
                class="cursor-pointer dark:hover:bg-navy-600 px-2 py-1.5 rounded-lg"
                @click="handleMenuItemClick(item.id)"
              >
                {{ item.label }}
              </div>
            </div>
          </template>
        </dropdown-select>
      </div>
      <div
        class="flex flex-row items-center justify-stretch gap-4 max-sm:w-full"
      >
        <div class="sm:w-[200px] w-full">
          <date-input />
        </div>
        <div class="text-sm font-medium dark:text-navy-100">/</div>
        <div class="sm:w-[200px] w-full">
          <date-input />
        </div>
      </div>
      <div class="sm:ml-6">
        <link-button label="Reset"></link-button>
      </div>
    </div>
    <table class="w-full table-fixed mt-6">
      <thead>
        <tr class="h-10">
          <th
            v-for="item in headerItems"
            :key="item.id"
            :class="[
              'px-3 py-2.5 text-sm font-medium text-navy-100 text-left',
              item.hideable === false ? '' : `max-${item.hideable}:hidden`,
            ]"
          >
            {{ item.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-if="displayTableData.length">
          <tr
            v-for="item in displayTableData"
            :key="item.id"
            class="dark:text-white text-navy-800 h-[72px] dark:hover:bg-navy-700 hover:bg-navy-10 cursor-pointer space-y-2"
          >
            <td class="px-3 align-middle rounded-l-xl text-sm font-medium">
              {{ item.time }}
            </td>
            <td
              class="px-3 align-middle text-sm font-semibold max-sm:rounded-r-xl"
            >
              {{ item.projectName }}
            </td>
            <td class="px-3 align-middle text-sm font-semibold max-md:hidden">
              {{ item.commitedAmount }}
            </td>
            <td class="px-3 align-middle text-sm font-semibold max-lg:hidden">
              {{ item.price }}
            </td>
            <td class="px-3 align-middle text-sm font-semibold max-lg:hidden">
              {{ item.decutedTokens }}
            </td>
            <td class="px-3 align-middle text-sm font-semibold max-xl:hidden">
              {{ item.decutedTokens1 }}
            </td>
            <td
              class="px-3 align-middle text-sm font-semibold dark:text-navy-100 text-navy-200 md:rounded-r-xl max-md:hidden"
            >
              {{ item.status }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="isEmpty(displayTableData)">
      <div class="h-40 flex flex-col items-center justify-center w-full">
        <div>
          <double-coin-icon class="w-8 h-8 dark:text-navy-400" />
        </div>
        <div>No Data</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { isEmpty } from "@/utils/utils";
import RadioButtonList from "@/components/Selector/RadioButtonList.vue";
import BreadcrumbMenu from "@/components/Breadcrumb/BreadcrumbMenu.vue";
import DateInput from "@/components/DateInput/DateInput.vue";
import DoubleCoinIcon from "@/assets/icons/common/DoubleCoinIcon.vue";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from "@heroicons/vue/24/outline";

const searchText = ref("");

const handleCoinMenuSelected = (id) => {
  console.log(id);
};

const breadCrumbData = [
  {
    id: 1,
    label: "Launchpad",
    pathName: "launchpad",
  },
  {
    id: 2,
    label: "Subscription History",
    active: true,
  },
];

const radioButtonListData = [
  { id: 1, label: "All" },
  { id: 2, label: "In Progress" },
  { id: 3, label: "Finished" },
];

const coinSelectMenuData = [
  { id: 1, label: "BTC" },
  { id: 2, label: "USDC" },
  { id: 3, label: "USDT" },
];

const headerItems = [
  {
    id: 1,
    label: "Time",
    hideable: false,
  },
  {
    id: 2,
    label: "Project Name",
    hideable: false,
  },
  {
    id: 3,
    label: "Commited Amount",
    hideable: "md",
  },
  {
    id: 4,
    label: "Price",
    hideable: "lg",
  },
  {
    id: 5,
    label: "Decuted Tokens",
    hideable: "lg",
  },
  {
    id: 6,
    label: "Decuted Tokens",
    hideable: "xl",
  },
  {
    id: 7,
    label: "Status",
    hideable: "md",
  },
];

const displayTableData = [
  {
    id: 1,
    time: "05.02.2024, 15:47:26",
    projectName: "Arkham (ARKM)",
    commitedAmount: "50,000 USDT",
    price: "0.1028 USDT",
    decutedTokens: "100 USDT",
    decutedTokens1: "100 USDT",
    status: "Completed",
  },
  {
    id: 2,
    time: "05.02.2024, 15:47:26",
    projectName: "Bubble (BUBBLE)",
    commitedAmount: "50,000 USDT",
    price: "3.102 ETH",
    decutedTokens: "100 USDT",
    decutedTokens1: "100 USDT",
    status: "Completed",
  },
];
</script>
