<template>
  <div
    :class="[
      currentTheme === 'light'
        ? `xl:bg-[url('@/assets/images/auth/left_back_light.svg')]`
        : `xl:bg-[url('@/assets/images/auth/left_back_dark.svg')]`,
      'bg-no-repeat min-h-svh m-auto',
    ]"
  >
    <img
      class="px-6 py-4 cursor-pointer"
      @click="handleLogoClick"
      :src="[
        currentTheme === 'dark'
          ? '/assets/images/logo/logo_with_icon_dark.svg'
          : '/assets/images/logo/logo_with_icon_light.svg',
      ]"
      alt="Exzi Logo"
    />
    <div class="sm:max-w-[500px] max-w-[420px] xl:ml-[848px] m-auto p-4">
      <slot />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";

const router = useRouter();
const handleLogoClick = () => {
  router.push({ name: "home" });
};
</script>
