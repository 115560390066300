<template>
  <div>
    <text-edit
      type="email"
      :filled="false"
      rounded="xl"
      label="Email address"
      placeholder="Email address"
      v-model="email"
      :error="emailError"
    />
    <div class="h-6" />

    <password-input
      label="Password"
      placeholder="Password"
      v-model="password"
      :error="passwordError"
    />
    <div class="h-6" />

    <password-input
      label="Confirm Password"
      placeholder="Confirm Password"
      v-model="confirmPassword"
      :error="confirmPasswordError"
    />
    <div class="h-6" />

    <collapse-view>
      <template #header="{ handleContentClick }">
        <div
          class="flex space-x-3 items-center mb-2 cursor-pointer"
          @click="handleContentClick"
        >
          <p class="font-bold text-sm">Referral code (Optional)</p>
          <chevron-down-icon class="w-5 h-5 stroke-2" />
        </div>
      </template>
      <template #content>
        <text-edit
          type="text"
          :filled="false"
          rounded="xl"
          label=""
          placeholder="Referral code"
          v-model="refferal"
        />
      </template>
    </collapse-view>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineEmits } from "vue";
import { validateEmail, validatePassword } from "@/views/Auth/validation";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

const email = ref("");
const password = ref("");
const confirmPassword = ref("");
const refferal = ref("");
const emit = defineEmits([
  "changeEmail",
  "changePassword",
  "changeConfirmPassword",
  "changeRefferal",
]);

const emailError = computed(() =>
  email.value && !validateEmail(email.value) ? "Invalid email address" : ""
);
const passwordError = computed(() => validatePassword(password.value));
const confirmPasswordError = computed(() => {
  const isValid = validatePassword(confirmPassword.value);
  return isValid.every((item) => item === true)
    ? confirmPassword.value === password.value
      ? isValid
      : "Confirm password is not matched"
    : isValid;
});

watch(email, (newEmail) => {
  emit("changeEmail", newEmail);
});

watch(password, (newPassword) => {
  emit("changePassword", newPassword);
});

watch(confirmPassword, (newConfirmPassword) => {
  emit("changeConfirmPassword", newConfirmPassword);
});

watch(refferal, (newRefferal) => {
  emit("changeRefferal", newRefferal);
});
</script>
