<template>
  <dropdown-select width="full" @select="handleSelectToken">
    <template #header="{ handleContentClick, isShowingMenu }">
      <div class="font-semibold">
        <p v-if="!isEmpty(labelText)" class="text-sm mb-2">
          {{ labelText }}
        </p>
        <text-view
          :value="selectedCoin?.iso3"
          placeholder="Select Coin"
          width="full"
          rounded="xl"
          :error="error"
          @focus="handleContentClick(true)"
        >
          <template #prefix v-if="selectedCoin?.icon">
            <img
              class="w-6 h-6 rounded-full mr-4"
              :src="selectedCoin.icon"
              alt="Coin Image"
            />
          </template>
          <template #content>
            <span>{{ selectedCoin?.iso3 }}</span>
            <span class="ml-2 text-navy-200">{{ selectedCoin?.name }}</span>
          </template>
          <template #suffix>
            <chevron-down-icon v-if="!isShowingMenu" class="w-6 h-6" />
            <chevron-up-icon v-else class="w-6 h-6" />
          </template>
        </text-view>
      </div>
    </template>
    <template #menu="{ handleMenuItemClick }">
      <div
        class="dark:bg-navy-600 bg-white p-4 rounded-2xl dark:border dark:border-navy-400 mt-2 dark:shadow-none shadow-md"
      >
        <p class="font-semibold mb-4">Select Currency</p>
        <text-edit
          filled
          size="sm"
          rounded="full"
          placeholder="Select Currency"
          v-model="searchValue"
        >
          <template #prefix>
            <magnifying-glass-icon class="w-5 h-5 mr-2 text-navy-200" />
          </template>
        </text-edit>
        <div class="max-h-60 overflow-auto custom-scrollbar mt-6">
          <div
            v-for="currency in currenciesList"
            :key="currency.iso3"
            class="px-3 py-2 flex font-semibold gap-3 items-center hover:dark:bg-navy-400 hover:bg-navy-10 cursor-pointer rounded-xl"
            @click="handleMenuItemClick(currency)"
          >
            <img class="w-8 h-8" :src="currency.icon" alt="Token icon" />
            <div>
              <p class="text-base">{{ currency.iso3 }}</p>
              <p class="text-xs dark:text-navy-100 text-navy-200">
                {{ currency.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </dropdown-select>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";
import { isEmpty } from "@/utils/utils";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/vue/24/outline";
import TextView from "@/components/TextEdit/TextView.vue";

const props = defineProps({
  labelText: String,
  currencies: Array,
  defaultCoin: Object,
  error: String,
});

const emits = defineEmits(["select"]);

const selectedCoin = ref(props.defaultCoin);
const searchValue = ref("");

const currenciesList = computed(() =>
  (props.currencies ?? []).filter((item) =>
    item.iso3.toLowerCase().includes(searchValue.value.toLowerCase())
  )
);

const handleSelectToken = (coin) => {
  selectedCoin.value = coin;
  emits("select", coin);
};
</script>
