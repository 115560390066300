import { createI18n } from "vue-i18n";
import en from "./locales/en.json";

export default createI18n({
  locale: "ru",
  fallbackLocale: "ru",
  legacy: false,
  globalInjection: true,
  messages: { en },
  runtimeOnly: false,
});

export const availableLangs = [
  "ar",
  "de",
  "el",
  "en",
  "fa",
  "fr",
  "id",
  "it",
  "lt",
  "ms",
  "nl",
  "pl",
  "pt",
  "ru",
  "sv",
  "th",
  "tl",
  "tr",
  "uk",
  "vi",
  "zh_CN",
  "es",
  "hi",
];
