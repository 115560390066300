<template>
  <div
    class="w-[482px] max-sm:w-[calc(100%-32px)] max-sm:mx-4 rounded-2xl p-4 flex flex-col gap-6 shadow-xl dark:bg-navy-600 dark:border-navy-300 border dark:shadow-black/[0.32] bg-white shadow-black/[0.18]"
  >
    <div class="dark:border-navy-400 border-navy-50 bg-white dark:bg-navy-600">
      <text-edit
        type="text"
        size="sm"
        rounded="full"
        placeholder="Search ..."
        :filled="true"
        v-model="searchText"
      >
        <template #prefix>
          <div class="w-6 h-6 mr-2">
            <magnifying-glass-icon
              :class="[
                searchText.length > 0
                  ? 'dark:text-white text-navy-800'
                  : 'text-navy-200',
              ]"
            />
          </div>
        </template>
        <template #suffix v-if="searchText.length > 0">
          <div
            class="w-6 h-6 ml-2"
            @click="
              () => {
                searchText = '';
              }
            "
          >
            <x-circle-icon class="text-navy-200 stroke-2 w-6 h-6" />
          </div>
        </template>
      </text-edit>
    </div>
    <div v-if="searchText.length === 0">
      <div class="flex flex-col gap-3">
        <div
          :class="[
            'flex flex-row justify-between text-navy-200 h-5 items-center',
          ]"
        >
          <span class="text-sm font-semibold">History</span>
          <span><trash-icon class="stroke-2 w-5 h-5" /></span>
        </div>
        <div class="flex flex-row gap-2 flex-wrap">
          <text-badge
            v-for="item in coinData"
            :label="item.label"
            size="md"
            :class="[
              'text-xs dark:text-navy-50 dark:bg-navy-500 bg-navy-10 text-navy-300',
            ]"
            :key="item.id"
          >
            <img :src="item.icon" />
          </text-badge>
        </div>
      </div>
      <div class="flex flex-col mt-6">
        <div
          class="flex flex-row h-7 justify-between items-center dark:text-white text-navy-800"
        >
          <span :class="['text-base font-semibold']">Top Searches</span>
          <div class="flex-grow" />
          <span><chevron-left-icon class="stroke-2 w-5 h-5 mr-4" /> </span>
          <span><chevron-right-icon class="stroke-2 w-5 h-5" /> </span>
        </div>
        <div class="flex flex-row mt-4 flex-wrap gap-2 mb-2">
          <text-badge
            v-for="item in topSearchItemsData"
            :label="item.label"
            size="md"
            class="text-xs dark:text-white dark:bg-navy-400 bg-navy-10 text-navy-300"
            :key="item.id"
          />
        </div>
        <div class="flex flex-row items-center justify-center gap-3">
          <div class="w-1.5 h-1.5 rounded-full dark:bg-white bg-navy-300" />
          <div class="w-1.5 h-1.5 rounded-full dark:bg-navy-300 bg-navy-50" />
          <div class="w-1.5 h-1.5 rounded-full dark:bg-navy-300 bg-navy-50" />
          <div class="w-1.5 h-1.5 rounded-full dark:bg-navy-300 bg-navy-50" />
        </div>
      </div>
    </div>
    <div v-if="searchText.length > 0" class="flex flex-col overflow-auto">
      <div class="font-medium text-sm">
        <underline-tab :items="searchTabData" :bottom-line="true" />
      </div>
      <div
        class="h-12 flex flex-row items-center justify-start dark:text-navy-200"
      >
        Spot
      </div>
      <div class="flex flex-col custom-scrollbar">
        <search-spot-item
          v-for="item in searchSpotData"
          :tokenA="item.tokenA"
          :tokenB="item.tokenB"
          :price="item.price"
          :ratio="item.ratio"
          :key="item.id"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import TextBadge from "@/components/Badge/TextBadge.vue";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import SearchSpotItem from "@/layout/AppNavbar/item/SearchSpotItem.vue";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
  MagnifyingGlassIcon,
} from "@heroicons/vue/24/outline";
import { XCircleIcon } from "@heroicons/vue/24/solid";
const searchText = ref("");

const coinData = [
  {
    id: 0,
    label: "USDT",
    icon: "/assets/icons/usdt_icon.svg",
  },
  {
    id: 1,
    label: "BTC",
    icon: "/assets/icons/btc_icon.svg",
  },
  {
    id: 2,
    label: "USDC",
    icon: "/assets/icons/usdc_icon.svg",
  },
  {
    id: 3,
    label: "SOL",
    icon: "/assets/icons/sol_icon.svg",
  },
  {
    id: 4,
    label: "XRP",
    icon: "/assets/icons/xrp_icon.svg",
  },
];

const topSearchItemsData = [
  {
    id: 0,
    label: "BTC/USDT",
  },
  {
    id: 1,
    label: "ETH/USDT",
  },
  {
    id: 2,
    label: "PEPE/USDT",
  },
  {
    id: 3,
    label: "BGB/USDT",
  },
  {
    id: 4,
    label: "SOL/USDT",
  },
  {
    id: 5,
    label: "XRP/USDT",
  },
];

const searchTabData = [
  { id: 1, name: "All" },
  { id: 2, name: "Spot" },
];

const searchSpotData = [
  {
    id: 0,
    tokenA: "BTC",
    tokenB: "USDT",
    price: "$42.522,8",
    ratio: "-1.51%",
  },
  {
    id: 1,
    tokenA: "BTC",
    tokenB: "USDT",
    price: "$42.522,8",
    ratio: "-1.51%",
  },
  {
    id: 2,
    tokenA: "BTC",
    tokenB: "USDT",
    price: "$42.522,8",
    ratio: "-1.51%",
  },
  {
    id: 3,
    tokenA: "BTC",
    tokenB: "USDT",
    price: "$42.522,8",
    ratio: "-1.51%",
  },
  {
    id: 4,
    tokenA: "BTC",
    tokenB: "USDT",
    price: "$42.522,8",
    ratio: "-1.51%",
  },
  {
    id: 5,
    tokenA: "BTC",
    tokenB: "USDT",
    price: "$42.522,8",
    ratio: "-1.51%",
  },
  {
    id: 6,
    tokenA: "BTC",
    tokenB: "USDT",
    price: "$42.522,8",
    ratio: "-1.51%",
  },
  {
    id: 7,
    tokenA: "BTC",
    tokenB: "USDT",
    price: "$42.522,8",
    ratio: "-1.51%",
  },
  {
    id: 8,
    tokenA: "BTC",
    tokenB: "USDT",
    price: "$42.522,8",
    ratio: "-1.51%",
  },
  {
    id: 9,
    tokenA: "BTC",
    tokenB: "USDT",
    price: "$42.522,8",
    ratio: "-1.51%",
  },
  {
    id: 10,
    tokenA: "BTC",
    tokenB: "USDT",
    price: "$42.522,8",
    ratio: "-1.51%",
  },
  {
    id: 11,
    tokenA: "BTC",
    tokenB: "USDT",
    price: "$42.522,8",
    ratio: "-1.51%",
  },
];
</script>
