<template>
  <div class="flex flex-col mt-40 sm:mt-52">
    <div
      class="font-semibold text-[40px] text-center sm:text-5xl sm:font-bold dark:text-white text-navy-800"
    >
      Build your crypto portfolio
    </div>
    <div class="mt-12 sm:mt-16 relative">
      <img
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/home/portfolio.png'
            : '/assets/images/home/portfolio_light.png',
        ]"
        class="object-none object-top h-[368px]"
      />
      <div
        class="absolute top-0 left-0 h-full w-1/2 bg-gradient-to-r to-transparent z-50 dark:from-navy-800 from-white"
      />
      <div
        class="absolute top-0 right-0 h-full w-1/2 bg-gradient-to-l to-transparent z-50 dark:from-navy-800 from-white"
      />
    </div>
  </div>
</template>
<script setup></script>
