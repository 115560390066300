<template>
  <div
    class="sm:w-[440px] h-[387px] max-sm:w-[calc(100%-32px)] max-sm:mx-4 rounded-2xl shadow-xl cursor-pointer flex flex-col dark:bg-navy-600 border dark:border-navy-400 dar:shadow-black/[0.32] bg-white shadow-black/[0.16]"
  >
    <div class="px-6 justify-between flex flex-row items-center py-4">
      <span class="text-lg dark:font-extrabold font-semibold">
        Notifications
      </span>
      <span class="flex-grow" />
      <span
        class="text-sm font-medium underline dark:text-white text-primary-700"
      >
        View all
      </span>
    </div>
    <div class="h-8 w-full text-sm font-medium p-2">
      <underline-tab
        :items="notificationTabTitles"
        :show-arrows="true"
        :bottom-line="true"
      />
    </div>
    <div class="mt-3 mb-4 custom-scrollbar px-2 flex flex-col gap-2">
      <notification-item
        v-for="item in notificationData"
        :date="item.date"
        :title="item.title"
        :key="item.id"
      />
    </div>
  </div>
</template>
<script setup>
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import NotificationItem from "@/layout/AppNavbar/item/NotificationItem.vue";

const notificationTabTitles = [
  { id: 1, name: "All" },
  { id: 2, name: "Market Notifications" },
  { id: 3, name: "System Notifications" },
];

const notificationData = [
  {
    id: 0,
    title:
      "You set the stop loss level for copying user @SatoshiStacker to 5 USDT",
    date: "February 12, 14:19",
  },
  {
    id: 1,
    title:
      "You set the stop loss level for copying user @SatoshiStacker to 5 USDT",
    date: "February 12, 14:19",
  },
  {
    id: 2,
    title:
      "You set the stop loss level for copying user @SatoshiStacker to 5 USDT",
    date: "February 12, 14:19",
  },
  {
    id: 3,
    title:
      "You set the stop loss level for copying user @SatoshiStacker to 5 USDT",
    date: "February 12, 14:19",
  },
  {
    id: 4,
    title:
      "You set the stop loss level for copying user @SatoshiStacker to 5 USDT",
    date: "February 12, 14:19",
  },
  {
    id: 5,
    title:
      "You set the stop loss level for copying user @SatoshiStacker to 5 USDT",
    date: "February 12, 14:19",
  },
];
</script>
