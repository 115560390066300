import { COMPANY_NAME } from "@/constants/constants";
import { getData } from "@/views/Trading/Chart/datafeeds/helpers.js";
import {
  subscribeOnStream,
  unsubscribeFromStream,
} from "@/views/Trading/Chart/datafeeds/streaming";

const lastBarsCache = new Map();

// const supported_resolutions = ['1', '5', '15', '30', '60', '120', '180', '360', '720', 'D', '5D', 'W', 'M', '12M'];
// const supported_resolutions = ['60', 'D'];
// const supported_resolutions = ['1', '60', 'D'];
const supported_resolutions = [
  "1",
  "5",
  "15",
  "30",
  "60",
  "180",
  "360",
  "720",
  "D",
  "W",
];

// DatafeedConfiguration implementation
const configurationData = {
  supports_search: false,
  supports_group_request: false,
  supports_marks: true,
  supports_timescale_marks: true,
  exchanges: [
    {
      value: COMPANY_NAME,
      name: COMPANY_NAME,
      desc: COMPANY_NAME,
    },
  ],
  // supported_resolutions: SUPPORTED_RESOLUTIONS,
  supported_resolutions: supported_resolutions,

  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [
    {
      name: "crypto",
      value: "crypto",
    },
  ],
};

/**
 * Fix resolution, remove '1'
 *
 * @private
 * @param resolution
 */
// function fixResolution(resolution) {
//   console.log('--->');
//   // do search
//   if (['1D', '1W', '1M'].includes(resolution)) {
//     return resolution[resolution.length - 1];
//   }

//   return resolution;
// }

const Datafeed = {
  onReady: (callback) => {
    // console.log('[onReady]: Method call');

    setTimeout(() => callback(configurationData), 0);
  },
  resolveSymbol: async (
    symbolName,
    onSymbolResolvedCallback,
    onResolveErrorCallback
  ) => {
    //console.log('[resolveSymbol]: Method call', symbolName);

    if (!symbolName) {
      // console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);

      setTimeout(() => onResolveErrorCallback("Cannot resolve symbol"), 0);

      return;
    }

    const symbolParts = symbolName.split(/[:/]/);

    // Symbol information object
    const symbolInfo = {
      ticker: symbolParts[1] + symbolParts[2],
      name: symbolParts[1] + "/" + symbolParts[2],
      description: symbolParts[1] + "/" + symbolParts[2],
      type: "crypto",
      session: "24x7",
      timezone: "Etc/UTC",
      exchange: symbolParts[0],
      minmov: 1,
      pricescale: 100000000,
      visible_plots_set: "ohlcv",
      // has_daily: true,
      has_weekly_and_monthly: true,
      // has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 2,
      data_status: "streaming",
      has_intraday: true,
      intraday_multipliers: [
        "1",
        "5",
        "15",
        "30",
        "60",
        "180",
        "360",
        "720",
        "D",
        "W",
      ],
      format: "volume",
    };

    // console.log('[resolveSymbol]: Symbol resolved', symbolInfo);

    setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
  },

  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback) => {
    // console.log('[getBars]: Method call');
    // console.log("[getBars]: symbolInfo", symbolInfo);
    // console.log('[getBars]: resolution ----> ', resolution);
    // console.log('[getBars]: periodParams', periodParams);

    const { from, to, countBack, firstDataRequest } = periodParams;

    let api_resolution = resolution === "1D" ? "D" : resolution;
    api_resolution = api_resolution === "1W" ? "W" : api_resolution;

    const data = await getData(
      symbolInfo.ticker,
      api_resolution,
      from,
      to,
      countBack
    );
    if (data.length === 0) {
      // console.log('[getBars]: NO DATA');
      onHistoryCallback([], { noData: true });
      return;
    }

    let bars = [];
    data.reverse();

    data.forEach((bar) => {
      if (bar.time >= from && bar.time < to) {
        bars.push({
          time: bar.time * 1000,
          low: Number(bar.low_f),
          high: Number(bar.high_f),
          open: Number(bar.open_f),
          close: Number(bar.close_f),
          volume: Number(bar.volume_f),
        });
      }
    });
    if (firstDataRequest) {
      lastBarsCache.set(symbolInfo.full_name, { ...bars[bars.length - 1] });
    }
    onHistoryCallback(bars, { noData: false });
  },
  subscribeBars: (
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback
  ) => {
    // console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);

    subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
      lastBarsCache.get(symbolInfo.full_name)
    );
  },

  unsubscribeBars: (subscriberUID) => {
    // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
    unsubscribeFromStream(subscriberUID);
  },
};

export default Datafeed;
