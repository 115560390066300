<template>
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.4097 3.10938C35.4097 1.45252 34.0665 0.109375 32.4097 0.109375H12.1094C5.48197 0.109375 0.109375 5.48196 0.109375 12.1094V32.4098C0.109375 34.0666 1.45252 35.4098 3.10938 35.4098H32.4097C34.0665 35.4098 35.4097 34.0666 35.4097 32.4098V3.10938ZM29.5263 26.5262C29.5263 28.1831 28.1832 29.5262 26.5263 29.5262H8.99275C7.33589 29.5262 5.99275 28.1831 5.99275 26.5262V8.99274C5.99275 7.33589 7.33589 5.99275 8.99275 5.99275H26.5263C28.1832 5.99275 29.5263 7.33589 29.5263 8.99275V26.5262Z"
      fill="currentColor"
    />
    <path
      d="M64.8281 108.946C64.8281 110.57 66.1452 111.887 67.7698 111.887C69.3944 111.887 70.7115 110.57 70.7115 108.946C70.7115 107.321 69.3944 106.004 67.7698 106.004C66.1452 106.004 64.8281 107.321 64.8281 108.946Z"
      fill="currentColor"
    />
    <path
      d="M32.4671 100.122C34.0918 100.122 35.4088 101.439 35.4088 103.063V108.947C35.4088 110.571 36.7259 111.888 38.3505 111.888C39.9752 111.888 41.2922 110.571 41.2922 108.947V97.2383C41.2922 95.5814 39.949 94.2383 38.2922 94.2383H32.4671C30.8424 94.2383 29.5254 95.5553 29.5254 97.18C29.5254 98.8046 30.8424 100.122 32.4671 100.122Z"
      fill="currentColor"
    />
    <path
      d="M5.99219 108.946C5.99219 110.57 7.30922 111.887 8.93386 111.887C10.5585 111.887 11.8755 110.57 11.8755 108.946C11.8755 107.321 10.5585 106.004 8.93386 106.004C7.30922 106.004 5.99219 107.321 5.99219 108.946Z"
      fill="currentColor"
    />
    <path
      d="M76.5957 108.888C76.5957 110.544 77.9388 111.888 79.5957 111.888H99.8899C106.517 111.888 111.89 106.515 111.89 99.8876V79.5874C111.89 77.9305 110.547 76.5874 108.89 76.5874H79.5957C77.9388 76.5874 76.5957 77.9305 76.5957 79.5874V108.888ZM82.479 85.4706C82.479 83.8137 83.8221 82.4706 85.479 82.4706H103.006C104.663 82.4706 106.006 83.8137 106.006 85.4706V103.004C106.006 104.661 104.663 106.004 103.006 106.004H85.479C83.8221 106.004 82.479 104.661 82.479 103.004V85.4706ZM79.5957 0.109375C77.9388 0.109375 76.5957 1.45252 76.5957 3.10938V32.4098C76.5957 34.0666 77.9388 35.4098 79.5957 35.4098H108.89C110.547 35.4098 111.89 34.0666 111.89 32.4098V12.1094C111.89 5.48196 106.517 0.109375 99.8899 0.109375H79.5957ZM106.006 26.5262C106.006 28.1831 104.663 29.5262 103.006 29.5262H85.479C83.8221 29.5262 82.479 28.1831 82.479 26.5262V8.99274C82.479 7.33589 83.8221 5.99275 85.479 5.99275H103.006C104.663 5.99275 106.006 7.33589 106.006 8.99275V26.5262Z"
      fill="currentColor"
    />
    <path
      d="M32.4674 47.1802C30.8427 47.1802 29.5257 48.4973 29.5257 50.122C29.5257 51.7467 28.2086 53.0638 26.5839 53.0638H20.7008C19.0761 53.0638 17.759 51.7467 17.759 50.122C17.759 48.4973 19.076 47.1802 20.7007 47.1802C22.3253 47.1802 23.6423 45.8632 23.6423 44.2385C23.6423 42.6139 22.3253 41.2969 20.7007 41.2969H8.93386C7.30922 41.2969 5.99219 42.6139 5.99219 44.2385C5.99219 45.8632 7.30922 47.1802 8.93386 47.1802C10.5585 47.1802 11.8755 48.4973 11.8755 50.1219V56.0053C11.8755 57.63 13.1926 58.947 14.8173 58.947C16.4419 58.947 17.759 60.2641 17.759 61.8888C17.759 63.5134 19.076 64.8305 20.7007 64.8305C22.3253 64.8305 23.6423 63.5135 23.6423 61.8888C23.6423 60.2641 24.9594 58.947 26.5841 58.947H32.4091C34.066 58.947 35.4091 57.6039 35.4091 55.947V50.1219C35.4091 48.4973 34.0921 47.1802 32.4674 47.1802Z"
      fill="currentColor"
    />
    <path
      d="M5.99219 61.8832C5.99219 63.5078 7.30922 64.8249 8.93386 64.8249C10.5585 64.8249 11.8755 63.5078 11.8755 61.8832C11.8755 60.2585 10.5585 58.9414 8.93386 58.9414C7.30922 58.9414 5.99219 60.2585 5.99219 61.8832Z"
      fill="currentColor"
    />
    <path
      d="M29.5254 67.7659C29.5254 69.3905 30.8424 70.7076 32.4671 70.7076C34.0917 70.7076 35.4087 69.3905 35.4087 67.7659C35.4087 66.1413 34.0917 64.8242 32.4671 64.8242C30.8424 64.8242 29.5254 66.1413 29.5254 67.7659Z"
      fill="currentColor"
    />
    <path
      d="M44.2362 70.7109H38.3469C36.7239 70.7109 35.4082 72.0266 35.4082 73.6496C35.4082 75.2726 36.7239 76.5883 38.3469 76.5883H44.2362C45.8592 76.5883 47.1749 75.2726 47.1749 73.6496C47.1749 72.0266 45.8592 70.7109 44.2362 70.7109Z"
      fill="currentColor"
    />
    <path
      d="M67.7101 94.2361C69.367 94.2361 70.7101 92.8929 70.7101 91.2361V79.5276C70.7101 77.903 69.3931 76.5859 67.7685 76.5859C66.1438 76.5859 64.8268 77.903 64.8268 79.5276V85.4109C64.8268 87.0356 63.5097 88.3526 61.8851 88.3526C60.2604 88.3526 58.9434 89.6697 58.9434 91.2944C58.9434 92.919 60.2604 94.2361 61.8851 94.2361H67.7101Z"
      fill="currentColor"
    />
    <path
      d="M64.8281 26.5823C64.8281 28.2069 66.1452 29.524 67.7698 29.524C69.3944 29.524 70.7115 28.2069 70.7115 26.5823C70.7115 24.9577 69.3944 23.6406 67.7698 23.6406C66.1452 23.6406 64.8281 24.9577 64.8281 26.5823Z"
      fill="currentColor"
    />
    <path
      d="M97.1281 88.3516H91.3613C89.7045 88.3516 88.3613 89.6947 88.3613 91.3516V97.1183C88.3613 98.7751 89.7045 100.118 91.3613 100.118H97.1281C98.785 100.118 100.128 98.7751 100.128 97.1183V91.3516C100.128 89.6947 98.785 88.3516 97.1281 88.3516Z"
      fill="currentColor"
    />
    <path
      d="M97.1281 11.8789H91.3613C89.7045 11.8789 88.3613 13.2221 88.3613 14.8789V20.6456C88.3613 22.3025 89.7045 23.6456 91.3613 23.6456H97.1281C98.785 23.6456 100.128 22.3025 100.128 20.6456V14.8789C100.128 13.2221 98.785 11.8789 97.1281 11.8789Z"
      fill="currentColor"
    />
    <path
      d="M100.127 44.2385C100.127 45.8615 101.443 47.1802 103.066 47.1802C104.689 47.1802 106.004 45.8615 106.004 44.2385C106.004 42.6156 104.689 41.2969 103.066 41.2969C101.443 41.2969 100.127 42.6156 100.127 44.2385Z"
      fill="currentColor"
    />
    <path
      d="M100.127 67.7659C100.127 69.3888 101.443 70.7076 103.066 70.7076C104.689 70.7076 106.004 69.3888 106.004 67.7659C106.004 66.1429 104.689 64.8242 103.066 64.8242C101.443 64.8242 100.127 66.1429 100.127 67.7659Z"
      fill="currentColor"
    />
    <path
      d="M26.5855 100.121H14.8186C13.194 100.121 11.877 101.438 11.877 103.063C11.877 104.687 13.194 106.004 14.8186 106.004H26.5855C28.2101 106.004 29.5272 104.687 29.5272 103.063C29.5272 101.438 28.2101 100.121 26.5855 100.121Z"
      fill="currentColor"
    />
    <path
      d="M56.0009 100.122C54.3762 100.122 53.0592 98.8046 53.0592 97.18C53.0592 95.5553 51.7422 94.2383 50.1175 94.2383C48.4929 94.2383 47.1758 95.5553 47.1758 97.18V103.005C47.1758 104.662 48.5189 106.005 50.1758 106.005H61.8843C63.509 106.005 64.826 104.688 64.826 103.063C64.826 101.439 63.509 100.122 61.8843 100.122H56.0009Z"
      fill="currentColor"
    />
    <path
      d="M5.99219 91.2385C5.99219 92.8953 7.33533 94.2385 8.99219 94.2385H26.584C28.2086 94.2385 29.5257 92.9214 29.5257 91.2968C29.5257 89.6721 28.2086 88.355 26.584 88.355H14.8755C13.2187 88.355 11.8755 87.0119 11.8755 85.355V79.5301C11.8755 77.9054 13.1927 76.5883 14.8173 76.5883C16.4419 76.5883 17.759 77.9053 17.759 79.5299C17.759 81.1545 19.076 82.4715 20.7006 82.4715H26.5257C28.1825 82.4715 29.5257 81.1284 29.5257 79.4715V73.7109C29.5257 72.0541 28.1825 70.7109 26.5257 70.7109H8.99219C7.33533 70.7109 5.99219 72.0541 5.99219 73.7109V91.2385Z"
      fill="currentColor"
    />
    <path
      d="M47.1749 79.5275C47.1749 81.1521 45.8579 82.4691 44.2333 82.4691H38.35C36.7253 82.4691 35.4082 83.7862 35.4082 85.4109C35.4082 87.0356 36.7253 88.3526 38.35 88.3526H55.9999C57.6246 88.3526 58.9417 87.0355 58.9417 85.4108C58.9417 83.7862 57.6246 82.4691 56 82.4691C54.3754 82.4691 53.0583 81.1521 53.0583 79.5275C53.0583 77.9029 51.7412 76.5859 50.1166 76.5859C48.492 76.5859 47.1749 77.9029 47.1749 79.5275Z"
      fill="currentColor"
    />
    <path
      d="M53.0605 73.6496C53.0605 75.2726 54.3792 76.5883 56.0022 76.5883C57.6252 76.5883 58.9438 75.2726 58.9438 73.6496C58.9438 72.0266 57.6252 70.7109 56.0022 70.7109C54.3792 70.7109 53.0605 72.0266 53.0605 73.6496Z"
      fill="currentColor"
    />
    <path
      d="M82.4771 61.8831C82.4771 60.2585 81.1601 58.9414 79.5354 58.9414C77.9108 58.9414 76.5938 60.2584 76.5938 61.8831V67.7083C76.5938 69.3651 77.9369 70.7083 79.5938 70.7083H85.4189C87.0435 70.7083 88.3605 69.3912 88.3605 67.7666C88.3605 66.142 87.0435 64.8249 85.4188 64.8249C83.7942 64.8249 82.4771 63.5078 82.4771 61.8831Z"
      fill="currentColor"
    />
    <path
      d="M82.4771 50.122C82.4771 51.7467 83.7941 53.0638 85.4188 53.0638C87.0435 53.0638 88.3605 54.3809 88.3605 56.0056V61.8305C88.3605 63.4874 89.7037 64.8305 91.3605 64.8305H97.1273C98.7842 64.8305 100.127 63.4874 100.127 61.8305V56.0056C100.127 54.3809 98.8103 53.0638 97.1856 53.0638C95.5609 53.0638 94.2439 51.7467 94.2439 50.122C94.2439 48.4974 92.9268 47.1802 91.3022 47.1802C89.6776 47.1802 88.3605 45.8632 88.3605 44.2386C88.3605 42.6139 87.0435 41.2969 85.4189 41.2969H79.5354C77.9108 41.2969 76.5938 42.6139 76.5938 44.2386C76.5938 45.8632 77.9108 47.1802 79.5354 47.1802C81.1601 47.1802 82.4771 48.4974 82.4771 50.122Z"
      fill="currentColor"
    />
    <path
      d="M53.0605 50.1215C53.0605 51.7461 54.3776 53.0632 56.0022 53.0632C57.6268 53.0632 58.9438 51.7461 58.9438 50.1215C58.9438 48.4968 57.6268 47.1797 56.0022 47.1797C54.3776 47.1797 53.0605 48.4968 53.0605 50.1215Z"
      fill="currentColor"
    />
    <path
      d="M76.5936 50.1214C76.5936 48.4967 75.2765 47.1797 73.6519 47.1797C72.0272 47.1797 70.7101 48.4968 70.7101 50.1215C70.7101 51.7462 69.3931 53.0633 67.7683 53.0633H61.9434C60.2865 53.0633 58.9434 54.4064 58.9434 56.0633V67.7134C58.9434 69.3702 60.2865 70.7134 61.9434 70.7134H67.7101C69.367 70.7134 70.7101 69.3702 70.7101 67.7134V61.8882C70.7101 60.2635 72.0272 58.9465 73.6519 58.9465C75.2765 58.9465 76.5936 57.6294 76.5936 56.0048V50.1214Z"
      fill="currentColor"
    />
    <path
      d="M47.1749 61.8888C47.1749 60.2641 48.492 58.947 50.1167 58.947C51.7413 58.947 53.0583 57.63 53.0583 56.0054C53.0583 54.3808 51.7413 53.0638 50.1167 53.0638C48.492 53.0638 47.1749 51.7468 47.1749 50.1221V44.2969C47.1749 42.64 45.8318 41.2969 44.1749 41.2969H38.3499C36.7252 41.2969 35.4082 42.6139 35.4082 44.2385C35.4082 45.8632 36.7252 47.1802 38.3499 47.1802C39.9745 47.1802 41.2916 48.4973 41.2916 50.1219V56.0053C41.2916 57.63 39.9745 58.947 38.3499 58.947C36.7252 58.947 35.4082 60.2641 35.4082 61.8887C35.4082 63.5134 36.7253 64.8305 38.35 64.8305H44.2331C45.8578 64.8305 47.1749 63.5135 47.1749 61.8888Z"
      fill="currentColor"
    />
    <path
      d="M20.6437 11.8789H14.877C13.2201 11.8789 11.877 13.2221 11.877 14.8789V20.6456C11.877 22.3025 13.2201 23.6456 14.877 23.6456H20.6437C22.3006 23.6456 23.6437 22.3025 23.6437 20.6456V14.8789C23.6437 13.2221 22.3006 11.8789 20.6437 11.8789Z"
      fill="currentColor"
    />
    <path
      d="M64.826 20.7045C64.826 19.0798 63.5089 17.7628 61.8843 17.7628C60.2596 17.7628 58.9426 16.4458 58.9426 14.8211C58.9426 13.1965 60.2596 11.8794 61.8842 11.8794H67.7677C69.3923 11.8794 70.7094 10.5624 70.7094 8.93777C70.7094 7.31313 69.3923 5.99609 67.7677 5.99609H50.1175C48.4928 5.99609 47.1758 7.31313 47.1758 8.93777C47.1758 10.5624 48.4928 11.8794 50.1175 11.8794C51.7421 11.8794 53.0592 13.1965 53.0592 14.8212V20.6462C53.0592 22.3031 54.4024 23.6462 56.0592 23.6462H61.8843C63.509 23.6462 64.826 22.3292 64.826 20.7045Z"
      fill="currentColor"
    />
    <path
      d="M41.293 14.8206C41.293 16.4452 42.61 17.7623 44.2346 17.7623C45.8593 17.7623 47.1763 16.4452 47.1763 14.8206C47.1763 13.1959 45.8593 11.8789 44.2346 11.8789C42.61 11.8789 41.293 13.1959 41.293 14.8206Z"
      fill="currentColor"
    />
    <path
      d="M70.7099 38.3491C70.7099 36.7245 69.3929 35.4075 67.7683 35.4075C66.1436 35.4075 64.8265 34.0904 64.8265 32.4658C64.8265 30.8411 63.5095 29.524 61.8848 29.524C60.2602 29.524 58.9431 30.8411 58.9431 32.4658C58.9431 34.0904 57.6261 35.4075 56.0014 35.4075C54.3768 35.4075 53.0598 34.0905 53.0598 32.4658V26.5823C53.0598 24.9577 51.7427 23.6406 50.118 23.6406C48.4934 23.6406 47.1763 24.9577 47.1763 26.5823C47.1763 28.2069 45.8593 29.524 44.2346 29.524C42.61 29.524 41.293 30.8411 41.293 32.4657C41.293 34.0904 42.61 35.4075 44.2347 35.4075C45.8593 35.4075 47.1763 36.7245 47.1763 38.3491C47.1763 39.9737 48.4933 41.2907 50.1179 41.2907H67.7683C69.3929 41.2907 70.7099 39.9737 70.7099 38.3491Z"
      fill="currentColor"
    />
  </svg>
</template>
