<template>
  <div class="relative">
    <div
      class="absolute bottom-0 h-96 w-full bg-gradient-to-t from-30% to-transparent -z-10 dark:from-navy-800 from-white"
    ></div>
    <div
      class="absolute left-0 h-full w-36 sm:w-80 bg-gradient-to-r to-transparent -z-40 dark:from-navy-800 from-white"
    ></div>
    <div
      class="absolute right-0 h-full w-36 sm:w-80 bg-gradient-to-l to-transparent -z-40 dark:from-navy-800 from-white"
    ></div>
    <div
      class="sm:block absolute w-full bottom-0 h-[400px] -z-50 overflow-hidden"
    >
      <img
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/home/background_lines.svg'
            : '/assets/images/home/background_lines_light.svg',
        ]"
        class="w-full h-full object-cover absolute top-0 left-0"
      />
    </div>
    <div class="sm:block absolute w-full h-full -z-50 overflow-hidden">
      <img
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/home/background_ellipse_1.svg'
            : '/assets/images/home/background_ellipse_1_light.svg',
        ]"
        class="w-full h-full object-cover absolute top-0 left-0"
      />
    </div>
    <div
      class="flex flex-col lg:flex-row sm:px-16 justify-between gap-4 items-center px-4"
    >
      <div class="flex flex-col relative flex-1">
        <div
          class="text-5xl sm:text-[64px] font-bold text-wrap break-words text-left sm:mt-28 mt-12 dark:text-white text-navy-800"
        >
          {{ $t("translation.home.main.trade") }}
        </div>
        <div
          class="mt-6 text-lg sm:text-2xl font-medium mb-8 sm:mb-10 dark:text-navy-50 text-navy-300"
        >
          Buy, sell and hold the most popular cryptocurrencies in a simple and
          secure way
        </div>
        <div class="w-full sm:w-52">
          <fill-button size="lg" width="full" @click="handleStartTrading"
            >Start Trading</fill-button
          >
        </div>
        <div class="mt-8 hidden sm:flex flex-col gap-4">
          <div class="text-sm font-medium dark:text-navy-100 text-navy-300">
            Or Connect With
          </div>
          <div class="flex flex-row gap-4">
            <div
              class="rounded-full w-14 h-14 bg-navy-500 text-white flex items-center justify-center"
            >
              <google-icon class="w-6 h-6" />
            </div>
            <div
              class="rounded-full w-14 h-14 bg-navy-500 text-white flex items-center justify-center"
            >
              <apple-store class="w-6 h-6" />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-8 lg:mt-28 -z-20 mb-10 flex-1">
        <img
          class="lg:hidden xl:block"
          :src="
            currentTheme === 'dark'
              ? '/assets/images/home/app_logo.png'
              : '/assets/images/home/app_logo_light.png'
          "
        />
        <img
          class="xl:hidden max-lg:hidden"
          :src="
            currentTheme === 'dark'
              ? '/assets/images/home/phone_small.svg'
              : '/assets/images/home/phone_small_light.svg'
          "
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import AppleStore from "@/assets/icons/home/AppleStoreIcon.vue";
import GoogleIcon from "@/assets/icons/home/GoogleLogoIcon.vue";

const router = useRouter();

const handleStartTrading = () => {
  router.push({ name: "trading" });
};
</script>
