import moment from "moment";
import axios from "@/api/axios";
import { MARKET_CAP_API_KEY, SOCKET_URL } from "@/constants/constants";
import { postHttpRequest, getHttpRequest } from "@/api/api";
import store from "@/store/index";

const state = {
  selectedPair: {},
  mainPairDetailFromMarketCap: {},
  allPairs: [],
  activeCurrencies: {},
  orderBookList: {
    buy: [],
    sell: [],
  },
  orderBookHist: [],
  balancesVolume: {},
  newListings: [],
  openOrderHistory: [],
  orderHistory: [],
  balancesVolumeModified: [],
  allPairsModified: [],
  transactions: [],
  depositHistory: [],
  withdrawHistory: [],
  transferHistory: [],
};

const mutations = {
  SET_SELECTED_PAIR(state, selectedPair) {
    state.selectedPair = selectedPair;
  },
  SET_PAIR_DETAIL(state, pairDetail) {
    state.mainPairDetailFromMarketCap = pairDetail;
  },
  SET_ALL_PAIRS(state, allPairs) {
    state.allPairs = allPairs;
  },
  SET_ACTIVE_CURRENCIES(state, activeCurrencies) {
    state.activeCurrencies = activeCurrencies;
  },
  SET_ORDERBOOK_LIST(state, orderBookList) {
    state.orderBookList = orderBookList;
  },
  SET_ORDERBOOK_HIST(state, orderBookHist) {
    state.orderBookHist = orderBookHist;
  },
  ADD_ORDERBOOK_HIST(state, orderBookHist) {
    state.orderBookHist = [orderBookHist, ...state.orderBookHist];
  },
  SET_BALANCES_VOLUME(state, balancesVolume) {
    state.balancesVolume = balancesVolume;
  },
  SET_BALANCES_VOLUME_MODIFIED(state, balancesVolumeModified) {
    state.balancesVolumeModified = balancesVolumeModified;
  },
  SET_NEW_LISTINGS(state, newListings) {
    state.newListings = newListings;
  },
  SET_OPEN_ORDER_HISTORY(state, openOrderHistory) {
    state.openOrderHistory = openOrderHistory;
  },
  ADD_OPEN_ORDER_HISTORY(state, openOrderHistory) {
    state.openOrderHistory.list = [
      openOrderHistory,
      ...state.openOrderHistory.list,
    ];
  },
  DELETE_OPEN_ORDER_HISTORY(state, orderId) {
    state.openOrderHistory.list = (state.openOrderHistory?.list ?? []).filter(
      (order) => order.id === orderId
    );
  },
  SET_ORDER_HISTORY(state, orderHistory) {
    state.orderHistory = orderHistory;
  },
  ADD_ORDER_HISTORY(state, orderHistory) {
    state.orderHistory.list = [orderHistory, ...state.orderHistory.list];
  },
  SET_ALL_PAIRS_MODIFIED(state, modifiedPairs) {
    state.allPairsModified = modifiedPairs;
  },
  SET_TRANSACTIONS(state, transactions) {
    state.transactions = transactions;
  },
  SET_DEPOSIT_HISTORY(state, depositHistory) {
    state.depositHistory = depositHistory;
  },
  SET_WITHDRAW_HISTORY(state, withdrawHistory) {
    state.withdrawHistory = withdrawHistory;
  },
  SET_TRANSFER_HISTORY(state, transferHistory) {
    state.transferHistory = transferHistory;
  },
};

const actions = {
  setSelectedPair({ commit }, selectedPair) {
    commit("SET_SELECTED_PAIR", selectedPair);
  },
  setAllPairs({ commit }, allPairs) {
    commit("SET_ALL_PAIRS", allPairs);
  },
  setActiveCurrencies({ commit }, activeCurrencies) {
    commit("SET_ACTIVE_CURRENCIES", activeCurrencies);
  },
  setOrderBookList({ commit }, orderBookList) {
    commit("SET_ORDERBOOK_LIST", orderBookList);
  },
  // Fetch pair details from coin market cap
  async fetchPairDetail({ commit }, pairName) {
    const { data } = await axios.get(
      `https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest?symbol=${pairName}`,
      {
        headers: {
          "X-CMC_PRO_API_KEY": MARKET_CAP_API_KEY,
          Accept: "application/json",
        },
      }
    );
    commit("SET_PAIR_DETAIL", data.data[pairName]);
  },
  // Fetch pair data with loading
  async fetchPairData({ commit }) {
    try {
      const data = await getHttpRequest(store)("/api/default/ticker");
      if (data.status) {
        commit("SET_ALL_PAIRS", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  // Fetch pair data without loading (in background)
  async fetchPairDataNonBlocking({ commit }) {
    try {
      const { data } = await axios.get("/api/default/ticker");
      if (data.status) {
        commit("SET_ALL_PAIRS", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  // Fetch active currencies
  async fetchActiveCurrencies({ commit }) {
    try {
      const data = await getHttpRequest(store)(
        "/api/default/active-currencies"
      );
      if (data.status) {
        commit("SET_ACTIVE_CURRENCIES", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  // Fetch Order book
  async fetchOrderBookList({ commit }, pairId, buy = 1, sell = 1, limit = 100) {
    try {
      const { data } = await axios.get(
        `${SOCKET_URL}/book/list?p=5&buy=${buy}&sell=${sell}&limit=${limit}&pair_id=${pairId}`
      );
      commit("SET_ORDERBOOK_LIST", data);
    } catch (error) {
      console.log(error);
    }
  },
  addOrderBookHist({ commit }, orderBookHistItem) {
    commit("ADD_ORDERBOOK_HIST", {
      ...orderBookHistItem,
      time_create: moment(orderBookHistItem.time_create * 1000).format(
        "DD MMM hh:mm"
      ),
    });
  },
  // Fetch Order book history
  async fetchOrderBookHist({ commit }, pairId) {
    try {
      const { data } = await axios.get(
        `${SOCKET_URL}/book/hist?pair_id=${pairId}`
      );
      commit(
        "SET_ORDERBOOK_HIST",
        data.hist.map((item) => ({
          ...item,
          time_create: moment(item.time_create * 1000).format("DD MMM hh:mm"),
        }))
      );
    } catch (error) {
      console.log(error);
    }
  },
  setBalancesVolumeModified({ commit }, balancesVolumeModified) {
    commit("SET_BALANCES_VOLUME_MODIFIED", balancesVolumeModified);
  },
  async fetchBalancesVolume({ commit }) {
    try {
      const { data } = await axios("/v3/users/balances-volume");
      commit("SET_BALANCES_VOLUME", data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchNewListings({ commit }) {
    try {
      const data = await postHttpRequest(store)("/api/default/new-listings");
      if (data.status) {
        commit("SET_NEW_LISTINGS", data.data);
      }
    } catch (error) {
      console.error(error);
    }
  },
  // Fetch open order history
  async fetchOpenOrderHistory({ commit }) {
    try {
      const data = await getHttpRequest(store)("/api/p2p/orders");
      if (data.status) {
        commit("SET_OPEN_ORDER_HISTORY", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  // Fetch filled order history
  async fetchOrderHistory({ commit }) {
    try {
      const data = await getHttpRequest(store)("/api/p2p/history");
      if (data.status) {
        commit("SET_ORDER_HISTORY", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  addOrderHistory({ commit }, orderHistory) {
    commit("ADD_ORDER_HISTORY", orderHistory);
  },
  deleteOpenOrderHistory({ commit }, orderId) {
    commit("DELETE_OPEN_ORDER_HISTORY", orderId);
  },
  addOpenOrderHistory({ commit }, openOrderHistory) {
    commit("ADD_OPEN_ORDER_HISTORY", openOrderHistory);
  },
  setAllPairsModified({ commit }, modifiedPairs) {
    commit("SET_ALL_PAIRS_MODIFIED", modifiedPairs);
  },
  async fetchTransactions({ commit }, { page = 1, currency_id, type }) {
    try {
      commit("SET_TRANSACTIONS", { list: [], total_page: 0 });
      const data = await getHttpRequest(store)(
        `/api/transactions/list?page=${page}${
          currency_id ? "&currency_id=" + currency_id : ""
        }${type ? "&type=" + type : ""}`
      );
      if (data.status) {
        commit("SET_TRANSACTIONS", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async fetchDepositHistory({ commit }, { page = 1, currency_id }) {
    try {
      commit("SET_DEPOSIT_HISTORY", { list: [], total_page: 0 });
      const data = await postHttpRequest(store)("/api/deposit/list-refills", {
        page: page,
        currency_id,
      });
      if (data.status) {
        commit("SET_DEPOSIT_HISTORY", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async fetchWithdrawHistory({ commit }, { page = 1, currency_id }) {
    try {
      commit("SET_WITHDRAW_HISTORY", { list: [], total_page: 0 });
      const data = await postHttpRequest(store)("/api/deposit/list-withdraws", {
        page: page,
        currency_id,
      });
      if (data.status) {
        commit("SET_WITHDRAW_HISTORY", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async fetchTransferHistory({ commit }, { page = 1, currency_id = 451 }) {
    try {
      commit("SET_TRANSFER_HISTORY", { list: [], total_page: 0 });
      const data = await postHttpRequest(store)("/api/deposit/list-transfers", {
        page: page,
        currency_id,
        limit: 20,
        p: 5,
      });
      if (data.status) {
        commit("SET_TRANSFER_HISTORY", data.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
};

const getters = {
  selectedPair: (state) => state.selectedPair,
  mainPairDetailFromMarketCap: (state) => state.mainPairDetailFromMarketCap,
  allPairs: (state) => state.allPairs,
  activeCurrencies: (state) => state.activeCurrencies,
  bankCurrencies: (state) => {
    const currencies = {};
    for (const key in state.activeCurrencies) {
      if (state.activeCurrencies[key].type === 0) {
        currencies[key] = state.activeCurrencies[key];
      }
    }
    return currencies;
  },
  orderBookList: (state) => state.orderBookList,
  orderBookHist: (state) => state.orderBookHist,
  balancesVolume: (state) => state.balancesVolume,
  balancesVolumeModified: (state) => state.balancesVolumeModified,
  newListings: (state) => state.newListings,
  openOrderHistory: (state) => state.openOrderHistory,
  orderHistory: (state) => state.orderHistory,
  allPairsModified: (state) => state.allPairsModified,
  transactions: (state) => state.transactions,
  depositHistory: (state) => state.depositHistory,
  withdrawHistory: (state) => state.withdrawHistory,
  transferHistory: (state) => state.transferHistory,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
