<template>
  <dialog-view
    :is-open="isOpen"
    title="Security Authentication"
    @change-is-open="handleClose"
  >
    <template #content>
      <div class="mt-6">
        <!-- Email -->
        <text-edit
          type="number"
          rounded="xl"
          label="Email authentication"
          placeholder="Enter code"
          v-model="emailPin"
        >
          <template #suffix>
            <div class="shrink-0">
              <link-button
                class="font-semibold"
                label="Send Code"
                color="primary"
              />
            </div>
          </template>
        </text-edit>

        <!-- Phone -->
        <text-edit
          class="mt-6 mb-12"
          type="number"
          rounded="xl"
          label="Email authentication"
          placeholder="Enter code"
          v-model="phonePin"
        >
          <template #suffix>
            <div class="shrink-0">
              <link-button
                class="font-semibold"
                label="Send Code"
                color="primary"
              />
            </div>
          </template>
        </text-edit>

        <fill-button
          size="md"
          width="full"
          color="primary"
          :disabled="isEmpty(emailPin) && isEmpty(phonePin)"
          @click="handleClickConfirm"
          >Confirm</fill-button
        >
      </div>
    </template>
  </dialog-view>
</template>

<script setup>
import { isEmpty } from "@/utils/utils.js";
import { defineProps, defineEmits, ref } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";

defineProps({
  isOpen: Boolean,
});

const emits = defineEmits(["close", "confirm"]);

const emailPin = ref();
const phonePin = ref();

const handleClose = () => {
  emits("close");
};

const handleClickConfirm = () => {
  emits("confirm", {
    emailPin: emailPin.value,
    phonePin: phonePin.value,
  });
};
</script>
