<template>
  <div>
    <p class="text-title">Login</p>
    <div class="sm:h-12 h-6" />
    <underline-tab :items="items" @change="handleChangeSelectedTab" />
    <div class="sm:h-10 h-6" />
    <email-address
      v-if="selectedTab == items[0].id"
      @changeEmail="handleChangeEmail"
      @changePassword="handleChangePassword"
    />

    <phone-number
      v-if="selectedTab == items[1].id"
      @changeEmail="handleChangeEmail"
      @changePassword="handleChangePassword"
      @changePhone="handleChangePhone"
    />

    <q-r-code v-if="selectedTab === items[2].id" />

    <template v-if="selectedTab !== items[2].id">
      <div class="h-6" />
      <div class="text-sm">
        <link-button label="Forgot Password?" @click="handleForgetPassword()" />
      </div>
      <div class="sm:h-10 h-6" />
      <filled-button
        label="Login"
        :disabled="isDisabled"
        :isLoading="isLoading"
        @click="handleShowCapture()"
      />
      <gee-test
        v-if="captchaData.showCaptcha && captchaData.data.gt"
        :gt="captchaData.data.gt"
        :challenge="captchaData.data.challenge"
        :success="captchaData.data.success"
        :onSuccess="handleCaptchaSuccess"
      />
      <div class="h-6" />
      <div class="flex space-x-3 justify-center">
        <label class="text-navy-100">Don't have an account?</label>
        <link-button label="Sign up" @click="handleSignup" />
      </div>
      <div class="h-8" />
      <div class="flex items-center">
        <div class="w-full h-px bg-navy-500"></div>
        <label class="w-full pl-4 text-navy-200 text-center"
          >or continue with</label
        >
        <div class="w-full h-px bg-navy-500"></div>
      </div>
      <div class="h-4" />
      <div class="flex justify-center space-x-4">
        <div
          class="relative w-10 h-10 bg-navy-10 dark:bg-navy-500 rounded-full cursor-pointer"
          @click="handleSigninWithGoogle"
        >
          <img
            class="absolute m-auto inset-0"
            src="@/assets/icons/google.svg"
          />
        </div>

        <div
          class="relative w-10 h-10 bg-navy-10 dark:bg-navy-500 rounded-full cursor-pointer"
          @click="handleSigninWithMac"
        >
          <img
            v-if="currentTheme === 'light'"
            class="absolute m-auto inset-0"
            src="@/assets/icons/mac_black.svg"
          />
          <img
            v-if="currentTheme === 'dark'"
            class="absolute m-auto inset-0"
            src="@/assets/icons/mac_white.svg"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "@/api/axios";
import EmailAddress from "@/views/Auth/Signin/SignInWith/EmailAddress.vue";
import PhoneNumber from "@/views/Auth/Signin/SignInWith/PhoneNumber.vue";
import QRCode from "@/views/Auth/Signin/SignInWith/QRCode.vue";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import GeeTest from "@/components/GeeTest/GeeTest.vue";
import {
  validateEmail,
  validatePassword,
  validatePhoneNumber,
} from "@/views/Auth/validation";
import session_manager from "@/utils/SessionManager";

const items = [
  { id: 1, name: "Email address" },
  { id: 2, name: "Phone Number" },
  { id: 3, name: "QR Code" },
];

const store = useStore();
const router = useRouter();
const selectedTab = ref(1);
const email = ref("");
const phone = ref("");
const password = ref("");
const isLoading = ref(false);

const captchaData = ref({
  showCaptcha: false,
  data: {
    gt: "",
    challenge: "",
    success: 0,
  },
});

const isDisabled = computed(() => {
  if (selectedTab.value === items[0].id) {
    return (
      !validateEmail(email.value) ||
      !validatePassword(password.value).every((item) => item === true)
    );
  } else if (selectedTab.value === items[1].id) {
    return (
      !validatePhoneNumber(phone.value) ||
      !validatePassword(password.value).every((item) => item === true)
    );
  }
  return false;
});

const handleChangeSelectedTab = (tab) => {
  selectedTab.value = tab.id;
};

// Handle Email login
const handleChangeEmail = (newEmail) => {
  email.value = newEmail;
};

const handleChangePhone = (newPhone) => {
  phone.value = newPhone;
};

const handleChangePassword = (newPassword) => {
  password.value = newPassword;
};

const handleForgetPassword = () => {
  router.push({ name: "forget-password" });
};

const handleShowCapture = async () => {
  try {
    isLoading.value = true;
    const res = await axios.get("/api/captcha?p=5");
    isLoading.value = false;
    const { challenge, geettest_h, gt, success } = res.data.data;
    captchaData.value.data = {
      challenge,
      gt,
      success,
      geettest_h,
    };
    captchaData.value.showCaptcha = true;
  } catch (error) {
    console.log(error);
  }
};

const handleCaptchaSuccess = async (result) => {
  try {
    let loginData;
    if (selectedTab.value === items[0].id) {
      loginData = {
        username: email.value,
        password: password.value,
        remember_me: 0,
        geetestChallenge: result.geetest_challenge,
        geetestSecCode: result.geetest_seccode,
        geetestValidate: result.geetest_validate,
        geettest_h: captchaData.value.data.geettest_h,
      };
    } else if (selectedTab.value === items[1].id) {
      loginData = {
        phone: +phone.value,
        password: password.value,
        remember_me: 0,
        geetestChallenge: result.geetest_challenge,
        geetestSecCode: result.geetest_seccode,
        geetestValidate: result.geetest_validate,
        geettest_h: captchaData.value.data.geettest_h,
      };
    }
    isLoading.value = true;
    const res = await axios.post("/api/user/login", loginData);
    isLoading.value = false;
    captchaData.value.showCaptcha = false;

    if (res.data.status) {
      const authInfo = {
        token: res.data.token,
        secret: res.data.data.secret,
        email: res.data.data.email,
        phone: res.data.data.phone,
      };
      const codeInfo = {
        required: res.data.data.required,
        requiredType: res.data.data.required,
        codeSentTo: res.data.data.code_sent_to,
        timeBeforeNewCode: res.data.data.time_before_new_code,
      };
      session_manager.setTokenParsed(authInfo);
      session_manager.set2FactorCodeInfo(codeInfo);
      store.dispatch("setCode", codeInfo);
      store.dispatch("setAuthInfo", authInfo);
      router.push({ name: "verification-code", query: { back: "home" } });
    } else {
      store.dispatch("setToast", {
        type: "error",
        message: res.data.errors.join(", "),
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const handleSignup = () => {
  router.push({ name: "signup" });
};
const handleSigninWithGoogle = () => {
  // Handle Sign in with google
  console.log("sign in with google");
};
const handleSigninWithMac = () => {
  // Handle Sign in with mac
  console.log("sign in with mac");
};
</script>
