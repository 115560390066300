<template>
  <div
    class="w-[774px] h-[296px] rounded-2xl py-2 gap-x-6 gap-y-2 grid grid-cols-2 flex-row-reverse dark:bg-navy-600 border dark:border-navy-400 dark:shadow-md dark:shadow-black/[0.32] bg-white shadow-xl shadow-black/[0.16]"
  >
    <navbar-submenu-item
      v-for="item in moreSubmenuData"
      :key="item.id"
      :icon="item.icon"
      :label="item.label"
      :padding="true"
      :path-name="item.pathName"
      :title="item.title"
      @click="handleMenuItemClick"
    />
  </div>
</template>
<script setup>
import { defineEmits } from "vue";
import BlogIcon from "@/assets/icons/common/BlogIcon.vue";
import LaunchpadIcon from "@/assets/icons/common/LaunchpadIcon.vue";
import HelpIcon from "@/assets/icons/common/HelpIcon.vue";
import RewardsIcon from "@/assets/icons/common/RewardsIcon.vue";
import HandShakeIcon from "@/assets/icons/common/HandShakeIcon.vue";
import AnnouncementIcon from "@/assets/icons/announcement/AnnouncementIcon.vue";
import PeopleIcon from "@/assets/icons/common/PeopleIcon.vue";
import NavbarSubmenuItem from "@/layout/AppNavbar/item/NavbarSubmenuItem.vue";

const emit = defineEmits(["menuClick"]);

const moreSubmenuData = [
  {
    id: 0,
    title: "Blog",
    label: "Blockchain and crypto information",
    icon: BlogIcon,
    pathName: "blog",
  },
  {
    id: 1,
    title: "Launchpad",
    label: "Buy and sell crypto with ease",
    icon: LaunchpadIcon,
    pathName: "launchpad",
  },
  {
    id: 2,
    title: "Help Center",
    label: "Zero fees, no slippage",
    icon: HelpIcon,
    pathName: "help-center",
  },
  {
    id: 3,
    title: "Rewards Hub",
    label: "Complete simple tasks to clain rewards deals",
    icon: RewardsIcon,
  },
  {
    id: 4,
    title: "Affiliate Links",
    label: "Refer friends to earn 20% commision",
    icon: HandShakeIcon,
  },
  {
    id: 5,
    title: "Announcements",
    label: "Latest activites and announcements",
    icon: AnnouncementIcon,
    pathName: "announcement",
  },
  {
    id: 6,
    title: "Career",
    label: "Zero fees, no slippage",
    icon: PeopleIcon,
    pathName: "career",
  },
];

const handleMenuItemClick = () => {
  emit("menuClick");
};
</script>
