<template>
  <div class="">
    <table class="w-full">
      <thead>
        <tr>
          <th
            v-for="item in headerItems"
            :key="item.id"
            :class="`text-navy-200 text-[10px] pb-2 font-medium text-${item.align}`"
          >
            <label>{{ item.label }}</label>
            <chevron-up-down-icon
              class="w-4 h-4 inline cursor-pointer"
              @click="handleOrderBy(item.value)"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in sortedContextItems" :key="item.id" class="text-xs">
          <td
            :class="[
              'text-left',
              item.type === 1 ? 'text-red-500' : 'text-green-400',
            ]"
          >
            {{ item[headerItems[0].value] }}
          </td>
          <td class="text-left">{{ item[headerItems[1].value] }}</td>
          <td class="text-right">{{ item[headerItems[2].value] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { ref, toRefs, defineProps, computed } from "vue";
import { ChevronUpDownIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  headerItems: {
    type: Array,
    required: true,
  },
  contextItems: {
    type: Array,
    required: true,
  },
});

const { contextItems } = toRefs(props);

const isDec = ref(false);
const orderBy = ref(null);

const sortedContextItems = computed(() => {
  if (!orderBy.value) return contextItems.value;
  // Return a new sorted array instead of mutating the original array
  return [...contextItems.value].sort((aItem, bItem) =>
    isDec.value
      ? aItem[orderBy.value].localeCompare(bItem[orderBy.value])
      : bItem[orderBy.value].localeCompare(aItem[orderBy.value])
  );
});

const handleOrderBy = (order) => {
  if (orderBy.value === order) isDec.value = !isDec.value;
  else isDec.value = false;
  orderBy.value = order;
};
</script>
