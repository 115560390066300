import DashboardIcon from "@/assets/icons/profile-sidebar/DashboardIcon.vue";
import IdentityVerificationIcon from "@/assets/icons/profile-sidebar/IdentityVerificationIcon.vue";
import SecurityIcon from "@/assets/icons/profile-sidebar/SecurityIcon.vue";
import SettingsIcon from "@/assets/icons/profile-sidebar/SettingsIcon.vue";
import AddressBookIcon from "@/assets/icons/profile-sidebar/AddressBookIcon.vue";
import LoginHistoryIcon from "@/assets/icons/profile-sidebar/LoginHistoryIcon.vue";

import ProfileDashboard from "@/views/Profile/ProfileDashboard.vue";
import ProfileIdentityVerification from "@/views/Profile/ProfileIdentityVerification.vue";
import ProfileLoginHistory from "@/views/Profile/ProfileLoginHistory.vue";
import ProfileSecurity from "@/views/Profile/ProfileSecurity.vue";
import ProfileSettings from "@/views/Profile/ProfileSettings.vue";
import ProfileAddressBook from "@/views/Profile/ProfileAddressBook.vue";

export const profileSidebarItems = [
  {
    id: 1,
    name: "dashboard",
    path: "dashboard",
    label: "Dashboard",
    icon: DashboardIcon,
    content: ProfileDashboard,
  },
  {
    id: 2,
    name: "identity-verification",
    path: "identity-verification",
    label: "Identity Verification",
    icon: IdentityVerificationIcon,
    content: ProfileIdentityVerification,
  },
  {
    id: 3,
    name: "security",
    path: "security",
    label: "Security",
    icon: SecurityIcon,
    content: ProfileSecurity,
  },
  {
    id: 4,
    name: "settings",
    path: "settings",
    label: "Settings",
    icon: SettingsIcon,
    content: ProfileSettings,
  },
  {
    id: 5,
    name: "address-book",
    path: "address-book",
    label: "Address Book",
    icon: AddressBookIcon,
    content: ProfileAddressBook,
  },
  {
    id: 6,
    name: "login-history",
    path: "login-history",
    label: "Login History",
    icon: LoginHistoryIcon,
    content: ProfileLoginHistory,
  },
];
