<template>
  <div class="relative mt-[100px] sm:mt-[84px] self-center w-full">
    <div class="absolute w-full -z-10">
      <img
        class="mx-auto"
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/home/buy_crypto.svg'
            : '/assets/images/home/buy_crypto_light.svg',
        ]"
      />
    </div>
    <div class="flex flex-col items-center px-4 mt-[108px] sm:mt-32">
      <div class="text-lg font-medium dark:text-navy-100 text-navy-200">
        Simple trading experience
      </div>
      <div
        class="text-[40px] font-semibold mb-12 text-center sm:mb-16 sm:text-5xl dark:text-white text-navy-800"
      >
        Buy crypto in minutes
      </div>
      <div class="flex flex-col max-sm:gap-14 mb-16 sm:flex-row sm:w-full">
        <div class="flex flex-col items-center justify-between sm:basis-1/3">
          <div class="flex flex-row w-full items-center mb-6 justify-center">
            <div class="flex-grow w-full max-sm:hidden" />
            <div
              class="w-16 h-16 rounded-full border border-primary-700 flex justify-center items-center text-xl font-bold flex-shrink-0 dark:text-white dark:bg-navy-800 bg-primary-100 text-navy-800"
            >
              <span>1</span>
            </div>
            <div class="flex-grow w-full custom-line max-sm:hidden" />
          </div>
          <div
            class="text-xl font-medium flex-grow flex items-center justify-center text-center dark:text-white text-navy-800"
          >
            Create an account
          </div>
        </div>
        <div class="flex flex-col items-center justify-between sm:basis-1/3">
          <div class="flex flex-row w-full items-center mb-6 justify-center">
            <div class="flex-grow w-full max-sm:hidden custom-line" />
            <div
              class="w-16 h-16 rounded-full border border-primary-700 flex justify-center items-center text-xl font-bold flex-shrink-0 dark:text-white dark:bg-navy-800 bg-primary-100 text-navy-800"
            >
              <span>2</span>
            </div>
            <div class="flex-grow w-full max-sm:hidden custom-line" />
          </div>
          <div
            class="text-xl font-medium flex-grow flex items-center justify-center text-center dark:text-white text-navy-800"
          >
            Get verified
          </div>
        </div>
        <div class="flex flex-col items-center justify-between sm:basis-1/3">
          <div class="flex flex-row w-full items-center mb-6 justify-center">
            <div class="flex-grow w-full max-sm:hidden custom-line" />
            <div
              class="w-16 h-16 rounded-full border border-primary-700 flex justify-center items-center text-xl font-bold flex-shrink-0 dark:text-white dark:bg-navy-800 bg-primary-100 text-navy-800"
            >
              <span>3</span>
            </div>
            <div class="flex-grow w-full max-sm:hidden" />
          </div>
          <div
            class="text-xl font-medium flex-grow flex items-center justify-center text-center dark:text-white text-navy-800"
          >
            Buy and sell
          </div>
        </div>
      </div>
      <div class="flex flex-row w-full sm:w-48">
        <fill-button size="lg" width="full" @click="goToBuySell">
          Buy crypto
        </fill-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";

const router = useRouter();
const goToBuySell = () => {
  router.push({ name: "buy-sell" });
};
</script>
<style scoped>
.custom-line {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, transparent 50%, #39a3d0 50%);
  background-size: 8px 100%;
}
</style>
