<template>
  <dialog-view :contain-header="false" :is-open="isOpen">
    <template #header>
      <div
        class="w-full h-[260px] bg-gradient-to-t dark:from-orange-800 dark:to-navy-400 from-orange-200 to-white rounded-tl-3xl rounded-tr-3xl"
      >
        <div class="py-4 px-6 flex justify-end">
          <button
            @click="handleCloseDialog"
            class="dark:text-navy-100 text-navy-800 hover:text-navy-200"
          >
            <x-mark-icon class="w-5 h-5" />
          </button>
        </div>
        <div class="flex flex-col items-center justify-center mt-4">
          <token-display color="orange">
            <exclamation-circle-icon class="w-14 h-14" />
          </token-display>
          <p class="text-orange-900 dark:text-white text-xl mt-4">
            Potential risk warning!
          </p>
        </div>
      </div>
    </template>
    <template #content>
      <div>
        <p class="my-8 text-sm font-medium dark:text-white text-navy-300">
          Ensure you’ve selected the correct withdrawal address or account. Once
          the transaction is sent to the blockchain, we can't reverse it.
          <br /><br />Beware of fraud, it can be identified from signs such as
          low prices on prepaid virtual gift cards or requests to deposit funds
          to unknown platforms. EXZi will never ask you to withdraw funds to
          another address.
        </p>
        <check-box
          class="mb-12"
          label="I trust this address and want to continue with this withdrawal"
          @change="handleChange"
        />
        <fill-button
          width="full"
          size="md"
          color="primary"
          :disabled="!isTrust"
          @click="handleClickNext"
          >Next</fill-button
        >
      </div>
    </template>
  </dialog-view>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import TokenDisplay from "@/common/TokenInput/TokenCircleDisplay.vue";
import { ExclamationCircleIcon, XMarkIcon } from "@heroicons/vue/24/outline";

defineProps({
  isOpen: Boolean,
});

const emits = defineEmits(["close", "next"]);
const isTrust = ref(false);

const handleCloseDialog = () => {
  emits("close");
};

const handleClickNext = () => {
  emits("next");
};

const handleChange = (checked) => {
  isTrust.value = checked;
};
</script>
