<template>
  <div class="flex flex-col mt-40 px-4 sm:px-16">
    <div
      class="font-semibold text-[40px] mb-12 sm:mb-16 text-center sm:font-bold sm:text-5xl dark:text-white text-navy-800"
    >
      Why choose EXZi
    </div>
    <div class="sm:hidden max-sm:max-w-[361px] mx-auto">
      <div :class="[currentTheme === 'light' ? 'carousel' : 'carousel-dark']">
        <v-slick-carousel v-bind="homepageLogoCarouselSettings">
          <brand-card
            :icon="item.icon"
            :title="item.title"
            :label="item.label"
            :img-url="item.imgUrl"
            :img-light-url="item.imgLightUrl"
            v-for="item in homepageBrandCardsSliderData"
            :key="item.id"
          />
        </v-slick-carousel>
      </div>
    </div>
    <div class="hidden sm:flex flex-row gap-8 flex-wrap items-stretch">
      <brand-card
        v-for="item in homepageBrandCardsData"
        :key="item.id"
        :icon="item.icon"
        :title="item.title"
        :label="item.label"
        :img-url="item.imgUrl"
        :img-light-url="item.imgLightUrl"
        :large="item.large"
      />
    </div>
  </div>
</template>
<script setup>
import "v-slick-carousel/style.css";
import BrandCard from "@/views/Home/Cards/BrandCard.vue";
import { VSlickCarousel } from "v-slick-carousel";
import SecurityIcon from "@/assets/icons/common/SecurityIcon.vue";
import SmsIcon from "@/assets/icons/common/SmsIcon.vue";
import FingerprintIcon from "@/assets/icons/common/FingerprintIcon.vue";
import EuStarIcon from "@/assets/icons/common/EuStarIcon.vue";

const homepageLogoCarouselSettings = {
  slidesToShow: 4,
  arrows: false,
  dots: true,
};

const homepageBrandCardsData = [
  {
    id: 0,
    icon: SecurityIcon,
    title: "Robust Security",
    label:
      "Our platform provides the best security and the highest standards of legal compliance.",
    imgUrl: "/assets/images/home/robust_security.svg",
    imgLightUrl: "/assets/images/home/robust_security_light.svg",
    large: false,
  },
  {
    id: 1,
    icon: SmsIcon,
    title: "24/7 Support",
    label:
      "Reach out to our support team for assistance, or explore our Help Center.",
    imgUrl: "/assets/images/home/anytime_support.svg",
    imgLightUrl: "/assets/images/home/anytime_support_light.svg",
    large: false,
  },
  {
    id: 2,
    icon: FingerprintIcon,
    title: "Secured by Industry leading Tech.",
    label: "",
    imgUrl: "/assets/images/home/secured_tech.svg",
    imgLightUrl: "/assets/images/home/secured_tech_light.svg",
    large: false,
  },
  {
    id: 3,
    icon: EuStarIcon,
    title: "Authorized in the EU & compliant.",
    label:
      "UAB EXZi Markets is a Virtual Currency Exchange Operator authorized by the Lithuanian Financial Crime Investigation Service (FCIS), incorporated under the Laws of Lithuania with the company number 306143232.",
    imgUrl: "/assets/images/home/eu_compilant.svg",
    imgLightUrl: "/assets/images/home/eu_compilant_light.svg",
    large: true,
  },
];

const homepageBrandCardsSliderData = [
  {
    id: 0,
    icon: SecurityIcon,
    title: "Robust Security",
    label:
      "Our platform provides the best security and the highest standards of legal compliance.",
    imgUrl: "/assets/images/home/robust_security.svg",
    imgLightUrl: "/assets/images/home/robust_security_light.svg",
    large: false,
  },
  {
    id: 1,
    icon: SmsIcon,
    title: "24/7 Support",
    label:
      "Reach out to our support team for assistance, or explore our Help Center.",
    imgUrl: "/assets/images/home/anytime_support.svg",
    imgLightUrl: "/assets/images/home/anytime_support_light.svg",
    large: false,
  },
  {
    id: 2,
    icon: FingerprintIcon,
    title: "Secured by Industry leading Tech.",
    label: "",
    imgUrl: "/assets/images/home/secured_tech.svg",
    imgLightUrl: "/assets/images/home/secured_tech_light.svg",
    large: false,
  },
  {
    id: 3,
    icon: EuStarIcon,
    title: "Authorized in the EU & compliant.",
    label:
      "UAB EXZi Markets is a Virtual Currency Exchange Operator authorized by the Lithuanian Financial Crime Investigation Service (FCIS), incorporated under the Laws of Lithuania with the company number 306143232.",
    imgUrl: "/assets/images/home/eu_compilant_small.svg",
    imgLightUrl: "/assets/images/home/eu_compilant_light.svg",
    large: false,
  },
];
</script>
