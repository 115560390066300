<template>
  <div
    class="w-[330px] rounded-2xl p-4 flex flex-col shadow-xl dark:bg-navy-600 border dark:border-navy-400 dark:shadow-black/[0.32] bg-white shadow-black/[0.16]"
  >
    <asset-balance />
    <div class="mt-6">
      <div class="flex flex-row px-4 justify-between">
        <filled-button
          label="Deposit"
          type="iconTextButton"
          pathName="asset-deposit-crypto"
        >
          <arrow-down-tray-icon class="stroke-2 w-6 h-6" />
        </filled-button>
        <filled-button
          label="Withraw"
          type="iconTextButton"
          pathName="asset-withdraw-crypto"
        >
          <arrow-up-tray-icon class="stroke-2 w-6 h-6" />
        </filled-button>
        <filled-button
          label="Transfer"
          type="iconTextButton"
          @click="goToTransfer"
        >
          <arrows-up-down-icon class="stroke-2 w-6 h-6" />
        </filled-button>
      </div>
    </div>
    <div class="my-4 border dark:border-navy-500 border-navy-10"></div>
    <div class="flex flex-col gap-2">
      <profile-menu-item
        v-for="item in assetMenuData"
        :label="item.label"
        :key="item.id"
        :path-name="item.url"
      />
    </div>
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import ProfileMenuItem from "@/layout/AppNavbar/item/ProfileMenuItem.vue";
import AssetBalance from "@/common/AssetsBalance/AssetBalance.vue";
import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  ArrowsUpDownIcon,
} from "@heroicons/vue/24/outline";

const store = useStore();

const goToTransfer = () => {
  store.dispatch("setTransferData", {});
};

const assetMenuData = [
  {
    id: 0,
    label: "Asset overview",
    url: "asset-overview",
  },
  {
    id: 1,
    label: "Trading account",
    url: "asset-trading",
  },
  {
    id: 2,
    label: "Funding account",
    url: "asset-funding",
  },
  {
    id: 3,
    label: "Buy crypto",
    url: "buy-sell",
  },
];
</script>
