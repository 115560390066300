<template>
  <div class="relative">
    <div class="absolute overflow-y-visible -z-50 w-full top-36">
      <img
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/launchpad/launchpad_background_dark.svg'
            : '/assets/images/launchpad/launchpad_background_light.svg',
        ]"
        class="w-full"
      />
    </div>
    <div class="absolute -z-40 w-full">
      <img
        class="mx-auto"
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/launchpad/launchpad_ellipse_dark.svg'
            : '/assets/images/launchpad/launchpad_ellipse_light.svg',
        ]"
      />
    </div>
    <div
      class="absolute -z-50 h-full right-0 top-0 w-1/2 bg-gradient-to-r from-transparent dark:to-navy-800 to-white"
    />
    <div
      class="absolute -z-50 h-full left-0 top-0 w-1/2 bg-gradient-to-l from-transparent dark:to-navy-800 to-white"
    />
    <div
      class="absolute -z-50 h-screen left-0 top-0 w-full bg-gradient-to-b from-transparent dark:to-navy-800 to-white"
    />
    <div class="mx-auto container w-full flex flex-col items-center px-4">
      <div
        class="flex md:flex-row flex-col justify-between mt-12 md:mt-[104px] w-full items-center"
      >
        <div class="flex flex-col md:items-start items-center max-w-[464px]">
          <div
            class="text-2xl font-bold dark:text-white text-navy-800 md:text-6xl text-wrap"
          >
            Buy USDT in a few steps
          </div>
          <div
            class="mt-2 md:mt-6 text-sm font-medium md:text-xl md:font-normal dark:text-navy-50 text-navy-300"
          >
            Bitcoin, Ethereum, Tether, Solana, and more popular crypto
          </div>
          <div class="flex flex-row md:flex-col md:mt-6 mt-4 gap-3">
            <div class="text-sm font-medium dark:text-navy-200">
              Accepted payment methods:
            </div>
            <img
              :src="[
                currentTheme === 'dark'
                  ? '/assets/images/buy-sell/visa_logo.svg'
                  : '/assets/images/buy-sell/visa_logo_light.svg',
              ]"
              class="md:mt-3.5 w-32"
            />
          </div>
        </div>
        <div
          class="md:w-[432px] w-full border dark:border-navy-300 rounded-3xl flex flex-col dark:bg-navy-500 bg-navy-10 max-md:mt-10"
          :style="
            currentTheme === 'dark'
              ? 'box-shadow: 0px 0px 40px 0px rgba(66, 77, 112, 0.64);'
              : 'box-shadow: 2px 4px 16px 0px rgba(0, 0, 0, 0.08);'
          "
        >
          <div class="h-14 relative">
            <button
              :class="[
                'rounded-tl-3xl h-14 absolute left-0 leftButton w-1/2 ',
                selectedMode === 0
                  ? 'dark:bg-navy-700 dark:text-white bg-white text-navy-800 z-20'
                  : 'dark:bg-navy-500 text-navy-50 bg-navy-10 ',
              ]"
              @click="selectedMode = 0"
            >
              Buy
            </button>
            <button
              :class="[
                'rounded-tr-3xl  h-14  absolute right-0 w-1/2 rightButton',
                selectedMode === 1
                  ? 'dark:bg-navy-700 dark:text-white bg-white text-navy-800 z-20'
                  : 'dark:bg-navy-500 text-navy-50 bg-navy-10 ',
              ]"
              @click="selectedMode = 1"
            >
              Sell
            </button>
          </div>
          <div
            :class="[
              'flex flex-col px-6 py-8 w-full dark:bg-navy-700 bg-white',
              selectedMode === 0
                ? 'rounded-3xl rounded-tl-none'
                : 'rounded-3xl rounded-tr-none',
            ]"
          >
            <dropdown-select
              width="full"
              @select="(item) => handleFromTokenChange(item.id)"
            >
              <template #header="{ handleContentClick, isShowingMenu }">
                <div
                  :class="[
                    'w-full sm:h-20 h-16 items-center flex flex-row justify-between sm:pl-6 pl-4 sm:pr-3 pr-1.5 rounded-2xl border-2',
                    isShowingMenu
                      ? 'border-primary-700'
                      : 'dark:border-navy-400 border-navy-50',
                  ]"
                >
                  <div class="flex flex-col items-start">
                    <div class="text-xs font-medium dark:text-navy-100">
                      Pay
                    </div>
                    <input
                      v-model="payModel"
                      type="number"
                      class="bg-transparent outline-none text-xl font-semibold text-navy-200 placeholder-navy-200 w-full"
                    />
                  </div>
                  <div
                    class="rounded-xl dark:bg-navy-500 bg-primary-200 flex flex-row sm:p-3.5 p-3 items-center shrink-0"
                  >
                    <img class="size-6 mr-4" :src="fromTicker?.icon" />
                    <div class="dark:text-white text-lg font-semibold mr-3">
                      {{ fromTicker?.iso3 }}
                    </div>
                    <div
                      class="dark:text-white text-navy-800 cursor-pointer"
                      @click="handleContentClick"
                    >
                      <chevron-down-icon
                        v-if="!isShowingMenu"
                        class="w-4 h-4 stroke-2"
                      />
                      <chevron-up-icon v-else class="w-4 h-4 stroke-2" />
                    </div>
                  </div>
                </div>
              </template>
              <template #menu="{ handleMenuItemClick }">
                <available-currency-dropdown
                  label="Pay"
                  :currencies="fromTickerSearchableCurrency"
                  @click="handleMenuItemClick"
                />
              </template>
            </dropdown-select>
            <div
              :class="[
                'mt-2 w-full flex flex-row items-center',
                isLoggedIn ? '' : 'hidden',
              ]"
            >
              <div class="rounded-full px-3 py-1 border dark:border-navy-500">
                {{
                  selectedMode === 0
                    ? "32-3,200,000 TRY"
                    : `Available: ${getBalanceForToken(selectedFromTicker)} ${
                        fromTicker?.iso3
                      }`
                }}
              </div>
              <div class="grow" />
              <div
                class="flex flex-row items-center"
                :class="[selectedMode === 1 ? '' : 'hidden']"
              >
                <div
                  class="text-primary-700 text-sm font-semibold mr-6 cursor-pointer"
                  @click="payModel = getBalanceForToken(selectedFromTicker)"
                >
                  Max
                </div>
                <div
                  class="text-primary-700 text-sm font-semibold cursor-pointer"
                >
                  Transfer
                </div>
              </div>
            </div>
            <div class="mt-4 sm:mt-6" />
            <dropdown-select
              width="full"
              @select="(item) => handleToTokenChange(item.id)"
            >
              <template #header="{ handleContentClick, isShowingMenu }">
                <div
                  :class="[
                    'w-full sm:h-20 h-16 items-center flex flex-row justify-between sm:pl-6 pl-4 sm:pr-3 pr-1.5 rounded-2xl border-2',
                    isShowingMenu
                      ? 'border-primary-700'
                      : 'dark:border-navy-400 border-navy-50',
                  ]"
                >
                  <div class="flex flex-col items-start">
                    <div class="text-xs font-medium dark:text-navy-100">
                      Receive
                    </div>
                    <input
                      v-model="receiveModel"
                      type="number"
                      class="bg-transparent outline-none text-xl font-semibold text-navy-200 placeholder-navy-200 w-full"
                    />
                  </div>
                  <div
                    class="rounded-xl dark:bg-navy-500 bg-primary-200 flex flex-row sm:p-3.5 p-3 items-center shrink-0"
                  >
                    <img class="size-6 mr-4" :src="toTicker?.icon" />
                    <div class="dark:text-white text-lg font-semibold mr-3">
                      {{ toTicker?.iso3 }}
                    </div>
                    <div
                      class="dark:text-white text-navy-800 cursor-pointer"
                      @click="handleContentClick"
                    >
                      <chevron-down-icon
                        v-if="!isShowingMenu"
                        class="w-4 h-4 stroke-2"
                      />
                      <chevron-up-icon v-else class="w-4 h-4 stroke-2" />
                    </div>
                  </div>
                </div>
              </template>
              <template #menu="{ handleMenuItemClick }">
                <available-currency-dropdown
                  label="Receive"
                  :currencies="toTickerSearchableCurrency"
                  @click="handleMenuItemClick"
                />
              </template>
            </dropdown-select>
            <div
              class="mt-3 sm:mt-4 w-fit rounded-full py-1.5 px-3 dark:text-white text-sm font-semibold dark:bg-navy-500 text-primary-700 bg-primary-300"
            >
              {{
                isEmpty(toTicker)
                  ? ""
                  : `1 ${toTicker?.iso3} = ${formatNumberWithGroupedDecimals(
                      tokenPrice
                    )} ${fromTicker?.iso3}`
              }}
            </div>
            <div
              class="w-full rounded-2xl border dark:border-navy-400 px-4 h-20 flex flex-row items-center mt-4 sm:mt-6 cursor-pointer"
              @click="handleBankTransferClick"
            >
              <div
                class="rounded-full size-10 flex items-center justify-center dark:bg-navy-500 mr-4 bg-primary-300"
              >
                <bank-icon class="size-6 text-primary-700 dark:text-white" />
              </div>
              <div class="flex flex-col items-start">
                <div
                  class="text-lg font-semibold dark:text-white text-navy-800"
                >
                  Bank Transfer
                </div>
                <div
                  class="text-xs font-medium dark:text-navy-100 text-navy-200"
                >
                  0 Commission
                </div>
              </div>
              <div class="grow" />
              <chevron-right-icon class="size-6 stroke-2" />
            </div>
            <div class="w-full mt-6 sm:mt-8">
              <fill-button
                :color="selectedMode === 0 ? 'green' : 'red'"
                width="full"
                size="md"
                @click="handleButtonClick"
              >
                {{ buttonLabel }}
              </fill-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="mt-32 sm:mt-44 font-bold text-[40px] dark:text-white text-navy-800 text-center"
      >
        Benefits of one-click buy/sell
      </div>
      <div
        class="flex flex-col max-sm:gap-14 sm:flex-row sm:w-full mt-12 sm:mt-16"
      >
        <div class="flex flex-col items-center justify-between sm:basis-1/3">
          <div class="flex flex-row w-full items-center mb-6 justify-center">
            <div class="flex-grow w-full max-sm:hidden" />
            <div
              class="w-16 h-16 rounded-full border border-primary-700 flex justify-center items-center text-xl font-bold flex-shrink-0 dark:text-white dark:bg-navy-800 bg-primary-100 text-navy-800"
            >
              <zero-fee-icon />
            </div>
            <div class="flex-grow w-full custom-line max-sm:hidden" />
          </div>
          <div
            class="text-xl font-medium flex-grow flex items-center justify-center text-center dark:text-white text-navy-800"
          >
            Zero Fee
          </div>
        </div>
        <div class="flex flex-col items-center justify-between sm:basis-1/3">
          <div class="flex flex-row w-full items-center mb-6 justify-center">
            <div class="flex-grow w-full max-sm:hidden custom-line" />
            <div
              class="w-16 h-16 rounded-full border border-primary-700 flex justify-center items-center text-xl font-bold flex-shrink-0 dark:text-white dark:bg-navy-800 bg-primary-100 text-navy-800"
            >
              <fast-forward-icon />
            </div>
            <div class="flex-grow w-full max-sm:hidden custom-line" />
          </div>
          <div
            class="text-xl font-medium flex-grow flex items-center justify-center text-center dark:text-white text-navy-800"
          >
            Super-Fast Settlement
          </div>
        </div>
        <div class="flex flex-col items-center justify-between sm:basis-1/3">
          <div class="flex flex-row w-full items-center mb-6 justify-center">
            <div class="flex-grow w-full max-sm:hidden custom-line" />
            <div
              class="w-16 h-16 rounded-full border border-primary-700 flex justify-center items-center text-xl font-bold flex-shrink-0 dark:text-white dark:bg-navy-800 bg-primary-100 text-navy-800"
            >
              <any-pair-icon />
            </div>
            <div class="flex-grow w-full max-sm:hidden" />
          </div>
          <div
            class="text-xl font-medium flex-grow flex items-center justify-center text-center dark:text-white text-navy-800"
          >
            Any pair
          </div>
        </div>
      </div>
      <div class="flex flex-col my-32 sm:my-52">
        <div
          class="font-semibold text-[40px] text-center sm:text-5xl sm:font-bold dark:text-white text-navy-800"
        >
          Convert USDT(TetherUS)<br />
          to oter currencies
        </div>
        <div class="mt-12 sm:mt-16 relative">
          <img
            :src="[
              currentTheme === 'dark'
                ? '/assets/images/home/portfolio.png'
                : '/assets/images/home/portfolio_light.png',
            ]"
            class="object-none object-top h-[368px]"
          />
          <div
            class="absolute top-0 left-0 h-full w-1/2 bg-gradient-to-r to-transparent z-50 dark:from-navy-800 from-white"
          />
          <div
            class="absolute top-0 right-0 h-full w-1/2 bg-gradient-to-l to-transparent z-50 dark:from-navy-800 from-white"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { formatNumberWithGroupedDecimals, isEmpty } from "@/utils/utils";
import { BTC_ID, USDT_ID } from "@/constants/constants";
import DropdownSelect from "@/components/Dropdown/DropdownSelect.vue";
import AvailableCurrencyDropdown from "@/common/CurrencyDropdown/AvailableCurrencyDropdown.vue";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/vue/24/outline";
import ZeroFeeIcon from "@/assets/icons/common/ZeroFeeIcon.vue";
import FastForwardIcon from "@/assets/icons/common/FastForwardIcon.vue";
import AnyPairIcon from "@/assets/icons/common/AnyPairIcon.vue";
import BankIcon from "@/assets/icons/common/BankIcon.vue";

const store = useStore();
const router = useRouter();
const route = useRoute();
const selectedFromTicker = computed(
  () => getTokenFromName(route.params.mainToken)?.id ?? USDT_ID
);
const selectedToTicker = computed(
  () => getTokenFromName(route.params.secondToken)?.id ?? BTC_ID
);
const activeCurrencies = computed(() => store.getters.activeCurrencies);
const balancesVolume = computed(() => store.getters.balancesVolume);
const isLoggedIn = computed(() => store.getters.isLoggedIn);
const allPairs = computed(() => store.getters.allPairs);

const payModel = ref(0);
const receiveModel = ref(0);
const selectedMode = ref(0);

const buttonLabel = computed(() => {
  if (isLoggedIn.value) {
    if (selectedMode.value === 0) {
      return "Buy";
    } else {
      return "Sell";
    }
  } else {
    return "Login or Sign Up";
  }
});

const fromTicker = computed(() => {
  return searchableCurrencies.value.find(
    (item) => item.id == selectedFromTicker.value
  );
});

const toTicker = computed(() => {
  return searchableCurrencies.value.find(
    (item) => item.id === selectedToTicker.value
  );
});

const searchableCurrencies = computed(() => {
  if (isEmpty(activeCurrencies.value)) {
    return [];
  }

  return Object.values(activeCurrencies.value).map((item) => ({
    icon: item.icon,
    iso3: item.iso3,
    name: item.name,
    id: item.id,
    balance: getBalanceForToken(item.id),
  }));
});

const getBalanceForToken = (token) => {
  if (isEmpty(balancesVolume?.value?.list) || isEmpty(activeCurrencies.value))
    return 0;
  let balance = balancesVolume.value.list.find(
    ({ currency, deposit_type }) =>
      currency.id === token && deposit_type === "spot"
  );
  return (
    balance?.balance_available /
    Math.pow(10, activeCurrencies.value[token].decimal)
  );
};

const getTokenPrice = (first, second) => {
  if (isEmpty(first) || isEmpty(second)) {
    return 0;
  }
  const pair = allPairs.value.find(
    (pair) =>
      pair.name === `${first.iso3}${second.iso3}` ||
      pair.name === `${second.iso3}${first.iso3}`
  );
  if (isEmpty(pair)) return 0;
  if (pair.main.iso3 === second.iso3) {
    return pair.rate_f;
  } else {
    return 1.0 / pair.rate_f;
  }
};

const tokenPrice = computed(() => {
  return getTokenPrice(fromTicker.value, toTicker.value);
});

const fromTickerSearchableCurrency = computed(() =>
  searchableCurrencies.value.filter(
    (item) => item.id !== selectedToTicker.value
  )
);

const toTickerSearchableCurrency = computed(() =>
  searchableCurrencies.value.filter(
    (item) => item.id !== selectedFromTicker.value
  )
);

const getTokenFromName = (name) => {
  return Object.values(activeCurrencies.value).find(
    (item) => item.iso3 === name
  );
};

const getTokenFromId = (id) => {
  return Object.values(activeCurrencies.value).find((item) => item.id === id);
};

const handleFromTokenChange = (tokenId) => {
  router.push({
    name: "buy-sell",
    params: {
      mainToken: getTokenFromId(tokenId).iso3,
      secondToken: toTicker.value.iso3,
    },
  });
};

const handleToTokenChange = (tokenId) => {
  router.push({
    name: "buy-sell",
    params: {
      mainToken: fromTicker.value.iso3,
      secondToken: getTokenFromId(tokenId).iso3,
    },
  });
};

const handleButtonClick = () => {
  if (isLoggedIn.value) {
    if (selectedMode.value === 0) {
      //Buy
    } else {
      //Sell
    }
  } else {
    router.push({ name: "signin" });
  }
};

const handleBankTransferClick = () => {
  if (isLoggedIn.value) {
    if (selectedMode.value === 0) {
      router.push({ name: "asset-deposit-fiat" });
    } else {
      router.push({ name: "asset-withdraw-fiat" });
    }
  }
};
</script>
<style lang="css" scoped>
.leftButton::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 80%;
  height: 100%;
  border-radius: 0 12px 0 0;
  transform: skewX(20deg) translateX(10px);
  transform-origin: top right;
  background-color: inherit;
  z-index: -1;
}

.rightButton::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  border-radius: 12px 0 0 0;
  transform: skewX(-20deg) translateX(-10px);
  transform-origin: top left;
  background-color: inherit;
  z-index: -1;
}
</style>
