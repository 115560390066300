<template>
  <div class="w-full relative">
    <div class="absolute w-full -z-50">
      <img
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/career/career_detail_background_dark.svg'
            : '/assets/images/career/career_detail_background_light.svg',
        ]"
        class="mx-auto"
      />
    </div>
    <div
      class="container w-full mx-auto flex flex-col items-center justify-center px-2"
      v-if="!isEmpty(careerDetailMockData)"
    >
      <div
        class="flex flex-row items-center justify-center gap-2 sm:mt-10 mt-6"
      >
        <div
          class="text-navy-100 text-xs font-medium cursor-pointer"
          @click="router.push({ name: 'career' })"
        >
          Career
        </div>
        <chevron-right-icon class="text-navy-100 w-4 h-4" />
        <div
          class="dark:text-navy-10 text-navy-200 text-xs font-medium cursor-pointer"
        >
          Jobs Detail
        </div>
      </div>
      <div
        class="mt-8 text-center dark:text-white text-navy-800 text-[40px] font-bold"
      >
        {{ careerDetailMockData.title }}
      </div>
      <div class="flex flex-row items-center justify-center gap-4 mt-4">
        <div
          v-for="(item, index) in careerDetailMockData.badges"
          :key="index"
          class="rounded-full dark:bg-navy-400 bg-navy-10 text-navy-300 dark:text-white px-4 py-2.5 text-sm font-semibold"
        >
          {{ item }}
        </div>
      </div>
      <div class="mt-3 text-center text-lg font-medium text-navy-100">
        {{ careerDetailMockData.location }}
      </div>
      <div class="mt-10">
        <fill-button
          color="primary"
          size="lg"
          width="fit"
          @click="handleApplyClick"
        >
          Apply for this job
        </fill-button>
      </div>
      <div
        v-html="careerDetailMockData.text"
        class="mt-12 sm:mt-20 dark:text-navy-50 text-navy-400"
      />
      <div class="mt-12 sm:mt-24">
        <fill-button
          color="primary"
          size="lg"
          width="fit"
          @click="handleApplyClick"
        >
          Apply for this job
        </fill-button>
      </div>
      <div
        class="mt-[104px] sm:mt-[120px] flex flex-col items-center justify-center w-full"
      >
        <div class="dark:text-white text-navy-800 font-bold text-[40px]">
          Hiring process
        </div>
        <div
          class="mt-16 flex flex-col sm:flex-row sm:items-start items-center justify-between w-full max-sm:gap-10"
        >
          <template v-for="item in hireCardData" :key="item.id">
            <div class="flex flex-col max-w-[304px]">
              <img
                :src="
                  currentTheme === 'dark' ? item.darkImgUrl : item.lightImgUrl
                "
                class="w-[88px] h-[88px] mx-auto"
              />
              <div
                class="dark:text-white font-semibold text-lg text-center mt-4"
              >
                {{ item.title }}
              </div>
              <div
                class="dark:text-navy-100 text-navy-300 font-medium text-base text-center mt-1"
              >
                {{ item.content }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div
        class="mt-[104px] sm:mt-[188px] flex-col items-center justify-between mb-52"
      >
        <div class="dark:text-white text-navy-800 text-xl font-semibold">
          Share this job with friends
        </div>
        <div
          class="flex flex-row gap-8 items-center justify-center dark:text-navy-50 text-navy-300 mt-10"
        >
          <telegram-icon class="w-6 h-6" />
          <twitter-icon class="w-6 h-6" />
          <linkedin-icon class="w-6 h-6" />
        </div>
      </div>
    </div>
    <dialog-view
      v-if="showApplyDialog"
      :is-open="showApplyDialog"
      :contain-header="true"
      title="Apply for this job"
      @change-is-open="handleChangeOpenDialog"
    >
      <template #content>
        <div class="dark:text-white text-navy-800 flex flex-col">
          <div class="text-lg font-semibold">
            Affiliate Business Development
          </div>
          <div
            class="flex sm:flex-row flex-col items-center justify-center w-full mt-8 gap-6"
          >
            <div class="w-full">
              <text-edit
                label="First Name"
                placeholder="Enter first name"
                rounded="xl"
                :filled="false"
              />
            </div>
            <div class="w-full">
              <text-edit
                label="Last Name"
                placeholder="Enter last name"
                rounded="xl"
                :filled="false"
              />
            </div>
          </div>
          <div class="mt-6">
            <phone-input
              label="Phone number"
              placeholder="Enter your number"
              :filled="false"
              v-model="phoneNumber"
            />
          </div>
          <div class="mt-6">
            <text-edit
              :multiline="true"
              label="Description"
              placeholder="Description"
              rounded="xl"
              :filled="false"
            />
          </div>
          <div class="mt-6">
            <file-select />
          </div>
          <div class="mt-12 w-full">
            <fill-button width="full" @click="handlePerformApplyClick">
              Apply
            </fill-button>
          </div>
        </div>
      </template>
    </dialog-view>
    <dialog-view
      v-if="showSuccessDialog"
      :is-open="showSuccessDialog"
      :contain-header="false"
      @change-is-open="handleChangeSuccessDialog"
    >
      <template #header>
        <div class="flex flex-col">
          <div class="relative w-full">
            <div class="absolute top-4 right-4">
              <x-mark-icon
                class="w-8 h-8 dark:text-navy-100 cursor-pointer"
                @click="handleChangeSuccessDialog"
              />
            </div>
            <div class="top-0 -z-50 overflow-x-hidden h-[272px] rounded-t-3xl">
              <img
                :src="[
                  currentTheme === 'dark'
                    ? '/assets/images/career/career_apply_success_dark.svg'
                    : '/assets/images/career/career_apply_success_light.svg',
                ]"
                class="h-full w-auto object-none"
              />
            </div>
            <div class="absolute top-20 w-full">
              <img
                class="mx-auto"
                :src="[
                  currentTheme === 'dark'
                    ? '/assets/images/career/career_apply_check_dark.svg'
                    : '/assets/images/career/career_apply_check_light.svg',
                ]"
              />
              <div
                class="text-xl font-semibold text-white mt-4 mx-auto text-center"
              >
                You have successfully applied!
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <div
          class="flex flex-col items-center justify-center p-6 sm:gap-12 gap-6 w-full"
        >
          <div
            class="text-base font-medium dark:text-white text-navy-800 text-center"
          >
            After reviewing your application, we will get back to you as soon as
            possible. Thank you for your interest in EXZİ.
          </div>
          <div class="w-full">
            <fill-button size="lg" color="primary" width="full"
              >Verify Now
            </fill-button>
          </div>
        </div>
      </template>
    </dialog-view>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { isEmpty, scrollToTop } from "@/utils/utils";
import TextEdit from "@/components/TextEdit/TextEdit.vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import PhoneInput from "@/components/PhoneInput/PhoneInput.vue";
import FileSelect from "@/components/FileSelect/FileSelect.vue";
import LinkedinIcon from "@/assets/icons/common/LinkedinIcon.vue";
import TelegramIcon from "@/assets/icons/common/TelegramIcon.vue";
import TwitterIcon from "@/assets/icons/common/TwitterIcon.vue";
import { ChevronRightIcon, XMarkIcon } from "@heroicons/vue/24/outline";

const showApplyDialog = ref(false);
const showSuccessDialog = ref(false);
const phoneNumber = ref("");
const router = useRouter();

onMounted(async () => {
  scrollToTop();
  try {
    const response = await fetch("/mock/career/career_detail.json");
    const data = await response.json();
    careerDetailMockData.value = data;
  } catch (error) {
    console.error(error);
  }
});

const handleApplyClick = () => {
  showApplyDialog.value = true;
};

const handlePerformApplyClick = () => {
  showApplyDialog.value = false;
  showSuccessDialog.value = true;
};

const handleChangeSuccessDialog = () => {
  showSuccessDialog.value = !showSuccessDialog.value;
};

const handleChangeOpenDialog = () => {
  showApplyDialog.value = !showApplyDialog.value;
};

const careerDetailMockData = ref({});

const hireCardData = [
  {
    id: 1,
    darkImgUrl: "/assets/images/career/hr_interview_dark.svg",
    lightImgUrl: "/assets/images/career/hr_interview_light.svg",
    title: "HR Interview",
    content: "Option to be paid in crypto. Health insurance.",
  },
  {
    id: 2,
    darkImgUrl: "/assets/images/career/case_study_dark.svg",
    lightImgUrl: "/assets/images/career/case_study_light.svg",
    title: "Case Study & Technical Interview",
    content: "Company sponsored holidays Team building activities",
  },
  {
    id: 3,
    darkImgUrl: "/assets/images/career/final_interview_dark.svg",
    lightImgUrl: "/assets/images/career/final_interview_light.svg",
    title: "Final Interview",
    content: "Company sponsored holidays Team building activities",
  },
  {
    id: 4,
    darkImgUrl: "/assets/images/career/welcome_abroad_dark.svg",
    lightImgUrl: "/assets/images/career/welcome_abroad_light.svg",
    title: "Welcome Aboard!",
    content: "Free language classes Relocation supports",
  },
];
</script>
