<template>
  <div
    class="flex flex-row px-3 py-2.5 rounded-xl dark:bg-navy-500 bg-primary-200 text-sm font-medium dark:text-navy-100 text-primary-800 items-center"
  >
    <div class="mr-2 w-5 h-5">
      <information-circle-icon class="mr-2 w-5 h-5" />
    </div>
    <div>{{ props.label }}</div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
import { InformationCircleIcon } from "@heroicons/vue/24/solid";
const props = defineProps(["label"]);
</script>
