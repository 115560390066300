<template>
  <div
    :class="[
      'sm:px-16 px-4 self-center pb-24 pt-4 w-full',
      backgroundColorClass,
    ]"
  >
    <div class="grid grid-cols-1 lg:grid-cols-3">
      <div class="flex flex-col flex-shrink col-span-1 sm:w-fit w-full">
        <div class="flex flex-col">
          <img
            class="w-[104px]"
            :src="[
              currentTheme === 'dark'
                ? '/assets/icons/footer/logo.svg'
                : '/assets/icons/footer/logo_light.svg',
            ]"
            alt="logo"
          />
          <p class="text-lg font-normal dark:text-navy-100 text-navy-400 mt-4">
            Now it's time to trade fearlessly
          </p>
        </div>
        <div class="flex flex-col mt-8">
          <p
            class="text-base font-medium dark:text-navy-100 text-navy-200 mb-3"
          >
            Download apps
          </p>
          <div
            class="w-full dark:border dark:border-navy-600 dark:bg-navy-800 bg-primary-100 rounded-2xl p-4 flex flex-row items-center gap-5 cursor-pointer"
          >
            <apple-store-icon
              class="w-10 h-10 dark:text-white text-primary-700"
            />
            <div class="flex flex-col">
              <p class="text-xs font-medium dark:text-navy-50 text-navy-200">
                Download on the
              </p>
              <p class="text-sm font-semibold dark:text-white text-navy-800">
                Apple Store
              </p>
            </div>
            <div class="grow" />
            <arrow-up-right-icon
              class="w-5 h-5 dark:text-navy-200 text-primary-700 stroke-2"
            />
          </div>
          <div class="mt-3" />
          <div
            class="w-full dark:border dark:border-navy-600 dark:bg-navy-800 bg-primary-100 rounded-2xl p-4 flex flex-row items-center gap-5 cursor-pointer"
          >
            <google-play-icon
              class="w-10 h-10 dark:text-white text-primary-700"
            />
            <div class="flex flex-col">
              <p class="text-xs font-medium dark:text-navy-50 text-navy-200">
                Get it on
              </p>
              <p class="text-sm font-semibold dark:text-white text-navy-800">
                Google Play
              </p>
            </div>

            <div class="grow" />
            <arrow-up-right-icon
              class="w-5 h-5 dark:text-navy-200 text-primary-700 stroke-2"
            />
          </div>
        </div>
        <div class="flex lg:hidden flex-col gap-4 mb-14">
          <p
            class="text-base font-semibold dark:text-navy-100 text-navy-200 mt-8"
          >
            Social
          </p>
          <div class="dark:text-navy-50 text-navy-800 flex flex-row gap-8 mt-3">
            <telegram-icon class="w-6 h-6" />
            <twitter-icon class="w-6 h-6" />
            <linkedin-icon class="w-6 h-6" />
            <instagram-icon class="w-6 h-6" />
          </div>
        </div>
      </div>
      <div
        class="col-span-2 grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4"
      >
        <div
          class="flex flex-col gap-4"
          v-for="(links, index) in footerLinks"
          :key="index"
        >
          <div
            class="text-base sm:text-lg text-navy-100 font-semibold flex flex-row items-center justify-between"
          >
            <p>{{ links.title }}</p>
            <div
              class="block sm:hidden cursor-pointer"
              @click="links.visible = !links.visible"
            >
              <chevron-down-icon
                v-show="!links.visible"
                class="w-4 h-4 text-navy-100 stroke-2"
              ></chevron-down-icon>
              <chevron-up-icon
                v-show="links.visible"
                class="w-4 h-4 text-navy-100 stroke-2"
              ></chevron-up-icon>
            </div>
          </div>
          <div
            class="sm:text-base text-sm font-normal dark:text-navy-50 text-navy-800 hidden sm:flex flex-col gap-2"
          >
            <p
              v-for="(item, index) in links.links"
              :key="index"
              class="cursor-pointer hover:text-primary-700"
            >
              {{ item.sub_title }}
            </p>
          </div>
          <div
            v-show="links.visible"
            class="text-sm font-normal dark:text-navy-50 text-navy-800 flex sm:hidden flex-col gap-2"
          >
            <p
              v-for="(item, index) in links.links"
              :key="index"
              class="cursor-pointer hover:text-primary-700"
            >
              {{ item.sub_title }}
            </p>
          </div>
        </div>
        <div class="hidden lg:flex flex-col gap-4">
          <p class="text-[18px] text-navy-100 font-bold">Social</p>
          <div
            class="text-[16px] dark:text-navy-50 text-navy-800 flex flex-row gap-4"
          >
            <component
              v-for="item in socialIconList"
              :is="item.icon"
              :key="item.id"
              class="w-6 h-6 hover:text-primary-700 cursor-pointer"
            />
          </div>
        </div>

        <div></div>

        <div class="flex flex-col gap-4 -mt-4 sm:mt-4">
          <div
            class="text-base sm:text-lg text-navy-100 font-semibold flex flex-row items-center justify-between"
          >
            <p>{{ partnerLinks.title }}</p>
            <div
              class="block sm:hidden cursor-pointer"
              @click="partnerLinks.visible = !partnerLinks.visible"
            >
              <chevron-down-icon
                v-show="!partnerLinks.visible"
                class="w-4 h-4 text-navy-100 stroke-2"
              ></chevron-down-icon>
              <chevron-up-icon
                v-show="partnerLinks.visible"
                class="w-4 h-4 text-navy-100 stroke-2"
              ></chevron-up-icon>
            </div>
          </div>
          <div
            class="sm:text-base text-sm font-normal dark:text-navy-50 text-navy-800 hidden sm:flex flex-col gap-2"
          >
            <p
              v-for="(item, index) in partnerLinks.links"
              :key="index"
              class="cursor-pointer hover:text-primary-700"
            >
              {{ item.sub_title }}
            </p>
          </div>
          <div
            v-show="partnerLinks.visible"
            class="dark:text-navy-50 text-navy-800 text-sm font-normal flex sm:hidden flex-col gap-2"
          >
            <p
              v-for="(item, index) in partnerLinks.links"
              :key="index"
              class="cursor-pointer hover:text-primary-700"
            >
              {{ item.sub_title }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="sm:my-6 my-8 border-t dark:border-t-navy-500 border-t-navy-10"
    ></div>
    <div class="flex flex-col lg:flex-row">
      <div class="flex flex-row items-center shrink-0">
        <div class="flex-shrink-0">
          <img src="@/assets/icons/footer/medal.svg" alt="medal" />
        </div>
        <div class="mx-4">
          <div class="h-4 border-r border-r-navy-300"></div>
        </div>
        <div class="flex-shrink-0">
          <img
            :src="[
              currentTheme === 'dark'
                ? '/assets/icons/footer/hacken.svg'
                : '/assets/icons/footer/hacken_light.svg',
            ]"
            alt="hacken"
          />
        </div>
      </div>
      <div class="text-sm font-normal text-navy-200 lg:mx-[88px] max-lg:mt-8">
        UAB EXZi Markets is a Virtual Currency Exchange Operator authorized by
        the Lithuanian Financial Crime Investigation Service (FCIS),
        incorporated under the Laws of Lithuania with the company number
        306143232. <br />The Trading System has been tested and audited by
        HACKEN
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useRoute } from "vue-router";
import {
  ArrowUpRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/vue/24/outline";
import TelegramIcon from "@/assets/icons/common/TelegramIcon.vue";
import TwitterIcon from "@/assets/icons/common/TwitterIcon.vue";
import LinkIcon from "@/assets/icons/common/LinkIcon.vue";
import InstagramIcon from "@/assets/icons/common/InstagramIcon.vue";
import AppleStoreIcon from "@/assets/icons/common/AppleStoreIcon.vue";
import GooglePlayIcon from "@/assets/icons/common/GooglePlayIcon.vue";
import LinkedinIcon from "@/assets/icons/common/LinkedinIcon.vue";

const route = useRoute();

const backgroundColorClass = computed(() => {
  if (route.name === "trading") {
    return "dark:bg-navy-800 bg-navy-10";
  }
  return "dark:bg-navy-800 bg-white";
});

const socialIconList = [
  {
    id: 0,
    icon: TelegramIcon,
  },
  {
    id: 1,
    icon: TwitterIcon,
  },
  {
    id: 2,
    icon: LinkIcon,
  },
  {
    id: 3,
    icon: InstagramIcon,
  },
];

const footerLinks = ref([
  {
    title: "Services",
    visible: false,
    links: [
      {
        sub_title: "Beginner's Guide",
        url: "",
      },
      {
        sub_title: "Help Center",
        url: "",
      },
      {
        sub_title: "Legal",
        url: "",
      },
      {
        sub_title: "FAQ",
        url: "",
      },
      {
        sub_title: "Contact Us",
        url: "",
      },
    ],
  },
  {
    title: "Company",
    visible: false,
    links: [
      {
        sub_title: "Exchange",
        url: "",
      },
      {
        sub_title: "Market",
        url: "",
      },
      {
        sub_title: "Career",
        url: "",
      },
      {
        sub_title: "Proof of Funds",
        url: "",
      },
      {
        sub_title: "Fees",
        url: "",
      },
    ],
  },
  {
    title: "Community",
    visible: false,
    links: [
      {
        sub_title: "Referral",
        url: "",
      },
      {
        sub_title: "Campaign",
        url: "",
      },
      {
        sub_title: "Bug Bounty",
        url: "",
      },
    ],
  },
]);
const partnerLinks = ref({
  title: "Partners",
  visible: false,
  links: [
    {
      sub_title: "TradingView",
      url: "",
    },
    {
      sub_title: "Ondato",
      url: "",
    },
    {
      sub_title: "Fireblocks",
      url: "",
    },
  ],
});
</script>
