<template>
  <div class="w-full flex gap-12">
    <div class="w-full">
      <div class="flex h-fit items-center justify-between mb-12">
        <p class="font-semibold text-2xl">Withdraw Fiat</p>
        <outline-button color="primary" size="sm" @click="goToWithdrawCrypto">
          <span>Withdraw Crypto</span>
          <chevron-right-icon class="w-5 h-5" />
        </outline-button>
      </div>
    </div>
    <faq-small />
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import FaqSmall from "@/common/FAQ/FaqSmall.vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";

const router = useRouter();

const goToWithdrawCrypto = () => {
  router.push({ name: "asset-withdraw-crypto" });
};
</script>
