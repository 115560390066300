import AssetOverview from "@/views/Asset/Overview/AssetOverview.vue";
import DashboardIcon from "@/assets/icons/profile-sidebar/DashboardIcon.vue";
import FundingIcon from "@/assets/icons/asset/FundingIcon.vue";
import TradingIcon from "@/assets/icons/asset/TradingIcon.vue";
import DepositIcon from "@/assets/icons/asset/DepositIcon.vue";
import WithdrawIcon from "@/assets/icons/asset/WithdrawIcon.vue";
import TransactionHistoryIcon from "@/assets/icons/asset/TransactionHistoryIcon.vue";
import InvoicesIcon from "@/assets/icons/asset/InvoicesIcon.vue";
import AssetFunding from "@/views/Asset/Funding/AssetFunding.vue";
import AssetDepositCrypto from "@/views/Asset/Deposit/Crypto/AssetDepositCrypto.vue";
import AssetDepositFiat from "@/views/Asset/Deposit/Fiat/AssetDepositFiat.vue";
import AssetWithdrawCrypto from "@/views/Asset/Withdraw/Crypto/AssetWithdrawCrypto.vue";
import AssetWithdrawFiat from "@/views/Asset/Withdraw/Fiat/AssetWithdrawFiat.vue";
import AssetTrading from "@/views/Asset/Trading/AssetTrading.vue";
import AssetTransactionHistory from "@/views/Asset/TransactionHistory/AssetTransactionHistory.vue";

export const assetMenuItems = [
  {
    id: 1,
    name: "asset-overview",
    path: "overview",
    label: "Asset Overview",
    icon: DashboardIcon,
    content: AssetOverview,
  },
  {
    id: 2,
    name: "asset-funding",
    path: "funding",
    label: "Funding",
    icon: FundingIcon,
    content: AssetFunding,
  },
  {
    id: 3,
    name: "asset-trading",
    path: "trading",
    label: "Trading",
    icon: TradingIcon,
    content: AssetTrading,
  },
  {
    id: 4,
    name: "asset-deposit",
    path: "deposit",
    label: "Deposit",
    hasSubItems: true,
    icon: DepositIcon,
    subItems: [
      {
        id: 1,
        name: "asset-deposit-crypto",
        path: "crypto",
        label: "Crypto",
        content: AssetDepositCrypto,
        background:
          "dark:bg-navy-700 dark:border dark:border-navy-500 bg-white dark:text-white text-primary-700",
      },
      {
        id: 2,
        name: "asset-deposit-fiat",
        path: "fiat",
        label: "Fiat",
        content: AssetDepositFiat,
        background:
          "dark:bg-navy-700 dark:border dark:border-navy-500 bg-white dark:text-white text-primary-700",
      },
    ],
  },
  {
    id: 5,
    name: "asset-withdraw",
    path: "withdraw",
    label: "Withdraw",
    icon: WithdrawIcon,
    hasSubItems: true,
    subItems: [
      {
        id: 1,
        name: "asset-withdraw-crypto",
        path: "crypto",
        label: "Crypto",
        content: AssetWithdrawCrypto,
        background:
          "dark:bg-navy-700 dark:border dark:border-navy-500 bg-white dark:text-white text-primary-700",
      },
      {
        id: 2,
        name: "asset-withdraw-fiat",
        path: "fiat",
        label: "Fiat",
        content: AssetWithdrawFiat,
        background:
          "dark:bg-navy-700 dark:border dark:border-navy-500 bg-white dark:text-white text-primary-700",
      },
    ],
  },
  {
    id: 6,
    name: "asset-transaction-history",
    path: "transaction-history/:url?",
    label: "Transaction History",
    icon: TransactionHistoryIcon,
    content: AssetTransactionHistory,
  },
  {
    id: 7,
    name: "asset-invoices",
    path: "invoices",
    label: "Invoices",
    icon: InvoicesIcon,
    content: AssetOverview,
  },
];
