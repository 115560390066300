<template>
  <dialog-view
    :contain-header="true"
    :is-open="model"
    title="Are you sure you want to delete this address?"
    @change-is-open="handleChangeOpen"
    max-size="max-w-[440px]"
  >
    <template #content>
      <div
        class="mt-4 text-sm font-medium dark:text-white text-navy-800 mb-8 w-full text-wrap"
      >
        Are you sure you want to delete from your address book?
      </div>
      <div
        class="w-full flex sm:flex-row flex-col items-center justify-stretch gap-6"
      >
        <div class="w-full">
          <outline-button
            @click="handleCancelClick"
            color="primary"
            size="md"
            width="full"
          >
            Cancel
          </outline-button>
        </div>
        <div class="w-full">
          <fill-button
            @click="handleDeleteClick"
            color="red"
            size="md"
            width="full"
          >
            Yes, delete
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel, defineEmits } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";

const model = defineModel();
const emit = defineEmits(["confirm"]);

const handleCancelClick = () => {
  model.value = false;
};

const handleDeleteClick = () => {
  model.value = false;
  emit("confirm");
};

const handleChangeOpen = () => {
  model.value = !model.value;
};
</script>
