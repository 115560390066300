<template>
  <div class="">
    <favorite-logout v-if="isLoggedIn === false" />
    <favorite-recommend
      v-if="isLoggedIn === true && favoritePairs.length === 0"
      :filter="filter"
    />
    <favorite-table
      v-if="isLoggedIn === true && favoritePairs.length > 0"
      :filter="filter"
    />
  </div>
</template>
<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";
import FavoriteLogout from "@/views/Market/Tabs/Favorite/FavoriteLogout.vue";
import FavoriteRecommend from "@/views/Market/Tabs/Favorite/FavoriteRecommend.vue";
import FavoriteTable from "@/views/Market/Tabs/Favorite/FavoriteTable.vue";

defineProps(["filter"]);
const store = useStore();
const isLoggedIn = computed(() => store.getters.isLoggedIn);
const favoritePairs = computed(() => store.getters.favoritePairs);
</script>
