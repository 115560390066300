<template>
  <div class="max-w-[360px] text-center mx-auto sm:mt-48 mt-32">
    <h1 class="text-3xl font-bold mb-4">404 Page Not Found</h1>
    <p class="text-navy-400 dark:text-navy-100 mb-4">
      The page you are looking for does not exist.
    </p>
    <link-button label="Go to home" @click="handleGoHome" />
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";

const router = useRouter();
const handleGoHome = () => {
  router.push({ name: "home" });
};
</script>
