<template>
  <div class="flex flex-col w-full mb-24">
    <div class="flex flex-row items-center justify-stretch gap-3 w-full">
      <div class="text-2xl font-semibold dark:text-white text-navy-800">
        Login History
      </div>
      <div class="grow" />
      <div class="text-xs font-medium dark:text-white text-navy-300">
        Security
      </div>
      <text-badge
        label="High"
        :icon="ShieldCheckIcon"
        class="bg-green-100 text-green-700 font-semibold text-sm"
      />
    </div>
    <div class="w-full custom-scrollbar mb-12">
      <table class="mt-8 sm:mt-12 table-auto w-full">
        <thead>
          <tr class="h-10">
            <th
              v-for="item in tableHeaderItems"
              :key="item.id"
              :class="[
                'text-sm font-medium dark:text-navy-100 text-navy-200 text-left px-3',
                item.hide === false ? '' : `max-${item.hide}:hidden`,
              ]"
            >
              {{ item.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in loginHistoryList"
            :key="item.login_time"
            class="h-[72px] dark:text-white text-navy-800 text-sm font-medium cursor-pointer dark:hover:bg-navy-700 hover:bg-navy-10"
          >
            <td class="px-3 rounded-l-lg">{{ item.ip_address }}</td>
            <td class="px-3">{{ item.user_agent }}</td>
            <td class="px-3 font-semibold max-lg:hidden">
              {{ item.location }}
            </td>
            <td class="px-3 font-semibold max-sm:hidden">
              {{
                moment(item.login_time * 1000).format("MM.DD.YYYY, hh:mm:ss")
              }}
            </td>
            <td class="px-3 text-red-500 max-md:hidden rounded-r-lg">
              Offline
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination-component
      :total="loginHistoryTotalCountPage"
      :initial-page="1"
      :step-disabled="true"
      step="20"
      @change="handleChangePage"
    />
  </div>
</template>
<script setup>
import { computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import TextBadge from "@/components/Badge/TextBadge.vue";
import PaginationComponent from "@/components/Pagination/PaginationComponent.vue";
import { ShieldCheckIcon } from "@heroicons/vue/24/outline";

const store = useStore();
const loginHistoryList = computed(() => store.getters.loginHistoryList);
const loginHistoryTotalCountPage = computed(
  () => store.getters.loginHistoryTotalCountPage
);

const handleChangePage = ([currentPage]) => {
  console.log(currentPage);
  store.dispatch("fetchLoginHistory", currentPage);
};

onBeforeMount(() => {
  store.dispatch("fetchLoginHistory");
});

const tableHeaderItems = [
  {
    id: 1,
    label: "IP Address",
    hide: false,
  },
  {
    id: 2,
    label: "Login Device",
    hide: false,
  },
  {
    id: 3,
    label: "Location",
    hide: "lg",
  },
  {
    id: 4,
    label: "Time",
    hide: "sm",
  },
  {
    id: 5,
    label: "Status",
    hide: "md",
  },
];
</script>
