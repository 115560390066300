<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.403 2.78881C22.2489 2.6607 22.0663 2.57151 21.8705 2.52876C21.6747 2.48602 21.4715 2.49098 21.278 2.54321C21.0846 2.59545 20.9065 2.69343 20.7588 2.8289C20.6112 2.96437 20.4982 3.13336 20.4296 3.32162L16.9921 12.7607L13.2171 9.10287C13.0903 8.97991 12.9389 8.88521 12.7729 8.82501C12.6068 8.76481 12.4299 8.74048 12.2538 8.75362C12.0777 8.76676 11.9063 8.81707 11.7511 8.90124C11.5958 8.98541 11.4601 9.10152 11.353 9.24193C7.96863 13.6763 6.24988 18.1372 6.24988 22.4997C6.24988 26.1465 7.69853 29.6438 10.2772 32.2225C12.8558 34.8011 16.3532 36.2497 19.9999 36.2497C23.6466 36.2497 27.144 34.8011 29.7226 32.2225C32.3012 29.6438 33.7499 26.1465 33.7499 22.4997C33.7499 13.2107 25.8139 5.62475 22.403 2.78881ZM19.9999 33.7497C17.0172 33.7464 14.1576 32.5601 12.0486 30.451C9.93951 28.342 8.75319 25.4824 8.74988 22.4997C8.74988 19.0622 10.0139 15.5013 12.5093 11.8997L16.6296 15.8966C16.7792 16.0419 16.9628 16.1475 17.1637 16.2037C17.3646 16.2599 17.5763 16.2649 17.7797 16.2183C17.983 16.1717 18.1714 16.075 18.3278 15.9369C18.4841 15.7989 18.6035 15.6239 18.6749 15.4279L22.1546 5.88568C25.6577 9.07475 31.2499 15.2982 31.2499 22.4997C31.2466 25.4824 30.0602 28.342 27.9512 30.451C25.8421 32.5601 22.9825 33.7464 19.9999 33.7497Z"
      fill="currentColor"
    />
  </svg>
</template>
