<template>
  <div class="relative">
    <div class="absolute w-full h-fit -z-50">
      <div
        class="absolute top-0 h-full w-full bg-gradient-to-t dark:from-navy-800 from-30% from-white dark:to-transparent to-60% z-50"
      />
      <div
        class="absolute top-0 h-full w-1/4 bg-gradient-to-r dark:from-navy-800 from-white to-transparent"
      />
      <div
        class="absolute top-0 h-full w-1/4 bg-gradient-to-l dark:from-navy-800 from-white to-transparent right-0"
      />
    </div>
    <div class="absolute -z-50">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/help-center/ellipse-dark.svg'
            : '/assets/images/help-center/ellipse-light.svg'
        "
      />
    </div>
    <div class="container mx-auto w-full px-2 flex flex-col sm:px-16 z-50">
      <div class="mt-10 flex flex-row items-center text-xs font-medium">
        <div
          class="mr-2 dark:text-navy-100 cursor-pointer"
          @click="router.push({ name: 'help-center' })"
        >
          Help Center
        </div>
        <div class="w-4 h-4 mr-2">
          <chevron-right-icon class="w-4 h-4 stroke-2 text-navy-100" />
        </div>
        <div class="text-navy-200 dark:text-navy-10 cursor-pointer">
          Help Center Detail
        </div>
      </div>
      <div class="flex lg:flex-row flex-col mt-6 gap-20">
        <div class="flex flex-col basis-2/3">
          <div class="text-[32px] font-semibold dark:text-white text-navy-800">
            {{ articleData.title }}
          </div>
          <div
            class="mt-6 flex flex-row text-xs font-medium dark:text-navy-100"
          >
            <div>{{ articleDate }}</div>
            <div class="mx-4 border-l border-l-navy-100" />
            <div>Updated on {{ articleDate }}</div>
            <div class="mx-4 border-l border-l-navy-100" />
            <div>3 min read</div>
          </div>
          <div class="flex flex-row gap-4 lg:hidden items-center mt-6">
            <div class="text-sm font-medium text-navy-100">Share</div>
            <div class="flex flex-row gap-4 dark:text-white text-navy-300">
              <div
                class="w-10 h-10 flex items-center justify-center dark:bg-navy-400 rounded-full bg-navy-10"
              >
                <telegram-icon />
              </div>
              <div
                class="w-10 h-10 flex items-center justify-center dark:bg-navy-400 rounded-full bg-navy-10"
              >
                <twitter-icon />
              </div>
              <div
                class="w-10 h-10 flex items-center justify-center dark:bg-navy-400 rounded-full bg-navy-10"
              >
                <linkedin-icon />
              </div>
            </div>
          </div>
          <div class="mt-[72px] lg:hidden">
            <div
              class="text-2xl font-semibold dark:text-white text-navy-800 px-4"
            >
              Top questions
            </div>
            <div
              :class="[
                currentTheme === 'light' ? 'carousel' : 'carousel-dark',
                'mt-6',
              ]"
            >
              <v-slick-carousel v-bind="helpCenterViewPageCarouselSetting">
                <template v-for="item in topQuestionData" :key="item.id">
                  <article-summary-item
                    :heading="item.heading"
                    :update-time="item.time"
                    size="sm"
                    @click="handleMenuItemClick(item.url)"
                  />
                </template>
              </v-slick-carousel>
            </div>
          </div>
          <div class="mt-10" v-html="articleData.text"></div>
        </div>
        <div class="flex flex-col flex-1 max-lg:hidden min-w-[416px]">
          <div class="flex flex-col gap-4">
            <div class="text-sm font-medium text-navy-100">Share</div>
            <div class="flex flex-row gap-4 dark:text-white text-navy-300">
              <div
                class="w-14 h-14 flex items-center justify-center dark:bg-navy-400 rounded-full bg-navy-10"
              >
                <telegram-icon />
              </div>
              <div
                class="w-14 h-14 flex items-center justify-center dark:bg-navy-400 rounded-full bg-navy-10"
              >
                <twitter-icon />
              </div>
              <div
                class="w-14 h-14 flex items-center justify-center dark:bg-navy-400 rounded-full bg-navy-10"
              >
                <linkedin-icon />
              </div>
            </div>
          </div>
          <div class="mt-[72px]">
            <div
              class="text-2xl font-semibold dark:text-white text-navy-800 px-4"
            >
              Top questions
            </div>
            <div class="flex flex-col mt-6 w-full">
              <template v-for="(item, index) in topQuestionData" :key="item.id">
                <div
                  v-if="index !== 0"
                  class="my-2 dark:border-t-navy-500 border-t-navy-10 border-t"
                />
                <article-summary-item
                  :heading="item.heading"
                  :update-time="item.time"
                  size="sm"
                  @click="handleMenuItemClick(item.url)"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { VSlickCarousel } from "v-slick-carousel";
import { isEmpty, scrollToTop } from "@/utils/utils";
import ArticleSummaryItem from "@/views/HelpCenter/Items/ArticleSummaryItem.vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import TelegramIcon from "@/assets/icons/common/TelegramIcon.vue";
import TwitterIcon from "@/assets/icons/common/TwitterIcon.vue";
import LinkedinIcon from "@/assets/icons/common/LinkedinIcon.vue";

const route = useRoute();
const router = useRouter();
const store = useStore();
const articleData = ref({});

const helpCenterViewPageCarouselSetting = {
  slidesToShow: 4,
  arrows: false,
  dots: true,
};

const topQuestionArticles = computed(() => store.getters.topQuestionArticles);

const topQuestionData = computed(() => {
  if (isEmpty(topQuestionArticles.value)) {
    return [];
  }
  return topQuestionArticles.value.map((item, index) => {
    const date = new Date(item.time_create * 1000);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return {
      id: index,
      heading: item.title,
      url: item.url,
      time: `Updated on ${formattedDate}`,
    };
  });
});

const articleDate = computed(() => {
  if (isEmpty(articleData.value)) {
    return;
  }
  const date = new Date(articleData.value.time_create * 1000);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
});

const fetchArticle = async () => {
  // const result = await postHttpRequest(store)("/api/news/view", {
  //   url: url,
  //   lang: "en",
  // });
  // if (result.status === true) {
  //   articleData.value = result.data;
  // }
  const response = await fetch("/mock/help-center/sample_article.json");
  const data = await response.json();
  articleData.value = data;
};

onMounted(async () => {
  scrollToTop();
  store.dispatch("getTopQuestionArticles");
  const url = route.params.url;
  if (isEmpty(url)) {
    return {};
  }
  try {
    fetchArticle(url);
  } catch (error) {
    console.error(error);
  }
});

watch(
  () => route.params.url,
  async () => {
    scrollToTop();
    const url = route.params.url;
    if (isEmpty(url)) {
      return {};
    }
    try {
      fetchArticle(url);
    } catch (error) {
      console.error(error);
    }
  }
);

const handleMenuItemClick = (url) => {
  router.push({
    name: "help-center-article",
    params: {
      url: url,
    },
  });
};
</script>
<style scoped>
.custom-line {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, transparent 50%, #39a3d0 50%);
  background-size: 8px 100%;
}

.carousel :deep(.v-slick-dots li button:before) {
  color: #9ca5c4;
}

.carousel :deep(.v-slick-dots li.active button:before) {
  color: #0e111b;
}

.carousel-dark :deep(.v-slick-dots li button:before) {
  color: #424d70;
}

.carousel-dark :deep(.v-slick-dots li.active button:before) {
  color: #ffffff;
}
</style>
