<template>
  <filter-dialog
    v-model="showFilterDialog"
    @reset="handleResetClick"
    :coin="coinSelect"
    :status="statusSelect"
    :type="typeSelect"
  />
  <div
    class="flex flex-row items-center sm:mt-6 mt-4 gap-4 w-full"
    v-if="props.displaySearchbar"
  >
    <div class="max-sm:hidden">
      <combo-box
        placeholder="Coin"
        :items="coinComboItem"
        v-model="coinSelect"
      />
    </div>
    <div class="max-sm:hidden">
      <combo-box
        placeholder="Type"
        :items="typeComboItem"
        v-model="typeSelect"
      />
    </div>
    <div class="max-sm:hidden">
      <combo-box
        placeholder="Status"
        :items="statusComboItem"
        v-model="statusSelect"
      />
    </div>
    <div class="sm:hidden">
      <outline-button color="primary" size="md" @click="handleFilterClick">
        <funnel-icon class="size-5" />
        <div>Filter</div>
      </outline-button>
    </div>
    <div class="sm:w-[250px] w-full">
      <text-edit
        type="text"
        size="sm"
        rounded="full"
        placeholder="Search"
        :filled="false"
        v-model="searchText"
      >
        <template #prefix>
          <div class="mr-2">
            <magnifying-glass-icon
              :class="[
                searchText.length > 0
                  ? 'dark:text-white text-navy-800'
                  : 'text-navy-200',
                'w-6 h-6',
              ]"
            />
          </div>
        </template>
        <template #suffix v-if="searchText.length > 0">
          <div
            class="ml-2"
            @click="
              () => {
                searchText = '';
              }
            "
          >
            <x-circle-icon class="text-navy-200 stroke-2 w-6 h-6" />
          </div>
        </template>
      </text-edit>
    </div>
    <div class="max-sm:hidden">
      <link-button label="Reset" @click="handleResetClick" />
    </div>
  </div>
  <div class="w-full custom-scrollbar">
    <table class="sm:w-full mt-6 mb-4 table-auto w-max text-sm">
      <thead>
        <tr class="h-10">
          <th
            v-for="item in tableHeaderItems"
            :key="item.id"
            :class="[
              'text-sm font-medium dark:text-navy-100 text-navy-200 px-3',
              `text-${item.align}`,
              item.hide === false ? '' : `max-${item.hide}:hidden`,
            ]"
          >
            {{ item.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in displayData"
          :key="item.id"
          class="h-[72px] dark:hover:bg-navy-700 hover:bg-navy-10 cursor-pointer"
        >
          <td class="px-3 rounded-l-xl">
            {{ moment(item.time_done * 1000).format("MM.DD.YYYY, hh:mm:ss") }}
          </td>
          <td class="px-3">
            <div class="flex items-center">
              <img
                :src="getCurrencyFromId(item.currency_id)?.icon"
                class="w-6 h-6 mr-2 rounded-full"
              />
              <div class="flex flex-col">
                <div
                  class="text-sm font-semibold dark:text-white text-navy-800"
                >
                  {{ item.iso3 }}
                </div>
                <div class="text-xs font-semibold text-navy-100">
                  {{ getCurrencyFromId(item.currency_id)?.name }}
                </div>
              </div>
            </div>
          </td>
          <td class="px-3">
            <div class="flex items-center">
              <img
                :src="getCurrencyFromId(item.currency_id)?.icon"
                class="w-6 h-6 mr-2"
              />
              <div class="flex flex-col">
                <div
                  class="text-sm font-semibold dark:text-white text-navy-800"
                >
                  {{ item.iso3 }}
                </div>
                <div class="text-xs font-semibold text-navy-100">
                  {{ getCurrencyFromId(item.currency_id)?.name }}
                </div>
              </div>
            </div>
          </td>
          <td class="px-3">
            {{ parseFloat(item.amount_f).toFixed(4) }}
          </td>
          <td class="px-3 rounded-r-xl dark:text-navy-100 text-navy-200">
            {{ item.is_done ? "Completed" : "Failed" }}
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="displayData?.length === 0" class="w-full">
      <div class="h-40 flex flex-col items-center justify-center w-full gap-3">
        <div>
          <double-coin-icon class="w-8 h-8 dark:text-navy-400 text-navy-300" />
        </div>
        <div class="text-navy-100 font-semibold text-sm">No Data</div>
      </div>
    </div>
  </div>
  <pagination-component
    class="mt-12"
    v-show="displayData?.length > 0"
    :total="totalPage"
    :initial-page="1"
    :step-disabled="true"
    :step="20"
    @change="handleChangePage"
  />
</template>
<script setup>
import { defineProps, computed, ref, watch, provide, onBeforeMount } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { isEmpty } from "@/utils/utils";
import PaginationComponent from "@/components/Pagination/PaginationComponent.vue";
import FilterDialog from "@/common/TransactionHistory/FilterDialog.vue";
import DoubleCoinIcon from "@/assets/icons/common/DoubleCoinIcon.vue";
import {
  XCircleIcon,
  MagnifyingGlassIcon,
  FunnelIcon,
} from "@heroicons/vue/24/outline";

const props = defineProps({
  displaySearchbar: Boolean,
});
const store = useStore();
const searchText = ref("");
const transactions = computed(() => store.getters.transactions ?? []);
const allPairsList = computed(() => store.getters.activeCurrencies);
const coinSelect = ref(null);
const typeSelect = ref(null);
const statusSelect = ref(null);
const fetchType = "TRADE";

provide("coin", coinSelect);
provide("type", typeSelect);
provide("status", statusSelect);

const displayData = computed(() => {
  if (isEmpty(transactions.value?.list)) {
    return [];
  }
  return transactions.value.list.filter((item) => {
    let statusResult = true;
    if (statusSelect.value?.id === 1) {
      statusResult = item.is_done === true;
    } else if (statusSelect.value?.id === 2) {
      statusResult = item.is_done === false;
    }
    return (
      item.iso3.toLowerCase().includes(searchText.value.toLowerCase()) &&
      statusResult
    );
  });
});

const coinComboItem = computed(() => {
  return Object.values(allPairsList.value).map((item) => ({
    id: item.id,
    label: item.iso3,
  }));
});

const totalPage = computed(() => store.getters.transactions?.totalPage ?? 0);
const handleChangePage = ([currentPage]) => {
  if (!isEmpty(coinSelect.value)) {
    store.dispatch("fetchDepositHistory", {
      page: currentPage,
      currency_id: coinSelect.value.id,
      type: fetchType,
    });
  } else {
    store.dispatch("fetchDepositHistory", {
      page: currentPage,
      type: fetchType,
    });
  }
};

watch(
  () => coinSelect.value,
  (newValue) => {
    if (!isEmpty(newValue)) {
      store.dispatch("fetchTransactions", {
        page: 1,
        currency_id: newValue.id,
        type: fetchType,
      });
    } else {
      store.dispatch("fetchTransactions", {
        page: 1,
        type: fetchType,
      });
    }
  }
);

onBeforeMount(() => {
  store.dispatch("fetchTransactions", {
    page: 1,
    type: fetchType,
  });
});

const activeCurrencies = computed(() => store.getters.activeCurrencies);

const getCurrencyFromId = (id) => {
  if (isEmpty(activeCurrencies.value)) {
    return;
  }
  return activeCurrencies.value[id];
};

const showFilterDialog = ref(false);
const handleFilterClick = () => {
  showFilterDialog.value = true;
};

const handleResetClick = () => {
  coinSelect.value = null;
  typeSelect.value = null;
  statusSelect.value = null;
  searchText.value = "";
};

const typeComboItem = [
  { id: "DEPOSIT", label: "Deposit" },
  { id: "TRADE", label: "Trade" },
  { id: "WITHDRAW", label: "Withdraw" },
  { id: "INTERNAL_TRANSFER", label: "Internal Transfer" },
];

const statusComboItem = [
  { id: 1, label: "Completed" },
  { id: 2, label: "Canceled" },
];

const tableHeaderItems = [
  {
    id: 1,
    label: "Time",
    align: "left",
    hide: false,
  },
  {
    id: 2,
    label: "From",
    align: "left",
    hide: false,
  },
  {
    id: 3,
    label: "To",
    align: "left",
    hide: false,
  },
  {
    id: 4,
    label: "Price",
    align: "left",
    hide: false,
  },
  {
    id: 5,
    label: "Status",
    align: "left",
    hide: false,
  },
];
</script>
