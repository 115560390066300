const local_setting_manager = {
  getLanguage() {
    return localStorage.getItem("lang") ?? "English";
  },
  getCurrency() {
    return localStorage.getItem("currency") ?? "USD";
  },
  getNotifyManageAssets() {
    return localStorage.getItem("notifyManageAssets") === "true" ? true : false;
  },
  getNotifyTrade() {
    return localStorage.getItem("notifyTrade") === "true" ? true : false;
  },
  getNotifyCustomPriceAlert() {
    return localStorage.getItem("notifyCustomPriceAlert") === "true"
      ? true
      : false;
  },
  getNotifyDeleteNewFeature() {
    return localStorage.getItem("notifyDeleteNewFeature") === "true"
      ? true
      : false;
  },
  setLanguage(language) {
    localStorage.setItem("lang", language);
  },
  setCurrency(currency) {
    localStorage.setItem("currency", currency);
  },
  setNotifyManageAssets(value) {
    localStorage.setItem("notifyManageAssets", value);
  },
  setNotifyTrade(value) {
    localStorage.setItem("notifyTrade", value);
  },
  setNotifyCustomPriceAlert(value) {
    localStorage.setItem("notifyCustomPriceAlert", value);
  },
  setNotifyDeleteNewFeature(value) {
    localStorage.setItem("notifyDeleteNewFeature", value);
  },
};

export default local_setting_manager;
