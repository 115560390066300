<template>
  <dialog-view
    :contain-header="false"
    v-if="model"
    :is-open="model"
    bg-color="gray"
  >
    <template #header>
      <div
        class="w-full bg-gradient-to-t dark:from-green-900 dark:to-navy-400 from-orange-200 to-white rounded-tl-3xl rounded-tr-3xl"
      >
        <div class="py-4 px-6 flex justify-end">
          <button
            @click="handleCloseDialog"
            class="dark:text-navy-100 text-navy-800 hover:text-navy-200"
          >
            <x-mark-icon class="w-5 h-5" />
          </button>
        </div>
        <div class="flex flex-col items-center justify-center mt-4">
          <token-circle-display color="green">
            <check-icon class="w-14 h-14" />
          </token-circle-display>
          <p
            class="text-green-900 dark:text-white mt-4 text-center text-base font-semibold"
          >
            {{ headerDescription }}
          </p>
          <p class="mt-4 text-green-800 dark:text-navy-100 text-sm font-medium">
            {{ headerComment }}
          </p>
          <p class="text-xl font-semibold mb-4">
            {{ headerValue }}
          </p>
        </div>
      </div>
    </template>
    <template #content>
      <slot></slot>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel, defineProps } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";
import TokenCircleDisplay from "@/common/TokenInput/TokenCircleDisplay.vue";
import { XMarkIcon, CheckIcon } from "@heroicons/vue/24/outline";

const model = defineModel();
defineProps({
  headerDescription: String,
  headerComment: String,
  headerValue: String,
});

const handleCloseDialog = () => {
  model.value = false;
};
</script>
