<template>
  <div
    class="flex flex-col dark:bg-navy-800 bg-white p-4 rounded-2xl gap-3 dark:shadow-none shadow-xl shadow-black/[0.08] w-[304px] shrink-0 max-sm:hidden h-fit"
  >
    <sidebar-menu-item
      v-for="item in props.menuData"
      :key="item.id"
      :menu-item-data="item"
      :selected="
        !!route.matched.find((matchedItem) => matchedItem.name === item.name)
      "
      @click="handleSelectMenuItem(item)"
    />
  </div>
  <div class="sm:hidden w-full">
    <dropdown-select @select="handleSelectMenuItem" width="full">
      <template #header="{ isShowingMenu, handleContentClick }">
        <div
          @click="handleContentClick"
          class="rounded-lg flex flex-row w-full px-3 py-2.5 dark:bg-navy-500 bg-primary-200 dark:text-white text-primary-700 gap-3"
        >
          <component
            :is="props.menuData[currentSelectedMenuItemIndex].icon"
            class="w-5 h-5"
          />
          <div class="flex-1 text-sm font-medium">
            {{ props.menuData[currentSelectedMenuItemIndex].label }}
          </div>
          <div class="cursor-pointer">
            <chevron-up-icon v-if="isShowingMenu" class="w-5 h-5" />
            <chevron-down-icon v-else class="w-5 h-5" />
          </div>
        </div>
      </template>
      <template #menu="{ handleMenuItemClick }">
        <div
          class="flex flex-col dark:bg-navy-700 bg-white p-2 rounded-2xl gap-3 sm:hidden mt-2 border shadow-lg shadow-black/[0.08] dark:border-none"
        >
          <sidebar-menu-item
            v-for="item in props.menuData"
            :key="item.id"
            :menu-item-data="item"
            @click="handleMenuItemClick(item)"
          />
        </div>
      </template>
    </dropdown-select>
  </div>
</template>
<script setup>
import { defineProps, watch, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import SidebarMenuItem from "@/components/SidebarMenu/SidebarMenuItem.vue";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  menuData: Array,
});

const router = useRouter();
const route = useRoute();

const currentSelectedMenuItemIndex = ref(0);

watch(
  () => route.matched,
  (matched) => {
    currentSelectedMenuItemIndex.value =
      props.menuData?.findIndex((item) => item.name === matched[2]?.name) ?? 0;
  }
);

onMounted(() => {
  currentSelectedMenuItemIndex.value =
    props.menuData?.findIndex((item) => item.name === route.matched[2]?.name) ??
    0;
});

const handleSelectMenuItem = (item) => {
  router.push({ name: item.name });
};
</script>
