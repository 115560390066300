<template>
  <div class="w-full flex flex-col items-center mb-28">
    <div class="text-5xl font-bold w-full text-center">Referral history</div>
    <div class="overflow-auto custom-scrollbar w-full sm:mt-16 mt-12">
      <table class="sm:w-full w-[700px] text-sm">
        <thead class="dark:bg-navy-700 bg-navy-10 h-10">
          <tr class="font-medium dark:text-navy-100 text-navy-200">
            <td
              v-for="(item, index) in headerItems"
              :key="item.id"
              :class="[
                item.align,
                'p-3',
                index === 0 && 'rounded-l-2xl',
                index === headerItems.length - 1 && 'rounded-r-2xl',
              ]"
            >
              {{ item.label }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in referralHistory"
            :key="index"
            class="h-18 my-1"
          >
            <td class="px-3 py-4">
              {{ item.time_register }}
            </td>
            <td class="px-3 py-4">
              {{ item.email }}
            </td>
            <td class="px-3 py-4">
              {{ referralCode }}
            </td>
            <td class="px-3 py-4">30%</td>
            <td class="px-3 py-4">Signed up</td>
            <td class="px-3 py-4">{{ item.bonus.amount_usdt }}USDT</td>
            <td class="text-left px-3 py-4">
              <link-button
                label="Remind"
                @click="handleClickRemind(referralCode)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <template v-if="isEmpty(referralHistory)">
      <div class="my-20">
        <no-open-orders-icon class="mx-auto" />
        <p class="mt-3 font-semibold">No referral history</p>
      </div>
    </template>
  </div>
  <dialog-view
    v-if="isRemindDialogShow"
    :is-open="isRemindDialogShow"
    :contain-header="true"
    title="Send referral email"
    maxSize="max-w-[440px]"
    @change-is-open="handleChangeOpenDialog"
  >
    <template #content>
      <div class="dark:text-white text-navy-800 flex flex-col">
        <div class="text-sm font-medium">
          Remind your invitee (UID: {{ dialogData.code }}) to start trading and
          receive 30% commissions from successful trades.
        </div>
        <div class="mt-12 w-full gap-6 flex sm:flex-row flex-col">
          <outline-button
            width="full"
            @click="handleClickRemindDialogCancel"
            color="primary"
          >
            Cancel
          </outline-button>
          <fill-button width="full" @click="handleClickRemindDialogConfirm">
            Confirm
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import DialogView from "@/components/Dialog/DialogView.vue";
import NoOpenOrdersIcon from "@/assets/icons/common/NoOpenOrders.vue";

const headerItems = [
  { id: 1, label: "Time of Sign up", align: "text-left" },
  { id: 2, label: "Account Name", align: "text-left" },
  { id: 3, label: "Referral Code", align: "text-left" },
  { id: 4, label: "My Commission Fee", align: "text-left" },
  { id: 5, label: "Status", align: "text-left" },
  { id: 6, label: "Commission", align: "text-left" },
  { id: 7, label: "Action", align: "text-left" },
];

const isRemindDialogShow = ref(false);
const store = useStore();
const dialogData = ref({
  code: "",
});
const referralHistory = computed(() => store.getters.referralHistory);
const referralCode = computed(() => store.getters.referralCode);

const handleClickRemind = (code) => {
  dialogData.value = {
    ...dialogData.value,
    code,
  };
  isRemindDialogShow.value = !isRemindDialogShow.value;
};

const handleClickRemindDialogCancel = () => {
  isRemindDialogShow.value = false;
};

const handleClickRemindDialogConfirm = () => {
  isRemindDialogShow.value = false;
  store.dispatch("setToast", {
    type: "success",
    message: "Email sent successfully.",
  });
};

const handleChangeOpenDialog = () => {
  isRemindDialogShow.value = !isRemindDialogShow.value;
};
</script>
