<template>
  <div class="max-w-[416px]">
    <div class="text-title font-semibold">Reset Password</div>
    <div class="mt-3 text-lg font-medium dark:text-navy-100">Almost done!</div>
    <div class="sm:h-10 h-6" />
    <password-input
      label="New Password"
      placeholder="Password"
      v-model="password"
      :error="passwordError"
    />
    <div class="h-6" />
    <password-input
      label="Confirm Password"
      placeholder="Password"
      v-model="confirmPassword"
      :error="confirmPasswordError"
    />
    <div class="h-10" />
    <filled-button
      label="Set Password"
      :disabled="isDisabled"
      :isLoading="isLoading"
      @click="handleSetPassword"
    />
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import axios from "@/api/axios";
import { validatePassword } from "@/views/Auth/validation";

const store = useStore();
const password = ref("");
const confirmPassword = ref("");
const isLoading = ref(false);
const router = useRouter();
const route = useRoute();
const passwordError = computed(() => validatePassword(password.value));
const confirmPasswordError = computed(() => {
  const isValid = validatePassword(confirmPassword.value);
  return isValid.every((item) => item === true)
    ? confirmPassword.value === password.value
      ? isValid
      : "Confirm password is not matched"
    : isValid;
});

const isDisabled = computed(() => {
  return !(
    validatePassword(password.value).every((item) => item === true) &&
    confirmPassword.value === password.value
  );
});

const handleSetPassword = async () => {
  const requestData = {
    password: password.value,
    retry_password: confirmPassword.value,
    code: route.params.key,
  };
  isLoading.value = true;
  const res = await axios.post("/api/user/recovery", requestData);
  isLoading.value = false;
  if (res.data.status) {
    store.dispatch("setToast", {
      type: "success",
      message: "Your password has been updated and you can now log in.",
    });
    router.push({ name: "signin", query: { back: "login" } });
  } else {
    store.dispatch("setToast", {
      type: "error",
      message: Object.values(res.data.errors).join(", "),
    });
  }
  return;
};
</script>
