<template>
  <div class="flex flex-col w-full mb-24">
    <div class="w-full flex flex-row items-center justify-between">
      <div class="text-2xl font-semibold dark:text-white text-navy-800">
        Settings
      </div>
      <div class="flex flex-row items-center gap-3">
        <div class="dark:text-white text-xs font-medium text-navy-300">
          Security
        </div>
        <div
          class="flex flex-row items-center px-2 py-1.5 bg-green-200 text-green-900 rounded-full gap-1"
        >
          <check-badge-icon class="size-5 stroke-2" />
          <div class="text-sm font-semibold">High</div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-full mt-8 sm:mt-12">
      <profile-list-item
        :icon="LanguageIcon"
        title="Language"
        comment="Your Language"
      >
        <list-dropdown
          :items="languageList"
          @change="handleLanguageChange"
          :default-selected="defaultLanguageId"
        />
      </profile-list-item>
      <div
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-6"
      />
      <profile-list-item
        :icon="CurrencyIcon"
        title="Currency"
        comment="Your Currency"
      >
        <list-dropdown
          :items="currencyList"
          @change="handleCurrencyChange"
          :default-selected="defaultCurrencyId"
        />
      </profile-list-item>
      <div
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-6"
      />
      <profile-list-item :icon="ThemeIcon" title="Theme" comment="Your Theme">
        <list-dropdown
          :items="themeData"
          @change="handleThemeChange"
          :default-selected="defaultThemeId"
        />
      </profile-list-item>
      <div
        class="dark:text-white text-navy-800 mt-16 text-xl font-semibold mb-8"
      >
        Notifications
      </div>
      <profile-list-item
        :icon="ManageIcon"
        title="Manage Assets"
        comment="Get notified when you buy, sell, deposit and withdraw assets"
      >
        <switch-component v-model="manageAssetState" />
      </profile-list-item>
      <div
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-6"
      />
      <profile-list-item
        :icon="TradeIcon"
        title="Trade"
        comment="Stay updated on your trading activities"
      >
        <switch-component v-model="tradeState" />
      </profile-list-item>
      <div
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-6"
      />
      <profile-list-item
        :icon="PriceAlertIcon"
        title="Custom Price Alerts"
        comment="Get web and push alerts when crypto reaches your set price"
      >
        <switch-component v-model="customPriceAlertState" />
      </profile-list-item>
      <div
        class="dark:text-white text-navy-800 mt-16 text-xl font-semibold mb-8"
      >
        Market Updates
      </div>
      <profile-list-item
        :icon="PriceAlertIcon"
        title="Delete New Features"
        comment="Get the latest on new tokens and releases"
      >
        <switch-component v-model="deleteNewFeaturesState" />
      </profile-list-item>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { languageList } from "@/constants/constants";
import ProfileListItem from "@/views/Profile/Items/ProfileListItem.vue";
import SwitchComponent from "@/components/Switch/SwitchComponent.vue";
import ListDropdown from "@/views/Profile/Items/ListDropdown.vue";
import CurrencyIcon from "@/assets/icons/common/CurrencyIcon.vue";
import LanguageIcon from "@/assets/icons/common/LanguageIcon.vue";
import ManageIcon from "@/assets/icons/common/ManageIcon.vue";
import PriceAlertIcon from "@/assets/icons/common/PriceAlertIcon.vue";
import ThemeIcon from "@/assets/icons/common/ThemeIcon.vue";
import TradeIcon from "@/assets/icons/common/TradeIcon.vue";
import { CheckBadgeIcon } from "@heroicons/vue/24/outline";
import DarkIcon from "@/assets/icons/common/DarkIcon.vue";
import LightIcon from "@/assets/icons/common/LightIcon.vue";

const store = useStore();

const notifyManageAssets = computed(() => store.getters.notifyManageAssets);
const notifyTrade = computed(() => store.getters.notifyTrade);
const notifyCustomPriceAlert = computed(
  () => store.getters.notifyCustomPriceAlert
);
const notifyDeleteNewFeatures = computed(
  () => store.getters.notifyDeleteNewFeatures
);
const currentLanguage = computed(() => store.getters.currentLanguage);
const currentTheme = computed(() => store.getters.currentTheme);
const currentCurrency = computed(() => store.getters.currency);

const manageAssetState = ref(notifyManageAssets.value);
const tradeState = ref(notifyTrade.value);
const customPriceAlertState = ref(notifyCustomPriceAlert.value);
const deleteNewFeaturesState = ref(notifyDeleteNewFeatures.value);

const defaultThemeId = ref(1);
const defaultLanguageId = ref(1);
const defaultCurrencyId = ref(1);

watch(
  () => manageAssetState.value,
  (newVal) => {
    store.dispatch("setNotifyManageAssets", newVal);
  }
);

watch(
  () => tradeState.value,
  (newVal) => {
    store.dispatch("setNotifyTrade", newVal);
  }
);

watch(
  () => customPriceAlertState.value,
  (newVal) => {
    store.dispatch("setNotifyCustomPriceAlert", newVal);
  }
);

watch(
  () => deleteNewFeaturesState.value,
  (newVal) => {
    store.dispatch("setNotifyDeletenewFeatures", newVal);
  }
);

onMounted(() => {
  if (currentTheme.value === "dark") {
    defaultThemeId.value = 1;
  } else {
    defaultThemeId.value = 2;
  }
  defaultCurrencyId.value =
    currencyList.find((item) => currentCurrency.value === item.label)?.id ?? 1;
  defaultLanguageId.value =
    languageList.find((item) => currentLanguage.value === item.label)?.id ?? 1;
});

const handleThemeChange = (item) => {
  if (item.label === "Dark") {
    store.dispatch("setTheme", "dark");
  } else {
    store.dispatch("setTheme", "light");
  }
};

const handleLanguageChange = (item) => {
  store.dispatch("setLanguage", item.label);
};

const handleCurrencyChange = (item) => {
  store.dispatch("setCurrency", item.label);
};

const currencyList = [
  {
    id: 0,
    label: "USD",
  },
  {
    id: 1,
    label: "CYN",
  },
  {
    id: 2,
    label: "RUB",
  },
  {
    id: 3,
    label: "JPY",
  },
  {
    id: 4,
    label: "EUR",
  },
  {
    id: 5,
    label: "THB",
  },
];

const themeData = [
  {
    id: 1,
    label: "Dark",
    icon: DarkIcon,
  },
  {
    id: 2,
    label: "Light",
    icon: LightIcon,
  },
];
</script>
