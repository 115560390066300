<template>
  <success-dialog
    v-if="model"
    v-model="model"
    header-description="Conversion Successful"
    header-comment="Your transaction price"
    :header-value="`${formatNumber(props.fromTokenAmount)} ${
      props.fromToken.iso3
    }`"
  >
    <div class="flex flex-col dark:bg-navy-500 bg-white rounded-b-3xl">
      <div class="flex flex-col gap-y-3 sm:mb-12 mb-9">
        <div class="flex flex-row items-center justify-between">
          <div class="text-sm font-medium text-navy-100">Price</div>
          <div class="text-sm font-medium dark:text-white text-navy-800">
            {{
              `1 ${props.fromToken.iso3} = ${formatNumber(1 / props.price)} ${
                props.toToken.iso3
              }`
            }}
          </div>
        </div>
        <div class="flex flex-row items-center justify-between">
          <div class="text-sm font-medium text-navy-100">Pay from</div>
          <div class="text-sm font-medium dark:text-white text-navy-800">
            Trading wallet
          </div>
        </div>
        <div class="flex flex-row items-center justify-between">
          <div class="text-sm font-medium text-navy-100">You will receive</div>
          <div class="text-sm font-medium dark:text-white text-navy-800">
            {{ `${formatNumber(toTokenAmount)} ${props.toToken.iso3}` }}
          </div>
        </div>
      </div>
      <div class="flex flex-row w-full gap-x-6">
        <outline-button size="lg" width="full" @click="handleTradeClick">
          Go Trade
        </outline-button>
        <fill-button size="lg" width="full" @click="handleStatusClick">
          View Status
        </fill-button>
      </div>
    </div>
  </success-dialog>
</template>
<script setup>
import SuccessDialog from "@/common/Dialog/SuccessDialog.vue";

import { defineProps, defineEmits, computed, defineModel } from "vue";
import { formatNumber } from "@/utils/utils";

const props = defineProps(["fromToken", "fromTokenAmount", "toToken", "price"]);
const model = defineModel();
const emit = defineEmits(["viewStatusClick", "goTradeClick"]);

const toTokenAmount = computed(() => {
  return props.fromTokenAmount * (1 / props.price);
});

const handleTradeClick = () => {
  model.value = false;
  emit("goTradeClick");
};

const handleStatusClick = () => {
  model.value = false;
  emit("viewStatusClick");
};
</script>
