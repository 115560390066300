<template>
  <div
    :class="[
      'rounded-full flex items-center gap-1 w-fit',
      sizeClass,
      bgClass,
      textClass,
    ]"
  >
    <div v-if="icon" class="flex items-center justify-center">
      <icon />
    </div>
    <p :class="['font-semibold', textSizeClass]">{{ label }}</p>
  </div>
</template>

<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  icon: Object,
  type: String,
  label: String,
  size: String,
});

const sizeClass = computed(() => {
  switch (props.size) {
    case "bg":
      return "px-3 py-2";
    case "md":
    default:
      return "px-2 py-1";
  }
});

const textSizeClass = computed(() => {
  switch (props.size) {
    case "bg":
      return "text-sm";
    case "md":
    default:
      return "text-xs";
  }
});

const bgClass = computed(() => {
  switch (props.type) {
    case "error":
      return "bg-red-200";
    case "warning":
      return "bg-orange-200";
    case "success":
      return "bg-green-200";
    default:
      return "dark:bg-navy-500 bg-navy-10";
  }
});

const textClass = computed(() => {
  switch (props.type) {
    case "error":
      return "text-red-800";
    case "warning":
      return "text-orange-700";
    case "success":
      return "text-green-800";
    default:
      return "dark:text-white text-navy-800";
  }
});
</script>
