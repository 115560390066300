<template>
  <div class="overflow-auto custom-scrollbar">
    <table class="sm:w-full w-[700px] text-sm">
      <thead>
        <tr class="font-medium dark:text-navy-100 text-navy-200">
          <td
            v-for="item in headerItems"
            :key="item.id"
            :class="[item.align, 'p-3']"
          >
            {{ item.label }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="balance in balances" :key="balance.currency.iso3">
          <td class="px-3 py-4">
            <div class="flex items-center gap-2">
              <img :src="balance.icon" class="w-6 h-6 rounded-full" />
              <div>
                <p>{{ balance.currency.iso3 }}</p>
                <p
                  class="font-semibold text-xs dark:text-navy-100 text-navy-200"
                >
                  {{ balance.currency.name }}
                </p>
              </div>
            </div>
          </td>
          <td class="px-3 py-4">
            {{ balance.balance / Math.pow(10, balance.currency.decimal) }}
          </td>
          <td class="px-3 py-4">
            {{ balance.balance_available_f }}
          </td>
          <td class="text-right px-3 py-4">
            <link-button label="Deposit" @click="goToDeposit" />
            <span class="dark:text-navy-300 text-navy-50 mx-3">|</span>
            <link-button label="Withdraw" @click="goToWithdraw" />
            <span class="dark:text-navy-300 text-navy-50 mx-3">|</span>
            <link-button label="Transfer" @click="goToTransfer(balance)" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const headerItems = [
  { id: 1, label: "Coin", align: "text-left" },
  { id: 2, label: "Total Balance", align: "text-left" },
  { id: 3, label: "Available", align: "text-left" },
  { id: 4, label: "Action", align: "text-right" },
];

const store = useStore();
const router = useRouter();

const balances = computed(() =>
  Object.values(store.getters.balancesVolumeModified).filter(
    (item) => item.balance != 0
  )
);

const activeCurrencies = computed(() => store.getters.activeCurrencies);

const goToDeposit = () => {
  router.push({ name: "asset-deposit-crypto" });
};

const goToWithdraw = () => {
  router.push({ name: "asset-withdraw-crypto" });
};

const goToTransfer = (balance) => {
  if (activeCurrencies.value) {
    store.dispatch("setTransferData", {
      currency: activeCurrencies.value[balance.currency.id],
    });
  }
};
</script>
