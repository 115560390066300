<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 custom-scrollbar"
  >
    <div
      :class="[
        ' bg-white border dark:border-navy-400 rounded-3xl shadow-xl w-full m-4',
        maxSize ? maxSize : 'max-w-xl',
        bgColorClass,
      ]"
    >
      <div
        v-if="containHeader"
        class="flex justify-between items-center px-6 py-4"
      >
        <h3 class="text-xl font-semibold dark:text-white text-navy-800">
          {{ title }}
        </h3>
        <button
          @click="closeDialog"
          class="dark:text-navy-100 text-navy-800 hover:text-navy-200"
        >
          <x-mark-icon class="w-5 h-5" />
        </button>
      </div>
      <div v-else>
        <slot name="header"></slot>
      </div>
      <div class="m-6">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  watch,
  onBeforeMount,
  onUnmounted,
} from "vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  title: String,
  isOpen: Boolean,
  containHeader: {
    type: Boolean,
    default: true,
  },
  maxSize: String,
  bgColor: String,
});

const bgColorClass = computed(() => {
  switch (props.bgColor) {
    case "gray":
      return "dark:bg-navy-500";
    case "black":
    default:
      return "dark:bg-navy-700";
  }
});

const emits = defineEmits(["changeIsOpen"]);

const closeDialog = () => {
  emits("changeIsOpen");
};

onBeforeMount(() => {
  if (props.isOpen) {
    document.body.classList.add("no-scroll");
  }
});

onUnmounted(() => {
  document.body.classList.remove("no-scroll");
});

watch(
  () => props.isOpen,
  (newVal) => {
    console.log(props.isOpen);
    if (newVal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }
);
</script>
