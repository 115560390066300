<template>
  <div class="w-full flex flex-row items-center justify-stretch">
    <div
      class="size-14 rounded-full dark:bg-navy-500 bg-primary-300 text-primary-700 dark:text-white mr-4 flex items-center justify-center"
    >
      <component :is="props.icon" />
    </div>
    <div class="flex flex-col items-start gap-1">
      <div class="dark:text-white text-navy-800 text-base font-semibold">
        {{ props.title }}
      </div>
      <div class="dark:text-navy-100 text-navy-200 text-sm font-medium">
        {{ props.comment }}
      </div>
    </div>
    <div class="grow" />
    <slot />
  </div>
</template>
<script setup>
import { defineProps } from "vue";
const props = defineProps({
  icon: Object,
  title: String,
  comment: String,
});
</script>
