<template>
  <dialog-view :is-open="model" :contain-header="false" bg-color="gray">
    <template #header>
      <div
        class="flex flex-col pt-3 pb-4 sm:py-4 sm:px-6 w-full px-4 rounded-t-3xl"
        :style="[
          currentTheme === 'light'
            ? `background-image: url('/assets/images/convert/confirm_convert_modal_light.svg');`
            : `background-image: url('/assets/images/convert/confirm_convert_modal_dark.svg');`,
        ]"
      >
        <div class="flex flex-row justify-center items-center w-full mb-4">
          <div class="text-xl font-semibold dark:text-white text-navy-800">
            Confirm Convert
          </div>
          <div class="flex-grow"></div>
          <div
            class="dark:text-navy-100 text-navy-200 cursor-pointer"
            @click="handleCloseClick"
          >
            <x-mark-icon class="stroke-2 sm:w-6 sm:h-6 w-5 h-5" />
          </div>
        </div>
        <div class="flex flex-row justify-between max-sm:py-2">
          <div class="flex flex-col items-center justify-center">
            <div class="px-8">
              <token-circle-display :img-url="props.fromToken.icon" />
            </div>
            <div
              class="dark:text-navy-200 text-primary-800 text-sm font-medium text-center mt-4"
            >
              From
            </div>
            <div
              class="dark:text-white text-navy-800 text-lg font-semibold text-center"
            >
              {{
                `${formatNumber(props.fromTokenAmount)} ${props.fromToken.iso3}`
              }}
            </div>
          </div>
          <div class="h-full flex-1">
            <img
              src="/assets/images/convert/convert_arrow.svg"
              class="sm:mt-[60px] mt-12 mx-auto"
            />
          </div>
          <div class="flex flex-col items-center justify-center">
            <div class="px-8">
              <token-circle-display :img-url="props.toToken.icon" />
            </div>
            <div
              class="dark:text-navy-200 text-primary-800 text-sm font-medium text-center mt-4"
            >
              To
            </div>
            <div
              class="dark:text-white text-navy-800 text-lg font-semibold text-center text-wrap"
            >
              {{ `${formatNumber(toTokenAmount)} ${props.toToken.iso3}` }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="flex flex-col">
        <div class="flex flex-col gap-y-3 sm:mb-12 mb-9">
          <div class="flex flex-row items-center justify-between">
            <div class="text-sm font-medium text-navy-100">Price</div>
            <div class="text-sm font-medium dark:text-white text-navy-800">
              {{
                `1 ${props.fromToken.iso3} = ${formatNumber(1 / props.price)} ${
                  props.toToken.iso3
                }`
              }}
            </div>
          </div>
          <div class="flex flex-row items-center justify-between">
            <div class="text-sm font-medium text-navy-100">Pay from</div>
            <div class="text-sm font-medium dark:text-white text-navy-800">
              Trading wallet
            </div>
          </div>
          <div class="flex flex-row items-center justify-between">
            <div class="text-sm font-medium text-navy-100">Inverse Price</div>
            <div class="text-sm font-medium dark:text-white text-navy-800">
              {{
                `1 ${props.toToken.iso3} = ${formatNumber(props.price)} ${
                  props.fromToken.iso3
                }`
              }}
            </div>
          </div>
          <div class="flex flex-row items-center justify-between">
            <div class="text-sm font-medium text-navy-100">
              You will receive
            </div>
            <div class="text-sm font-medium dark:text-white text-navy-800">
              {{ `${formatNumber(toTokenAmount)} ${props.toToken.iso3}` }}
            </div>
          </div>
        </div>
        <div
          class="flex flex-row items-center justify-center text-sm font-medium text-navy-100 mb-6"
        >
          Please request a new quote.
        </div>
        <div class="flex flex-row w-full">
          <fill-button size="lg" width="full" @click="emit('convertClick')">
            {{ `Convert (${leftTime}s)` }}
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import {
  defineModel,
  defineProps,
  defineEmits,
  computed,
  onMounted,
  ref,
} from "vue";
import { formatNumber } from "@/utils/utils";
import DialogView from "@/components/Dialog/DialogView.vue";
import TokenCircleDisplay from "@/common/TokenInput/TokenCircleDisplay.vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";

const model = defineModel();
const leftTime = ref(8);
const props = defineProps(["fromToken", "fromTokenAmount", "toToken", "price"]);
const emit = defineEmits(["convertClick"]);

const toTokenAmount = computed(() => {
  return props.fromTokenAmount * (1 / props.price);
});

const handleCloseClick = () => {
  model.value = false;
};

onMounted(() => {
  setInterval(() => {
    if (leftTime.value === 0) {
      model.value = false;
    }
    leftTime.value--;
  }, 1000);
});
</script>
