<template>
  <div :class="['sm:items-stretch sm:flex-1']">
    <div
      :class="[
        'flex rounded-[32px] h-full self-stretch flex-1 sm:min-w-[288px] max-sm:h-[458px] max-sm:justify-between lg:min-w-[400px] dark:bg-navy-600 bg-primary-100',
        props.large === true ? 'flex-row min-h-[308px] @container' : 'flex-col',
      ]"
    >
      <div
        :class="[
          'flex flex-col px-6 sm:px-10 @4xl:basis-7/12',
          props.large === true ? '' : '',
        ]"
      >
        <div class="mb-6 pt-6 dark:text-primary-600 text-primary-700">
          <component :is="icon" class="w-12 h-12" />
        </div>
        <div
          class="sm:text-[32px] font-semibold text-[24px] dark:text-white text-navy-800"
        >
          {{ props.title }}
        </div>
        <div
          v-if="isEmpty(props.label) === false"
          :class="[
            'mt-3',
            props.large === true
              ? 'text-navy-200 text-sm'
              : 'dark:text-white sm:text-lg font-normal text-base text-navy-300',
          ]"
        >
          {{ props.label }}
        </div>
        <div
          v-if="props.large"
          :class="[
            props.large === true
              ? 'items-end flex-row justify-end flex @4xl:hidden'
              : 'mt-auto items-end flex flex-row justify-end',
          ]"
        >
          <img
            :src="[currentTheme === 'dark' ? props.imgUrl : props.imgLightUrl]"
            class="rounded-br-[32px] h-full object-fill"
          />
        </div>
      </div>
      <div
        :class="[
          props.large === true
            ? 'items-end flex-row justify-end hidden @4xl:flex'
            : 'mt-auto items-end flex flex-row justify-end',
        ]"
      >
        <img
          :src="[currentTheme === 'dark' ? props.imgUrl : props.imgLightUrl]"
          class="rounded-br-[32px] h-full object-fill"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
import { isEmpty } from "@/utils/utils";

const props = defineProps([
  "title",
  "label",
  "imgUrl",
  "imgLightUrl",
  "icon",
  "width",
  "height",
  "large",
]);
</script>
