<template>
  <div
    class="p-1 rounded-full dark:bg-navy-800 bg-navy-10 dark:text-white text-navy-100 flex"
  >
    <button
      v-for="item in items"
      :key="item.id"
      :class="[
        'py-2 flex-1 rounded-full text-sm font-semibold',
        selectedTab.id === item.id
          ? `${item.bgColor} ${item.textColor}`
          : 'text-navy-200 dark:text-navy-100',
      ]"
      @click="handleClick(item)"
    >
      {{ item.label }}
    </button>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  items: {
    type: Array,
  },
});

const emit = defineEmits(["select"]);

const selectedTab = ref(props.items[0]);

const handleClick = (item) => {
  selectedTab.value = item;
  emit("select", item);
};
</script>
