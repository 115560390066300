<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 10.875H13.125V5.625H13.5C14.1962 5.625 14.8639 5.90156 15.3562 6.39384C15.8484 6.88613 16.125 7.55381 16.125 8.25C16.125 8.54837 16.2435 8.83452 16.4545 9.0455C16.6655 9.25647 16.9516 9.375 17.25 9.375C17.5484 9.375 17.8345 9.25647 18.0455 9.0455C18.2565 8.83452 18.375 8.54837 18.375 8.25C18.3735 6.95753 17.8594 5.71842 16.9455 4.8045C16.0316 3.89058 14.7925 3.37649 13.5 3.375H13.125V2.25C13.125 1.95163 13.0065 1.66548 12.7955 1.4545C12.5845 1.24353 12.2984 1.125 12 1.125C11.7016 1.125 11.4155 1.24353 11.2045 1.4545C10.9935 1.66548 10.875 1.95163 10.875 2.25V3.375H10.5C9.20707 3.375 7.96709 3.88861 7.05285 4.80285C6.13861 5.71709 5.625 6.95707 5.625 8.25C5.625 9.54293 6.13861 10.7829 7.05285 11.6971C7.96709 12.6114 9.20707 13.125 10.5 13.125H10.875V18.375H9.75C9.05381 18.375 8.38613 18.0984 7.89384 17.6062C7.40156 17.1139 7.125 16.4462 7.125 15.75C7.125 15.4516 7.00647 15.1655 6.7955 14.9545C6.58452 14.7435 6.29837 14.625 6 14.625C5.70163 14.625 5.41548 14.7435 5.2045 14.9545C4.99353 15.1655 4.875 15.4516 4.875 15.75C4.87649 17.0425 5.39058 18.2816 6.3045 19.1955C7.21842 20.1094 8.45753 20.6235 9.75 20.625H10.875V21.75C10.875 22.0484 10.9935 22.3345 11.2045 22.5455C11.4155 22.7565 11.7016 22.875 12 22.875C12.2984 22.875 12.5845 22.7565 12.7955 22.5455C13.0065 22.3345 13.125 22.0484 13.125 21.75V20.625H14.25C15.5429 20.625 16.7829 20.1114 17.6971 19.1971C18.6114 18.2829 19.125 17.0429 19.125 15.75C19.125 14.4571 18.6114 13.2171 17.6971 12.3029C16.7829 11.3886 15.5429 10.875 14.25 10.875ZM10.5 10.875C9.80381 10.875 9.13613 10.5984 8.64384 10.1062C8.15156 9.61387 7.875 8.94619 7.875 8.25C7.875 7.55381 8.15156 6.88613 8.64384 6.39384C9.13613 5.90156 9.80381 5.625 10.5 5.625H10.875V10.875H10.5ZM14.25 18.375H13.125V13.125H14.25C14.9462 13.125 15.6139 13.4016 16.1062 13.8938C16.5984 14.3861 16.875 15.0538 16.875 15.75C16.875 16.4462 16.5984 17.1139 16.1062 17.6062C15.6139 18.0984 14.9462 18.375 14.25 18.375Z"
      fill="currentColor"
    />
  </svg>
</template>
