<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1268_84788)">
      <path
        d="M38.8546 17.9165L25.0749 9.14148C24.6999 8.90175 24.2675 8.76707 23.8227 8.75152C23.3779 8.73597 22.9371 8.84011 22.5463 9.05307C22.1555 9.26603 21.829 9.58 21.601 9.9622C21.3729 10.3444 21.2517 10.7808 21.2499 11.2259V17.054L8.82488 9.14148C8.44991 8.90175 8.01745 8.76707 7.57267 8.75152C7.12788 8.73597 6.68707 8.84011 6.29627 9.05307C5.90547 9.26603 5.57901 9.58 5.35097 9.9622C5.12293 10.3444 5.00168 10.7808 4.99988 11.2259V28.7759C5.00219 29.2207 5.12375 29.6568 5.35191 30.0387C5.58006 30.4206 5.90645 30.7343 6.29708 30.9472C6.6877 31.16 7.12826 31.2643 7.57285 31.249C8.01745 31.2337 8.4498 31.0994 8.82488 30.8602L21.2499 22.9477V28.7759C21.2522 29.2207 21.3738 29.6568 21.6019 30.0387C21.8301 30.4206 22.1565 30.7343 22.5471 30.9472C22.9377 31.16 23.3783 31.2643 23.8229 31.249C24.2674 31.2337 24.6998 31.0994 25.0749 30.8602L38.8546 22.0852C39.2056 21.8624 39.4947 21.5545 39.6951 21.1901C39.8954 20.8258 40.0004 20.4167 40.0004 20.0009C40.0004 19.585 39.8954 19.176 39.6951 18.8116C39.4947 18.4472 39.2056 18.1393 38.8546 17.9165ZM7.49988 28.7415V11.2618L21.2218 20.0009L7.49988 28.7415ZM23.7499 28.7415V11.2618L37.4718 20.0009L23.7499 28.7415Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1268_84788">
        <rect width="40" height="40" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
