<template>
  <button
    :class="[
      widthClass,
      sizeClass,
      disabled ? 'text-navy-200 cursor-not-allowed' : colorClass,
      'flex items-center gap-2 rounded-full justify-center border ',
    ]"
    @click="handleClick"
  >
    <slot />
  </button>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  width: String,
  size: String,
  color: String,
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["click"]);

const widthClass = computed(() => {
  switch (props.width) {
    case "full":
      return "w-full";
    case "fit":
      return "w-fit";
    default:
      return "w-fit";
  }
});

const sizeClass = computed(() => {
  switch (props.size) {
    case "lg":
      return "px-12 py-4 text-md font-semibold";
    case "md":
      return "px-6 py-3 text-sm font-semibold";
    case "sm":
      return "px-4 py-2 text-sm font-semibold";
    default:
      return "px-12 py-4";
  }
});

const colorClass = computed(() => {
  switch (props.color) {
    case "green":
      return "text-green-400 hover:text-green-500";
    case "red":
      return "text-red-400 hover:text-red-500";
    case "primary":
      return "text-primary-700 hover:text-primary-800 border-primary-700 group-hover:text-white group-hover:bg-primary-700";
    default:
      return "dark:text-white text-navy-800 dark:hover:text-navy-50 hover:text-navy-600 dark:border-navy-400 border-navy-50";
  }
});

const handleClick = () => {
  emits("click");
};
</script>
