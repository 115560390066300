<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.794 17.7684C13.8472 20.2227 9.46161 20.0677 6.69665 17.3034C3.93169 14.539 3.77668 10.1522 6.23165 7.20602C6.46852 6.92165 6.8991 6.89914 7.16097 7.16101L16.8383 16.839C17.1002 17.1009 17.0778 17.5315 16.794 17.7684ZM19.0708 4.92918C15.1659 1.02361 8.83412 1.02361 4.92918 4.92918C1.02361 8.83475 1.02361 15.1659 4.92918 19.0708C8.83412 22.9764 15.1659 22.9764 19.0708 19.0708C22.9764 15.1659 22.9764 8.83475 19.0708 4.92918Z"
      fill="currentColor"
    />
  </svg>
</template>
