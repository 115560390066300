<template>
  <div
    class="w-full flex flex-col p-2 sm:p-4 rounded-2xl hover:dark:bg-navy-600 hover:bg-navy-10 cursor-pointer flex-1"
    @click="handleClick"
  >
    <img :src="props.imageURL" class="rounded-2xl" />
    <div class="flex mt-2">
      <text-badge
        :label="item"
        class="px-3 py-2 border-[1px] dark:border-[#242A3D] border-[#C5CBDD] text-xs font-medium mr-2"
        v-for="item in Badges"
        :key="item"
      />
    </div>
    <div class="h-full flex flex-col justify-between">
      <div class="mt-2">
        <div class="mt-2 text-xl font-medium dark:text-white text-[#0E111B]">
          {{ props.header }}
        </div>
        <div
          class="mt-2 text-base font-normal dark:text-[#C5CBDD] text-[#424D70]"
        >
          {{ props.content }}
        </div>
      </div>
      <div class="mt-2 text-xs font-medium dark:text-[#9CA5C4] text-[#9CA5C4]">
        {{ changeUnixTime(props.updateTime) }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps } from "vue";
import TextBadge from "@/components/Badge/TextBadge.vue";
import { changeUnixTime } from "@/utils/utils";

const props = defineProps([
  "imageURL",
  "header",
  "content",
  "updateTime",
  "size",
]);

const Badges = ["Spot", "Latest Events"];

const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};
</script>
