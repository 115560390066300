<template>
  <div class="flex flex-col w-full custom-scrollbar">
    <div class="w-full flex flex-row items-center justify-between">
      <div class="text-2xl font-semibold dark:text-white text-navy-800">
        Address Book
      </div>
      <div class="flex flex-row items-center gap-3">
        <div class="dark:text-white text-xs font-medium text-navy-300">
          Security
        </div>
        <div
          class="flex flex-row items-center px-2 py-1.5 bg-green-200 text-green-900 rounded-full gap-1"
        >
          <check-badge-icon class="size-5 stroke-2" />
          <div class="text-sm font-semibold">High</div>
        </div>
      </div>
    </div>
    <div
      class="mt-8 sm:mt-12 flex sm:flex-row flex-col items-center justify-between gap-4"
    >
      <div class="flex flex-row items-center gap-4 max-sm:w-full">
        <combo-box
          placeholder="Coin"
          :items="coinComboItem"
          v-model="coinSelect"
        />
        <div class="sm:w-[250px] w-full">
          <text-edit
            type="text"
            size="sm"
            rounded="full"
            placeholder="Search label"
            :filled="false"
            v-model="searchText"
          >
            <template #prefix>
              <div class="mr-2">
                <magnifying-glass-icon
                  :class="[
                    searchText.length > 0
                      ? 'dark:text-white text-navy-800'
                      : 'text-navy-200',
                    'w-6 h-6',
                  ]"
                />
              </div>
            </template>
            <template #suffix v-if="searchText.length > 0">
              <div
                class="ml-2"
                @click="
                  () => {
                    searchText = '';
                  }
                "
              >
                <x-circle-icon class="text-navy-200 stroke-2 w-6 h-6" />
              </div>
            </template>
          </text-edit>
        </div>
        <link-button label="Reset" @click="handleResetClick"></link-button>
      </div>
      <div class="max-sm:w-full w-fit shrink-0">
        <outline-button
          size="md"
          color="primary"
          width="full"
          @click="handleAddAddressClick"
        >
          <plus-icon class="size-5 stroke-2" />
          <span>Add Address</span>
        </outline-button>
      </div>
    </div>
    <div class="w-full mt-6">
      <table class="sm:w-full mt-6 mb-4 table-auto w-max custom-scrollbar">
        <thead>
          <tr class="h-10">
            <th
              v-for="item in tableHeaderItems"
              :key="item.id"
              :class="[
                'text-sm font-medium dark:text-navy-100 text-navy-200 px-3',
                `text-${item.align}`,
                item.hide === false ? '' : `max-${item.hide}:hidden`,
              ]"
            >
              {{ item.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in displayData"
            :key="item.id"
            class="h-[72px] dark:hover:bg-navy-700 hover:bg-navy-10 cursor-pointer text-sm font-medium dark:text-white text-navy-800"
          >
            <td class="px-3 rounded-l-xl">{{ item.type }}</td>
            <td class="px-3">
              {{ item.network.nice_name }}
            </td>
            <td class="px-3">
              <div class="flex flex-row items-center gap-3">
                <div style="width: 16ch" class="text-ellipsis overflow-hidden">
                  {{ item.address }}
                </div>
                <div
                  class="size-8 rounded-full dark:bg-navy-500 dark:text-white bg-navy-50 text-navy-800 items-center justify-center flex cursor-pointer"
                  @click="handleCopyAddress(item.address)"
                >
                  <copy-icon class="size-5" />
                </div>
              </div>
            </td>
            <td class="px-3">{{ item.name }}</td>
            <td class="px-3 rounded-r-xl">
              <div class="w-8">
                <dropdown-select alignment="end">
                  <template #header="{ handleContentClick }">
                    <div
                      class="w-8 h-8 flex items-center justify-center rounded-full cursor-pointer dark:hover:bg-navy-300 hover:bg-navy-50"
                      @click="handleContentClick"
                    >
                      <ellipsis-vertical-icon class="w-6 h-6" />
                    </div>
                  </template>
                  <template #menu="{ handleMenuItemClick }">
                    <div
                      class="w-[208px] dark:bg-navy-600 border dark:border-navy-400 rounded-2xl p-2 bg-white border-navy-10"
                      :style="[
                        currentTheme === 'dark'
                          ? 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.32)'
                          : 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.16);',
                      ]"
                    >
                      <div
                        v-for="menuItem in tableMenuData"
                        :key="menuItem.id"
                        class="rounded-lg w-full px-3 py-2.5 dark:text-white text-navy-800 cursor-pointer dark:hover:bg-navy-500 hover:bg-navy-10 flex flex-row group dark:hover:text-white hover:text-primary-700 group"
                        @click="
                          () => {
                            handleMenuItemClick();
                            handleActionMenuItemClick(menuItem.id, item.id);
                          }
                        "
                      >
                        <component
                          :is="menuItem.icon"
                          class="w-5 h-5 mr-3 text-navy-100 group-hover:text-primary-700 group-hover:dark:text-white"
                        />
                        <div class="text-sm font-medium">
                          {{ menuItem.label }}
                        </div>
                      </div>
                    </div>
                  </template>
                </dropdown-select>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="h-48" />
    <address-add-dialog
      v-model="showAddDialog"
      v-if="showAddDialog"
      @request-send="handleAddRequestSent"
    />
    <address-edit-label-dialog v-model="showEditDialog" v-if="showEditDialog" />
    <address-delete-confirm-dialog
      v-model="showDeleteDialog"
      v-if="showDeleteDialog"
      @confirm="handleDeleteConfirm"
    />
    <two-factor-verification-modal
      v-if="verificationConfirm"
      :is-open="verificationConfirm"
      @close="handleCloseVerificationConfirm"
      @confirm="handleConfirmVerificationConfirm"
    />
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { postHttpRequest } from "@/api/api";
import { copyToClipboard, isEmpty } from "@/utils/utils";
import AddressAddDialog from "@/views/Profile/Dialogs/AddressAddDialog.vue";
import AddressEditLabelDialog from "@/views/Profile/Dialogs/AddressEditLabelDialog.vue";
import AddressDeleteConfirmDialog from "@/views/Profile/Dialogs/AddressDeleteConfirmDialog.vue";
import TwoFactorVerificationModal from "@/common/TwoFactorVerificationModal/TwoFactorVerificationModal.vue";
import {
  CheckBadgeIcon,
  XCircleIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  EllipsisVerticalIcon,
} from "@heroicons/vue/24/outline";
import CopyIcon from "@/assets/icons/common/CopyIcon.vue";
import EditIcon from "@/assets/icons/common/EditIcon.vue";
import TrashIcon from "@/assets/icons/common/TrashIcon.vue";

const store = useStore();
const allPairsList = computed(() => store.getters.activeCurrencies);
const addressBook = computed(() => store.getters.profile.address_book);
const activeCurrencies = computed(() => store.getters.activeCurrencies);

const coinSelect = ref(null);
const searchText = ref("");
const showEditDialog = ref(false);
const showDeleteDialog = ref(false);
const showAddDialog = ref(false);
const verificationConfirm = ref(false);
const actionType = ref(0); // 0: Add address, 1: Delete address
const addAddressData = ref({});
const actionItemId = ref(0);

const coinComboItem = computed(() => {
  return Object.values(allPairsList.value).map((item) => ({
    id: item.id,
    label: item.iso3,
  }));
});

const addressBookList = computed(() => {
  if (isEmpty(addressBook.value)) {
    return [];
  }
  const list = [];
  Object.entries(addressBook.value).forEach(([key, value]) => {
    value.forEach((item) => {
      item.type = key;
      item.network = getNetworkFromIso3AndType(key, item.network_type);
      list.push(item);
    });
  });
  return list;
});

const getNetworkFromIso3AndType = (iso3, type) => {
  const item = Object.values(activeCurrencies.value).find(
    (item) => item.iso3 === iso3
  );
  return !isEmpty(item) ? item.networks[type] : "";
};

const displayData = computed(() => {
  return addressBookList.value.filter(
    (item) =>
      item.type.includes(coinSelect.value?.label ?? "") &&
      item.name.toLowerCase().includes(searchText.value.toLowerCase())
  );
});

const handleActionMenuItemClick = (menuItemId, itemId) => {
  actionItemId.value = itemId;
  if (menuItemId === 1) {
    showEditDialog.value = true;
  } else if (menuItemId === 2) {
    showDeleteDialog.value = true;
  }
};

const handleResetClick = () => {
  coinSelect.value = null;
  searchText.value = "";
};

const handleAddAddressClick = () => {
  showAddDialog.value = true;
};

const handleCloseVerificationConfirm = () => {
  verificationConfirm.value = false;
};

const handleDeleteConfirm = async () => {
  const res = await postHttpRequest(store)("/api/account/delete-address-book", {
    send_to_email: 1,
    send_to_phone: 1,
    id: actionItemId.value,
  });
  if (res.status) {
    actionType.value = 1;
    verificationConfirm.value = true;
  } else {
    store.dispatch("setToast", {
      type: "error",
      message: "An error occured while deleting new address",
    });
  }
};

const handleConfirmVerificationConfirm = async (data) => {
  if (actionType.value === 0) {
    const requestData = {
      ...addAddressData.value,
      send_to_email: 0,
      send_to_phone: 0,
      email_code: data.emailPin,
      phone_code: data.phonePin,
    };
    const res = await postHttpRequest(store)(
      "/api/account/add-book",
      requestData
    );
    if (res.status) {
      store.dispatch("setToast", {
        type: "success",
        message: "Address successfully added",
      });
      store.dispatch("fetchAccountInfo");
    } else {
      store.dispatch("setToast", {
        type: "error",
        message: "An error occured while adding new address",
      });
    }
  } else if (actionType.value === 1) {
    const res = await postHttpRequest(store)(
      "/api/account/delete-address-book",
      {
        id: actionItemId.value,
        send_to_email: 0,
        send_to_phone: 0,
        email_code: data.emailPin,
        phone_code: data.phone_code,
      }
    );
    if (res.status) {
      store.dispatch("setToast", {
        type: "success",
        message: "Address successfully removed",
      });
      store.dispatch("fetchAccountInfo");
    } else {
      store.dispatch("setToast", {
        type: "error",
        message: "An error occured while deleting new address",
      });
    }
  }
  verificationConfirm.value = false;
};

const handleAddRequestSent = (data) => {
  verificationConfirm.value = true;
  actionType.value = 0;
  addAddressData.value = data;
};

const handleCopyAddress = (address) => {
  copyToClipboard(address);
  store.dispatch("setToast", {
    type: "success",
    message: "An address successfully copied.",
  });
};

const tableHeaderItems = [
  {
    id: 1,
    label: "Coin",
    align: "left",
    hide: false,
  },
  {
    id: 2,
    label: "Transfer Network",
    align: "left",
    hide: false,
  },
  {
    id: 3,
    label: "Address",
    align: "left",
    hide: false,
  },
  {
    id: 4,
    label: "Wallet Label",
    align: "left",
    hide: false,
  },
  {
    id: 5,
    label: "Status",
    align: "left",
    hide: false,
  },
];

const tableMenuData = [
  {
    id: 1,
    label: "Edit Label",
    icon: EditIcon,
  },
  {
    id: 2,
    label: "Delete",
    icon: TrashIcon,
  },
];
</script>
