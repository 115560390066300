<template>
  <div
    :class="[
      'w-10 h-6 rounded-full cursor-pointer p-1 delay-0 ease-out duration-300 transition-all',
      model ? 'bg-primary-700' : 'dark:bg-navy-400 bg-navy-50',
    ]"
    @click="model = !model"
  >
    <div
      :class="[
        'size-4 bg-white delay-0 ease-out duration-300 transition-all rounded-full',
        model ? 'ml-4' : 'ml-0',
      ]"
    ></div>
  </div>
</template>
<script setup>
import { defineModel } from "vue";
const model = defineModel();
</script>
