<template>
  <div>
    <p v-if="label" class="font-bold mb-2 text-sm">
      {{ props.label }}
    </p>
    <div class="relative">
      <div
        :class="[
          'flex justify-between p-4 rounded-xl border-2 w-full',
          `bg-white ${!totalError && 'border-navy-50'} dark:bg-navy-700 ${
            !totalError && 'dark:border-navy-400'
          }`,
          totalError ? 'border-red-500' : 'focus-within:border-primary-700',
        ]"
      >
        <input
          :class="[
            'w-full focus:outline-none placeholder:text-navy-200 placeholder:tracking-normal text-sm',
            currentTheme === 'light'
              ? 'bg-white input-light-mode'
              : 'bg-navy-700 input-dark-mode',
          ]"
          v-model="inputValue"
          :placeholder="props.placeholder"
          :type="isShowingPassword ? 'text' : 'password'"
          @focus="handleFocus"
          @blur="handleBlur"
        />
        <x-circle-icon
          v-if="inputValue.length"
          class="w-6 h-6 cursor-pointer mr-3 text-navy-200 dark:text-navy-100"
          @click="handleRemoveInputValue"
        />
        <eye-icon
          v-if="!isShowingPassword"
          class="w-6 h-6 cursor-pointer text-navy-200 dark:text-navy-100"
          @click="togglePasswordVisibility"
        />
        <eye-slash-icon
          v-if="isShowingPassword"
          class="w-6 h-6 cursor-pointer text-navy-200 dark:text-navy-100"
          @click="togglePasswordVisibility"
        />
      </div>
      <div
        v-if="focused"
        class="w-full shadow-xl rounded-xl absolute top-16 z-10 p-3 flex flex-col space-y-2 bg-white dark:bg-navy-400"
      >
        <div
          v-for="item in validationItems"
          :key="item.id"
          :class="[
            'flex space-x-2 items-center',
            item.valid ? 'text-trade-buy' : 'text-navy-100 dark:text-navy-200',
          ]"
        >
          <check-icon class="w-4 h-4 stroke-2" />
          <label>{{ item.text }}</label>
        </div>
      </div>
    </div>
    <p
      v-if="totalError && typeof totalError === 'string'"
      class="text-red-500 mt-2"
    >
      {{ totalError }}
    </p>
  </div>
</template>

<script setup>
import { defineProps, defineModel, ref, watch, computed } from "vue";
import { EyeIcon, EyeSlashIcon, CheckIcon } from "@heroicons/vue/24/outline";
import { XCircleIcon } from "@heroicons/vue/24/solid";

const isShowingPassword = ref(false);
const focused = ref(false);
const validationItems = ref([
  { id: 1, valid: false, text: "Min 12 characters" },
  { id: 2, valid: false, text: "At least one uppercase letter" },
  { id: 3, valid: false, text: "At least 2 number" },
  { id: 4, valid: false, text: "At least 2 symbol" },
]);
const props = defineProps(["value", "placeholder", "label", "error"]);
const inputValue = defineModel();
const totalError = computed(() => {
  if (typeof props.error === "string") {
    return props.error.length ? props.error : false;
  } else {
    return (
      !props.error.every((item) => item === true) &&
      !(props.error.every((item) => item === false) && !inputValue.value.length)
    );
  }
});

watch(props, () => {
  validationItems.value = validationItems.value.map((item, index) => ({
    ...item,
    valid: props.error[index],
  }));
});

const togglePasswordVisibility = () => {
  isShowingPassword.value = !isShowingPassword.value;
};

const handleRemoveInputValue = () => {
  inputValue.value = "";
};

const handleFocus = () => {
  focused.value = true;
};

const handleBlur = () => {
  focused.value = false;
};
</script>
