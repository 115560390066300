<template>
  <div class="w-full">
    <p class="font-semibold text-xl mb-6 mt-1">Withdraw Amount</p>
    <text-edit
      size="md"
      width="full"
      rounded="xl"
      :error="error.amount"
      :placeholder="`Min ${network.withdraw.min_amount}`"
      v-model="amount"
    >
      <template #suffix>
        <div class="flex gap-2 font-semibold text-base!">
          <p>{{ coin.iso3 }}</p>
          <link-button label="Max" @click="handleClickMax" />
        </div>
      </template>
    </text-edit>
    <p class="mt-4">
      <span class="dark:text-navy-100 text-navy-200 mr-3"> Available: </span>
      <span>
        {{ availableBalance.balance_available_f }}
        {{ coin.iso3 }}</span
      >
    </p>

    <div
      class="mt-8 border-t pt-8 dark:border-navy-300 border-navy-50 text-sm flex items-center sm:justify-between"
    >
      <span class="dark:text-navy-100 text-navy-200"> Network fee </span>
      <span>
        {{ network.withdraw.fee.fixed }}
        {{ coin.iso3 }}
      </span>
    </div>

    <div
      class="mt-2 mb-4 flex sm:flex-row flex-col items-center justify-between text-sm"
    >
      <span class="dark:text-navy-100 text-navy-200"> Receive Amount </span>
      <p>
        <span class="text-2xl font-bold mr-2">
          {{ receiveAmount }}
        </span>
        <span class="text-lg">{{ coin.iso3 }}</span>
      </p>
    </div>

    <fill-button
      size="md"
      width="full"
      color="primary"
      @click="handleClickWithdraw"
    >
      Withdraw
    </fill-button>

    <ol
      class="mt-8 text-xs font-medium dark:text-navy-200 text-navy-300 list-decimal pl-4"
    >
      <li class="mb-3">
        Do not deposit assets other than USDT, as doing so may result in the
        irretrievability of the deposited assets.
      </li>
      <li class="mb-3">
        Deposits below the minimum amount will not be credited and cannot be
        refunded.
      </li>
      <li>
        Ensure the security of your computer and browser to prevent information
        from being tampered with or leaked.
      </li>
    </ol>
    <potential-risk
      v-if="confirmWithdraw"
      :is-open="confirmWithdraw"
      @close="handleCloseWithdraw"
      @next="handleClickNext"
    >
    </potential-risk>
    <withdraw-verification
      v-if="isVerification"
      :is-open="isVerification"
      :token="coin.iso3"
      :network="`${walletAddress.network.name}(${walletAddress.network.token_type})`"
      :pay-from="walletAddress.address"
      :withdraw-amount="data.amount"
      :network-fee="data.fee_from_amount"
      @confirm="handleConfirm"
      @close="handleCloseConfirm"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from "vue";
import { useStore } from "vuex";
import { postHttpRequest } from "@/api/api";
import PotentialRisk from "@/views/Asset/Withdraw/Crypto/PotentialRisk.vue";
import WithdrawVerification from "@/views/Asset/Withdraw/Crypto/WithdrawVerification.vue";
import { isEmpty } from "@/utils/utils";

const props = defineProps({
  network: Object,
  coin: Object,
  walletAddress: Object,
  availableBalance: Object,
});
const store = useStore();

const amount = ref();
const confirmWithdraw = ref(false);
const isVerification = ref(false);
const data = ref({});
const requestId = ref();
const error = ref({});
const emits = defineEmits(["withdraw"]);

const receiveAmount = computed(() => {
  if (amount.value && props.network) {
    const receive =
      amount.value -
      props.network.withdraw.fee.fixed -
      (amount.value * props.network.withdraw.fee.percent) / 100;
    return receive < 0 ? 0 : receive;
  }
  return 0;
});

const handleClickMax = () => {
  amount.value = props.availableBalance.balance_available_f;
};

const handleClickWithdraw = async () => {
  if (isEmpty(amount.value)) {
    error.value.amount = "Amount is required.";
    return;
  } else if (!receiveAmount.value) {
    error.value.amount = "Not enough.";
    return;
  } else {
    error.value.amount = null;
  }
  data.value = {
    amount: amount.value,
    deposit_type: "spot",
    fee_from_amount: amount.value - receiveAmount.value,
    iso: props.coin.iso3,
    network_type: props.network.id,
    to_address: props.walletAddress.address,
  };

  try {
    const res = await postHttpRequest(store)("/api/withdraw/index", data.value);
    if (res.status) {
      requestId.value = res.data.id;
      confirmWithdraw.value = true;
    }
  } catch (error) {
    console.log(error);
  }
};

const handleCloseWithdraw = () => {
  confirmWithdraw.value = false;
};

const handleClickNext = () => {
  confirmWithdraw.value = false;
  isVerification.value = true;
};

const handleConfirm = () => {
  if (requestId.value) {
    isVerification.value = false;
    emits("withdraw", requestId.value);
  }
};

const handleCloseConfirm = () => {
  isVerification.value = false;
};
</script>
