import local_setting_manager from "@/utils/SettingManager";

const state = {
  notifyManageAssets: local_setting_manager.getNotifyManageAssets(),
  notifyTrade: local_setting_manager.getNotifyTrade(),
  notifyCustomPriceAlert: local_setting_manager.getNotifyCustomPriceAlert(),
  notifyDeleteNewFeatures: local_setting_manager.getNotifyDeleteNewFeature(),
  currency: local_setting_manager.getCurrency(),
};

const mutations = {
  SET_NOTIFY_MANAGE_ASSETS(state, value) {
    state.notifyManageAssets = value;
  },
  SET_NOTIFY_TRADE(state, value) {
    state.notifyTrade = value;
  },
  SET_NOTIFY_CUSTOM_PRICE_ALERT(state, value) {
    state.notifyCustomPriceAlert = value;
  },
  SET_NOTIFY_DELETE_NEW_FEATURES(state, value) {
    state.notifyDeleteNewFeatures = value;
  },
  SET_CURRENCY(state, value) {
    state.currency = value;
  },
};

const actions = {
  setNotifyManageAssets({ commit }, value) {
    commit("SET_NOTIFY_MANAGE_ASSETS", value);
    local_setting_manager.setNotifyManageAssets(value);
  },
  setNotifyTrade({ commit }, value) {
    commit("SET_NOTIFY_TRADE", value);
    local_setting_manager.setNotifyTrade(value);
  },
  setNotifyCustomPriceAlert({ commit }, value) {
    commit("SET_NOTIFY_CUSTOM_PRICE_ALERT", value);
    local_setting_manager.setNotifyCustomPriceAlert(value);
  },
  setNotifyDeletenewFeatures({ commit }, value) {
    commit("SET_NOTIFY_DELETE_NEW_FEATURES", value);
    local_setting_manager.setNotifyDeleteNewFeature(value);
  },
  setCurrency({ commit }, value) {
    commit("SET_CURRENCY", value);
    local_setting_manager.setCurrency(value);
  },
};

const getters = {
  notifyManageAssets: (state) => state.notifyManageAssets,
  notifyTrade: (state) => state.notifyTrade,
  notifyCustomPriceAlert: (state) => state.notifyCustomPriceAlert,
  notifyDeleteNewFeatures: (state) => state.notifyDeleteNewFeatures,
  currency: (state) => state.currency,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
