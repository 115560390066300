<template>
  <div class="relative">
    <div class="absolute w-full h-fit -z-50">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/help-center/background-texture-dark.svg'
            : '/assets/images/help-center/background-texture-light.svg'
        "
        class="w-full"
      />
      <div
        class="absolute top-0 h-full w-full bg-gradient-to-t dark:from-navy-800 from-30% from-white dark:to-transparent to-60% z-50"
      />
      <div
        class="absolute top-0 h-full w-1/4 bg-gradient-to-r dark:from-navy-800 from-white to-transparent"
      />
      <div
        class="absolute top-0 h-full w-1/4 bg-gradient-to-l dark:from-navy-800 from-white to-transparent right-0"
      />
    </div>
    <div class="absolute -z-50">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/help-center/ellipse-dark.svg'
            : '/assets/images/help-center/ellipse-light.svg'
        "
      />
    </div>
    <div
      class="container mx-auto w-full px-2 flex flex-col items-center sm:px-16"
    >
      <div
        class="sm:mt-16 mt-10 text-lg font-medium sm:text-xl sm:font-normal text-navy-300 dark:text-navy-50 text-center"
      >
        Help Center
      </div>
      <div
        class="mt-3 sm:mt-6 text-center text-navy-800 dark:text-white text-4xl font-bold sm:text-[56px]"
      >
        Here to help you 24/7
      </div>
      <div class="sm:w-[367px] w-full mt-6 sm:mt-10">
        <text-edit
          type="text"
          size="sm"
          rounded="full"
          placeholder="Search"
          :filled="true"
          v-model="searchText"
        >
          <template #prefix>
            <div class="mr-2">
              <magnifying-glass-icon
                :class="[
                  searchText.length > 0
                    ? 'dark:text-white text-navy-800'
                    : 'text-navy-200',
                  'w-6 h-6',
                ]"
              />
            </div>
          </template>
          <template #suffix v-if="searchText.length > 0">
            <div
              class="ml-2"
              @click="
                () => {
                  searchText = '';
                }
              "
            >
              <x-circle-icon class="text-navy-200 stroke-2 w-6 h-6" />
            </div>
          </template>
        </text-edit>
      </div>
      <div class="w-full mt-16 sm:mt-24">
        <div
          class="dark:text-white text-navy-800 text-[32px] font-semibold mb-6 sm:mb-10"
        >
          Self-Service
        </div>
        <div>
          <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
            <self-service-item
              v-for="item in selfServiceData"
              :label="item.label"
              :key="item.id"
              @click="handleMenuItemClick(item.url)"
            />
          </div>
        </div>
      </div>
      <div class="w-full mt-[104px] sm:mt-[168px]">
        <div class="flex lg:flex-row w-full gap-8 flex-col items-start">
          <short-article-view
            label="Top Questions"
            :article-data="topQuestionData"
            @itemClick="handleMenuItemClick"
          />
          <short-article-view
            label="Beginner's Guide"
            :article-data="beginnerArticleData"
            @itemClick="handleMenuItemClick"
          />
        </div>
      </div>
      <div class="w-full mt-[104px] sm:mt-[168px]">
        <div
          class="px-4 font-semibold text-[32px] dark:text-white text-navy-800 sm:mb-10 mb-8"
        >
          Popular Articles
        </div>
        <div
          class="grid md:grid-flow-col md:grid-rows-3 gap-x-8 max-md:grid-cols-1"
        >
          <div
            v-for="(item, index) in popularArticleData"
            :key="index"
            class="flex flex-col justify-between h-full"
          >
            <article-summary-item
              :heading="item.heading"
              :content="item.content"
              :update-time="item.time"
              @click="handleMenuItemClick(item.url)"
            />
            <div
              v-if="index % 3 !== 2"
              class="my-2 border-t dark:border-t-navy-500 border-t-navy-10"
            ></div>
          </div>
        </div>
      </div>
      <div class="w-full mt-[104px] sm:mt-[168px]">
        <div
          class="flex md:flex-row flex-col items-center justify-center gap-8"
        >
          <redeem-card
            title="Still have problems?"
            content="We provide 24/7 customer service that always satisfied your needs"
          />
          <redeem-card
            title="Have some problems?"
            content="We provide 24/7 customer service that always satisfied your needs"
          />
        </div>
      </div>
      <div class="w-full mt-[104px] sm:mt-[168px] mb-20">
        <div class="flex flex-col px-4 w-full">
          <div class="dark:text-white font-bold text-5xl mb-8 text-navy-800">
            FAQ
          </div>
          <faq-component :faq-data="helpCenterFaqData" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { isEmpty } from "@/utils/utils";
import SelfServiceItem from "@/views/HelpCenter/Items/SelfServiceItem.vue";
import ArticleSummaryItem from "@/views/HelpCenter/Items/ArticleSummaryItem.vue";
import FaqComponent from "@/common/FAQ/FaqComponent.vue";
import ShortArticleView from "@/views/HelpCenter/ShortArticleView.vue";
import RedeemCard from "@/views/HelpCenter/RedeemCard.vue";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/vue/24/outline";

const store = useStore();
const searchText = ref("");
const router = useRouter();
const helpCenterFaqData = ref([]);

onMounted(() => {
  store.dispatch("getSelfServiceArticles");
  store.dispatch("getTopQuestionArticles");
  store.dispatch("getBeginnerGuideArticles");
  store.dispatch("getPopularArticles");
});

onMounted(async () => {
  try {
    const response = await fetch("/mock/help-center/faq.json");
    const data = await response.json();
    helpCenterFaqData.value = data;
  } catch (error) {
    console.error(error);
  }
});

const selfServiceArticles = computed(() => store.getters.selfServiceArticles);
const topQuestionArticles = computed(() => store.getters.topQuestionArticles);
const beginnerArticles = computed(() => store.getters.beginnerGuideArticles);
const popularArticles = computed(() => store.getters.popularArticles);

const selfServiceData = computed(() => {
  if (isEmpty(selfServiceArticles.value)) {
    return [];
  }
  return selfServiceArticles.value
    .filter((item) => {
      return item.text.toLowerCase().includes(searchText.value.toLowerCase());
    })
    .map((item, index) => {
      return {
        id: index,
        label: item.title,
        url: item.url,
      };
    });
});

const topQuestionData = computed(() => {
  if (isEmpty(topQuestionArticles.value)) {
    return [];
  }
  return topQuestionArticles.value.map((item, index) => {
    const date = new Date(item.time_create * 1000);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return {
      id: index,
      heading: item.title,
      url: item.url,
      time: `Updated on ${formattedDate}`,
    };
  });
});

const beginnerArticleData = computed(() => {
  if (isEmpty(beginnerArticles.value)) {
    return [];
  }
  return beginnerArticles.value.map((item, index) => {
    const date = new Date(item.time_create * 1000);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return {
      id: index,
      heading: item.title,
      url: item.url,
      time: `Updated on ${formattedDate}`,
    };
  });
});

const popularArticleData = computed(() => {
  if (isEmpty(popularArticles.value)) {
    return [];
  }
  return popularArticles.value.map((item, index) => {
    const date = new Date(item.time_create * 1000);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return {
      id: index,
      heading: item.title,
      content: item.short_text,
      url: item.url,
      time: `Updated on ${formattedDate}`,
    };
  });
});

const handleMenuItemClick = (url) => {
  router.push({
    name: "help-center-article",
    params: {
      url: url,
    },
  });
};
</script>
