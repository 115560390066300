<template>
  <p class="font-semibold text-base">Recent Transactions</p>
  <template v-if="!isEmpty(transactions)">
    <div class="overflow-auto custom-scrollbar">
      <table class="w-[600px] mt-6 sm:w-full text-sm text-navy-100">
        <tr
          v-for="transaction in transactions"
          :key="transaction.id"
          class="hover:dark:bg-navy-700 hover:bg-navy-10 cursor-pointer"
        >
          <td
            class="font-semibold dark:text-white text-navy-800 px-3 py-2 rounded-l-xl"
          >
            <div class="flex gap-3">
              <span
                class="w-8 h-8 dark:bg-navy-500 bg-primary-300 rounded-full flex items-center justify-center"
              >
                <component
                  :is="transaction.icon"
                  class="w-4 h-4 dark:text-white text-primary-700"
                />
              </span>
              <span>
                {{ transaction.label }}
                {{ transaction.iso3 }}
              </span>
            </div>
          </td>
          <td>
            {{
              moment(transaction.time_done * 1000).format(
                "MM/DD/YYYY, hh:mm:ss"
              )
            }}
          </td>
          <td
            :class="
              transaction.amount_f > 0 ? 'text-trade-buy' : 'text-red-500'
            "
          >
            {{ (transaction.amount_f > 0 ? "+" : "") + transaction.amount_f }}
          </td>
          <td class="rounded-r-xl">{{ transaction.status_label }}</td>
        </tr>
      </table>
    </div>
    <div class="h-3" />
    <pagination-component
      :total="totalPage"
      :initial-page="1"
      :step-disabled="true"
      step="20"
      @change="handleChangePage"
    />
  </template>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import { capitalizeFirstLetter, isEmpty } from "@/utils/utils";
import PaginationComponent from "@/components/Pagination/PaginationComponent.vue";
import { ArrowsUpDownIcon } from "@heroicons/vue/24/outline";
import DepositIcon from "@/assets/icons/asset/DepositIcon.vue";
import WithdrawIcon from "@/assets/icons/asset/WithdrawIcon.vue";

const store = useStore();
const transactions = computed(() =>
  (store.getters.transactions.list ?? []).map((item) => {
    const label =
      item.type == "TRADE"
        ? capitalizeFirstLetter(item.trade.order_direction)
        : item.type;
    let icon = "";
    switch (label) {
      case "Deposit":
        icon = DepositIcon;
        break;
      case "Withdraw":
        icon = WithdrawIcon;
        break;
      case "Buy":
      case "Sell":
      case "INTERNAL_TRANSFER":
      default:
        icon = ArrowsUpDownIcon;
        break;
    }
    return {
      ...item,
      icon: icon,
      label: label,
    };
  })
);
const totalPage = computed(() => store.getters.transactions.totalPage);
const handleChangePage = ([currentPage]) => {
  store.dispatch("fetchTransactions", currentPage);
};
</script>
