<template>
  <div class="relative">
    <div class="absolute overflow-y-visible -z-50 w-full top-36">
      <img
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/launchpad/launchpad_background_dark.svg'
            : '/assets/images/launchpad/launchpad_background_light.svg',
        ]"
        class="w-full"
      />
    </div>
    <div class="absolute -z-40 w-full">
      <img
        class="mx-auto"
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/launchpad/launchpad_ellipse_dark.svg'
            : '/assets/images/launchpad/launchpad_ellipse_light.svg',
        ]"
      />
    </div>
    <div
      class="absolute -z-50 h-full right-0 top-0 w-1/2 bg-gradient-to-r from-transparent dark:to-navy-800 to-white"
    />
    <div
      class="absolute -z-50 h-full left-0 top-0 w-1/2 bg-gradient-to-l from-transparent dark:to-navy-800 to-white"
    />
    <div
      class="absolute -z-50 h-screen left-0 top-0 w-full bg-gradient-to-b from-transparent dark:to-navy-800 to-white"
    />
    <div class="container mx-auto flex flex-col w-full px-4">
      <refer-more />
      <div class="sm:mt-[200px] mt-[104px]">
        <refer-friend />
      </div>
      <div class="sm:mt-48 mt-24">
        <referral-history />
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import { onMounted } from "vue";
import ReferMore from "@/views/Referral/ReferMore.vue";
import ReferFriend from "@/views/Referral/ReferFriend.vue";
import ReferralHistory from "@/views/Referral/ReferralHistory.vue";

const store = useStore();
onMounted(() => {
  store.dispatch("getReferral");
});
</script>
