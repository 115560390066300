<template>
  <div
    :class="[
      'relative sm:w-[120px] sm:h-[120px] w-24 h-24 rounded-full',
      colorClass[0],
    ]"
  >
    <div
      :class="[
        'absolute sm:w-[100px] sm:h-[100px] w-20 h-20 rounded-full sm:top-[10px] sm:left-[10px] top-2 left-2',
        colorClass[1],
      ]"
    >
      <div
        :class="[
          'sm:w-[80px] sm:h-[80px] w-16 h-16 absolute rounded-full sm:top-[10px] sm:left-[10px] top-2 left-2',
          colorClass[2],
        ]"
      >
        <img
          v-if="imgUrl"
          :src="imgUrl"
          class="rounded-full w-[53px] h-[53px] absolute my-auto mx-auto top-0 bottom-0 left-0 right-0"
        />
        <div
          v-else
          class="text-white rounded-full w-[53px] h-[53px] absolute my-auto mx-auto top-0 bottom-0 left-0 right-0"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from "vue";

const props = defineProps({
  imgUrl: String,
  color: String,
});

const colorClass = computed(() => {
  switch (props.color) {
    case "orange":
      return [
        "dark:bg-orange-800 bg-orange-200",
        "dark:bg-orange-700 bg-orange-400",
        "dark:bg-orange-600 bg-orange-500",
      ];
    case "green":
      return [
        "dark:bg-green-800 bg-[#40B591]",
        "dark:bg-green-700 bg-[#009C6C]",
        "dark:bg-green-500 bg-[#009C6C]",
      ];
    default:
      return [
        "dark:bg-navy-200 bg-primary-300",
        "dark:bg-navy-50 bg-primary-400",
        "dark:bg-white bg-navy-10",
      ];
  }
});
</script>
