<template>
  <div class="flex flex-col flex-1 w-full">
    <div
      class="flex flex-col justify-between sm:items-center sm:flex-row items-start sm:mb-10 mb-8 px-4 gap-4"
    >
      <div class="text-[32px] font-semibold dark:text-white text-navy-800">
        {{ props.label }}
      </div>
      <div class="w-full sm:w-fit flex-shrink-0">
        <outline-button color="primary" size="md" width="full">
          <div class="font-semibold text-sm" @click="handleViewAllClick">
            View All
          </div>
        </outline-button>
      </div>
    </div>
    <template v-for="(item, index) in props.articleData" :key="item.id">
      <div
        v-if="index !== 0"
        class="my-2 dark:border-t-navy-500 border-t-navy-10 border-t"
      ></div>
      <article-summary-item
        :heading="item.heading"
        :update-time="item.time"
        @click="handleItemClick(item.url)"
      />
    </template>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
import ArticleSummaryItem from "@/views/HelpCenter/Items/ArticleSummaryItem.vue";

const props = defineProps(["articleData", "label"]);
const emit = defineEmits(["viewAllClick", "itemClick"]);

const handleViewAllClick = () => {
  emit("viewAllClick");
};

const handleItemClick = (url) => {
  emit("itemClick", url);
};
</script>
