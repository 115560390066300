<template>
  <div v-if="!isEmpty(profileData)">
    <p class="text-2xl dark:text-white text-navy-800">Identity Verification</p>
    <div class="mt-8 flex items-center gap-3">
      <circle-user-icon />
      <label class="text-lg font-semibold ml-3">{{
        profileData.profile.email_masked
      }}</label>

      <!-- Should update the type according to the verification status -->
      <badge-with-icon
        :label="verificationStatus"
        type="error"
        :icon="UnVerifiedIcon"
      />
    </div>
    <div
      class="mt-8 p-6 dark:bg-navy-600 bg-white border dark:border-navy-500 borer-navy-50 rounded-2xl w-full"
    >
      <div class="">
        <p class="font-semibold">
          To have full access to all features of EXZi and unlock transaction
          limits you must verify
        </p>
        <p class="mt-3 dark:text-navy-100 mb-6">
          It only takes 3-5 minutes to verify your account. Complete to unlock
          deposit and trading permissions.
        </p>
        <fill-button size="md" width="fit">
          <p>Get Verified</p>
          <arrow-right-icon class="w-5 h-5" />
        </fill-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import { ArrowRightIcon } from "@heroicons/vue/24/outline";
import BadgeWithIcon from "@/components/Badge/BadgeWithIcon";
import UnVerifiedIcon from "@/assets/icons/verification/UnVerifiedIcon.vue";
import CircleUserIcon from "@/assets/icons/user/CircleUserIcon.vue";

const store = useStore();
const profileData = computed(() => store.getters.profile);
const verificationStatus = computed(
  () => store.getters.verificationInfo.verification_state
);
</script>
