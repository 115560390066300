<template>
  <dropdown-select width="full" @select="handleSelect">
    <template #header="{ handleContentClick, isShowingMenu }">
      <div
        class="flex flex-row items-center justify-between h-10 border dark:border-navy-400 border-navy-50 rounded-xl w-full px-3 min-w-32"
      >
        <div
          :class="[
            model ? 'dark:text-white text-navy-800' : 'text-navy-100',
            'text-sm font-medium mr-2',
          ]"
        >
          {{ model ? model.label : props.placeholder }}
        </div>
        <div
          class="dark:text-white text-navy-800 cursor-pointer"
          @click="handleContentClick"
        >
          <chevron-up-icon v-if="isShowingMenu" class="w-4 h-4" />
          <chevron-down-icon v-else class="w-4 h-4" />
        </div>
      </div>
    </template>
    <template #menu="{ handleMenuItemClick }">
      <div
        class="w-full dark:bg-navy-600 border dark:border-navy-400 rounded-2xl p-2 bg-white border-navy-10 custom-scrollbar max-h-96"
        :style="[
          currentTheme === 'dark'
            ? 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.32)'
            : 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.16);',
        ]"
      >
        <div
          v-for="item in props.items"
          :key="item.id"
          class="rounded-lg w-full px-3 py-2.5 dark:text-white text-navy-800 cursor-pointer dark:hover:bg-navy-500 hover:bg-navy-10 flex flex-row"
          @click="handleMenuItemClick(item)"
        >
          <component :is="item.icon" class="w-5 h-5 mr-3" />
          <div class="text-sm font-medium">{{ item.label }}</div>
        </div>
      </div>
    </template>
  </dropdown-select>
</template>
<script setup>
import { defineProps, defineModel } from "vue";
import DropdownSelect from "@/components/Dropdown/DropdownSelect.vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";

const props = defineProps(["placeholder", "items"]);

const model = defineModel();
const handleSelect = (value) => {
  model.value = value;
};
</script>
