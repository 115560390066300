<template>
  <div
    class="cursor-pointer w-fit flex flex-row items-center gap-2"
    @click="handleClick"
  >
    <div
      :class="[
        'rounded-[5px] border border-navy-100 w-5 h-5 flex items-center justify-center shrink-0',
        isChecked ? 'bg-primary-700 text-white' : 'bg-transparent ',
      ]"
    >
      <check-icon v-if="isChecked" class="w-4 h-4 stroke-2" />
    </div>
    <span class="font-medium text-sm dark:text-navy-100 text-navy-200">{{
      label
    }}</span>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { CheckIcon } from "@heroicons/vue/24/outline";

const props = defineProps(["defaultValue", "label"]);
const emit = defineEmits(["change"]);
const isChecked = ref(props.defaultValue);

const handleClick = () => {
  isChecked.value = !isChecked.value;
  emit("change", isChecked.value);
};
</script>
