<template>
  <div class="w-full mb-24">
    <div
      class="flex justify-between sm:items-center sm:flex-row flex-col sm:mb-12 mb-6 gap-3"
    >
      <p class="text-2xl font-semibold">Asset Overview</p>
      <div class="gap-4 flex overflow-auto custom-scrollbar">
        <fill-button size="sm" @click="goToDeposit">
          <deposit-icon />
          <span>Deposit</span>
        </fill-button>
        <outline-button size="sm" color="primary" @click="goToWithdraw"
          >Withdraw</outline-button
        >
        <outline-button size="sm" color="primary" @click="goToBuySell"
          >Buy Crypto</outline-button
        >
        <outline-button size="sm" color="primary" @click="goToTransfer">
          Transfer
        </outline-button>
      </div>
      <asset-balance class="sm:hidden block" size="lg" />
    </div>
    <div class="flex gap-6 sm:flex-row flex-col-reverse">
      <div class="flex-1">
        <asset-balance size="lg" class="sm:block hidden" />
        <div class="h-14"></div>
        <underline-tab
          :items="tabItems"
          :default-selected="tabItems[0]"
          @change="handleChangeTab"
        />
        <template v-if="selectedTab.id == 1">
          <account-view class="mt-6 mb-14" />
          <recent-transactions />
        </template>
        <template v-else>
          <coin-view />
        </template>
      </div>
      <div
        class="flex sm:flex-col flex-row gap-10 overflow-auto custom-scrollbar"
      >
        <asset-movements />
        <balance-details />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import AssetBalance from "@/common/AssetsBalance/AssetBalance.vue";
import RecentTransactions from "@/views/Asset/Overview/RecentTransactions.vue";
import AccountView from "@/views/Asset/Overview/AccountView.vue";
import CoinView from "@/views/Asset/Overview/CoinView.vue";
import AssetMovements from "@/views/Asset/Overview/AssetMovements.vue";
import BalanceDetails from "@/views/Asset/Overview/BalanceDetails.vue";
import DepositIcon from "@/assets/icons/asset/DepositIcon.vue";

const tabItems = [
  { id: 1, name: "My Accounts" },
  { id: 2, name: "Coin View" },
];

const router = useRouter();
const store = useStore();

const selectedTab = ref(tabItems[0]);

onMounted(() => {
  store.dispatch("fetchTransactions", 1);
});

const handleChangeTab = (item) => {
  selectedTab.value = item;
};

const goToDeposit = () => {
  router.push({ name: "asset-deposit-crypto" });
};

const goToWithdraw = () => {
  router.push({ name: "asset-withdraw-crypto" });
};

const goToBuySell = () => {
  router.push({ name: "buy-sell" });
};

const goToTransfer = () => {
  store.dispatch("setTransferData", {});
};
</script>
