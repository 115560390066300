<template>
  <button
    :class="[
      'w-full rounded-2xl p-4 flex flex-row items-center gap-5 cursor-pointer',
      styleClass,
    ]"
    @click="handleClick"
  >
    <apple-store-icon
      v-if="type === 'apple'"
      class="w-10 h-10 dark:text-white text-primary-700"
    />
    <google-play-icon
      v-if="type === 'android'"
      class="w-10 h-10 dark:text-white text-primary-700"
    />
    <div class="flex flex-col text-left">
      <p class="text-xs font-medium dark:text-navy-50 text-navy-200">
        {{ type === "apple" ? "Download on the" : "Get it on" }}
      </p>
      <p class="text-sm font-semibold dark:text-white text-navy-800">
        {{ type === "apple" ? "Apple Store" : "Google Play" }}
      </p>
    </div>
    <div class="grow" />
    <arrow-up-right-icon
      class="w-5 h-5 dark:text-navy-200 text-primary-700 stroke-2"
    />
  </button>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import { ArrowUpRightIcon } from "@heroicons/vue/24/outline";
import AppleStoreIcon from "@/assets/icons/home/AppleStoreIcon.vue";
import GooglePlayIcon from "@/assets/icons/common/GooglePlayIcon.vue";

const props = defineProps({
  type: {
    type: String,
    default: "apple",
  },
  mode: String,
});

const emits = defineEmits(["click"]);

const styleClass = computed(() => {
  switch (props.mode) {
    case "outline":
      return "dark:border dark:border-navy-600 dark:bg-navy-800 bg-primary-100";
    case "fill":
    default:
      return "dark:bg-navy-500 bg-white";
  }
});

const handleClick = () => {
  emits("click");
};
</script>
