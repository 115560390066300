<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.875 3.75H14.0625V2.8125C14.0625 2.23234 13.832 1.67594 13.4218 1.2657C13.0116 0.855468 12.4552 0.625 11.875 0.625H8.125C7.54484 0.625 6.98844 0.855468 6.5782 1.2657C6.16797 1.67594 5.9375 2.23234 5.9375 2.8125V3.75H3.125C2.87636 3.75 2.6379 3.84877 2.46209 4.02459C2.28627 4.2004 2.1875 4.43886 2.1875 4.6875C2.1875 4.93614 2.28627 5.1746 2.46209 5.35041C2.6379 5.52623 2.87636 5.625 3.125 5.625H3.4375V16.25C3.4375 16.6644 3.60212 17.0618 3.89515 17.3549C4.18817 17.6479 4.5856 17.8125 5 17.8125H15C15.4144 17.8125 15.8118 17.6479 16.1049 17.3549C16.3979 17.0618 16.5625 16.6644 16.5625 16.25V5.625H16.875C17.1236 5.625 17.3621 5.52623 17.5379 5.35041C17.7137 5.1746 17.8125 4.93614 17.8125 4.6875C17.8125 4.43886 17.7137 4.2004 17.5379 4.02459C17.3621 3.84877 17.1236 3.75 16.875 3.75ZM7.8125 2.8125C7.8125 2.72962 7.84542 2.65013 7.90403 2.59153C7.96263 2.53292 8.04212 2.5 8.125 2.5H11.875C11.9579 2.5 12.0374 2.53292 12.096 2.59153C12.1546 2.65013 12.1875 2.72962 12.1875 2.8125V3.75H7.8125V2.8125ZM14.6875 15.9375H5.3125V5.625H14.6875V15.9375ZM9.0625 8.125V13.125C9.0625 13.3736 8.96373 13.6121 8.78791 13.7879C8.6121 13.9637 8.37364 14.0625 8.125 14.0625C7.87636 14.0625 7.6379 13.9637 7.46209 13.7879C7.28627 13.6121 7.1875 13.3736 7.1875 13.125V8.125C7.1875 7.87636 7.28627 7.6379 7.46209 7.46209C7.6379 7.28627 7.87636 7.1875 8.125 7.1875C8.37364 7.1875 8.6121 7.28627 8.78791 7.46209C8.96373 7.6379 9.0625 7.87636 9.0625 8.125ZM12.8125 8.125V13.125C12.8125 13.3736 12.7137 13.6121 12.5379 13.7879C12.3621 13.9637 12.1236 14.0625 11.875 14.0625C11.6264 14.0625 11.3879 13.9637 11.2121 13.7879C11.0363 13.6121 10.9375 13.3736 10.9375 13.125V8.125C10.9375 7.87636 11.0363 7.6379 11.2121 7.46209C11.3879 7.28627 11.6264 7.1875 11.875 7.1875C12.1236 7.1875 12.3621 7.28627 12.5379 7.46209C12.7137 7.6379 12.8125 7.87636 12.8125 8.125Z"
      fill="currentColor"
    />
  </svg>
</template>
