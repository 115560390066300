<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9805 5.5106L14.4891 2.01998C14.1961 1.72706 13.7987 1.5625 13.3844 1.5625C12.9701 1.5625 12.5727 1.72706 12.2797 2.01998L2.64532 11.6536C2.49973 11.7983 2.3843 11.9704 2.30571 12.16C2.22713 12.3497 2.18695 12.553 2.18751 12.7583V16.2497C2.18751 16.6641 2.35213 17.0615 2.64515 17.3545C2.93818 17.6475 3.3356 17.8122 3.75001 17.8122H16.875C17.1236 17.8122 17.3621 17.7134 17.5379 17.5376C17.7137 17.3618 17.8125 17.1233 17.8125 16.8747C17.8125 16.626 17.7137 16.3876 17.5379 16.2118C17.3621 16.0359 17.1236 15.9372 16.875 15.9372H9.76563L17.9805 7.72076C18.1256 7.57566 18.2408 7.40338 18.3193 7.21377C18.3979 7.02416 18.4383 6.82093 18.4383 6.61568C18.4383 6.41044 18.3979 6.2072 18.3193 6.01759C18.2408 5.82798 18.1256 5.6557 17.9805 5.5106ZM7.10938 15.9372H4.06251V12.8903L10.625 6.32779L13.6719 9.37467L7.10938 15.9372ZM15 8.04654L11.9531 4.99967L13.3859 3.56685L16.4328 6.61373L15 8.04654Z"
      fill="currentColor"
    />
  </svg>
</template>
