<template>
  <div>
    <dropdown-select width="full" @select="handleSelectToken">
      <template #header="{ handleContentClick, isShowingMenu }">
        <text-edit
          v-model="selectedCoin"
          placeholder="Search coin"
          width="full"
          rounded="xl"
          @focus="handleContentClick(true)"
        >
          <template #prefix>
            <img
              v-if="selectedCurrency.icon"
              :src="selectedCurrency.icon"
              class="w-6 h-6 mr-4 rounded-full"
            />
            <magnifying-glass-icon v-else class="w-6 h-6 mr-4 text-navy-200" />
          </template>
          <template #suffix>
            <chevron-down-icon v-if="!isShowingMenu" class="w-6 h-6" />
            <chevron-up-icon v-else class="w-6 h-6" />
          </template>
        </text-edit>
      </template>
      <template #menu="{ handleMenuItemClick }">
        <div
          class="dark:bg-navy-600 bg-white p-4 rounded-2xl dark:border dark:border-navy-400 mt-2 dark:shadow-none shadow-md"
        >
          <template v-if="showHistory">
            <div class="flex itemx-center justify-between">
              <p class="text-navy-200 text-sm font-semibold">History</p>
              <trash-icon
                class="w-5 h-5 text-navy-200 cursor-pointer"
                @click="handleRemoveHistory"
              />
            </div>
            <div
              class="mt-3 pb-3 mb-3 overflow-auto custom-scrollbar flex gap-2"
            >
              <text-badge
                v-for="historyItem in historyArray"
                :key="historyItem.iso3"
                size="md"
                class="dark:bg-navy-500 dark:text-navy-50 text-xs font-medium"
                :label="historyItem.iso3"
              >
                <img :src="historyItem.icon" class="w-5 h-5" alt="Token icon" />
              </text-badge>
            </div>
          </template>
          <div class="max-h-60 overflow-auto custom-scrollbar">
            <div
              v-for="currency in currencies"
              :key="currency.iso3"
              class="px-3 py-2 flex font-semibold gap-3 items-center hover:dark:bg-navy-400 hover:bg-navy-10 cursor-pointer rounded-xl"
              @click="handleMenuItemClick(currency)"
            >
              <img class="w-8 h-8" :src="currency.icon" alt="Token icon" />
              <div>
                <p class="text-base">{{ currency.iso3 }}</p>
                <p class="text-xs dark:text-navy-100 text-navy-200">
                  {{ currency.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </dropdown-select>
    <div
      v-if="showHistory"
      class="mt-4 overflow-auto custom-scrollbar flex gap-2"
    >
      <text-badge
        v-for="historyItem in historyArray"
        :key="historyItem.iso3"
        size="md"
        :class="[
          'text-xs font-medium',
          selectedCoin.toLowerCase() === historyItem.iso3.toLowerCase()
            ? 'dark:bg-navy-400 bg-navy-50'
            : 'dark:bg-navy-500 bg-navy-10 dark:text-navy-50 text-navy-300',
        ]"
        :label="historyItem.iso3"
        @click="handleSelectToken(historyItem)"
      >
        <img :src="historyItem.icon" class="w-5 h-5" alt="Token icon" />
      </text-badge>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineEmits, defineProps } from "vue";
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
} from "@heroicons/vue/24/outline";
import TextBadge from "@/components/Badge/TextBadge.vue";

const props = defineProps({
  currencies: Array,
  showHistory: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits(["select"]);
const history = ref(new Set());
const selectedCoin = ref("");
const selectedCurrency = ref({});

const currencies = computed(() =>
  props.currencies.filter((item) =>
    item.iso3.toLowerCase().includes(selectedCoin.value.toLowerCase())
  )
);

const historyArray = computed(() => Array.from(history.value));

const handleSelectToken = (currency) => {
  history.value.add(currency);
  selectedCoin.value = currency.iso3;
  selectedCurrency.value = currency;
};

const handleRemoveHistory = () => {
  history.value = new Set();
};

watch(selectedCoin, () => {
  const coin = props.currencies.find(
    (item) => item.iso3.toLowerCase() == selectedCoin.value.toLowerCase()
  );
  if (coin) {
    selectedCurrency.value = coin;
    history.value.add(coin);
  }
});

watch(selectedCurrency, () => {
  emits("select", selectedCurrency.value);
});
</script>
