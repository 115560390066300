<template>
  <div
    class="flex sm:flex-row flex-col rounded-2xl border dark:border-navy-500 border-navy-50 p-3 gap-6 sm:items-center"
  >
    <div class="p-3 rounded-2xl dark:bg-navy-500 bg-primary-200 relative w-fit">
      <div
        class="w-8 h-8 rounded-full dark:bg-navy-500 bg-primary-200 flex items-center justify-center absolute top-14 left-14"
      >
        <img class="w-6 h-6" :src="tokenImg" alt="Token image" />
      </div>
      <qr-code class="dark:text-white text-primary-800" />
    </div>
    <div>
      <p class="text-sm font-medium dark:text-navy-100 text-navy-200">
        Address
      </p>
      <p class="text-base font-semibold mt-1 mb-4 w-full break-all">
        {{ address }}
      </p>
      <fill-button size="md" color="primary" @click="handleCopyAddress">
        <document-duplicate-icon class="w-5 h-5" />
        <span>Copy Address</span>
      </fill-button>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useStore } from "vuex";
import QrCode from "@/assets/icons/common/QrCode.vue";
import { DocumentDuplicateIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  address: String,
  tokenImg: String,
});

const store = useStore();

const handleCopyAddress = async () => {
  try {
    await navigator.clipboard.writeText(props.address);
    store.dispatch("setToast", {
      type: "success",
      message: "Copied address",
    });
  } catch (error) {
    store.dispatch("setToast", {
      type: "error",
      message: "Copy failed",
    });
  }
};
</script>
