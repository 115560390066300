import numeral from "numeral";
import moment from "moment";
import { TRADE_ACTION_TYPES, TRADE_TYPES } from "@/constants/constants";
import TraderPrepare from "@/utils/TraderPrepare";
import {
  addPlus,
  numberAddCommas,
  formatNumberInKilo,
} from "@/utils/NumberFormating";

export const isEmpty = (data) => {
  // Check for undefined or null
  if (data === undefined || data === null) {
    return true;
  }

  // Check for string type
  if (typeof data === "string") {
    return data.trim() === "";
  }

  // Check for object type (including arrays)
  if (typeof data === "object") {
    if (Array.isArray(data)) {
      return data.length === 0;
    } else {
      return Object.keys(data).length === 0;
    }
  }

  // Return false for other types (numbers, functions, etc.)
  return false;
};

export const allPairItemsFromData = (allPairs, activeCurrencies) =>
  !isEmpty(allPairs) && !isEmpty(activeCurrencies)
    ? allPairs.map((currentPair) => {
        const item = { ...currentPair };
        item.id = currentPair._id;
        item.name = currentPair.name;
        item.pair_name = `${currentPair.main.iso3}/${currentPair.second.iso3}`;
        item.main = activeCurrencies[currentPair.main.id];
        item.second = activeCurrencies[currentPair.second.id];
        // 24h Change
        item.price_f = currentPair.rate_f;
        item.price = TraderPrepare.formatNumCurrency(
          +currentPair.rate_f,
          8,
          2,
          true
        );
        item.actualPrice = currentPair.rate_f;
        item.percent = addPlus(currentPair?.percent.toFixed(2)) + "%";
        item.actualPercent = currentPair?.percent;
        item.isMinus = currentPair?.percent < 0;

        // 24h High
        item.high24 = numberAddCommas(
          currentPair?.last_rateW_f,
          currentPair.main.decimal
        );

        // 24h Low
        item.low24 = numberAddCommas(
          currentPair?.last_rateH_f,
          currentPair.main.decimal
        );

        // 24h Volume main
        item.volume24main = formatNumberInKilo(
          currentPair?.volume_world / Math.pow(10, currentPair.main.decimal)
        );

        // 24h Volume second
        item.volume24second = formatNumberInKilo(
          currentPair?.volume_second_world /
            Math.pow(10, currentPair.second.decimal)
        );
        return item;
      })
    : [];

export const formatNumberWithGroupedDecimals = (number) => {
  const parts = parseFloat(number).toFixed(9).split(".");
  const integerPart = numeral(parts[0]).format("0,0");
  let decimalPart = "";
  if (parts[1]) {
    const trimmedDecimal = parts[1].replace(/0+$/, "");
    if (trimmedDecimal.length > 0) {
      decimalPart = trimmedDecimal.replace(/(\d{3})(?=\d)/g, "$1,");
    }
  }
  return decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
};

export const formatNumber = (number) => {
  let preciseNumber = parseFloat(number).toPrecision(10);
  let floatNumber = parseFloat(preciseNumber);
  return floatNumber.toFixed(
    Math.min(6, (floatNumber.toString().split(".")[1] || "").length)
  );
};

export const convertToField = (obj, field) => {
  if (!obj) return "";
  if (!field) return obj;
  const fields = field.split(".");
  return fields.reduce((acc, cur) => {
    return acc ? acc[cur] : acc;
  }, obj);
};

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return 1;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 2;
  }

  return 0;
};

export const formatOrderHistory = (history) => {
  return history.map((item) => ({
    ...item,
    time_create_modified: moment(item.time_create * 1000).format(
      "MM-DD hh:mm:ss"
    ),
    pair_name_modified: `${item.currency_main_iso3}/${item.currency_second_iso3}`,
    type_trade_modified: TRADE_TYPES[item.type_trade],
    type_modified: TRADE_ACTION_TYPES[item.type],
    rate_f_modified: `${numberAddCommas(
      item.status === 2 ? item.rate_avg_done_f : item.rate_f
    )} ${item.currency_second_iso3}`,
    rate_avg_done_f_modified: numberAddCommas(
      item.status === 2 ? item.rate_avg_done_f : item.rate_f
    ),
    volume_f_modified: numberAddCommas(item.volume_f),
    price_done_f_modified: numberAddCommas(item.price_f),
    filled_modified: `${numberAddCommas(
      (item.price_done_f * item.filled_percent) / 100
    )} ${item.currency_main_iso3}`,
    status_modified: item.status !== 2,
  }));
};

export const scrollToTop = () => {
  const body = document.body;
  body.scrollIntoView({ behavior: "instant", block: "start" });
};

export const formatFileSize = (bytes) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Bytes";

  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const fileSize = (bytes / Math.pow(1024, i)).toFixed(2);

  return `${fileSize} ${sizes[i]}`;
};

// Get Local Timezone. Format => UTC+/-H
export const getLocalUtcOffset = () => {
  const timezoneOffset = new Date().getTimezoneOffset();
  const sign = timezoneOffset <= 0 ? "+" : "-";
  const hours = Math.floor(Math.abs(timezoneOffset) / 60);
  return `UTC${sign}${String(hours)}`;
};

// Get Time from UTC+/-H Format.
export const getTimeFromUtcOffset = (utcOffset) => {
  const match = utcOffset.match(/UTC([+-])(\d{1,2})/);
  if (!match) {
    throw new Error("Invalid UTC offset format");
  }
  const sign = match[1] === "+" ? 1 : -1;
  const hours = parseInt(match[2], 10);
  const totalOffsetInHours = sign * (hours * 60);
  const utcTime = new Date(
    new Date().getTime() + new Date().getTimezoneOffset() * 60000
  );
  const localTime = new Date(utcTime.getTime() + totalOffsetInHours * 60000);
  const options = { hour: "2-digit", minute: "2-digit", hour12: false };
  return localTime.toLocaleTimeString("en-GB", options);
};

// Get All Timezones and Insert LocalTimezone. First Foramt => UTC+/-H: UTC-12 to UTC+11
export const getTimezones = () => {
  var timezones = Array.from({ length: 24 }, (_, i) => {
    const offset = 12 - i;
    const sign = offset >= 0 ? "+" : "-";
    return `UTC${sign}${String(Math.abs(offset))}`;
  }).filter(
    (timezone) =>
      timezone !== getLocalUtcOffset() &&
      timezone !== "UTC-2" &&
      timezone !== "UTC-11"
  );
  timezones = [getLocalUtcOffset(), ...timezones];
  return timezones.map((item, index) => ({
    value: item,
    label:
      index === 0
        ? `${getTimeFromUtcOffset(item)} (${item}, Local Time zone)`
        : `${getTimeFromUtcOffset(item)} (${item})`,
  }));
};

export const mapUtcToTradingViewTimezone = (timezone) => {
  const timezoneMapping = {
    "UTC+0": "Etc/UTC", // UTC
    "UTC+1": "Europe/Berlin", // Central European Time (CET)
    "UTC+2": "Europe/Helsinki", // Eastern European Time (EET)
    "UTC+3": "Europe/Moscow", // Moscow Standard Time (MSK)
    "UTC+4": "Asia/Dubai", // Gulf Standard Time (GST)
    "UTC+5": "Asia/Karachi", // Pakistan Standard Time (PKT)
    "UTC+6": "Asia/Dhaka", // Bangladesh Standard Time (BST)
    "UTC+7": "Asia/Bangkok", // Indochina Time (ICT)
    "UTC+8": "Asia/Singapore", // Singapore Standard Time (SGT)
    "UTC+9": "Asia/Tokyo", // Japan Standard Time (JST)
    "UTC+10": "Australia/Sydney", // Australian Eastern Standard Time (AEST)
    "UTC+11": "Pacific/Norfolk", // Norfolk Island Time (NFT)
    "UTC+12": "Pacific/Auckland", // New Zealand Standard Time (NZST)

    "UTC-1": "Atlantic/Reykjavik", // Iceland Time (GMT)
    // "UTC-2": "Atlantic/South_Georgia", // South Georgia Time (GST) #Unavailable Timezone in Trading view Chart.
    "UTC-3": "America/Argentina/Buenos_Aires", // Argentina Time (ART)
    "UTC-4": "America/Caracas", // Venezuela Time (VET)
    "UTC-5": "America/New_York", // Eastern Standard Time (EST)
    "UTC-6": "America/Chicago", // Central Standard Time (CST)
    "UTC-7": "US/Mountain", // Mountain Standard Time (MST)
    "UTC-8": "America/Los_Angeles", // Pacific Standard Time (PST)
    "UTC-9": "America/Anchorage", // Alaska Standard Time (AKST)
    "UTC-10": "Pacific/Honolulu", // Hawaii-Aleutian Standard Time (HST)
    // "UTC-11": "Pacific/Midway", // Samoa Standard Time (SST) - Not in your list, closest is Pacific/Norfolk #Unavailable Timezone in Trading view Chart.
  };
  return timezoneMapping[timezone] || getLocalUtcOffset();
};

export const formatDate = (date) => {
  return moment(date).format("DD.MM.yyyy");
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const changeUnixTime = (value) => {
  const timestamp = value * 1000;
  const date = new Date(timestamp);
  const month = date.toLocaleString("en-US", { month: "long" });
  const day = date.getDate();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const formattedDate = `${month} ${day}, ${hours}:${minutes}`;
  return formattedDate;
};

// Check validation for error object
export const checkFormValidation = (errorObject) => {
  return Object.values(errorObject).every((item) => item === null);
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};
