import session_manager from "@/utils/SessionManager";

const state = {
  language: session_manager.getLanguage(),
};

const mutations = {
  SET_LANGUAGE(state, language) {
    state.language = language;
    session_manager.setLanguage(language);
  },
};

const actions = {
  setLanguage({ commit }, language) {
    commit("SET_LANGUAGE", language);
  },
};

const getters = {
  currentLanguage: (state) => state.language,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
