<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.121 15.5559C20.3072 14.1562 19.875 12.1472 19.875 9.75C19.875 7.66142 19.0453 5.65838 17.5685 4.18153C16.0916 2.70469 14.0886 1.875 12 1.875C9.91145 1.875 7.90841 2.70469 6.43156 4.18153C4.95471 5.65838 4.12503 7.66142 4.12503 9.75C4.12503 12.1481 3.69471 14.1562 2.88096 15.5559C2.71491 15.8414 2.6269 16.1656 2.62574 16.4958C2.62459 16.8261 2.71035 17.1509 2.8744 17.4375C3.03742 17.7243 3.27402 17.9624 3.5598 18.1272C3.84557 18.292 4.17015 18.3775 4.50003 18.375H7.8919C7.98493 19.4003 8.45797 20.3537 9.21811 21.048C9.97825 21.7423 10.9705 22.1273 12 22.1273C13.0295 22.1273 14.0218 21.7423 14.7819 21.048C15.5421 20.3537 16.0151 19.4003 16.1082 18.375H19.5C19.8294 18.377 20.1534 18.2913 20.4387 18.1265C20.7239 17.9617 20.9601 17.7239 21.1228 17.4375C21.2876 17.1513 21.3742 16.8267 21.3738 16.4965C21.3735 16.1662 21.2863 15.8418 21.121 15.5559ZM12 19.875C11.5678 19.8751 11.1488 19.7258 10.8139 19.4525C10.4791 19.1791 10.249 18.7985 10.1625 18.375H13.8375C13.7511 18.7985 13.521 19.1791 13.1861 19.4525C12.8513 19.7258 12.4323 19.8751 12 19.875ZM5.1244 16.125C5.95409 14.4375 6.37503 12.2944 6.37503 9.75C6.37503 8.25816 6.96766 6.82742 8.02255 5.77252C9.07744 4.71763 10.5082 4.125 12 4.125C13.4919 4.125 14.9226 4.71763 15.9775 5.77252C17.0324 6.82742 17.625 8.25816 17.625 9.75C17.625 12.2934 18.045 14.4375 18.8747 16.125H5.1244Z"
      fill="currentColor"
    />
  </svg>
</template>
