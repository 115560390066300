<template>
  <div
    :class="[
      'min-w-[290px] h-[144px]  rounded-xl pt-2 shadow-lg gap-2 dark:border-navy-400 border dark:bg-navy-600 dark:shadow-md dark:shadow-black/[0.32] bg-white shadow-black/[0.16]',
      showPairList === true ? 'rounded-r-none' : '',
    ]"
  >
    <navbar-submenu-item
      :padding="true"
      title="Spot Trading"
      label="Buy and cell crypto with ease"
      :icon="SpotTradingIcon"
      path-name="trading"
      @click="handleSubMenuClick"
      @mouseEnter="handleMouseEnter"
      @mouseLeave="handleMouseLeave"
      :show-extra-content="showPairList"
    >
      <div
        :class="[
          'border border-navy-10 dark:border-navy-400 rounded-2xl overflow-hidden box-border',
          showPairList === true ? 'rounded-l-none' : '',
        ]"
      >
        <pair-dropdown-menu
          :all-pair-items="props.allPairItems.value"
          :display-only-pairs="true"
          @click-item="handleClickMenuItem"
        />
      </div>
    </navbar-submenu-item>
    <navbar-submenu-item
      :padding="true"
      title="Convert"
      label="Zero fees, no slippage"
      :icon="ConvertIcon"
      path-name="convert"
      @click="handleSubMenuClick"
    />
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import NavbarSubmenuItem from "@/layout/AppNavbar/item/NavbarSubmenuItem.vue";
import PairDropdownMenu from "@/common/PairDropdown/PairDropdownMenu.vue";
import SpotTradingIcon from "@/assets/icons/common/SpotTradingIcon.vue";
import ConvertIcon from "@/assets/icons/common/ConvertIcon.vue";

const props = defineProps(["allPairItems"]);
const emit = defineEmits(["menuClick"]);
const showPairList = ref(true);
const router = useRouter();

const handleMouseEnter = () => {
  showPairList.value = true;
};

const handleMouseLeave = () => {
  showPairList.value = false;
};

const handleClickMenuItem = (name) => {
  emit("menuClick");
  router.push({
    name: "trading",
    params: {
      pair: name,
    },
  });
};

const handleSubMenuClick = () => {
  emit("menuClick");
};
</script>
