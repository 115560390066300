<template>
  <div class="w-full">
    <slot name="top" />
    <div
      class="flex sm:flex-row flex-col sm:gap-12 gap-6 container mx-auto w-full px-2 sm:px-4"
    >
      <sidebar-menu :menu-data="props.menuData" />
      <slot name="content" :top-data="topData" />
    </div>
    <slot name="bottom" />
  </div>
</template>
<script setup>
import SidebarMenu from "@/components/SidebarMenu/SidebarMenu.vue";
import { defineProps, ref } from "vue";

const topData = ref("");

const props = defineProps({
  menuData: Array,
});
</script>
