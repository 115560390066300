<template>
  <div class="relative mt-40">
    <div
      class="absolute left-0 h-full w-36 sm:w-80 bg-gradient-to-r to-transparent -z-40 dark:from-navy-800 from-white"
    />
    <div
      class="absolute right-0 h-full w-36 sm:w-80 bg-gradient-to-l to-transparent -z-40 dark:from-navy-800 from-white"
    />
    <div
      class="sm:block absolute w-full top-60 h-[400px] -z-50 overflow-hidden"
    >
      <img
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/home/background_lines.svg'
            : '/assets/images/home/background_lines_light.svg',
        ]"
        class="w-full h-full object-cover absolute top-0 left-0"
      />
    </div>
    <div class="sm:block absolute w-full h-full -z-50 overflow-hidden">
      <img
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/home/background_ellipse_1.svg'
            : '/assets/images/home/background_ellipse_1_light.svg',
        ]"
        class="w-auto h-auto object-cover absolute sm:top-0 top-48 left-0 my-auto"
      />
    </div>
    <div class="absolutue">
      <img
        src="/assets/images/home/bitcoin_icon.png"
        class="w-32 absolute left-[50%] top-10"
      />
    </div>
    <div class="absolutue">
      <img
        src="/assets/images/home/coin2_icon.png"
        class="w-20 absolute right-[10%] top-[20%]"
      />
    </div>
    <div class="absolutue">
      <img
        src="/assets/images/home/coin3_icon.png"
        class="w-36 absolute right-[10%] top-[60%] max-sm:top-[70%]"
      />
    </div>
    <div class="absolutue">
      <img
        src="/assets/images/home/coin4_icon.png"
        class="w-24 absolute left-[20%] top-[65%] max-sm:top-[75%]"
      />
    </div>
    <div class="absolutue">
      <img
        src="/assets/images/home/coin3_icon.png"
        class="w-28 absolute left-[10%] top-[50%] max-sm:hidden"
      />
    </div>
    <div class="flex flex-col mt-[300px] px-4 mb-96 max-w-[900px] mx-auto">
      <div
        class="font-semibold text-[40px] mb-12 text-center sm:text-5xl sm:font-bold dark:text-white text-navy-800"
      >
        Start your crypto journey
      </div>
      <div
        class="text-lg sm:text-xl font-normal text-center mb-10 dark:text-navy-50 text-navy-400"
      >
        EXZi makes it easy to get started. Sign up today to buy and sell 200+
        cryptocurrencies.
      </div>
      <div class="w-80 mx-auto">
        <fill-button size="lg" @click="handleTradingClick">
          Start trading now
        </fill-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const handleTradingClick = () => {
  router.push({ name: "trading" });
};
</script>
