<template>
  <div class="flex flex-col items-center px-4">
    <div
      class="text-[40px] font-bold mb-12 text-center sm:mb-16 sm:text-5xl dark:text-white text-navy-800"
    >
      How to referral a friend
    </div>
    <div class="flex flex-col max-sm:gap-14 mb-16 sm:flex-row sm:w-full">
      <div class="flex flex-col items-center justify-between sm:basis-1/3">
        <div class="flex flex-row w-full items-center mb-6 justify-center">
          <div class="flex-grow w-full max-sm:hidden"></div>
          <div
            class="w-16 h-16 rounded-full border border-primary-700 flex justify-center items-center text-xl font-bold flex-shrink-0 dark:text-white dark:bg-navy-800 bg-primary-100 text-navy-800"
          >
            <span>
              <img
                :src="
                  currentTheme === 'dark'
                    ? '/assets/images/referral/referralfriend/step-1.svg'
                    : '/assets/images/referral/referralfriend/step-1-black.svg'
                "
              />
            </span>
          </div>
          <div class="flex-grow w-full custom-line max-sm:hidden"></div>
        </div>
        <div
          class="text-xl font-medium flex-grow flex items-center justify-center text-center dark:text-white text-navy-800"
        >
          Sign up and get verified
        </div>
      </div>
      <div class="flex flex-col items-center justify-between sm:basis-1/3">
        <div class="flex flex-row w-full items-center mb-6 justify-center">
          <div class="flex-grow w-full max-sm:hidden custom-line" />
          <div
            class="w-16 h-16 rounded-full border border-primary-700 flex justify-center items-center text-xl font-bold flex-shrink-0 dark:text-white dark:bg-navy-800 bg-primary-100 text-navy-800"
          >
            <span>
              <img
                :src="
                  currentTheme === 'dark'
                    ? '/assets/images/referral/referralfriend/step-2.svg'
                    : '/assets/images/referral/referralfriend/step-2-black.svg'
                "
              />
            </span>
          </div>
          <div class="flex-grow w-full max-sm:hidden custom-line" />
        </div>
        <div
          class="text-xl font-medium flex-grow flex items-center justify-center text-center dark:text-white text-navy-800"
        >
          Share referral code with your friends
        </div>
      </div>
      <div class="flex flex-col items-center justify-between sm:basis-1/3">
        <div class="flex flex-row w-full items-center mb-6 justify-center">
          <div class="flex-grow w-full max-sm:hidden custom-line" />
          <div
            class="w-16 h-16 rounded-full border border-primary-700 flex justify-center items-center text-xl font-bold flex-shrink-0 dark:text-white dark:bg-navy-800 bg-primary-100 text-navy-800"
          >
            <span>
              <img
                :src="
                  currentTheme === 'dark'
                    ? '/assets/images/referral/referralfriend/step-3.svg'
                    : '/assets/images/referral/referralfriend/step-3-black.svg'
                "
              />
            </span>
          </div>
          <div class="flex-grow w-full max-sm:hidden" />
        </div>
        <div
          class="text-xl font-medium flex-grow flex items-center justify-center text-center dark:text-white text-navy-800"
        >
          Trade and get reward
        </div>
      </div>
    </div>
  </div>
</template>
<script setup></script>
