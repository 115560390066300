import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/assets/tailwind.css";
import "@/assets/css/input.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import i18n from "./i18n";
import clevertap from "clevertap-web-sdk";
import { CLEVERTAP_PROJECT_ID } from "./constants/constants";

// Components
import TextEdit from "@/components/TextEdit/TextEdit.vue";
import PasswordInput from "@/components/TextEdit/PasswordInput.vue";
import LinkButton from "@/components/Button/LinkButton.vue";
import FilledButton from "@/components/Button/FilledButton.vue";
import ListSelect from "@/components/List/ListSelect.vue";
import ToastView from "@/components/Toast/ToastView.vue";
import CollapseView from "@/components/Collapse/CollapseView.vue";
import CheckBox from "@/components/CheckBox/CheckBox.vue";
import FillButton from "@/components/Button/FillButton.vue";
import OutlineButton from "@/components/Button/OutlineButton.vue";
import DropdownSelect from "@/components/Dropdown/DropdownSelect.vue";
import ComboBox from "@/components/ComboBox/ComboBox.vue";

// Mixins
import themeMixin from "@/mixins/themeMixins";

const app = createApp(App);
app.component("text-edit", TextEdit);
app.component("password-input", PasswordInput);
app.component("link-button", LinkButton);
app.component("fill-button", FillButton);
app.component("outline-button", OutlineButton);
app.component("filled-button", FilledButton);
app.component("list-select", ListSelect);
app.component("toast-view", ToastView);
app.component("collapse-view", CollapseView);
app.component("check-box", CheckBox);
app.component("dropdown-select", DropdownSelect);
app.component("VueDatePicker", VueDatePicker);
app.component("combo-box", ComboBox);

if (CLEVERTAP_PROJECT_ID) {
  clevertap.spa = true;
  clevertap.init(CLEVERTAP_PROJECT_ID);

  window.addEventListener("message", (event) => {
    const eventType = event.data?.type;
    if (
      (eventType === "request_pushes" || eventType === "loggedin") === false
    ) {
      return;
    }

    if (eventType === "request_pushes") {
      clevertap.notifications.push({
        titleText: "Would you like to receive Push Notifications?",
        bodyText:
          "We promise to only send you relevant content and give you updates on your transactions",
        okButtonText: "Sign me up!",
        rejectButtonText: "No thanks",
        okButtonColor: "#f28046",
      });
    }

    if (event.data?.user) {
      const { clevertap: clevertapConfig = null } = event.data.user;

      clevertap.onUserLogin.push({
        Site: {
          Identity: clevertapConfig.identification,
          "MSG-push": true,
        },
      });
    }
  });
}

app.mixin(themeMixin);
app.use(store);
app.use(router);
app.use(i18n);
app.mount("#app");
