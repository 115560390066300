<template>
  <div
    class="dark:bg-navy-600 border dark:border-navy-400 border-navy-10 rounded-2xl p-3.5 sm:p-4 bg-white shadow-xl shadow-black/[0.16] dark:shadow-none"
  >
    <div class="flex flex-col">
      <div class="dark:text-white text-base font-semibold mb-3.5 sm:mb-4">
        {{ props.label }}
      </div>
      <div
        class="dark:border-navy-400 border-navy-50 bg-white dark:bg-navy-600"
      >
        <text-edit
          v-model="searchText"
          :filled="false"
          placeholder="Search"
          rounded="full"
          size="sm"
          type="text"
        >
          <template #prefix>
            <div class="w-6 h-6 mr-2">
              <magnifying-glass-icon
                :class="[
                  searchText.length > 0
                    ? 'dark:text-white text-navy-800'
                    : 'text-navy-200',
                ]"
              />
            </div>
          </template>
          <template v-if="searchText.length > 0" #suffix>
            <div
              class="w-6 h-6 ml-2"
              @click="
                () => {
                  searchText = '';
                }
              "
            >
              <x-circle-icon class="text-navy-200 stroke-2 w-6 h-6" />
            </div>
          </template>
        </text-edit>
      </div>
      <div
        class="flex flex-col mt-3.5 sm:mt-4 custom-scrollbar h-[248px] gap-2"
      >
        <div
          v-for="item in filterdList"
          :key="item.id"
          class="flex flex-row px-3 py-2 items-center dark:hover:bg-navy-400 rounded-xl cursor-pointer hover:bg-navy-10"
          @click="handleClick(item)"
        >
          <div>
            <img :src="[item.icon]" class="w-8 h-8 mr-3" />
          </div>
          <div class="flex flex-col">
            <div class="text-base font-semibold dark:text-white text-navy-800">
              {{ item.iso3 }}
            </div>
            <div class="text-xs font-semibold text-navy-100">
              {{ item.name }}
            </div>
          </div>
          <div class="flex-grow" />
          <div class="dark:text-white text-navy-800 text-base font-medium">
            {{ item.balance.toFixed(2) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineEmits, defineProps, ref } from "vue";
import { isEmpty } from "@/utils/utils";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/vue/24/outline";

const searchText = ref("");
const props = defineProps(["currencies", "label"]);
const emits = defineEmits(["click"]);

const filterdList = computed(() => {
  if (isEmpty(props.currencies)) return;
  const word = searchText.value.toLowerCase();
  return props.currencies.filter((value) => {
    if (value.name.toLowerCase().indexOf(word) >= 0) {
      return true;
    }
    return value.iso3.toLowerCase().indexOf(word) >= 0;
  });
});

const handleClick = (item) => {
  emits("click", item);
};
</script>
