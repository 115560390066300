<template>
  <div
    v-if="!isEmpty(selectedPair)"
    class="rounded-xl md:px-4 px-3 bg-white dark:bg-navy-700"
  >
    <div class="flex space-x-8 flex-wrap relative pr-8 text-sm">
      <div class="flex items-center my-2">
        <div
          v-if="selectedPair.main"
          class="flex items-center cursor-pointer"
          @click="handleDropdownOpen"
        >
          <img
            class="w-8 h-8 mr-4"
            :src="selectedPair.main?.icon"
            alt="Token icon"
          />
          <label class="font-bold mr-2">
            {{ selectedPair.main?.iso3 }}/{{ selectedPair.second?.iso3 }}
          </label>
          <chevron-down-icon class="stroke-2 w-4 h-4 mr-6" />
        </div>
        <div
          class="h-8 border rounded-full mr-6 border-navy-50 dark:border-navy-400"
        />
        <div>
          <p class="text-green-400 font-bold text-base">
            {{ selectedPair.price }}
          </p>
          <p class="text-xs text-navy-200">
            {{ selectedPair.percent }}
          </p>
        </div>
      </div>

      <!-- 24h High -->
      <div v-for="item in pairDetailItems" :key="item.id" class="my-2">
        <p class="text-xs text-navy-200">{{ item.label }}</p>
        <p class="font-bold text-navy-800 dark:text-white">
          {{ item.value }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

const pairDetailItems = computed(() => [
  { id: 1, label: "24h High", value: selectedPair.value.high24 },
  { id: 2, label: "24h Low", value: selectedPair.value.low24 },
  {
    id: 3,
    label: `24h Amount (${selectedPair.value.main?.iso3})`,
    value: selectedPair.value.volume24main,
  },
  {
    id: 4,
    label: `24h Volume (${selectedPair.value.second?.iso3})`,
    value: selectedPair.value.volume24second,
  },
]);

const store = useStore();
const emit = defineEmits(["clickContent"]);

const selectedPair = computed(() => store.getters.selectedPair);

const handleDropdownOpen = () => {
  emit("clickContent");
};
</script>
