<template>
  <div
    class="w-full flex flex-col items-center justify-center mb-36"
    v-if="!isEmpty(articleData)"
  >
    <div
      class="flex flex-row flex-wrap items-center justify-start w-full text-xs font-medium text-navy-100 self-start mb-8 gap-2"
    >
      <div class="cursor-pointer">Announcements</div>
      <chevron-right-icon class="w-4 h-4 stroke-2" />
      <div class="cursor-pointer" @click="router.push({ name: route.name })">
        {{ route.name }}
      </div>
      <chevron-right-icon class="w-4 h-4 stroke-2" />
      <div class="text-navy-200 dark:text-navy-10 cursor-pointer">
        {{ route.params.url }}
      </div>
    </div>
    <div class="text-2xl font-semibold dark:text-white text-navy-800 mb-4">
      {{ articleData.h1 }}
    </div>
    <div
      class="flex flex-row items-center justify-start self-start gap-3 mb-10"
    >
      <div class="text-sm font-medium text-navy-100 mr-1">
        {{ createdTime }}
      </div>
      <div
        class="rounded-full dark:bg-navy-400 dark:text-white font-medium text-xs py-2 px-3 bg-navy-10 text-navy-300"
      >
        Spot
      </div>
      <div
        class="rounded-full dark:bg-navy-400 dark:text-white font-medium text-xs py-2 px-3 bg-navy-10 text-navy-300"
      >
        Latest Events
      </div>
      <div
        class="rounded-full dark:bg-navy-400 dark:text-white font-medium text-xs py-2 px-3 bg-navy-10 text-navy-300"
      >
        ETH
      </div>
      <div
        class="rounded-full dark:bg-navy-400 dark:text-white font-medium text-xs py-2 px-3 bg-navy-10 text-navy-300"
      >
        <share-fat-icon class="w-5 h-5" />
      </div>
    </div>
    <img :src="articleData.image" alt="Image" class="w-full mb-8" />
    <div v-html="articleData.text"></div>
    <div class="mt-16 w-full">
      <div class="flex flex-col mb-10 px-4 w-full">
        <div
          class="dark:text-white font-bold text-[32px] sm:text-xl mb-4 text-navy-800"
        >
          FAQ
        </div>
        <faq-component :faq-data="announcementPageFaqData" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { isEmpty } from "@/utils/utils";
import FaqComponent from "@/common/FAQ/FaqComponent.vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import ShareFatIcon from "@/assets/icons/announcement/ShareFatIcon.vue";

const route = useRoute();
const router = useRouter();

const announcementPageFaqData = ref([]);

const articleData = ref({});
const createdTime = computed(() => {
  if (isEmpty(articleData.value)) {
    return "";
  }
  return moment(articleData.value.time_create * 1000).format("MMM DD hh:mm");
});

onMounted(() => {
  // try {
  //   const data = postHttpRequest(store)("/api/news/view", {
  //     lang: "en",
  //     url: url,
  //     p: 5,
  //   });
  //   if (data.status === true) {
  //     articleData.value = data.data;
  //   }
  // } catch (error) {
  //   console.error(error);
  // }
});

onMounted(async () => {
  try {
    const response = await fetch(
      "/mock/announcement/announcement_article.json"
    );
    const data = await response.json();
    articleData.value = data;
  } catch (error) {
    console.error(error);
  }
});

onMounted(async () => {
  try {
    const response = await fetch("/mock/announcement/faq.json");
    const data = await response.json();
    announcementPageFaqData.value = data;
  } catch (error) {
    console.error(error);
  }
});
</script>
