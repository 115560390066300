<template>
  <div class="relative">
    <div class="absolute w-full h-fit -z-50">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/help-center/background-texture-dark.svg'
            : '/assets/images/help-center/background-texture-light.svg'
        "
        class="w-full"
      />
      <div
        class="absolute top-0 h-full w-full bg-gradient-to-t dark:from-navy-800 from-30% from-white dark:to-transparent to-60% z-50"
      />
      <div
        class="absolute top-0 h-full w-1/4 bg-gradient-to-r dark:from-navy-800 from-white to-transparent"
      />
      <div
        class="absolute top-0 h-full w-1/4 bg-gradient-to-l dark:from-navy-800 from-white to-transparent right-0"
      />
    </div>
    <div class="absolute -z-50">
      <img
        :src="
          currentTheme === 'dark'
            ? '/assets/images/help-center/ellipse-dark.svg'
            : '/assets/images/help-center/ellipse-light.svg'
        "
      />
    </div>
    <div
      class="container mx-auto w-full px-16 flex flex-col items-center sm:px-1"
    >
      <div class="sm:mt-[64px] mt-[40px]">
        <div
          class="flex flex-col sm:gap-[40px] gap-[24px] max-w-[768px] items-center"
        >
          <div class="flex flex-col sm:gap-[24px] gap-[12px]">
            <div
              class="text-center text-navy-800 dark:text-white text-4xl font-bold sm:text-[56px]"
            >
              Blog
            </div>
            <div
              class="text-lg font-medium sm:text-xl sm:font-normal text-navy-300 dark:text-navy-50 text-center"
            >
              Move forward with key industry insights, learn about our latest
              products, and get exclusive access to all the buzz at EXZi
            </div>
          </div>
          <div class="sm:w-[367px] w-full">
            <text-edit
              type="text"
              size="sm"
              rounded="full"
              placeholder="Search"
              :filled="true"
              v-model="searchText"
            >
              <template #prefix>
                <div class="mr-2">
                  <MagnifyingGlassIcon
                    :class="[
                      searchText.length > 0
                        ? 'dark:text-white text-navy-800'
                        : 'text-navy-200',
                      'w-6 h-6',
                    ]"
                  />
                </div>
              </template>
              <template #suffix v-if="searchText.length > 0">
                <div
                  class="ml-2"
                  @click="
                    () => {
                      searchText = '';
                    }
                  "
                >
                  <XCircleIcon class="text-navy-200 stroke-2 w-6 h-6" />
                </div>
              </template>
            </text-edit>
          </div>
        </div>
      </div>
      <div class="sm:mt-20 mt-10 flex w-full gap-8 justify-between">
        <div class="flex flex-col sm:basis-7/12 flex-1 overflow-hidden">
          <div
            class="w-full"
            :class="[
              currentTheme === 'light' ? 'carousel' : 'carousel-dark',
              'mt-6',
            ]"
          >
            <v-slick-carousel v-bind="blogPageCarouselSettings">
              <template v-for="item in topTopics" :key="item.id">
                <img :src="item.image_default" class="rounded-[16px]" />
              </template>
            </v-slick-carousel>
            <article-summary-item
              :heading="topTopics[0]?.title"
              :update-time="changeUnixTime(topTopics[0]?.time_create)"
              :content="topTopics[0]?.short_text"
              size="sm"
              @click="handleBlogItemClick(topTopics[0]?.url)"
            />
          </div>
        </div>
        <div
          class="flex flex-col flex-1 max-lg:hidden min-w-[416px] basis-5/12"
        >
          <div
            class="text-2xl font-semibold dark:text-white text-navy-800 px-4"
          >
            <div class="flex gap-3 items-center ml-4 mb-6">
              <FireIcon class="text-red-500 w-6 h-6 stroke-2" />
              <div
                class="font-semibold text-[20px] dark:text-white text-navy-800"
              >
                Top Topics
              </div>
            </div>
          </div>
          <div class="flex flex-col mt-6 w-full max-lg:hidden">
            <template v-for="(item, index) in topTopics" :key="item.id">
              <div
                v-if="index !== 0"
                class="my-2 dark:border-t-navy-500 border-t-navy-10 border-t"
              />
              <article-summary-item
                :heading="item.title"
                :update-time="changeUnixTime(item.time_create)"
                :content="item.short_text"
                size="sm"
                @click="handleBlogItemClick(item.url)"
              />
            </template>
          </div>
        </div>
      </div>
      <div class="mt-[72px] lg:hidden w-full">
        <div class="flex gap-3 items-center ml-4 mb-6">
          <FireIcon class="text-red-500 w-6 h-6 stroke-2" />
          <div class="font-semibold text-[20px] dark:text-white text-navy-800">
            Top Topics
          </div>
        </div>
        <div
          :class="[
            currentTheme === 'light' ? 'carousel' : 'carousel-dark',
            'mt-6',
          ]"
        >
          <VSlickCarousel v-bind="blogTopTopicsCarouselSetting">
            <template v-for="item in topTopics" :key="item.id">
              <article-summary-item
                :heading="item.title"
                :update-time="changeUnixTime(item.time_create)"
                :content="item.short_text"
                size="sm"
                @click="handleBlogItemClick(item.url)"
              />
            </template>
          </VSlickCarousel>
        </div>
      </div>
      <div class="w-full">
        <div
          class="dark:text-white text-navy-800 text-[32px] font-semibold mb-6 sm:mb-10 w-full"
        >
          Latest Articles
        </div>
        <div class="flex gap-4">
          <radio-button-list
            :items="LATEST_ARTICLES_BADGET"
            @change="handleChangeLatestAritclesBadget"
          />
        </div>
        <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mt-10">
          <article-item
            :imageURL="item.image_default"
            :header="item.title"
            :content="item.short_text"
            :updateTime="item.time_create"
            v-for="item in latestArticles.slice(
              0,
              latestArticlesPaginationSettings.stepCount
            )"
            :key="item"
            @click="handleBlogItemClick(item.url)"
          />
        </div>
        <div class="w-full mt-14">
          <pagination-component
            @change="handlePageChange"
            :initial-page="1"
            :total="6"
            :step="10"
            :step-disabled="false"
            :steps="paginationSettings.steps"
          />
        </div>
      </div>
      <div class="w-full mt-52">
        <div
          class="flex flex-col justify-between sm:items-center sm:flex-row items-start"
        >
          <div
            class="dark:text-white text-navy-800 text-[32px] font-semibold mb-6 sm:mb-10 w-full"
          >
            Crypto Insights
          </div>
          <div class="w-full sm:w-fit flex-shrink-0">
            <outline-button color="primary" size="md" width="full">
              <div class="font-semibold text-sm" @click="handleViewAllClick">
                {{ cryptoInsightsViewAll ? "View Less" : "View All" }}
              </div>
            </outline-button>
          </div>
        </div>
        <div class="grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mt-10">
          <article-item
            :imageURL="item.image_default"
            :header="item.title"
            :content="item.short_text"
            :updateTime="item.time_create"
            v-for="item in cryptoInsightsViewAll
              ? cryptoInsights
              : cryptoInsights.slice(0, 3)"
            :key="item"
            @click="handleBlogItemClick(item.url)"
          />
        </div>
      </div>
      <div
        class="mt-40 mb-40 sm:h-[296px] h-[360px] overflow-hidden flex items-center justify-center rounded-lg w-full relative"
      >
        <div class="absolute w-full h-full">
          <img
            class="w-full h-full object-cover"
            :src="
              currentTheme === 'dark'
                ? '/assets/images/blog/background-start-trading-dark-sm.svg'
                : '/assets/images/blog/background-start-trading-light-sm.svg'
            "
          />
        </div>
        <div class="absolute w-full h-full md:hidden">
          <img
            class="w-full h-full object-cover"
            :src="
              currentTheme === 'dark'
                ? '/assets/images/blog/background-start-trading-dark.svg'
                : '/assets/images/blog/background-start-trading-light.svg'
            "
          />
        </div>
        <div class="w-full flex rounded-xl items-center justify-center">
          <div class="flex flex-col z-10">
            <div
              class="font-semibold text-[40px] mb-12 text-center sm:text-5xl sm:font-bold dark:text-white text-navy-800"
            >
              It's time to trade fearlessly now
            </div>
            <div class="mx-auto">
              <fill-button size="lg" @click="handleClickStartTrading"
                >Start trading now</fill-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { VSlickCarousel } from "v-slick-carousel";
import { changeUnixTime } from "@/utils/utils";
import RadioButtonList from "@/components/Selector/RadioButtonList.vue";
import PaginationComponent from "@/components/Pagination/PaginationComponent.vue";
import ArticleSummaryItem from "@/views/Blog/Items/ArticleSummaryItem.vue";
import ArticleItem from "@/views/Blog/Items/ArticleItem.vue";
import "v-slick-carousel/style.css";
import { FireIcon } from "@heroicons/vue/24/solid";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/vue/24/outline";

const store = useStore();
const router = useRouter();
const topTopics = computed(() => store.getters.topTopics);
const latestArticles = computed(() => store.getters.latestArticles);
const cryptoInsights = computed(() => store.getters.cryptoInsights);
const searchText = ref("");
const cryptoInsightsViewAll = ref(false);
const latestArticlesPaginationSettings = ref({
  totalCount: 0,
  pageNumber: 1,
  stepCount: 10,
  data: [],
});
const blogPageCarouselSettings = {
  slidesToShow: 4,
  arrows: true,
  dots: true,
};
var blogTopTopicsCarouselSetting = {
  slidesToShow: 4,
  arrows: false,
  dots: true,
};
const paginationSettings = {
  steps: [
    { id: "10", size: "10" },
    { id: "15", size: "15" },
    { id: "20", size: "20" },
  ],
};

const LATEST_ARTICLES_BADGET = [
  { label: "All", id: "All" },
  { label: "Ecosystem", id: "Ecosystem" },
  { label: "Market Insight", id: "Market Insight" },
  { label: "Learn with Exzi", id: "Learn with Exzi" },
];

const handleChangeLatestAritclesBadget = (value) => value;
const handlePageChange = (data) => {
  latestArticlesPaginationSettings.value = {
    ...latestArticlesPaginationSettings.value,
    pageNumber: data[0],
    stepCount: parseInt(data[1]),
  };
};

watch([latestArticles], () => {
  latestArticlesPaginationSettings.value = {
    ...latestArticlesPaginationSettings.value,
    totalCount: latestArticles.value.length,
    pageNumber: 1,
    data: latestArticles.value.slice(
      0,
      latestArticlesPaginationSettings.value.stepCount
    ),
  };
});

watch([topTopics], () => {
  blogTopTopicsCarouselSetting.slidesToShow = topTopics.value.length;
});

onMounted(() => {
  store.dispatch("getTopTopics");
  store.dispatch("getLatestArticles");
  store.dispatch("getCryptoInsights");
});

const handleBlogItemClick = (url) => {
  router.push({
    name: "blog-detail",
    params: {
      url: url,
    },
  });
};

const handleViewAllClick = () => {
  cryptoInsightsViewAll.value = !cryptoInsightsViewAll.value;
};

const handleClickStartTrading = () => {
  router.push({
    name: "trading",
  });
};
</script>
<style scoped>
.custom-line {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, transparent 50%, #39a3d0 50%);
  background-size: 8px 100%;
}

.carousel :deep(.v-slick-dots li button:before) {
  color: #9ca5c4;
}

.carousel :deep(.v-slick-dots li.active button:before) {
  color: #0e111b;
}

.carousel-dark :deep(.v-slick-dots li button:before) {
  color: #424d70;
}

.carousel-dark :deep(.v-slick-dots li.active button:before) {
  color: #ffffff;
}
</style>
