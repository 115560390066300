<template>
  <div class="w-full flex items-center max-sm:gap-6 justify-between">
    <div class="flex flex-row items-center justify-center gap-3">
      <div class="mr-3">
        <icon-button
          :disabled="!previousPageAvailable"
          @click="handlePreviousPage"
        >
          <chevron-left-icon class="w-5 h-5" />
        </icon-button>
      </div>
      <icon-button
        v-for="item in rangeValue"
        :key="item.id"
        color="primary"
        :activated="item.value === currentPage"
        @click="handlePageButtonClick(item.value)"
      >
        {{ item.value }}
      </icon-button>
      <div class="ml-3 mr-6 flex flex-row items-center justify-center gap-6">
        <icon-button :disabled="!nextPageAvailable" @click="handleNextPage">
          <chevron-right-icon class="w-5 h-5" />
        </icon-button>
      </div>
    </div>
    <div
      class="flex flex-row items-center justify-between flex-1 gap-6"
      v-if="!props.stepDisabled"
    >
      <div class="text-navy-100 text-sm font-medium">
        {{ (currentPage - 1) * pageSize + 1 }}-{{ currentPage * pageSize }}
        of
        {{ props.total * props.step }}
      </div>
      <dropdown-select @select="handlePageSizeSelect" alignment="end">
        <template #header="{ handleContentClick, isShowingMenu }">
          <div
            class="flex flex-row items-center justify-center text-navy-200 gap-2"
          >
            <div class="font-medium text-sm">{{ pageSize }} of Contents</div>
            <div @click="handleContentClick" class="cursor-pointer">
              <tri-up-icon v-if="isShowingMenu" class="w-5 h-5" />
              <tri-down-icon v-else class="w-5 h-5" />
            </div>
          </div>
        </template>
        <template #menu="{ handleMenuItemClick }">
          <div
            class="flex flex-col w-fit p-2 dark:text-white text-navy-800 text-sm font-medium gap-1 rounded-lg dark:bg-navy-500 bg-navy-10"
          >
            <div
              v-for="item in pageSizeData"
              :key="item.id"
              :class="[
                'cursor-pointer px-2 py-1 rounded-md',
                item.size === pageSize ? 'dark:bg-navy-400 bg-navy-50' : '',
              ]"
              @click="handleMenuItemClick(item.size)"
            >
              {{ item.size }}
            </div>
          </div>
        </template>
      </dropdown-select>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref, computed, defineEmits, watch } from "vue";
import IconButton from "@/components/Button/IconButton.vue";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import TriUpIcon from "@/assets/icons/TriUpIcon.vue";
import TriDownIcon from "@/assets/icons/TriDownIcon.vue";

const props = defineProps([
  "total",
  "initialPage",
  "stepDisabled",
  "steps",
  "step",
]);

const emit = defineEmits(["change"]);
const pageSize = ref(props.stepDisabled ? props.step : props.steps[0].size);

const maximumPage = computed(() => {
  return props.total;
});
const currentPage = ref(props.initialPage ?? 1);
const rangeStart = ref(props.initialPage ?? 1);

const rangeEnd = computed(() => {
  return Math.min(rangeStart.value + 2, maximumPage.value);
});

const nextPageAvailable = computed(() => {
  return currentPage.value < maximumPage.value;
});

const previousPageAvailable = computed(() => {
  return currentPage.value > 1;
});

watch(
  () => props.total,
  () => {
    currentPage.value = 1;
  }
);

watch(
  () => currentPage.value,
  (newVal) => {
    emit("change", [newVal, pageSize.value]);
  }
);

const rangeValue = computed(() => {
  let result = [];
  let value = rangeStart.value;
  let index = 0;
  while (value <= rangeEnd.value) {
    result.push({ id: index, value: value });
    value++;
    index++;
  }
  return result;
});

const handlePageSizeSelect = (size) => {
  rangeStart.value = 1;
  currentPage.value = 1;
  pageSize.value = size;
};

const handlePageButtonClick = (page) => {
  currentPage.value = page;
};

const handleNextPage = () => {
  if (nextPageAvailable.value) {
    if (currentPage.value < rangeEnd.value) {
      currentPage.value++;
    } else {
      currentPage.value++;
      rangeStart.value++;
    }
  }
};

const handlePreviousPage = () => {
  if (previousPageAvailable.value) {
    if (currentPage.value > rangeStart.value) {
      currentPage.value--;
    } else {
      currentPage.value--;
      rangeStart.value--;
    }
  }
};

const pageSizeData = computed(() => props.steps);
</script>
