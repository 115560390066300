<template>
  <div class="relative w-full">
    <div class="absolute -top-40 opacity-[0.18] -z-50 w-full">
      <img
        src="/assets/images/career/exzi_logo_background.png"
        class="max-w-[800px] mx-auto w-full"
      />
    </div>
    <div class="absolute w-full -z-50">
      <img
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/career/background_ellipse_dark.svg'
            : '/assets/images/career/background_ellipse_light.svg',
        ]"
        class="mx-auto"
      />
    </div>
    <div
      class="flex flex-col sm:pt-[104px] pt-12 w-full container items-center justify-center mx-auto px-4"
    >
      <div class="flex flex-col items-center justify-center">
        <div
          class="font-bold sm:text-[56px] dark:text-white text-[40px] text-navy-800 text-center"
        >
          Build the Future with US
        </div>
        <div
          class="mt-6 font-medium text-lg dark:text-navy-50 sm:text-2xl text-navy-400 text-center"
        >
          We’re believers in the future of decentralized technology.
        </div>
        <div class="sm:mt-10 mt-8">
          <fill-button
            width="fit"
            size="lg"
            @click="handleScrollToOpenPosition"
          >
            Open Positions
          </fill-button>
        </div>
      </div>
      <div class="flex flex-col mt-[120px] w-full">
        <div
          class="font-bold text-[40px] dark:text-white text-navy-800 text-center"
        >
          Our values
        </div>
        <div
          class="sm:mt-16 mt-12 flex flex-col sm:flex-row items-center justify-between w-full max-sm:gap-10"
        >
          <template v-for="item in valueCardsData" :key="item.id">
            <div class="flex flex-col max-w-[304px]">
              <img
                :src="
                  currentTheme === 'dark' ? item.darkImgUrl : item.lightImgUrl
                "
                class="py-6 px-16"
              />
              <div
                class="dark:text-white font-semibold text-lg text-center mt-4"
              >
                {{ item.title }}
              </div>
              <div
                class="dark:text-navy-100 text-navy-300 font-medium text-base text-center mt-1"
              >
                {{ item.content }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="flex flex-col mt-[120px] w-full">
        <div
          class="font-bold text-[40px] dark:text-white text-navy-800 text-center"
        >
          Why work here
        </div>
        <div
          class="font-medium text-lg dark:text-navy-100 text-navy-400 text-center"
        >
          We are proud to offer competitive benefits that enable healthy and
          fulfilling careers at EXZi
        </div>
        <div
          class="sm:mt-16 mt-12 flex flex-col sm:flex-row items-center justify-between w-full max-sm:gap-10"
        >
          <template v-for="item in workCardData" :key="item.id">
            <div class="flex flex-col max-w-[304px]">
              <img :src="item.imgUrl" class="w-[88px] h-[88px] mx-auto" />
              <div
                class="dark:text-white font-semibold text-lg text-center mt-4"
              >
                {{ item.title }}
              </div>
              <div
                class="dark:text-navy-100 text-navy-300 font-medium text-base text-center mt-1"
              >
                {{ item.content }}
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="flex flex-col mt-[104px] sm:mt-52 w-full">
        <div
          ref="openPositionElement"
          class="font-bold text-2xl dark:text-white text-navy-800 mb-12 w-full"
        >
          Open Positions (12)
        </div>
        <div
          class="flex flex-col sm:flex-row gap-4 items-center justify-start w-full sm:mb-16 mb-12"
        >
          <div class="max-sm:w-full">
            <dropdown-select>
              <template #header>
                <div
                  class="flex flex-row items-center justify-between dark:bg-navy-700 bg-white border dark:border-navy-400 border-navy-50 rounded-xl px-3 py-2.5 sm:w-52 w-full"
                >
                  <div class="text-sm font-medium dark:text-navy-100">
                    Department
                  </div>
                  <chevron-down-icon
                    class="w-4 h-4 stroke-2 dark:text-white text-navy-800 cursor-pointer"
                  />
                </div>
              </template>
              <template #menu></template>
            </dropdown-select>
          </div>
          <div class="max-sm:w-full">
            <dropdown-select>
              <template #header>
                <div
                  class="flex flex-row items-center justify-between dark:bg-navy-700 bg-white border dark:border-navy-400 border-navy-50 rounded-xl px-3 py-2.5 sm:w-52 w-full"
                >
                  <div class="text-sm font-medium dark:text-navy-100">
                    Work Type
                  </div>
                  <chevron-down-icon
                    class="w-4 h-4 stroke-2 dark:text-white text-navy-800 cursor-pointer"
                  />
                </div>
              </template>
              <template #menu></template>
            </dropdown-select>
          </div>
          <div class="sm:ml-4 w-full sm:w-fit">
            <fill-button
              :disabled="true"
              color="primary"
              size="md"
              width="full"
            >
              Search Job
            </fill-button>
          </div>
        </div>
        <template v-for="item in positionMockData" :key="item.id">
          <div class="w-full flex flex-col gap-4 cursor-pointer mb-16">
            <div class="text-lg font-bold dark:text-white text-navy-800 mb-2">
              {{ item.departmentName }}
            </div>
            <div
              class="flex sm:flex-row flex-col p-6 items-center justify-between dark:bg-navy-700 bg-primary-100 hover:bg-primary-200 dark:hover:bg-navy-600 rounded-2xl group"
              v-for="positionItem in item.position"
              :key="positionItem.id"
            >
              <div class="flex flex-col gap-2">
                <div
                  class="text-base font-semibold dark:text-white text-navy-800"
                >
                  {{ positionItem.title }}
                </div>
                <div
                  class="text-base font-medium dark:text-navy-100 text-navy-300"
                >
                  {{ positionItem.info }}
                </div>
              </div>
              <div class="w-full sm:w-fit max-sm:mt-6">
                <outline-button
                  color="primary"
                  size="md"
                  width="full"
                  @click="
                    router.push({
                      name: 'career-detail',
                      params: { url: positionItem.url },
                    })
                  "
                >
                  Apply
                </outline-button>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

const router = useRouter();
const openPositionElement = ref();
const store = useStore();

const handleScrollToOpenPosition = () => {
  openPositionElement.value.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

const positionMockData = computed(() => store.getters.careerList);

onMounted(() => {
  store.dispatch("fetchCareerList");
});

const valueCardsData = [
  {
    id: 1,
    darkImgUrl: "/assets/images/career/value_image_dark.svg",
    lightImgUrl: "/assets/images/career/value_image_light.svg",
    title: "User-Focused",
    content: "We genuinely care about the voice of each and every user.",
  },
  {
    id: 2,
    darkImgUrl: "/assets/images/career/value_image_dark.svg",
    lightImgUrl: "/assets/images/career/value_image_light.svg",
    title: "Open Communication",
    content: "We genuinely care about the voice of each and every user.",
  },
  {
    id: 3,
    darkImgUrl: "/assets/images/career/value_image_dark.svg",
    lightImgUrl: "/assets/images/career/value_image_light.svg",
    title: "Collaboration",
    content: "We genuinely care about the voice of each and every user.",
  },
];

const workCardData = [
  {
    id: 1,
    imgUrl: "/assets/images/career/career_salary_image.svg",
    title: "Competitive salary",
    content: "Option to be paid in crypto. Health insurance.",
  },
  {
    id: 2,
    imgUrl: "/assets/images/career/career_remote_image.svg",
    title: "Remote work for many roles",
    content: "Company sponsored holidays. Team building activities",
  },
  {
    id: 3,
    imgUrl: "/assets/images/career/career_learn_image.svg",
    title: "Learning and development programs",
    content: "Free language classes Relocation supports",
  },
];
</script>
