<template>
  <div>
    <text-edit
      type="email"
      :filled="false"
      rounded="xl"
      label="Email address"
      placeholder="Email address"
      v-model="email"
      :error="emailError"
    />
    <div class="h-6" />

    <password-input
      label="Password"
      placeholder="Password"
      v-model="password"
      :error="passwordError"
    />
  </div>
</template>

<script setup>
import { ref, computed, watch, defineEmits } from "vue";
import { validateEmail, validatePassword } from "@/views/Auth/validation";

const email = ref("");
const password = ref("");
const emit = defineEmits(["changeEmail", "changePassword"]);
const emailError = computed(() =>
  email.value && !validateEmail(email.value) ? "Invalid email address" : ""
);
const passwordError = computed(() => validatePassword(password.value));

watch(email, (newEmail) => {
  emit("changeEmail", newEmail);
});

watch(password, (newPassword) => {
  emit("changePassword", newPassword);
});
</script>
