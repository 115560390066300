<template>
  <div v-if="!isEmpty(selectedPair)" class="py-3 px-4 h-[510px]">
    <div class="flex items-center justify-between">
      <selector-view
        :items="selectorItems"
        :selected-tab="selectedTab"
        @select-tab="handleSelectTab"
      />
      <dropdown-select @select="handleSelectDecimal">
        <template #header="{ handleContentClick }">
          <p
            class="border dark:border-navy-400 border-navy-50 py-2 px-3 rounded-full dark:text-white cursor-pointer"
            @click="handleContentClick"
          >
            <span class="mr-2 text-xs font-medium">{{
              currentDecimal.name
            }}</span>
            <chevron-down-icon class="w-4 h-4 inline stroke-2" />
          </p>
        </template>
        <template #menu="{ handleMenuItemClick }">
          <div
            class="dark:bg-navy-700 bg-white text-xs border dark:border-navy-500 border-navy-50 py-2 rounded-xl mt-1"
          >
            <p
              v-for="item in deciamlItems"
              :key="item.id"
              :class="[
                'py-1 px-2 hover:bg-navy-10 dark:hover:bg-navy-500 cursor-pointer',
                currentDecimal.id === item.id
                  ? 'dark:bg-navy-500 bg-navy-10'
                  : '',
              ]"
              @click="handleMenuItemClick(item)"
            >
              {{ item.name }}
            </p>
          </div>
        </template>
      </dropdown-select>
    </div>
    <div class="h-3" />
    <div
      v-if="!isEmpty(orderBookList)"
      :class="[
        'flex flex-col gap-3',
        selectedTab.showBuy ? 'flex-col-reverse' : '',
      ]"
    >
      <order-book-list-context
        v-if="selectedTab.showBuy"
        isBuy
        :showLines="selectedTab.showLines"
        :hideHeader="!selectedTab.showBuyHeader"
        :headerItems="headerItems"
        :contextItems="orderBookList.buy.slice(0, selectedTab.count)"
      />
      <div
        :class="[
          selectedPair.percent[0] == '+' ? 'text-trade-buy' : 'text-red-500',
          'text-lg font-semibold flex gap-2 items-center',
        ]"
      >
        <label>{{
          addCommasToNumber(selectedPair.price_f, currentDecimal.value)
        }}</label>
        <arrow-up-icon v-if="selectedPair.percent[0] == '+'" class="w-4 h-4" />
        <arrow-down-icon v-else class="w-4 h-4" />
        <label class="text-xs font-medium text-navy-200"
          >=${{ addCommasToNumber(selectedPair.price_f, -2) }}</label
        >
      </div>
      <order-book-list-context
        v-if="selectedTab.showSell"
        :showLines="selectedTab.showLines"
        :hideHeader="!selectedTab.showSellHeader"
        :headerItems="headerItems"
        :contextItems="orderBookList.sell.slice(0, selectedTab.count)"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import { addCommasToNumber } from "@/utils/NumberFormating";
import { socket } from "@/socket/socket";
import OrderBookListContext from "@/views/Trading/OrderBook/OrderBookList/OrderBookListContext.vue";
import SelectorView from "@/components/Selector/SelectorView.vue";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDownIcon,
} from "@heroicons/vue/24/outline";

// Store
const store = useStore();
const selectedPair = computed(() => store.getters.selectedPair);
const orderBookList = computed(() => {
  const buyItems = store.getters.orderBookList.buy;
  const buyMaxPrice = buyItems.reduce((acc, cur) => {
    return Math.max(parseFloat(cur.price_f), acc);
  }, 0);
  const sellItems = store.getters.orderBookList.sell;
  const sellMaxPrice = sellItems.reduce((acc, cur) => {
    return Math.max(parseFloat(cur.price_f), acc);
  }, 0);

  const maxPrice = Math.max(buyMaxPrice, sellMaxPrice);
  return {
    buy: buyItems
      .sort((aItem, bItem) =>
        selectedTab.value.showBuy
          ? bItem.rate_f.localeCompare(aItem.rate_f)
          : aItem.rate_f.localeCompare(bItem.rate_f)
      )
      .map((item) => ({
        ...item,
        rate_f_original: item.rate_f,
        rate_f: addCommasToNumber(item.rate_f, currentDecimal.value.value),
        percent_f: parseFloat(item.price_f) / maxPrice,
      })),
    sell: sellItems.map((item) => ({
      ...item,
      rate_f_original: item.rate_f,
      rate_f: addCommasToNumber(item.rate_f, currentDecimal.value.value),
      percent_f: parseFloat(item.price_f) / maxPrice,
    })),
  };
});
const currentTheme = computed(() => store.getters.currentTheme);

// Constants
const deciamlItems = [
  { id: 1, name: "0.0001", value: -4 },
  { id: 2, name: "0.001", value: -3 },
  { id: 3, name: "0.01", value: -2 },
  { id: 4, name: "0.1", value: -1 },
  { id: 5, name: "1", value: 0 },
  { id: 6, name: "10", value: 1 },
  { id: 7, name: "100", value: 2 },
  { id: 8, name: "1000", value: 3 },
  { id: 9, name: "10000", value: 4 },
];
const selectorItems = computed(() => [
  {
    id: 1,
    content: `/assets/icons/selector/${currentTheme.value}/order_book_both.svg`,
    showBuy: true,
    showSell: true,
    showLines: true,
    showBuyHeader: false,
    showSellHeader: true,
    count: 10,
  },
  {
    id: 2,
    content: `/assets/icons/selector/${currentTheme.value}/order_book_buy.svg`,
    showBuy: true,
    showSell: false,
    showLines: false,
    showBuyHeader: true,
    showSellHeader: false,
    count: 20,
  },
  {
    id: 3,
    content: `/assets/icons/selector/${currentTheme.value}/order_book_sell.svg`,
    showBuy: false,
    showSell: true,
    showLines: false,
    showBuyHeader: false,
    showSellHeader: true,
    count: 20,
  },
]);

// Ref
const selectedTab = ref(selectorItems.value[0]);
const currentDecimal = ref(deciamlItems[2]);

// Computed
const headerItems = computed(() => [
  {
    id: 1,
    name: "Price",
    label: `Price (${selectedPair.value.second.iso3})`,
    align: "left",
  },
  {
    id: 2,
    name: "Amount",
    label: `Amount (${selectedPair.value.main.iso3})`,
    align: "center",
  },
  {
    id: 3,
    name: "Total",
    label: `Total (${selectedPair.value.main.iso3})`,
    align: "right",
  },
]);

watch(selectedPair, (currentValue, prevValue) => {
  if (prevValue && prevValue.id) {
    socket.emit("unsubscribe", `book_snapshot_${prevValue.id}`);
  }
  socket.emit("subscribe", `book_snapshot_${currentValue.id}`);
});

const handleSelectTab = (id) => {
  selectedTab.value = id;
};

const handleSelectDecimal = (item) => {
  currentDecimal.value = item;
};

onUnmounted(() => {
  if (selectedPair.value) {
    socket.emit("unsubscribe", `book_snapshot_${selectedPair.value.id}`);
  }
});
</script>
