<template>
  <table class="w-full">
    <thead>
      <tr class="h-10">
        <th
          v-for="item in headerItems"
          :key="item.id"
          :class="[
            'text-sm font-medium dark:text-navy-100 text-navy-200 px-3',
            item.hideable === true ? 'max-sm:hidden' : '',
          ]"
        >
          <div
            :class="[
              'flex items-center cursor-pointer',
              item.align === 'left' ? 'justify-start' : '',
              item.align === 'right' ? 'justify-end' : '',
              ,
            ]"
            @click="
              () => {
                if (item.sortable === true) {
                  handleSortClicked(item.id);
                }
              }
            "
          >
            <div>{{ item.label }}</div>
            <img
              v-if="item.sortable === true"
              src="@/assets/icons/vector.svg"
            />
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="item in props.displayData"
        :key="item.id"
        :class="['dark:hover:bg-navy-700 hover:bg-navy-10 h-[72px]']"
      >
        <td class="px-3">
          <div class="flex flex-row items-center justify-start">
            <star-icon
              :class="[
                'w-4 h-4 mr-3 cursor-pointer',
                item.favorite === true
                  ? 'text-orange-400'
                  : 'dark:text-navy-300 text-navy-50',
              ]"
              @click="
                () => {
                  if (isLoggedIn === false) {
                    return;
                  }
                  if (favoritePairs.includes(item.id)) {
                    store.dispatch('removeFavoritePair', item.id);
                  } else {
                    store.dispatch('addFavoritePair', item.id);
                  }
                }
              "
            />
            <img :src="item.imgUrl" class="w-6 h-6 mr-2" />
            <div
              class="text-sm font-semibold dark:text-white text-navy-800 mr-1"
            >
              {{ item.token1 }}
            </div>
            <div class="text-sm font-semibold text-navy-100">
              {{ item.token2 }}
            </div>
          </div>
        </td>
        <td
          class="text-left px-3 text-sm font-semibold dark:text-white text-navy-800"
        >
          ${{ addCommasToNumber(item.rate, -2) }}
        </td>
        <td class="text-left px-3 text-sm font-semibold">
          <percent-display :value="item.percent" />
        </td>
        <td
          class="text-left px-3 max-sm:hidden dark:text-white text-navy-800 text-sm font-semibold"
        >
          ${{ formatNumberInKilo(item.volume24h) }}
        </td>
        <td
          class="text-left px-3 text-sm font-semibold dark:text-white text-navy-800 max-sm:hidden"
        >
          ${{ formatNumberInKilo(item.marketCap) }}
        </td>
        <td
          class="text-left px-3 text-sm font-semibold dark:text-white text-navy-800 max-sm:hidden"
        >
          ${{ Date(item.timeStart).toISOString().split("T")[0] }}
        </td>
        <td class="text-right px-3 max-sm:hidden">
          <div
            v-if="isLoggedIn === true"
            class="flex items-center justify-end text-primary-700"
          >
            <div class="mr-3 cursor-pointer">Details</div>
            <div class="border-r h-4 border-navy-300" />
            <div
              class="ml-3 cursor-pointer"
              @click="
                () => {
                  router.push({ name: 'trading', params: { pair: item.id } });
                }
              "
            >
              Trade
            </div>
          </div>
          <div v-else class="flex items-center justify-end text-primary-700">
            <div
              class="cursor-pointer"
              @click="
                () => {
                  router.push({ name: 'signin' });
                }
              "
            >
              Login or Sign up
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { addCommasToNumber, formatNumberInKilo } from "@/utils/NumberFormating";
import PercentDisplay from "@/common/PairTable/PercentDisplay.vue";
import { StarIcon } from "@heroicons/vue/24/solid";

const store = useStore();
const router = useRouter();
const props = defineProps(["displayData"]);
const emit = defineEmits(["sortClick"]);

const favoritePairs = computed(() => store.getters.favoritePairs);
const isLoggedIn = computed(() => store.getters.isLoggedIn);

const headerItems = [
  {
    id: 1,
    label: "Trading pair",
    sortable: false,
    align: "left",
    hideable: false,
  },
  {
    id: 2,
    label: "Last Price",
    sortable: true,
    align: "left",
    hideable: false,
  },
  {
    id: 3,
    label: "24h Change",
    sortable: true,
    align: "left",
    hideable: false,
  },
  {
    id: 4,
    label: "24h Volume",
    sortable: true,
    align: "left",
    hideable: true,
  },
  {
    id: 5,
    label: "Market Cap",
    sortable: false,
    align: "left",
    hideable: true,
  },
  {
    id: 6,
    label: "Listing Date",
    sortable: false,
    align: "left",
    hideable: true,
  },
  {
    id: 7,
    label: "Action",
    sortable: false,
    align: "right",
    hideable: true,
  },
];

const handleSortClicked = (id) => {
  emit("sortClick", id);
};
</script>
