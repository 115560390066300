const state = {
  isInTransfer: false,
  selectedTransferCurrency: {},
  selectedWalletType: {},
};

const mutations = {
  SET_IS_IN_TRANSFER(state, isInTransfer) {
    state.isInTransfer = isInTransfer;
  },
  SET_SELECTED_CURRENCY(state, selectedTransferCurrency) {
    state.selectedTransferCurrency = selectedTransferCurrency;
  },
  SET_SELECTED_WALLET_TYPE(state, selectedWalletType) {
    state.selectedWalletType = selectedWalletType;
  },
};

const actions = {
  setIsInTransfer({ commit }, isInTransfer) {
    commit("SET_IS_IN_TRANSFER", isInTransfer);
  },
  setSelectedTransferCurrency({ commit }, selectedTransferCurrency) {
    commit("SET_SELECTED_CURRENCY", selectedTransferCurrency);
  },
  setSelectedWalletType({ commit }, selectedWalletType) {
    commit("SET_SELECTED_WALLET_TYPE", selectedWalletType);
  },
  setTransferData({ commit }, data) {
    commit("SET_IS_IN_TRANSFER", true);
    commit("SET_SELECTED_CURRENCY", data.currency);
    commit("SET_SELECTED_WALLET_TYPE", data.wallet);
  },
  clearTransferData({ commit }) {
    commit("SET_IS_IN_TRANSFER", false);
    commit("SET_SELECTED_CURRENCY", {});
    commit("SET_SELECTED_WALLET_TYPE", {});
  },
};

const getters = {
  isInTransfer: (state) => state.isInTransfer,
  selectedTransferCurrency: (state) => state.selectedTransferCurrency,
  selectedWalletType: (state) => state.selectedWalletType,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
