import { postHttpRequest } from "@/api/api";
import store from "@/store/index";

const state = {
  config: {},
  favorite_pairs: [],
  loginHistory: {},
};

const mutations = {
  SET_CONFIG(state, config) {
    state.config = config;
  },
  SET_FAVORITE_PAIRS(state, pairs) {
    state.favorite_pairs = pairs;
  },
  SET_LOGIN_HISTORY(state, value) {
    state.loginHistory = value;
  },
};

const actions = {
  setConfig({ commit }, config) {
    commit("SET_CONFIG", config);
  },
  setFavoritePairs({ commit }, config) {
    commit("SET_FAVORITE_PAIRS", config);
  },
  async fetchConfig({ commit }) {
    try {
      const data = await postHttpRequest(store)("/api/default/config");
      if (data.status === true) {
        commit("SET_CONFIG", data.data);
        commit("SET_FAVORITE_PAIRS", data.data.favorite_pairs);
      }
    } catch (error) {
      console.error(error);
    }
  },
  async addFavoritePair({ commit }, pair_iso) {
    try {
      const data = await postHttpRequest(store)(
        "/api/account/favorite-pair-add",
        {
          pair: pair_iso,
        }
      );
      commit("SET_FAVORITE_PAIRS", data.data.pairs);
    } catch (error) {
      console.error(error);
    }
  },
  async addFavoritePairs({ commit }, pairs) {
    try {
      let data;
      for (const pair of pairs) {
        data = await postHttpRequest(store)("/api/account/favorite-pair-add", {
          pair: pair,
        });
      }
      commit("SET_FAVORITE_PAIRS", data.data.pairs);
    } catch (error) {
      console.error(error);
    }
  },
  async removeFavoritePair({ commit }, pair_iso) {
    try {
      const data = await postHttpRequest(store)(
        "/api/account/favorite-pair-remove",
        {
          pair: pair_iso,
        }
      );
      commit("SET_FAVORITE_PAIRS", data.data.pairs);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchLoginHistory({ commit }, page = 1) {
    try {
      const data = await postHttpRequest(store)(
        `/api/account/login-history?page=${page}`,
        {
          limit: 20,
        }
      );
      commit("SET_LOGIN_HISTORY", data.data);
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  config: (state) => state.config,
  favoritePairs: (state) => state.favorite_pairs,
  loginHistoryList: (state) => state.loginHistory?.list,
  loginHistoryPage: (state) => state.loginHistory?.pagination?.page,
  loginHistoryTotalCountPage: (state) =>
    state.loginHistory?.pagination?.totalCountPage,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
