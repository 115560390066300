<template>
  <div
    class="w-[304px] max-h-[390px] h-fit custom-scrollbar dark:bg-navy-700 bg-white dark:border-none border border-navy-50 rounded-2xl p-4"
  >
    <div v-for="(item, index) in faqData" :key="item.id" class="w-full">
      <div
        v-if="index != 0"
        class="w-full border-t dark:border-t-navy-500 border-t-navy-10 my-4"
      />
      <collapse-view
        :is-show="collapseState[index]"
        @header-click="
          (value) => {
            collapseState.fill(false);
            collapseState[index] = value;
          }
        "
      >
        <template #header="{ handleContentClick, isShowingContent }">
          <div
            :class="[
              isShowingContent
                ? 'text-primary-700'
                : 'dark:text-navy-100 text-navy-800',
              'text-xs font-medium  cursor-pointer w-fit',
            ]"
            @click="handleContentClick"
          >
            {{ item.title }}
          </div>
        </template>
        <template #content>
          <div class="mt-3 text-xs font-medium dark:text-white text-navy-800">
            {{ item.content }}
          </div>
        </template>
      </collapse-view>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import CollapseView from "@/components/Collapse/CollapseView.vue";

const faqData = ref([]);
const collapseState = ref(Array(faqData.value?.length ?? 0).fill(false));

onBeforeMount(async () => {
  try {
    const response = await fetch("/mock/help-center/faq.json");
    const data = await response.json();
    faqData.value = data;
    collapseState.value = Array(faqData.value?.length ?? 0).fill(false);
  } catch (error) {
    console.error(error);
  }
});
</script>
