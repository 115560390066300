<template>
  <div
    :class="[
      'min-h-10 flex flex-col items-center justify-center w-full',
      colorClass,
      widthClass,
      paddingClass,
    ]"
  >
    <div class="flex flex-row items-center justify-center gap-2">
      <check-circle-icon v-if="props.type === 'success'" :class="iconClass" />
      <exclamation-triangle-icon
        v-else-if="props.type === 'warning' || props.type === 'danger'"
        :class="iconClass"
      />
      <information-circle-icon v-else :class="iconClass" />
      <div :class="titleClass">{{ props.title }}</div>
      <div
        v-if="props.buttonLabel"
        class="ml-6 flex flex-row items-center justify-center cursor-pointer"
        @click="handleClick"
      >
        <div class="text-sm font-semibold mr-2">{{ props.buttonLabel }}</div>
        <arrow-right-icon class="w-5 h-5 stroke-2" />
      </div>
    </div>
    <div v-if="props.content" class="text-sm font-medium mt-2">
      {{ props.content }}
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { ArrowRightIcon } from "@heroicons/vue/24/outline";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/vue/24/solid";

const props = defineProps({
  type: String,
  title: String,
  buttonLabel: String,
  content: String,
  width: String,
});

const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};

const colorClass = computed(() => {
  switch (props.type) {
    case "success":
      return "bg-green-200 text-green-900";
    case "danger":
      return "bg-red-100 text-red-700";
    case "warning":
      return "bg-orange-200 text-orange-900";
    case "primary":
    default:
      return "bg-primary-200 text-primary-800";
  }
});

const widthClass = computed(() => {
  switch (props.width) {
    case "bar":
    case "card":
      return "w-fit";
    case "full":
    default:
      return "w-full";
  }
});

const iconClass = computed(() => {
  switch (props.width) {
    case "card":
      return "w-6 h-6 flex-none";
    case "bar":
    case "full":
    default:
      return "w-5 h-5 flex-none";
  }
});

const paddingClass = computed(() => {
  switch (props.width) {
    case "card":
      return "p-4 rounded-xl";
    case "bar":
      return "py-2.5 px-3 rounded-xl";
    case "full":
    default:
      return "py-2.5 px-3";
  }
});

const titleClass = computed(() => {
  switch (props.width) {
    case "card":
      return "font-bold text-base";
    case "full":
    case "bar":
    default:
      return "font-medium text-sm";
  }
});
</script>
