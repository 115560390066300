<template>
  <div>
    <p v-if="label" class="font-bold mb-2 text-sm">
      {{ label }}
    </p>

    <dropdown-select @select="handleSelectCode">
      <template #header="{ handleContentClick }">
        <div
          :class="[
            'flex justify-between p-4 rounded-xl border-2 w-full items-center space-x-3',
            `bg-white ${!error && 'border-navy-50'} dark:bg-navy-700 ${
              !error && 'dark:border-navy-400'
            }`,
            error ? 'border-red-500' : 'focus-within:border-primary-700',
          ]"
        >
          <div
            class="flex items-center space-x-1 cursor-pointer"
            @click="handleContentClick"
          >
            <label>
              {{ countryCode }}
            </label>
            <span class="w-4 h-4">
              <chevron-down-icon class="stroke-2" />
            </span>
          </div>
          <div class="w-px bg-navy-200 h-4" />
          <input
            :class="[
              'w-full focus:outline-none placeholder:text-navy-200 appearance-none',
              currentTheme === 'light'
                ? 'bg-white input-light-mode'
                : 'bg-navy-700 input-dark-mode',
            ]"
            :value="inputValue"
            :placeholder="placeholder"
            @input="handleChangeNumber"
            type="text"
          />
        </div>
      </template>

      <template #menu="{ handleMenuItemClick }">
        <div
          class="p-4 rounded-xl border-2 mt-2 w-full bg-white border-navy-50 dark:bg-navy-700 dark:border-navy-400"
        >
          <text-edit
            type="text"
            size="sm"
            rounded="full"
            placeholder="Search ..."
            :filled="true"
            v-model="countrySearch"
          >
            <template #prefix>
              <div class="w-6 h-6 mr-2">
                <magnifying-glass-icon />
              </div>
            </template>
          </text-edit>

          <div class="h-4" />

          <list-select
            :items="phoneNumberList"
            @onSelect="handleMenuItemClick"
          />
        </div>
      </template>
    </dropdown-select>

    <p v-if="error" class="text-red-500 mt-2">{{ error }}</p>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineModel } from "vue";
import parsePhoneNumber from "libphonenumber-js";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/vue/24/outline";
import phoneData from "./phoneData.json";

defineProps(["value", "placeholder", "label", "error", "type"]);
const model = defineModel();
const countryCode = ref(phoneData[0].dial_code);
const countrySearch = ref("");
const inputValue = ref("");
const error = ref();

const handleChangeNumber = (event) => {
  const value = event.target.value.replace(/[^0-9]/g, "");
  inputValue.value = value;
  const number = countryCode.value + inputValue.value;
  model.value = number;
  const parsedPhoneNumber = parsePhoneNumber(number);
  if (parsedPhoneNumber) {
    inputValue.value = parsedPhoneNumber.formatNational();
    if (parsedPhoneNumber.isValid()) {
      error.value = null;
    } else {
      error.value = "Phone number is not valid";
    }
  }
};

const phoneNumberList = computed(() => {
  const searchValue = countrySearch.value.toLowerCase();
  return phoneData
    .filter(
      (number) =>
        (number.name && number.name.toLowerCase().includes(searchValue)) ||
        (number.code && number.code.toLowerCase().includes(searchValue)) ||
        (number.dial_code &&
          number.dial_code.toLowerCase().includes(searchValue))
    )
    .slice(0, 10)
    .map((number) => ({
      id: number.code,
      name: `${number.emoji} ${number.name} | ${number.dial_code}`,
      value: number.dial_code,
    }));
});

const handleSelectCode = (code) => {
  countryCode.value = code;
};
</script>
