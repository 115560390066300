<template>
  <div
    class="w-full flex flex-col p-4 cursor-pointer hover:dark:bg-navy-600 hover:bg-navy-10 rounded-2xl"
    @click="handleClick"
  >
    <div class="mb-2">
      <text-badge
        v-if="props.type === 1"
        label="Hot"
        size="md"
        class="bg-red-200 text-red-600 font-semibold"
      >
        <fire-icon class="stroke-2" />
      </text-badge>
      <text-badge
        v-else-if="props.type === 2"
        label="New Listing"
        size="md"
        class="bg-orange-200 text-orange-900 font-semibold"
      >
        <bars3-icon class="stroke-2" />
      </text-badge>
      <text-badge
        v-if="props.type === 3"
        label="EXZi"
        size="md"
        class="bg-primary-700 text-white font-semibold"
      >
        <exzi-logo-icon class="" />
      </text-badge>
    </div>
    <div class="text-xl font-semibold dark:text-white text-navy-800 mb-2">
      {{ props.title }}
    </div>
    <div class="text-base font-normal text-navy-300 dark:text-navy-50 mb-3">
      {{ props.content }}
    </div>
    <div class="text-xs font-medium text-navy-100">{{ props.updateDate }}</div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
import TextBadge from "@/components/Badge/TextBadge.vue";
import ExziLogoIcon from "@/assets/icons/announcement/ExziLogoIcon.vue";
import { Bars3Icon, FireIcon } from "@heroicons/vue/24/outline";

const props = defineProps(["title", "content", "updateDate", "url", "type"]);
const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};
</script>
