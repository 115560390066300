import axios from "@/api/axios";

const state = {
  referral_link: "",
  referral_code: "",
  referral_history: [],
};

const mutations = {
  SET_REFERRAL_LINK(state, value) {
    state.referral_link = value;
  },
  SET_REFERRAL_CODE(state, value) {
    state.referral_code = value;
  },
  SET_REFERRAL_HISTORY(state, value) {
    state.referral_history = value;
  },
};

const actions = {
  async getReferral({ commit }) {
    try {
      const response = await axios.get("/api/referral");
      const data = response.data.data;
      commit("SET_REFERRAL_LINK", data.info.link);
      commit("SET_REFERRAL_CODE", data.info.code);
      commit("SET_REFERRAL_HISTORY", data.list);
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  referralLink: (state) => state.referral_link,
  referralCode: (state) => state.referral_code,
  referralHistory: (state) => state.referral_history,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
