<template>
  <div
    :class="[
      'hover:text-primary-800 inline text-sm',
      disabled
        ? 'text-navy-200 cursor-not-allowed'
        : 'text-primary-700 cursor-pointer',
    ]"
    @click="() => !disabled && emit('click')"
  >
    <span v-if="label">{{ label }}</span>
    <span v-else><icon /></span>
  </div>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";

defineProps(["label", "disabled", "icon"]);
const emit = defineEmits(["click"]);
</script>
