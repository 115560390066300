<template>
  <div>
    <div
      class="dark:text-white text-navy-800 text-[40px] font-bold sm:mb-10 mb-6"
    >
      Markets Overview
    </div>
    <div
      class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 w-full items-center gap-8"
    >
      <market-overview-card
        v-for="item in displayData"
        :key="item.id"
        :header-img="item.headerImg"
        :header-label="item.headerLabel"
        :item-data="item"
      />
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import MarketOverviewCard from "@/views/Market/MarketOverviewCard.vue";

const store = useStore();
const allPairs = computed(() => store.getters.allPairs);
const activeCurrencies = computed(() => store.getters.activeCurrencies);

const trendingPairs = computed(() => {
  if (isEmpty(allPairs.value) || isEmpty(activeCurrencies.value)) {
    return [];
  }
  return allPairs.value
    .map((item, index) => {
      return {
        id: index,
        img: activeCurrencies.value[item.main.id].icon,
        token1: item.main.iso3,
        token2: item.second.iso3,
        price: item.main.rate_usd / 1e8,
        percent: item.percent,
        volume: item.volume,
      };
    })
    .sort((valA, valB) => {
      return valA.volume - valB.volume;
    })
    .slice(0, 3);
});

const gainerPairs = computed(() => {
  if (isEmpty(allPairs.value) || isEmpty(activeCurrencies.value)) {
    return [];
  }
  return allPairs.value
    .map((item, index) => {
      return {
        id: index,
        img: activeCurrencies.value[item.main.id].icon,
        token1: item.main.iso3,
        token2: item.second.iso3,
        price: item.main.rate_usd / 1e8,
        percent: item.percent,
      };
    })
    .sort((valA, valB) => {
      return valB.percent - valA.percent;
    })
    .slice(0, 3);
});

const loserPairs = computed(() => {
  if (isEmpty(allPairs.value) || isEmpty(activeCurrencies.value)) {
    return [];
  }
  return allPairs.value
    .map((item, index) => {
      return {
        id: index,
        img: activeCurrencies.value[item.main.id].icon,
        token1: item.main.iso3,
        token2: item.second.iso3,
        price: item.main.rate_usd / 1e8,
        percent: item.percent,
      };
    })
    .sort((valA, valB) => {
      return valA.percent - valB.percent;
    })
    .slice(0, 3);
});

const displayData = computed(() => {
  return [
    {
      id: 0,
      headerImg: "/assets/icons/market/overview_trending.svg",
      headerLabel: "Trending",
      data: trendingPairs.value,
    },
    {
      id: 1,
      headerImg: "/assets/icons/market/overview_gainer.svg",
      headerLabel: "Gainers",
      data: gainerPairs.value,
    },
    {
      id: 2,
      headerImg: "/assets/icons/market/overview_losers.svg",
      headerLabel: "Losers",
      data: loserPairs.value,
    },
  ];
});
</script>
