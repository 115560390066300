export const TOAST_DURATION = 5000;
export const DEFAULT_RESEND_CODE_DURATION = 300;
export const COMPANY_NAME = "EXZi";

export const DEFAULT_PAIR_TO_SHOW = "BTCUSDT";
export const DEFAULT_PAIR = "BTC/USDT";

export const API_URL = "https://dev-api.exzi.com/";
export const SOCKET_URL = "https://dev-socket.exzi.com";
export const MARKET_CAP_API_KEY = "e6da2c31-6ffc-499d-8630-f36c94a0d7d0";

export const DEFAULT_LOCALE = "en";
export const AVAILABLE_LANGS = [
  "en",
  "ru",
  "ar",
  "de",
  "el",
  "es",
  "fa",
  "fr",
  "hi",
];
export const CLEVERTAP_PROJECT_ID = "656-68W-6W7Z";

export const TRADING_PAIRS = [
  { id: 1, name: "BTC/USDC", value: "BTCUSDC" },
  { id: 2, name: "ETH/USDC", value: "ETHUSDC" },
  { id: 3, name: "XRP/BTC", value: "XRPBTC" },
  { id: 4, name: "SOL/USDC", value: "SOLUSDC" },
];

export const languageList = [
  {
    id: 0,
    label: "English",
    locale: "en",
  },
  {
    id: 1,
    label: "简体中文",
    locale: "zh_CN",
  },
  {
    id: 2,
    label: "Русский",
    locale: "ru",
  },
  {
    id: 3,
    label: "Deutsch",
    locale: "de",
  },
  {
    id: 4,
    label: "Tiếng Việt",
    locale: "vi",
  },
  {
    id: 5,
    label: "Português (Portugal)",
    locale: "pt",
  },
  {
    id: 6,
    label: "Türkçe",
    locale: "tr",
  },
  {
    id: 7,
    label: "Українська",
    locale: "uk",
  },
];

export const walletTypes = [
  { id: 1, name: "Trading", value: "spot" },
  { id: 2, name: "Funding", value: "funding" },
];

export const TRADE_TYPES = ["Limit", "Market", "Stop Limit"];
export const TRADE_ACTION_TYPES = ["Buy", "Sell"];

export const verificationStep = [
  { id: 1, name: "choose" },
  { id: 2, name: "information" },
  { id: 3, name: "questionnaire" },
  { id: 4, name: "upload-start" },
  { id: 5, name: "upload-id" },
  { id: 5, name: "upload-passport" },
  { id: 5, name: "upload-dl" },
  { id: 5, name: "upload-face" },
];

export const BTC_ID = 2;
export const USDC_ID = 581;
export const USDT_ID = 451;

export const CMC_BASE_URL =
  "https://s3.coinmarketcap.com/generated/sparklines/web/7d/";
export const CMC = {
  BTC: 1,
  ETH: 1027,
  USDT: 825,
  USDC: 3408,
  BNB: 1839,
  BUSD: 4687,
  XRP: 52,
  DOGE: 74,
  ADA: 2010,
  MATIC: 3890,
  DAI: 4943,
  DOT: 6636,
  TRX: 1958,
  LTC: 2,
  SOL: 5426,
  SHIB: 5994,
  UNI: 7083,
  LEO: 3957,
  AVAX: 5805,
  WBTC: 3717,
  TON: 11419,
  LINK: 1975,
  XMR: 328,
  ATOM: 3794,
  ETC: 1321,
  BCH: 1831,
  XLM: 512,
  CRO: 3635,
  ALGO: 4030,
  OKB: 3897,
  QNT: 3155,
  APE: 18876,
  VET: 3077,
  NEAR: 6535,
  FIL: 2280,
  HBAR: 4642,
  ICP: 8916,
  EOS: 1765,
  USDP: 3330,
  EGLD: 6892,
  BSV: 3602,
  LUNC: 4172,
  HT: 2502,
  FLOW: 4558,
  AAVE: 7278,
  XTZ: 2011,
  TUSD: 2563,
  THETA: 2416,
  CHZ: 4066,
  SAND: 6210,
  USDD: 19891,
  TWT: 5964,
  AXS: 6783,
  ZEC: 1437,
  KCS: 2087,
  MANA: 1966,
  GUSD: 3306,
  BTT: 16086,
  BIT: 11221,
  FTM: 3513,
  CAKE: 7186,
  MKR: 1518,
  XCN: 18679,
  APT: 21794,
  GRT: 6719,
  PAXG: 4705,
  XEC: 10791,
  KLAY: 4256,
  DASH: 131,
  MIOTA: 1720,
  NEO: 1376,
  USDN: 5068,
  RUNE: 4157,
  FEI: 8642,
  GMX: 11857,
  SNX: 2586,
  OSMO: 12220,
  MINA: 8646,
  GT: 4269,
  NEXO: 2694,
  ETHW: 21296,
  STX: 4847,
  LDO: 8000,
  IMX: 10603,
  ZIL: 2469,
  CRV: 6538,
  "1INCH": 8104,
  CSPR: 5899,
  DCR: 1168,
  XDC: 2634,
  LRC: 1934,
  BAT: 1697,
  XEM: 873,
  HOT: 2682,
  ENJ: 2130,
  AR: 5632,
  BAL: 5728,
  KAVA: 4846,
  CELO: 5567,
  CVX: 9903,
  BTG: 2083,
  RVN: 2577,
  ENS: 13855,
  HNT: 5665,
  SUSHI: 6758,
  OP: 11840,
  GNO: 1659,
  TFUEL: 3822,
  ROSE: 7653,
  KSM: 5034,
  QTUM: 1684,
  CHSB: 2499,
  YFI: 5864,
  USTC: 7129,
  GLM: 1455,
  KDA: 5647,
  IOTX: 2777,
  XCH: 9258,
  GMT: 18069,
  //GMT: 10180,
  JST: 5488,
  XYM: 8677,
  ANKR: 3783,
  MASK: 8536,
  WAVES: 1274,
  LUNA: 20314,
  BNX: 9891,
  GLMR: 6836,
  ELON: 9436,
  POLY: 2496,
  ONE: 3945,
  // ONE: 2324,
  ASTR: 12885,
  WOO: 7501,
  JASMY: 8425,
  AMP: 6945,
  LPT: 3640,
  GALA: 7080,
  OMG: 1808,
  ONT: 2566,
  ZRX: 1896,
  ICX: 2099,
  RSR: 3964,
  HIVE: 5370,
  T: 17751,
  IOST: 2405,
  FLUX: 3029,
  SFP: 8119,
  DGB: 109,
  SC: 1042,
  RBN: 12387,
  NFT: 9816,
  AUDIO: 7455,
  ZEN: 1698,
  CEL: 2700,
  MAGIC: 14783,
  BONE: 11865,
  BTRST: 11584,
  BORA: 3801,
  STORJ: 1772,
  RNDR: 5690,
  UMA: 5617,
  WAXP: 2300,
  SXP: 4279,
  INJ: 7226,
  PEOPLE: 14806,
  ABBC: 3437,
  SCRT: 5604,
  SSV: 12999,
  RAD: 6843,
  EWT: 5268,
  RLC: 1637,
  XNO: 1567,
  // XNO: 8368,
  DYDX: 11156,
  LSK: 1214,
  OCEAN: 3911,
  SLP: 5824,
  PLA: 7461,
  PUNDIX: 9040,
  TRIBE: 9025,
  REQ: 2071,
  CVC: 1816,
  KNC: 9444,
  MXC: 3628,
  SKL: 5691,
  MED: 2303,
  API3: 7737,
  FXS: 6953,
  VGX: 1817,
  MX: 4041,
  SNT: 1759,
  PYR: 9308,
  ILV: 8719,
  DAO: 8420,
  ANT: 1680,
  WIN: 4206,
  REV: 2135,
  CHR: 3978,
  CELR: 3814,
  REN: 2539,
  METIS: 9640,
  WTRX: 18579,
  HEX: 5015,
  stETH: 8085,
  FRAX: 6952,
  WBNB: 7192,
  BTCB: 4023,
  BTTOLD: 3718,
  HBTC: 6941,
  XAUT: 5176,
  XRD: 11948,
  FTT: 4195,
  MV: 17704,
  USDJ: 5446,
  NXM: 5830,
  BGB: 11092,
  TMG: 22538,
  DFI: 5804,
  RPL: 2943,
  LUSD: 9566,
  KRD: 20014,
  SAFE: 3918,
  BRISE: 11079,
  TEL: 2394,
  LN: 4512,
  EURS: 2989,
  ZEON: 3795,
  BabyDoge: 10407,
  MC: 13523,
  vUSDC: 7958,
  USDX: 6651,
  KAS: 20396,
  CTC: 5198,
  VVS: 14519,
  WEMIX: 7548,
  VERI: 1710,
  DEXE: 7326,
  HOOK: 22764,
  LYXe: 5625,
  AVINOC: 3089,
  ERG: 1762,
  DESO: 10442,
  ONG: 3217,
  CKB: 4948,
  NMR: 1732,
  LOOKS: 17081,
  ACA: 6756,
  XPRT: 7281,
  ELF: 2299,
  SYS: 541,
  HI: 11240,
  ARRR: 3951,
  PROM: 4120,
  MVL: 2982,
  ANY: 5892,
  MDX: 8335,
  KEEP: 5566,
  MBOX: 9175,
  FX: 3884,
  SYN: 12147,
  BICO: 9543,
  CTSI: 5444,
  DKA: 5908,
  CEEK: 2856,
  FUN: 1757,
  TRAC: 2467,
  CFG: 6748,
  ARDR: 1320,
  MOB: 7878,
  FET: 3773,
  COTI: 3992,
  NU: 4761,
  STEEM: 1230,
  RKN: 5072,
  POWR: 2132,
  BSW: 10746,
  MAID: 291,
  ORBS: 3835,
  HUM: 3600,
  NEST: 5841,
  DENT: 1886,
  HFT: 22461,
  IQ: 2930,
  RACA: 11346,
  ALPHA: 7232,
  BAND: 4679,
  POLYX: 20362,
  STRAX: 1343,
  RENBTC: 5777,
  GAL: 11877,
  // GAL: 5228,
  POLIS: 11213,
  CTK: 4807,
  EVER: 7505,
  UOS: 4189,
  SPELL: 11289,
  MLK: 5266,
  BFC: 7817,
  SOLO: 5279,
  vBUSD: 7959,
  WEVER: 20701,
  RBTC: 3626,
  PHA: 6841,
  VLX: 4747,
  NKN: 2780,
  BNT: 1727,
  VAI: 7824,
  REEF: 6951,
  WRX: 5161,
  MFT: 2896,
  LOOM: 2588,
  CORE: 7242,
  WVLX: 19076,
  VOLT: 19650,
  REP: 1104,
  QUACK: 10641,
  WMT: 13769,
  MNW: 2763,
  LQTY: 7429,
  ORC: 5326,
  DAG: 2868,
  OXT: 5026,
  RAMP: 7463,
  XYO: 2765,
  OUSD: 7189,
  SURE: 5113,
  AGIX: 2424,
  XSGD: 8489,
  CFX: 7334,
  QKC: 2840,
  JOE: 11396,
  AMPL: 4056,
  DERO: 2665,
  COL: 20672,
  YFII: 5957,
  ARK: 1586,
  STG: 18934,
  SUN: 10529,
  AXEL: 6216,
  XVS: 7288,
  CBG: 11498,
  GHST: 7046,
  SRM: 6187,
  ASD: 3673,
  FORTH: 9421,
  STPT: 4006,
  OGN: 5117,
  TLM: 9119,
  UQC: 2273,
  ONUS: 15261,
  ADS: 1883,
  H2O: 19091,
  DAWN: 5618,
  XVG: 693,
  TIME: 1556,
  SFUND: 8972,
  CUSD: 7236,
  AXL: 17799,
  RIF: 3701,
  BETA: 11307,
  EFI: 8985,
  DODO: 7224,
  C98: 10903,
  STMX: 2297,
  MTL: 1788,
  BWO: 20382,
  VTHO: 3012,
  XWC: 268,
  UTK: 2320,
  DIVI: 3441,
  MBX: 18895,
  AERGO: 3637,
  MUSD: 5747,
  ACH: 6958,
  SAITAMA: 20513,
  DAR: 11374,
  MOVR: 9285,
  LEVER: 20873,
  AOG: 3316,
  TLOS: 4660,
  MLN: 1552,
  BADGER: 7859,
  COCOS: 4275,
  SSX: 5612,
  GTN: 3914,
  MBL: 4038,
  RSS3: 17917,
  WXT: 4090,
  POND: 7497,
  SUSD: 2927,
  MRS: 21178,
  NVIR: 15924,
  SUPER: 8290,
  HEDG: 3662,
  CEUR: 9467,
  ALPACA: 8707,
  CON: 3866,
  POLS: 7208,
  ETN: 2137,
  CTXC: 2638,
  PERP: 6950,
  BLOK: 11206,
  META: 3418,
  BOND: 7440,
  MCT: 16946,
  ALICE: 8766,
  TT: 3930,
  aETHc: 8100,
  DPI: 7055,
  HUSD: 4779,
  YGG: 10688,
  TRR: 9666,
  XCAD: 9868,
  CET: 2941,
  ATA: 10188,
  STRK: 8911,
  DUSK: 4092,
  DFG: 19590,
  AURORA: 14803,
  RLY: 8075,
  vUSDT: 7957,
  LAT: 9720,
  LAZIO: 12687,
  BOBA: 14556,
  MTRG: 5919,
  ARPA: 4039,
  GXC: 1750,
  PRO: 1974,
  VEGA: 10223,
  GODS: 10631,
  TRB: 4944,
  UFO: 10729,
  WOZX: 7882,
  RARE: 11294,
  TKO: 9020,
  ATLAS: 11212,
  LOKA: 17145,
  WAN: 2606,
  RSV: 6727,
  USDK: 4064,
  LTO: 3714,
  KP3R: 7535,
  BAKE: 7064,
  AVA: 2776,
  AE: 1700,
  TRU: 7725,
  LIT: 6833,
  KLV: 6724,
  ULT: 3666,
  NEBL: 1955,
  BTCST: 8891,
  IDEX: 3928,
  TVK: 8037,
  ICHI: 7726,
  ORN: 5631,
  VRA: 3816,
  ERN: 8615,
  FORT: 20622,
  GAFI: 11783,
  HSF: 16979,
  RAY: 8526,
  LOCUS: 3855,
  WNXM: 5939,
  EXRD: 7692,
  EUROC: 20641,
  CBK: 8107,
  STARL: 10821,
  WKAI: 19080,
  AUCTION: 8602,
  FWT: 7585,
  RARI: 5877,
  DIA: 6138,
  BIFI: 7311,
  KAI: 5453,
  ALPINE: 18112,
  TOMO: 2570,
  ALCX: 8613,
  LON: 8083,
  YOOSHI: 9892,
  KMD: 1521,
  BTS: 463,
  CRE: 3946,
  SOFI: 16552,
  LCX: 4950,
  GRS: 258,
  HUNT: 5380,
  DXD: 5589,
  CHP: 2569,
  SBD: 1312,
  VRSC: 5049,
  SNM: 9931,
  KUNCI: 18208,
  LINA: 7102,
  QUICK: 8206,
  FLM: 7150,
  XPR: 5350,
  CHO: 19018,
  CLV: 8384,
  XMON: 8509,
  CQT: 7411,
  MIM: 21557,
  SWEAT: 21351,
  PSG: 5226,
  BTM: 1866,
  SPS: 11035,
  CULT: 17742,
  SANTOS: 15248,
  AIOZ: 9104,
  BCD: 2222,
  NULS: 2092,
  QRDO: 10750,
  AKT: 7431,
  ASTRAFER: 20886,
  TROY: 5007,
  BZZ: 10293,
  FCT2: 4953,
  RFR: 2553,
  MONA: 213,
  // MONA: 7866,
  VIDT: 22710,
  QLC: 2321,
  ARG: 10430,
  EUM: 3968,
  UPP: 2866,
  PORTO: 14052,
  MOC: 2915,
  "1ECO": 16604,
  TPT: 5947,
  BOSON: 8827,
  WILD: 9674,
  DEP: 5429,
  REI: 19819,
  QI: 9288,
  FARM: 6859,
  FIO: 5865,
  MNGO: 11171,
  GAS: 1785,
  CREAM: 6193,
  UBT: 2758,
  GOG: 10630,
  STRM: 17464,
  EPS: 8938,
  BEL: 6928,
  PHB: 13969,
  FIRO: 1414,
  GYEN: 8771,
  AQT: 7460,
  GTC: 10052,
  VITE: 2937,
  HARD: 7576,
  SAITO: 9194,
  COS: 4036,
  HEZ: 7424,
  IRIS: 3874,
  UNFI: 7672,
  EPX: 19924,
  KRT: 5115,
  BAR: 5225,
  KIN: 1993,
  ROUTE: 8292,
  AGLD: 11568,
  BLZ: 2505,
  MEV: 19063,
  DATA: 2143,
  ONIT: 6739,
  BITCI: 8357,
  NOIA: 4191,
  BURGER: 7158,
  EGG: 4467,
  SWAP: 5829,
  ELA: 2492,
  CHESS: 10974,
  SOS: 16463,
  ADX: 1768,
  ALEPH: 5821,
  vXVS: 7960,
  CENNZ: 2585,
  DF: 4758,
  STC: 5966,
  BNANA: 3742,
  HERO: 10778,
  CITY: 10049,
  OM: 6536,
  PART: 1826,
  RMRK: 12140,
  WING: 7048,
  KEY: 2398,
  FSN: 2530,
  CLT: 5401,
  AHT: 6641,
  LA: 2090,
  MPL: 9417,
  MAP: 4956,
  BMX: 2933,
  BEAM: 3702,
  VOXEL: 15678,
  AST: 2058,
  GFI: 13967,
  GTO: 2289,
  FRONT: 5893,
  WTC: 1925,
  PIT: 9177,
  AION: 2062,
  PTS: 20825,
  COVAL: 788,
  YLD: 8066,
  PLU: 1392,
  VIB: 2019,
  AMO: 3260,
  KAR: 10042,
  FIS: 5882,
  NMC: 3,
  PNK: 3581,
  FIDA: 7978,
  SOUL: 2827,
  DVF: 10759,
  DORA: 8800,
  HDAO: 5299,
  KOK: 5185,
  KILT: 6830,
  TOKE: 11202,
  BIDR: 6855,
  MDT: 2348,
  NRG: 3218,
  PRE: 2245,
  ALI: 16876,
  DG: 15478,
  PIVX: 1169,
  PRQ: 5410,
  AMB: 2081,
  HNS: 5221,
  MARO: 3175,
  PROS: 8255,
  DEGO: 7087,
  ATOLO: 15388,
  OOKI: 16434,
  MATH: 5616,
  DERC: 10744,
  ANC: 8857,
  DEXT: 5866,
  VXV: 4441,
  APM: 5079,
  GBYTE: 1492,
  HYDRA: 8245,
  DREP: 9148,
  HIGH: 11232,
  MET: 2873,
  SERO: 4078,
  SAMO: 9721,
  APX: 16334,
  WABI: 2267,
  AUTO: 8387,
  VBIT: 6947,
  HOPR: 6520,
  ASM: 6069,
  DOCK: 2675,
  ZBC: 18860,
  CPOOL: 12573,
  XHV: 2662,
  DX: 3139,
  HTR: 5552,
  MIX: 4366,
  APL: 2992,
  SLND: 13524,
  BOO: 9608,
  DAD: 4862,
  BANANA: 8497,
  DNT: 1856,
  GST: 16352,
  OAX: 1853,
  SDN: 11451,
  GXA: 21060,
  ALT: 10897,
  BTRS: 4257,
  OXEN: 2748,
  BOA: 4217,
  AKRO: 4134,
  SIX: 3327,
  SDAO: 9638,
  SOLVE: 3724,
  MLT: 9764,
  FOX: 8200,
  BLCT: 5280,
  ALBT: 6957,
  PPC: 5,
  PDEX: 9017,
  AWC: 3667,
  STOS: 9760,
  URUS: 8616,
  SWTH: 2620,
  NCT: 2630,
  NIM: 2916,
  KAN: 2934,
  CLO: 2757,
  ZCN: 2882,
  ZNN: 4003,
  IHF: 3301,
  KRL: 2949,
  BABY: 10334,
  GEL: 7229,
  ROOK: 7678,
  MWC: 5031,
  HEGIC: 6929,
  TONE: 2578,
  VTC: 99,
  ABT: 2545,
  DDX: 7228,
  DPR: 8894,
  ACM: 8538,
  QOM: 18919,
  RAI: 8525,
  BTSE: 5305,
  PTU: 14179,
  FOR: 4118,
  DVI: 7590,
  CUBE: 5338,
  RISE: 15257,
  UFT: 7412,
  JUV: 5224,
  XDB: 4566,
  PEEL: 21013,
  PENDLE: 9481,
  SPA: 6715,
  MCRT: 15721,
  ZKS: 8202,
  MANC: 19624,
  WICC: 2346,
  QSP: 2212,
  CVP: 6669,
  BTC2: 3974,
  CRTS: 12064,
  ADP: 8044,
  MIR: 7857,
  IDRT: 4702,
  PKF: 8617,
  SENSO: 5522,
  PUSH: 9111,
  XNC: 5060,
  MVC: 7703,
  SUKU: 6180,
  PNT: 5794,
  CTX: 10368,
  HOGE: 8438,
  ZASH: 5610,
  NWC: 4890,
  WWY: 17047,
  ERC20: 2165,
  EVZ: 6430,
  PALLA: 10669,
  IHC: 14371,
  AFC: 11532,
  NFTX: 8191,
  CIV: 11345,
  HAPI: 8567,
  PZM: 1681,
  CERE: 4710,
  LSS: 10103,
  QRL: 1712,
  WNCG: 11222,
  RAE: 4887,
  GO: 2861,
  KDAG: 5626,
  SHPING: 3422,
  POSI: 11234,
  XEP: 8216,
  INSTAR: 2558,
  XCP: 132,
  LTX: 7616,
  GRID: 2134,
  AVT: 1948,
  DMD: 77,
  SOCKS: 7095,
  UNCX: 7664,
  INNBC: 5016,
  FRTS: 12131,
  BASIC: 5481,
  GET: 2354,
  HAI: 5583,
  BF: 4283,
  XFT: 6236,
  TTT: 5514,
  OVR: 8144,
  BSCPAD: 8660,
  DACXI: 10372,
  GOC: 3052,
  PERL: 4293,
  SOC: 5126,
  TITAN: 7206,
  XMC: 2655,
  CUDOS: 8258,
  WHALE: 6679,
  HIBS: 6470,
  MITH: 2608,
  ES: 4860,
  ATM: 5227,
  BEPRO: 5062,
  ACX: 22620,
  INTER: 11796,
  MSOL: 11461,
  RFOX: 7654,
  LBC: 1298,
  RDD: 118,
  DGD: 1229,
  FRA: 4249,
  CHNG: 9071,
  NSBT: 7320,
  CRPT: 2447,
  GMEE: 9103,
  BAN: 4704,
  EPIC: 5435,
  EMRX: 4490,
  ASTA: 6375,
  TEMCO: 3722,
  PEAK: 5354,
  EQUAD: 3625,
  BUY: 11750,
  STAT: 21156,
  RGT: 7486,
  MCB: 5956,
  VAL: 1154,
  BUX: 2465,
  REAP: 7677,
  QTCON: 4746,
  GARI: 12969,
  XPX: 3126,
  THG: 11926,
  TREEB: 12301,
  vDAI: 8214,
  XOR: 5802,
  NYE: 4268,
  ASK: 7105,
  AURY: 11367,
  VEED: 11330,
  FST: 3840,
  XDEFI: 13472,
  NUM: 13521,
  CCT: 19152,
  CRU: 6747,
  DIME: 90,
  ETHAX: 20090,
  SCP: 4074,
  MBS: 13011,
  ASR: 5229,
  GRIN: 3709,
  ZYN: 4951,
  ALU: 9637,
  FOAM: 3631,
  vLTC: 7964,
  ZANO: 4691,
  VELO: 7127,
  VEE: 2223,
  PROB: 4586,
  VR: 14822,
  VRT: 9691,
  EL: 5382,
  CELL: 8992,
  JUP: 1503,
  PNG: 8422,
  ARIA20: 8276,
  MEME: 1191,
  OBSR: 3698,
  ARV: 11541,
  FUND: 3854,
  KMA: 15305,
  IPAD: 11977,
  RED: 21690,
  RING: 5798,
  DYP: 8080,
  TORN: 8049,
  MOF: 2441,
  MINTME: 3361,
  SYLO: 5662,
  BIOT: 8034,
  UPUNK: 9473,
  MASQ: 8376,
  MYST: 1721,
  NXS: 789,
  SWASH: 12148,
  GF: 15585,
  VINU: 15270,
  CRP: 6865,
  SIDUS: 15463,
  GHX: 6554,
  NAKA: 12749,
  SIGNA: 10776,
  TOWN: 13503,
  DRGN: 2243,
  MSB: 7591,
  GULF: 19236,
  NEER: 14294,
  TEN: 2576,
  STAKE: 5601,
  CAS: 2529,
  DBC: 2316,
  QASH: 2213,
  RAINI: 9061,
  SHILL: 10462,
  CMP: 20056,
  SHFT: 8917,
  PCX: 4200,
  IGNIS: 2276,
  OG: 5309,
  WOM: 19623,
  SHIRYO: 13727,
  SWFTC: 2341,
  HPT: 3721,
  SOV: 8669,
  FCT: 1087,
  BONDLY: 7931,
  GOM2: 5839,
  INV: 8720,
  TRA: 7637,
  CGT: 5719,
  WRLD: 16678,
  TRV: 4060,
  MANDOX: 19747,
  BITCNY: 624,
  KROM: 14745,
  CAPS: 9291,
  CGG: 8648,
  ID: 8495,
  VIDYA: 6709,
  GOVI: 8408,
  BTR: 4167,
  POR: 11531,
  POLK: 8579,
  RVF: 9176,
  MTH: 1947,
  STRX: 9220,
  MOBI: 2429,
  POA: 2548,
  BCN: 372,
  "1WO": 2601,
  vLINK: 7975,
  BOR: 7509,
  DFYN: 9511,
  SAN: 1807,
  TIFI: 19476,
  XED: 8163,
  WIKEN: 4809,
  NXT: 66,
  NAP: 15137,
  POOLZ: 8271,
  TRVL: 14336,
  GSWAP: 7588,
  MHC: 3756,
  HC: 1903,
  PEX: 16104,
  GMM: 14304,
  MEETONE: 3136,
  DFA: 11150,
  KASTA: 16481,
  FB: 11308,
  EDG: 1596,
  ADK: 1706,
  VALOR: 3875,
  KALM: 10099,
  DFL: 10294,
  MAN: 2474,
  FUSE: 5634,
  XCHF: 4075,
  NEW: 3871,
  OTO: 3850,
  CIRUS: 11578,
  PKT: 9523,
  EPIK: 10047,
  CHI: 5541,
  OGV: 20949,
  ZIG: 9260,
  NOW: 3893,
  TOMS: 17523,
  MTV: 3853,
  BFT: 2605,
  REVV: 6993,
  XTM: 10421,
  SFI: 7617,
  VSP: 8492,
  ATRI: 7395,
  DOBO: 12859,
  SHR: 4197,
  INSUR: 8799,
  UNO: 8875,
  ENQ: 4245,
  NBT: 18101,
  ERA: 18483,
  CAJ: 3715,
  OOKS: 8349,
  NVT: 5906,
  MUSE: 7805,
  KYL: 8644,
  ILC: 3617,
  BLY: 6283,
  CARD: 2891,
  MAHA: 8043,
  VEMP: 11423,
  BAAS: 3142,
  BLANK: 8695,
};
