<template>
  <div class="relative">
    <div
      class="sm:mt-28 mt-12 flex justify-between flex-grow w-full md:flex-row flex-col"
    >
      <div class="flex flex-col justify-start sm:basis-1/2 w-full">
        <div
          class="sm:text-[64px] text-[48px] dark:text-white text-navy-800 font-bold"
        >
          Refer to win <br />
          more
        </div>
        <div
          class="sm:text-[24px] text-[18px] dark:text-white text-navy-800 text-lg font-normal mt-6"
        >
          Referral campaign will be coming soon
        </div>
      </div>
      <div class="w-full sm:basis-1/2">
        <div
          class="dark:bg-[#151823] bg-white rounded-[32px] flex w-full p-6 flex-col"
          :style="
            currentTheme === 'dark'
              ? 'box-shadow: 0px 0px 40px 0px #424D70A3'
              : 'box-shadow: 2px 4px 16px 0px #00000014'
          "
        >
          <div
            class="relative w-full h-[88px] bg-referral-card bg-[#F39D0E] rounded-2xl overflow-hidden"
          >
            <div class="absolute p-4 z-10 flex justify-between w-full h-full">
              <div class="flex flex-col">
                <div class="text-[#1B1F2D] text-xs font-semibold">
                  Your commission rate:
                </div>
                <div class="text-[#1B1F2D] text-3xl font-bold">30%</div>
              </div>
              <div>
                <div class="text-[#1B1F2D] text-xs font-medium">
                  Referral Settings
                </div>
              </div>
            </div>
            <div class="relative w-full h-[88px] mt-2">
              <img
                src="/assets/images/referral/card.svg"
                class="object-cover rounded-2xl h-[88px] absolute bottom-0 right-0"
              />
            </div>
            <div class="w-full h-[88px] -mt-24">
              <img src="/assets/images/referral/ellipse.svg" class="h-[88px]" />
            </div>
          </div>
          <div class="sm:mt-6 mt-4">
            <text-view
              :value="referralLink"
              placeholder="Select Coin"
              width="full"
              rounded="2xl"
              @focus="handleClickCopy(referralLink)"
            >
              <template #prefix>
                <div
                  class="sm:text-lg text-sm text-navy-200 shrink-0 self-center mr-3 font-semibold"
                >
                  Referral Link
                </div>
              </template>
              <template #suffix>
                <span
                  class="font-semibold sm:text-lg text-sm text-right truncate block whitespace-nowrap overflow-hidden shrink w-full"
                >
                  {{ referralLink }}
                </span>
                <div
                  class="flex justify-center items-center dark:bg-navy-500 bg-navy-10 sm:size-12 size-10 shrink-0 rounded-full ml-4 cursor-pointer"
                  v-on:click="() => handleClickCopy(referralLink)"
                >
                  <img
                    :src="
                      currentTheme === 'dark'
                        ? '/assets/images/referral/copy.svg'
                        : '/assets/images/referral/copy-black.svg'
                    "
                    class="sm:size-6 size-4"
                  />
                </div>
              </template>
            </text-view>
          </div>
          <div class="sm:mt-6 mt-4">
            <text-view
              :value="referralCode"
              placeholder="Select Coin"
              width="full"
              rounded="2xl"
              @focus="handleClickCopy(referralCode)"
            >
              <template #prefix>
                <div
                  class="sm:text-lg text-sm text-navy-200 shrink-0 self-center mr-3 font-semibold"
                >
                  Referral Link
                </div>
              </template>
              <template #suffix>
                <span
                  class="font-semibold sm:text-lg text-sm text-right truncate block whitespace-nowrap overflow-hidden shrink w-full"
                >
                  {{ referralCode }}
                </span>
                <div
                  class="flex justify-center items-center dark:bg-navy-500 bg-navy-10 sm:size-12 size-10 shrink-0 rounded-full ml-4 cursor-pointer"
                  v-on:click="() => handleClickCopy(referralCode)"
                >
                  <img
                    :src="
                      currentTheme === 'dark'
                        ? '/assets/images/referral/copy.svg'
                        : '/assets/images/referral/copy-black.svg'
                    "
                    class="sm:size-6 size-4"
                  />
                </div>
              </template>
            </text-view>
          </div>
          <div class="w-full flex justify-between mt-8 gap-6">
            <fill-button class="w-full bg-primary-700">
              Login or Sign Up
            </fill-button>
            <div
              className="h-14 w-14 rounded-full flex justify-center items-center border-primary-700 border-[1px] shrink-0"
            >
              <qr-code-icon class="text-primary-700 h-6 w-6" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { QrCodeIcon } from "@heroicons/vue/24/outline";
import TextView from "@/components/TextEdit/TextView.vue";

const store = useStore();
const referralLink = computed(() => store.getters.referralLink);
const referralCode = computed(() => store.getters.referralCode);

const handleClickCopy = (value) => {
  var textarea = document.createElement("textarea");
  textarea.value = value;
  textarea.style.position = "fixed";
  textarea.style.left = "-9999px";
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand("copy");
    store.dispatch("setToast", {
      type: "success",
      message: "Copied to clipboard successfully.",
    });
  } catch (err) {
    store.dispatch("setToast", {
      type: "error",
      message: "Failed to copy clipboard.",
    });
  }
};

onMounted(() => {
  store.dispatch("getReferral");
});
</script>
