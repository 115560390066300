<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9585 20.7206L9.59294 19.5402C9.06061 19.0773 8.73658 18.4061 8.73658 17.6886L8.73658 9.88881L11.2594 10.398C11.9074 10.5369 12.3703 9.79623 11.9769 9.2639L7.13958 2.73706C6.86185 2.3436 6.28323 2.3436 5.98234 2.73706L1.14507 9.2639C0.751611 9.79624 1.21451 10.5369 1.86256 10.398L4.38535 9.88881L4.38535 17.8275C4.38535 19.8411 6.02863 21.4844 8.04223 21.4844L10.6576 21.4844C11.0742 21.4844 11.2594 20.9752 10.9585 20.7206Z"
      fill="currentColor"
    />
    <path
      d="M13.0415 3.22137L14.407 4.40176C14.9394 4.86465 15.2634 5.53585 15.2634 6.25334L15.2634 14.0532L12.7406 13.544C12.0926 13.4051 11.6297 14.1457 12.0231 14.6781L16.8604 21.2049C17.1381 21.5984 17.7168 21.5984 18.0176 21.2049L22.8549 14.7012C23.2484 14.1689 22.7855 13.4282 22.1374 13.5671L19.6146 14.0532L19.6146 6.11447C19.6146 4.10087 17.9713 2.45759 15.9577 2.45759L13.3424 2.45759C12.9258 2.45759 12.7406 2.96678 13.0415 3.22137Z"
      fill="currentColor"
    />
  </svg>
</template>
