<template>
  <div class="h-12 sm:px-6 px-4 flex flex-row justify-between items-center">
    <img
      :src="
        currentTheme === 'dark'
          ? require('@/assets/images/navbar/navbar_logo_dark.svg')
          : require('@/assets/images/navbar/navbar_logo_light.svg')
      "
      alt="Exzi Logo"
      class="mr-12 cursor-pointer"
      @click="handleLogoClick"
    />
    <div class="lg:flex flex-row items-center hidden gap-8">
      <navbar-menu-item
        v-for="item in navbarData"
        :key="item.id"
        :alignment="item.alignment"
        :isSubMenu="item.isSubMenu"
        :label="item.label"
        :pathName="item.pathName"
        :sub-menu="item.subMenu"
        :sub-menu-props="item.subMenuProps"
      />
    </div>
    <div class="max-2xl:hidden ml-8">
      <filled-button
        class="bg-primary-700 bg-opacity-[18%] px-3 h-8 dark:text-primary-500 text-primary-800 text-sm hover:bg-opacity-100 hover:text-white"
        label="Rewards Hub"
        type="iconLabel"
        @click="handleRewardsHubClick"
      >
        <template #icon>
          <gift-icon class="stroke-2" />
        </template>
      </filled-button>
    </div>
    <div class="max-xl:hidden ml-8">
      <navbar-menu-item :isSubMenu="false" label="EXZi TR" />
    </div>
    <div class="flex-grow" />
    <div class="mr-2" v-if="!showSubMenu">
      <navbar-menu-item
        :is-sub-menu="true"
        :mobile-adapted="true"
        :sub-menu="SearchDropdown"
        alignment="center"
      >
        <template #icon="{ activated }">
          <icon-button :activated="activated">
            <magnifying-glass-icon class="stroke-2 w-6 h-6" />
          </icon-button>
        </template>
      </navbar-menu-item>
    </div>
    <div v-if="!isLoggedIn" class="max-sm:hidden mr-2 px-4">
      <navbar-menu-item :is-sub-menu="false" label="Login" pathName="signin" />
    </div>
    <div v-if="!isLoggedIn && !showSubMenu" class="mr-3">
      <filled-button
        class="px-6 py-1.5 bg-primary-700 text-white"
        label="Sign up"
        type="roundedTextButton"
        @click="handleSignUpClick"
      />
    </div>
    <div v-if="isLoggedIn" class="max-xl:hidden mr-7">
      <filled-button
        type="iconLabel"
        class="bg-primary-700 text-white h-8 px-3 text-sm"
        label="Deposit"
        @click="goToDeposit"
      >
        <template #icon>
          <arrow-down-tray-icon class="stroke-2"></arrow-down-tray-icon>
        </template>
      </filled-button>
    </div>
    <div v-if="isLoggedIn" class="max-sm:hidden mr-4">
      <navbar-menu-item
        :isSubMenu="true"
        :sub-menu="AssetsDropdown"
        alignment="center"
        label="Assets"
      />
    </div>
    <div v-if="isLoggedIn && !showSubMenu" class="mr-6">
      <navbar-menu-item
        :isSubMenu="true"
        :mobile-adapted="true"
        :sub-menu="ProfileDropdown"
        alignment="end"
      >
        <template #icon="{ activated }">
          <span
            :class="[
              'flex items-center justify-center cursor-pointer rounded-full w-8 h-8 my-2 text-center align-middle text-sm font-medium border border-navy-300 hover:bg-navy-300  hover:text-white',
              activated
                ? 'bg-navy-300 text-white'
                : 'dark:bg-navy-500 dark:text-white bg-navy-50 text-navy-800',
            ]"
            @click="handleContentClick"
          >
            SA
          </span>
        </template>
      </navbar-menu-item>
    </div>
    <div v-if="isLoggedIn && !showSubMenu" class="mr-2">
      <navbar-menu-item
        :is-sub-menu="true"
        :mobile-adapted="true"
        :sub-menu="NotificationDropdown"
        alignment="end"
      >
        <template #icon="{ activated }">
          <icon-button :activated="activated">
            <bell-icon class="w-6 h-6 stroke-2" />
          </icon-button>
        </template>
      </navbar-menu-item>
    </div>
    <div class="max-sm:hidden pr-2">
      <navbar-menu-item
        :is-sub-menu="true"
        :sub-menu="DownloadDropdown"
        alignment="end"
      >
        <template #icon="{ activated }">
          <icon-button :activated="activated">
            <arrow-down-tray-icon class="w-6 h-6 stroke-2" />
          </icon-button>
        </template>
      </navbar-menu-item>
    </div>
    <div class="max-sm:hidden pr-2">
      <navbar-menu-item
        :is-sub-menu="true"
        :sub-menu="LanguageDropdown"
        alignment="end"
      >
        <template #icon="{ activated }">
          <icon-button :activated="activated">
            <globe-alt-icon class="w-6 h-6 stroke-2" />
          </icon-button>
        </template>
      </navbar-menu-item>
    </div>
    <div class="max-sm:hidden">
      <icon-button @click="onThemeChange">
        <sun-icon class="w-6 h-6 stroke-2" />
      </icon-button>
    </div>
    <div class="sm:hidden">
      <icon-button @click="showSubMenu = !showSubMenu">
        <bars3-icon v-if="!showSubMenu" class="w-6 h-6 stroke-2" />
        <x-mark-icon v-else class="w-6 h-6 stroke-2" />
      </icon-button>
    </div>
    <app-navbar-mobile
      :is-show="showSubMenu"
      @close-click="showSubMenu = false"
    />
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import IconButton from "@/components/Button/IconButton.vue";
import { allPairItemsFromData } from "@/utils/utils";
import NotificationDropdown from "@/layout/AppNavbar/dropdown/NotificationDropdown.vue";
import ProfileDropdown from "@/layout/AppNavbar/dropdown/ProfileDropdown.vue";
import DownloadDropdown from "@/layout/AppNavbar/dropdown/DownloadDropdown.vue";
import AssetsDropdown from "@/layout/AppNavbar/dropdown/AssetsDropdown.vue";
import LanguageDropdown from "@/layout/AppNavbar/dropdown/LanguageDropdown.vue";
import SearchDropdown from "@/layout/AppNavbar/dropdown/SearchDropdown.vue";
import NavbarMenuItem from "@/layout/AppNavbar/item/NavbarMenuItem.vue";
import TradeDropdown from "@/layout/AppNavbar/dropdown/TradeDropdown.vue";
import MoreDropdown from "@/layout/AppNavbar/dropdown/MoreDropdown.vue";
import AppNavbarMobile from "@/layout/AppNavbar/AppNavbarMobile.vue";
import {
  ArrowDownTrayIcon,
  Bars3Icon,
  BellIcon,
  GiftIcon,
  GlobeAltIcon,
  MagnifyingGlassIcon,
  SunIcon,
  XMarkIcon,
} from "@heroicons/vue/24/outline";

const store = useStore();
const router = useRouter();
const isLoggedIn = computed(() => store.getters.isLoggedIn);
const showSubMenu = ref(false);

const onThemeChange = () => {
  store.dispatch("toggleTheme", store.getters.currentTheme);
};

const allPairsModified = computed(() => store.getters.allPairsModified);
const activeCurrencies = computed(() => store.getters.activeCurrencies);

const allPairItems = computed(() =>
  allPairItemsFromData(allPairsModified.value, activeCurrencies.value)
);

const handleLogoClick = () => {
  router.push({ name: "home" });
};

const handleSignUpClick = () => {
  router.push({ name: "signup" });
};

const handleRewardsHubClick = () => {
  router.push({ name: "referral" });
};

const goToDeposit = () => {
  router.push({ name: "asset-deposit-crypto" });
};

const navbarData = computed(() => [
  {
    id: 0,
    label: "Buy Crypto",
    isSubMenu: false,
    pathName: "buy-sell",
  },
  {
    id: 1,
    label: "Markets",
    pathName: "markets",
    isSubMenu: false,
  },
  {
    id: 2,
    label: "Trade",
    isSubMenu: true,
    subMenu: TradeDropdown,
    subMenuProps: { allPairItems },
    alignment: "center",
  },
  {
    id: 3,
    label: "Referral",
    isSubMenu: false,
    pathName: "referral",
  },
  {
    id: 4,
    label: "More",
    isSubMenu: true,
    subMenu: MoreDropdown,
    alignment: "center",
  },
]);
</script>
