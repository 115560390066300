<template>
  <dialog-view
    title="Edit Label"
    :contain-header="true"
    :is-open="model"
    @change-is-open="handleOpenChange"
  >
    <template #content>
      <div class="flex flex-col w-full mt-6">
        <label class="text-sm font-semibold dark:text-white text-navy-800 mb-2">
          Wallet Label
        </label>
        <div class="w-full">
          <text-edit
            placeholder="Edit label"
            :filled="false"
            v-model="label"
            rounded="lg"
          />
        </div>
        <div class="w-full mt-12">
          <fill-button
            size="md"
            width="full"
            color="primary"
            @click="handleConfirmClick"
            :disabled="!label.length"
          >
            Confirm
          </fill-button>
        </div>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineModel, ref } from "vue";
import DialogView from "@/components/Dialog/DialogView.vue";

const model = defineModel();
const label = ref("");

const handleOpenChange = () => {
  model.value = !model.value;
};

const handleConfirmClick = () => {
  model.value = false;
};
</script>
