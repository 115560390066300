<template>
  <div class="w-full">
    <div class="w-full px-2">
      <pair-brief />
    </div>
    <div class="sm:mt-10 mt-6 mb-24 sm:container mx-auto w-full sm:px-16 px-2">
      <market-overview />
      <div
        class="dark:text-white text-[32px] font-bold sm:text-[40px] text-navy-800 mt-16"
      >
        Market
      </div>
      <div class="mt-6 sm:mt-10">
        <underline-tab
          :items="marketItems"
          font-size="md"
          :default-selected="marketItems[0]"
          :bottom-line="true"
          @change="handleChangeSelectedTab"
        />
        <div class="sm:max-w-[247px] mt-6 w-full">
          <text-edit
            type="text"
            size="sm"
            rounded="full"
            placeholder="Search coin"
            :filled="false"
            v-model="searchText"
          >
            <template #prefix>
              <magnifying-glass-icon class="w-6 h-6 mr-2 text-navy-100" />
            </template>
          </text-edit>
        </div>
      </div>
      <div>
        <favorites-tab
          v-if="selectedTab === marketItems[0].id"
          :filter="searchText"
        />
        <spot-tab
          v-if="selectedTab === marketItems[1].id"
          :filter="searchText"
        />
        <new-listing-tab
          v-if="selectedTab === marketItems[2].id"
          :filter="searchText"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import UnderlineTab from "@/components/Tab/UnderlineTab.vue";
import PairBrief from "@/views/Trading/PairBrief.vue";
import FavoritesTab from "@/views/Market/Tabs/Favorite/FavoriteTab.vue";
import SpotTab from "@/views/Market/Tabs/SpotTab.vue";
import NewListingTab from "@/views/Market/Tabs/NewListingTab.vue";
import MarketOverview from "@/views/Market/MarketOverview.vue";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";

const selectedTab = ref(0);
const searchText = ref("");
const store = useStore();
const isLoggedIn = computed(() => store.getters.isLoggedIn);

const marketItems = [
  { id: 0, name: "Favorites" },
  { id: 1, name: "Spot" },
  { id: 2, name: "New Listing" },
];

const handleChangeSelectedTab = (item) => {
  selectedTab.value = item.id;
};

onMounted(() => {
  if (isLoggedIn.value) {
    store.dispatch("fetchConfig");
  }
  store.dispatch("fetchNewListings");
});
</script>
