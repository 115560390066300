<template>
  <div ref="captchaContainer"></div>
</template>
<script setup>
import { ref, onMounted, defineProps, defineEmits } from "vue";

const props = defineProps(["gt", "challenge", "success", "onSuccess"]);
const emit = defineEmits(["onClose"]);
const captchaContainer = ref(null);

onMounted(() => {
  window.initGeetest(
    {
      lang: "en",
      gt: props.gt,
      challenge: props.challenge,
      offline: !props.success,
      new_captcha: true,
      product: "bind",
      width: "100%",
    },
    function (captchaObj) {
      captchaObj.appendTo(captchaContainer.value);
      captchaObj.onSuccess(() => {
        const result = captchaObj.getValidate();
        props.onSuccess(result);
      });

      captchaObj.onReady(function () {
        captchaObj.verify();
      });

      captchaObj.onFail(function () {
        emit("onClose");
      });
    }
  );
});
</script>
