<template>
  <div
    class="sm:w-[328px] max-sm:w-[calc(100%-32px)] max-sm:mx-4 rounded-2xl shadow-xl dark:bg-navy-600 border dark:border-navy-400 dark:shadow-black/[0.32] bg-white shadow-black/[0.16]"
  >
    <div class="px-4 pt-4 flex flex-row">
      <img
        src="@/assets/images/navbar/navbar_user_avatar.png"
        class="w-14 h-14"
      />
      <div class="p-2" />
      <div>
        <div
          class="text-lg font-bold dark:text-white text-navy-800 text-wrap break-all"
        >
          {{ loggedInEmail }}
        </div>
        <div class="text-sm dark:text-navy-100 text-navy-300">
          UID: {{ profile.profile.id }}
        </div>
      </div>
    </div>
    <div
      v-if="!isVerified"
      class="my-4 bg-red-500 p-4 mx-4 bg-opacity-10 rounded-lg"
    >
      <div class="font-medium text-sm dark:text-white text-red-800">
        To have full access to all features of EXZi and unlock transaction
        limits you must verify.
      </div>
      <div class="flex flex-row mt-3.5 items-center justify-between">
        <text-badge
          label="Unverified"
          class="bg-red-200 text-red-800 font-semibold text-xs"
        >
          <img src="/assets/icons/unverified_warning.svg" class="w-4 h-4" />
        </text-badge>
        <div
          class="flex flex-row items-center gap-2 cursor-pointer text-sm font-semibold flex-none dark:text-white text-red-800"
          @click="handleClickVerify"
        >
          <div>Verify Now</div>
          <arrow-right-icon class="stroke-2 w-5 h-5" />
        </div>
      </div>
    </div>
    <div class="mx-4 my-6" v-else>
      <div class="flex flex-row">
        <text-badge
          label="Verified"
          class="bg-green-200 text-green-900 font-semibold text-xs"
          :icon="DiamondIcon"
        >
        </text-badge>
        <div class="px-1.5" />
        <text-badge
          label="VIP 0"
          class="bg-orange-200 text-orange-900 font-semibold text-xs"
          :icon="DiamondIcon"
        >
        </text-badge>
      </div>
    </div>
    <div class="mx-4 flex flex-col gap-2">
      <profile-menu-item
        v-for="item in profileMenuData"
        :label="item.label"
        :icon="item.icon"
        :key="item.id"
        :path-name="item.pathName"
      />
    </div>
    <div class="my-3 mx-4 border dark:border-navy-500 border-navy-10"></div>
    <div class="mx-4 pb-4">
      <profile-menu-item label="Log out" :icon="LogoutIcon" @click="logout" />
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import TextBadge from "@/components/Badge/TextBadge.vue";
import ProfileMenuItem from "@/layout/AppNavbar/item/ProfileMenuItem.vue";
import { ArrowRightIcon } from "@heroicons/vue/24/outline";
import DiamondIcon from "@/assets/icons/common/DiamondIcon.vue";
import IdentificationIcon from "@/assets/icons/common/IdentificationIcon.vue";
import SecurityIcon from "@/assets/icons/common/SecurityIcon.vue";
import ReferralsIcon from "@/assets/icons/common/ReferralsIcon.vue";
import SupportIcon from "@/assets/icons/common/SupportIcon.vue";
import AddressBookIcon from "@/assets/icons/common/AddressBookIcon.vue";
import LogoutIcon from "@/assets/icons/common/LogoutIcon.vue";

const store = useStore();
const router = useRouter();
const loggedInEmail = computed(() => store.getters.authInfo?.email ?? "");
const isVerified = computed(
  () => store.getters.verificationInfo?.verification_state === "VERIFIED"
);
const profile = computed(() => store.getters.profile);

const logout = () => {
  store.dispatch("clearAuthInfo");
  router.push({ name: "signin" });
};

const handleClickVerify = () => {
  router.push({ path: "/profile/identity-verification" });
};

const profileMenuData = [
  {
    id: 0,
    label: "Dashboard",
    icon: DiamondIcon,
    pathName: "dashboard",
  },
  {
    id: 1,
    label: "Identity Verification",
    icon: IdentificationIcon,
    pathName: "identity-verification",
  },
  {
    id: 2,
    label: "Security",
    icon: SecurityIcon,
    pathName: "security",
  },
  {
    id: 3,
    label: "Referrals",
    icon: ReferralsIcon,
  },
  {
    id: 4,
    label: "Support",
    icon: SupportIcon,
  },
  {
    id: 5,
    label: "Address Book",
    icon: AddressBookIcon,
    pathName: "address-book",
  },
];
</script>
