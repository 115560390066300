<template>
  <dialog-view
    contain-header
    title="Add New Address"
    :is-open="model"
    @change-is-open="handleCloseAdddAddress"
  >
    <template #content>
      <div class="my-6 flex flex-col gap-6">
        <select-token-dropdown
          label-text="Asset"
          :currencies="activeCurrencies"
          :error="error.token"
          @select="handleSelectToken"
        />

        <text-edit
          label="Wallet Address"
          size="md"
          rounded="xl"
          placeholder="Enter or paste the address"
          v-model="walletAddress"
          :error="error.address"
        >
          <template #suffix>
            <link-button
              class="font-semibold"
              color="primary"
              label="Paste"
              @click="handleClickPaste"
            />
          </template>
        </text-edit>

        <select-network-dropdown
          label-text="Network"
          :show-search-icon="false"
          :networks="selectedToken?.networks ?? []"
          :error="error.network"
          @select="handleSelectNetwork"
        />

        <text-edit
          rounded="xl"
          label="White Label"
          placeholder="Enter wallet label"
          v-model="whiteLabel"
          :error="error.white"
        >
        </text-edit>

        <fill-button
          class="mt-6"
          color="primary"
          width="full"
          size="md"
          @click="handleConfirm"
        >
          Confirm
        </fill-button>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { ref, computed, defineModel, defineEmits } from "vue";
import { useStore } from "vuex";
import { postHttpRequest } from "@/api/api";
import { checkFormValidation, isEmpty } from "@/utils/utils";
import DialogView from "@/components/Dialog/DialogView.vue";
import SelectTokenDropdown from "@/common/SelectCurrencyDropdown/SelectTokenDropdown.vue";
import SelectNetworkDropdown from "@/common/SelectNetworkDropdown/SelectNetworkDropdown.vue";

const store = useStore();
const model = defineModel();
const emit = defineEmits(["requestSend"]);

const activeCurrencies = computed(() =>
  Object.values(store.getters.activeCurrencies)
);

const selectedToken = ref();
const walletAddress = ref();
const selectedNetwork = ref();
const whiteLabel = ref();

const error = ref({
  token: null,
  address: null,
  network: null,
  white: null,
});

// Handle Select Token
const handleSelectToken = (token) => {
  selectedToken.value = token;
};

// Handle Click Paste Address
const handleClickPaste = async () => {
  try {
    // Check if the Clipboard API is supported
    if (navigator.clipboard) {
      // Get the clipboard content (text)
      walletAddress.value = await navigator.clipboard.readText();
    }
  } catch (error) {
    console.log(error);
  }
};

// Handle Select Network
const handleSelectNetwork = (network) => {
  selectedNetwork.value = network;
};

// Handle Confirm Add Address
const handleConfirm = async () => {
  if (isEmpty(selectedToken.value)) error.value.token = "Asset is required";
  else error.value.token = null;
  if (isEmpty(walletAddress.value))
    error.value.address = "Wallet address is required";
  else error.value.address = null;
  if (isEmpty(selectedNetwork.value))
    error.value.network = "Network is required";
  else error.value.network = null;
  if (isEmpty(whiteLabel.value)) error.value.white = "White label is required";
  else error.value.white = null;
  if (checkFormValidation(error.value)) {
    try {
      const data = {
        iso: selectedToken.value.iso3,
        name: whiteLabel.value,
        address: walletAddress.value,
        network_type: selectedNetwork.value.id,
        is_white: 1,
        send_to_email: 1,
        send_to_phone: 1,
      };
      //console.log(data);
      const res = await postHttpRequest(store)("/api/account/add-book", data);
      //console.log(res);
      if (res.status) {
        emit("requestSend", data);
        model.value = false;
      } else {
        store.dispatch("setToast", {
          type: "error",
          message: "An error occured while adding new address",
        });
      }
    } catch (error) {
      store.dispatch("setToast", {
        type: "error",
        message: error,
      });
    }
  }
};

const handleCloseAdddAddress = () => {
  model.value = !model.value;
};
</script>
