<template>
  <div>
    <p class="text-4xl mb-6 text-black dark:text-white">
      Enter the 6-digit verification code that was sent to
      {{ code.codeSentTo === "email" ? shownEmail : authData.phone }}
    </p>

    <p class="text-navy-100 mb-12">It's valid for 10 minutes.</p>

    <digit-code :error="error" @on-complete="handleChangeCode" />

    <div class="h-6" />

    <div class="mb-10">
      <p>
        Resend code in <span class="text-primary-700">{{ timeLeft }}s</span>
      </p>
    </div>

    <p class="text-navy-200 underline cursor-pointer" @click="handleResend">
      Can't receive a code?
    </p>
  </div>
</template>

<script setup>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import bs58 from "bs58";
import { postHttpRequest } from "@/api/api.js";
import session_manager from "@/utils/SessionManager.js";
import DigitCode from "@/components/DigitCode/DigitCode.vue";
import { isEmpty } from "@/utils/utils.js";

const timeLeft = ref(0);
const error = ref(false);

const store = useStore();
const router = useRouter();
const route = useRoute();

const code = computed(() => store.getters.code);
const authData = computed(() => store.getters.authInfo);

onMounted(() => {
  timeLeft.value = code.value.timeBeforeNewCode;
  setInterval(() => {
    timeLeft.value -= 1;
  }, 1000);
});

const shownEmail = computed(() => {
  if (authData.value.email) {
    const [name, domain] = authData.value.email.split("@");
    return name.split("").slice(0, 3).join("") + "*****@" + domain;
  } else {
    return null;
  }
});

const handleChangeCode = async (newCode) => {
  const res = await postHttpRequest(store)("/api/user/validate-code", {
    login_token: authData.value.token,
    code: newCode,
  });

  if (res.status) {
    const encoder = new TextEncoder();
    const privateKey = bs58.encode(
      encoder.encode(JSON.stringify(authData.value))
    );
    localStorage.setItem("privateKey", privateKey);
    session_manager.remove2FactorRequirement();
    store.dispatch("verifyCode");

    if (!isEmpty(route.query.back)) {
      router.go({ name: route.query.back });
    } else {
      router.go(-1);
    }
  } else {
    error.value = "Incorrect email authentication code, please try again";
  }
};

const handleResend = async () => {
  session_manager.removeToken();
  if (!isEmpty(route.query.back)) {
    router.go({ name: route.query.back });
  } else {
    router.go(-1);
  }
  // try {
  //   timeLeft.value = code.value.timeBeforeNewCode;
  //   const res = await axios.post("/api/user/send-code");
  //   if (res.status) {
  //     store.dispatch("setToast", {
  //       type: "success",
  //       message: `New code is sent to your ${res.data.code_sent_to}`,
  //     });
  //   } else {
  //     store.dispatch("setToast", {
  //       type: "error",
  //       message: res.errors.join(","),
  //     });
  //   }
  // } catch (error) {
  //   store.dispatch("setToast", {
  //     type: "error",
  //     message: "Error while resending code",
  //   });
  // }
};
</script>
