<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.375 2.5H7.375C7.20924 2.5 7.05027 2.56585 6.93306 2.68306C6.81585 2.80027 6.75 2.95924 6.75 3.125V6.25H3.625C3.45924 6.25 3.30027 6.31585 3.18306 6.43306C3.06585 6.55027 3 6.70924 3 6.875V16.875C3 17.0408 3.06585 17.1997 3.18306 17.3169C3.30027 17.4342 3.45924 17.5 3.625 17.5H13.625C13.7908 17.5 13.9497 17.4342 14.0669 17.3169C14.1842 17.1997 14.25 17.0408 14.25 16.875V13.75H17.375C17.5408 13.75 17.6997 13.6842 17.8169 13.5669C17.9342 13.4497 18 13.2908 18 13.125V3.125C18 2.95924 17.9342 2.80027 17.8169 2.68306C17.6997 2.56585 17.5408 2.5 17.375 2.5ZM13 16.25H4.25V7.5H13V16.25ZM16.75 12.5H14.25V6.875C14.25 6.70924 14.1842 6.55027 14.0669 6.43306C13.9497 6.31585 13.7908 6.25 13.625 6.25H8V3.75H16.75V12.5Z"
      fill="currentColor"
    />
  </svg>
</template>
