<template>
  <div class="mt-4 sm:mt-8">
    <pair-table :display-data="displayData" @sort-click="handleSortClicked" />
  </div>
</template>
<script setup>
import { defineProps, computed, ref } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "@/utils/utils";
import PairTable from "@/common/PairTable/PairTable.vue";

const props = defineProps(["filter"]);
const store = useStore();
const allPairs = computed(() => store.getters.allPairs);
const activeCurrencies = computed(() => store.getters.activeCurrencies);
const favoritePairs = computed(() => store.getters.favoritePairs);
const loggedIn = computed(() => store.getters.isLoggedIn);

const sortState = ref({
  id: 2,
  asc: true,
});

const displayData = computed(() => {
  if (isEmpty(allPairs.value) || isEmpty(activeCurrencies.value)) {
    return [];
  }
  let data = allPairs.value
    .map((item) => {
      return {
        id: item.name,
        token1: item.main.iso3,
        token2: item.second.iso3,
        price: item.main.rate_usd / 1e8,
        percent: item.percent,
        volume: item.volume_world_f,
        volume24h: item.volume_f,
        imgUrl: activeCurrencies.value[item.main.id].icon,
        favorite:
          loggedIn.value === true
            ? favoritePairs.value.includes(item.name)
            : false,
      };
    })
    .filter((item) => {
      return (
        item.token1.toLowerCase().includes(props.filter.toLowerCase()) ||
        item.token2.toLowerCase().includes(props.filter.toLowerCase())
      );
    })
    .sort((valA, valB) => {
      switch (sortState.value.id) {
        case 2:
          return valA.price - valB.price;
        case 3:
          return valA.percent - valB.percent;
        case 5:
          return valA.volume - valB.volume;
        case 6:
          return valA.volume24h - valB.volume24h;
      }
    });
  if (sortState.value.asc === true) {
    return data.reverse();
  }
  return data;
});

const handleSortClicked = (id) => {
  if (id === sortState.value.id) {
    sortState.value.asc = !sortState.value.asc;
    return;
  }
  sortState.value = {
    id: id,
    asc: false,
  };
};
</script>
