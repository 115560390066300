<template>
  <dialog-view
    title="Withdrawal Verification"
    :is-open="isOpen"
    @change-is-open="handleClose"
  >
    <template #content>
      <div>
        <div
          class="flex dark:text-navy-50 text-primary-800 text-sm font-medium items-center gap-2 p-2 dark:bg-navy-500 bg-primary-200 rounded-xl my-6"
        >
          <exclamation-circle-icon class="w-5 h-5" />
          <p>Select the same network as the withdrawal platform</p>
        </div>
        <div class="text-sm flex flex-col gap-3">
          <p class="flex justify-between items-center">
            <span class="dark:text-navy-100 text-navy-200">Network</span>
            <text-badge
              :label="network"
              size="md"
              class="dark:bg-navy-400 bg-primary-300 dark:text-white text-primary-800 text-[10px]"
            />
          </p>
          <p class="flex justify-between">
            <span class="dark:text-navy-100 text-navy-200">Pay from</span>
            <span>{{ payFrom }}</span>
          </p>
          <p class="flex justify-between">
            <span class="dark:text-navy-100 text-navy-200">
              Withdrawal amount
            </span>
            <span>{{ withdrawAmount }} {{ token }}</span>
          </p>
          <p class="flex justify-between">
            <span class="dark:text-navy-100 text-navy-200">Network fee</span>
            <span>{{ networkFee }} {{ token }}</span>
          </p>
          <p class="flex justify-between">
            <span class="dark:text-navy-100 text-navy-200">Wallet</span>
            <span>Funding Wallet</span>
          </p>
        </div>
        <fill-button
          class="mt-12"
          size="md"
          width="full"
          color="primary"
          @click="handleClickConfirm"
        >
          Confirm
        </fill-button>
      </div>
    </template>
  </dialog-view>
</template>
<script setup>
import { defineProps, defineEmits } from "vue";
import { ExclamationCircleIcon } from "@heroicons/vue/24/outline";
import DialogView from "@/components/Dialog/DialogView.vue";
import TextBadge from "@/components/Badge/TextBadge.vue";

defineProps({
  isOpen: Boolean,
  token: String,
  network: String,
  payFrom: String,
  withdrawAmount: String,
  networkFee: Number,
});

const emits = defineEmits(["close", "confirm"]);

const handleClickConfirm = () => {
  emits("confirm");
};
const handleClose = () => {
  emits("close");
};
</script>
