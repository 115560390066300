<template>
  <div>
    <p v-if="label" class="font-semibold mb-2 text-sm">
      {{ label }}
    </p>
    <button
      :class="[
        `flex items-center justify-between border-2 w-full ${sizeClass} ${roundedClass} text-left`,
        !filled
          ? `bg-white ${!error && 'border-navy-50'} dark:bg-navy-700 ${
              !error && 'dark:border-navy-400'
            }`
          : `bg-navy-10 ${!error && 'border-navy-10'} dark:bg-navy-500 ${
              !error && 'dark:border-navy-500'
            }`,
        error ? 'border-red-500' : 'focus-within:border-primary-700',
      ]"
      @click="handleFocus"
    >
      <slot name="prefix" />
      <p
        :class="[
          'w-full text-sm cursor-pointer',
          !filled
            ? currentTheme === 'light'
              ? 'bg-white'
              : 'bg-navy-700'
            : currentTheme === 'light'
            ? 'bg-navy-10'
            : 'bg-navy-500',
        ]"
      >
        <slot v-if="!isEmpty(value)" name="content" />
        <span v-else class="dark:text-navy-200 text-navy-100">
          {{ placeholder }}
        </span>
      </p>
      <slot name="suffix" />
    </button>
    <p v-if="error" class="text-red-500 mt-2 text-xs">
      {{ error }}
    </p>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import { isEmpty } from "@/utils/utils";

const props = defineProps({
  value: String,
  placeholder: String,
  label: String,
  filled: Boolean,
  size: String,
  rounded: String,
  error: String,
});

const emit = defineEmits(["focus"]);

const sizeClass = computed(() => {
  switch (props.size) {
    case "sm":
      return "px-3 py-2";
    default:
      return "p-4";
  }
});
const roundedClass = computed(() => `rounded-${props.rounded}`);

const handleFocus = () => {
  emit("focus");
};
</script>
