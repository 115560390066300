<template>
  <div
    class="w-full relative sm:p-10 p-4 rounded-3xl overflow-hidden cursor-pointer"
    :style="[
      currentTheme === 'dark'
        ? 'box-shadow: 0px 0px 8px 6px #424d70, 0px 0px 0px 4px #0e111b'
        : 'box-shadow: 0px 0px 0px 4px white',
    ]"
  >
    <div class="absolute bottom-0 right-0 -z-40 w-full h-full">
      <img
        v-if="currentTheme === 'dark'"
        class="w-full h-full object-cover"
        :src="[
          currentTheme === 'dark'
            ? '/assets/images/launchpad/live_project_item_background_dark.svg'
            : '',
        ]"
      />
    </div>
    <div
      class="absolute left-0 top-0 w-full h-full dark:bg-navy-600 bg-primary-100 -z-50"
    />
    <div class="flex flex-col items-center justify-center w-full">
      <div
        class="flex sm:flex-row flex-col items-start justify-between w-full gap-4"
      >
        <div class="flex flex-row items-center justify-center gap-5">
          <img :src="props.icon" class="w-14 h-14" />
          <div class="flex flex-col">
            <div class="dark:text-white text-navy-800 text-2xl font-semibold">
              {{ props.fullName }}
            </div>
            <div class="text-navy-200 dark:text-navy-100 text-base font-medium">
              {{ props.shortName }}
            </div>
          </div>
        </div>
        <div class="flex flex-row items-center justify-center gap-4">
          <text-badge
            size="md"
            class="dark:bg-navy-300 dark:text-primary-300 bg-primary-300 text-primary-800"
            :label="props.leftTime"
          />
          <text-badge
            :icon="ClockIcon"
            size="md"
            class="bg-orange-200 text-orange-900"
            label="Ongoing"
          />
        </div>
      </div>
      <div
        class="flex md:flex-row flex-col items-start justify-stretch mt-6 md:gap-16 gap-6"
      >
        <div class="text-sm font-normal dark:text-navy-50 text-navy-300">
          Arkham (ARKM) is an intel-to-earn economy powering the deanonymization
          of the blockchain withAI. Arkham uses AI to deanonymize the
          blockchain, showing users the people and companies behind
          cryptocurrency transactions; and Arkham introduces intel-to-earn, an
          intel economy that matches buyers and sellers of blockchain data at
          scale.
        </div>
        <div
          class="flex flex-row items-start justify-between shrink-0 gap-12 max-md:w-full"
        >
          <div
            class="flex flex-col items-start justify-start text-sm font-medium dark:text-white text-navy-800"
          >
            <div>Tokens offered</div>
            <div>Sale price</div>
            <div>Participants</div>
            <div>Total committed</div>
          </div>
          <div
            class="flex flex-col items-start justify-start text-sm font-medium dark:text-navy-100 text-navy-300"
          >
            <div>50,000,000 ARKM</div>
            <div>1 ARKM = 0.00042176 USDT</div>
            <div>114.546</div>
            <div>10,182,983 USDT</div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col sm:flex-row sm:items-end items-center justify-between w-full sm:gap-12 mt-8"
      >
        <div class="flex flex-col grow w-full">
          <div class="flex flex-row items-end justify-between w-full">
            <div class="flex flex-col dark:text-white text-navy-800">
              <div class="text-sm font-medium">Participants</div>
              <div class="text-2xl font-semibold">28,210</div>
            </div>
            <div class="flex flex-row items-end">
              <div
                class="dark:text-white text-2xl font-semibold text-navy-800 mr-1"
              >
                42,671
              </div>
              <div
                class="text-navy-300 text-base font-medium dark:text-navy-100"
              >
                /100,000 USDT
              </div>
            </div>
          </div>
          <div
            class="mt-6 h-6 dark:bg-navy-600 bg-primary-200 w-full rounded-full relative"
          >
            <div
              class="h-2 w-4/5 bg-gradient-to-r dark:from-green-200 dark:to-[#90deff] from-[#81f3d1] to-green-400 m-2 rounded-full"
              :style="[
                currentTheme === 'dark'
                  ? 'box-shadow: 0px 0px 12px 0px #a7f3d0'
                  : '',
              ]"
            ></div>
          </div>
        </div>
        <div class="sm:w-fit w-full mt-6">
          <fill-button
            color="primary"
            size="lg"
            width="full"
            @click="handleParticipateClick"
          >
            Participate
          </fill-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";
import TextBadge from "@/components/Badge/TextBadge.vue";
import { ClockIcon } from "@heroicons/vue/24/outline";

const props = defineProps([
  "icon",
  "fullName",
  "shortName",
  "leftTime",
  "description",
  "participants",
  "tokensOffered",
  "salePrice",
  "totalCommited",
  "selledAmount",
  "totalAmount",
  "id",
]);

const router = useRouter();

const handleParticipateClick = () => {
  router.push({ name: "launchpad-project-detail", params: { url: props.id } });
};
</script>
