<template>
  <dropdown-select
    alignment="translate-y-2"
    @select="
      (item) => {
        emits('tokenChange', item.id);
      }
    "
    width="full"
  >
    <template
      #header="{ handleContentClick: handleHeaderClick, isShowingMenu }"
    >
      <div class="w-full">
        <div
          :class="[
            'dark:shadow-[0px_0px_40px_rgba(66,77,112,0.64)] shadow-[0px_2px_16px_rgba(0,0,0,0.08)] border-2 dark:bg-navy-700 rounded-2xl flex flex-row p-2 justify-between sm:p-3 bg-white',
            isShowingMenu ? 'border-primary-700' : 'border-transparent',
          ]"
        >
          <div
            class="flex flex-none flex-col dark:bg-navy-500 bg-primary-200 rounded-xl px-2 py-1.5 sm:px-3.5"
          >
            <div
              class="dark:text-navy-100 text-navy-200 mb-1 text-xs font-medium"
            >
              {{ props.label }}
            </div>
            <div
              class="flex flex-row items-center cursor-pointer"
              @click="handleHeaderClick"
            >
              <img :src="props.selectedToken?.icon" class="w-6 h-6 mr-3" />
              <div
                class="mr-3 dark:text-white text-navy-800 text-lg font-semibold"
              >
                {{ props.selectedToken?.iso3 }}
              </div>
              <chevron-down-icon
                class="stroke-2 dark:text-white text-navy-800 w-4 h-4"
              />
            </div>
          </div>
          <div class="grow" />
          <div
            v-if="disabled === true"
            class="flex flex-col items-end justify-end"
          >
            <div class="mr-4">
              <input
                type="text"
                class="bg-transparent outline-none text-right placeholder:dark:text-navy-200 dark:text-navy-200 placeholder:text-navy-100 text-navy-100 text-xl font-semibold sm:w-fit max-sm:max-w-[150px]"
                placeholder="0.00001-20"
                v-model="tokenAmount"
                disabled
              />
            </div>
          </div>
          <div v-else class="flex flex-col items-end">
            <div
              class="flex flex-row rounded-full border dark:border-navy-500 px-3 py-1 items-center bg-navy-10 dark:bg-navy-700"
            >
              <div
                class="dark:text-white text-xs font-medium mr-1 text-navy-300"
              >
                Available:
              </div>
              <div class="font-bold text-xs dark:text-white mr-2 text-navy-300">
                {{
                  `${props.selectedToken?.balance ?? 0} ${
                    props.selectedToken?.iso3 ?? ""
                  }`
                }}
              </div>
              <div class="text-primary-700 text-xs font-semibold">Deposit</div>
            </div>
            <div class="flex flex-row pr-3 items-center mt-1.5">
              <div class="mr-6">
                <input
                  type="text"
                  class="bg-transparent outline-none text-right placeholder:dark:text-navy-200 dark:text-white placeholder:text-navy-100 text-navy-800 text-xl font-semibold sm:w-fit max-sm:max-w-[100px]"
                  :placeholder="[`0-${props.selectedToken?.balance ?? 0}`]"
                  v-model="tokenAmount"
                />
              </div>
              <div
                class="text-primary-700 text-sm font-semibold cursor-pointer"
                @click="
                  () => {
                    tokenAmount = props.selectedToken?.balance;
                  }
                "
              >
                Max
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #menu="{ handleMenuItemClick: handleFromMenuClick }">
      <available-currency-dropdown
        :label="`Convert ${props.label}`"
        :currencies="props.currencies"
        @click="handleFromMenuClick"
      />
    </template>
  </dropdown-select>
</template>
<script setup>
import { defineProps, defineEmits, defineModel } from "vue";
import DropdownSelect from "@/components/Dropdown/DropdownSelect.vue";
import AvailableCurrencyDropdown from "@/common/CurrencyDropdown/AvailableCurrencyDropdown.vue";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

const tokenAmount = defineModel();
const props = defineProps(["label", "currencies", "selectedToken", "disabled"]);
const emits = defineEmits(["tokenChange"]);
</script>
