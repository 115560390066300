<template>
  <div class="relative">
    <slot
      name="header"
      :handleContentClick="handleContentClick"
      :isShowingContent="isShowingContent"
    />
    <div v-show="isShowingContent">
      <slot name="content" />
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch } from "vue";

const props = defineProps(["isShow"]);
const emit = defineEmits(["headerClick"]);
const isShowingContent = ref(false);

watch(
  () => props.isShow,
  (newVal) => {
    isShowingContent.value = newVal;
  }
);

const handleContentClick = () => {
  isShowingContent.value = !isShowingContent.value;
  emit("headerClick", isShowingContent.value);
};
</script>
