<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9999 11.25C17.7764 11.25 15.9374 12.2562 14.6874 14.1578C13.6764 15.6984 13.1249 17.7734 13.1249 20C13.1249 22.2266 13.6811 24.3016 14.6874 25.8422C15.9374 27.7437 17.7718 28.75 19.9999 28.75C22.228 28.75 24.0624 27.7437 25.3124 25.8422C26.3233 24.3016 26.8749 22.2266 26.8749 20C26.8749 17.7734 26.3186 15.6984 25.3124 14.1578C24.0624 12.2562 22.2233 11.25 19.9999 11.25ZM19.9999 26.25C16.5468 26.25 15.6249 22.3219 15.6249 20C15.6249 17.6781 16.5468 13.75 19.9999 13.75C23.453 13.75 24.3749 17.6781 24.3749 20C24.3749 22.3219 23.453 26.25 19.9999 26.25ZM32.4999 5H7.49988C6.83684 5 6.20095 5.26339 5.73211 5.73223C5.26327 6.20107 4.99988 6.83696 4.99988 7.5V32.5C4.99988 33.163 5.26327 33.7989 5.73211 34.2678C6.20095 34.7366 6.83684 35 7.49988 35H32.4999C33.1629 35 33.7988 34.7366 34.2676 34.2678C34.7365 33.7989 34.9999 33.163 34.9999 32.5V7.5C34.9999 6.83696 34.7365 6.20107 34.2676 5.73223C33.7988 5.26339 33.1629 5 32.4999 5ZM32.4999 32.5H7.49988V7.5H32.4999V32.5Z"
      fill="currentColor"
    />
  </svg>
</template>
