<template>
  <div class="w-full flex gap-12">
    <div class="w-full">
      <div class="flex h-fit items-center justify-between mb-12">
        <p class="font-semibold text-2xl">Deposit Fiat</p>
        <outline-button color="primary" size="sm" @click="goToDepositCrypto">
          <span>Deposit Crypto</span>
          <chevron-right-icon class="w-5 h-5" />
        </outline-button>
      </div>
      <div>
        <!-- <vertical-circle-step
          :step-length="stepLength"
          :current-active-step="currentActiveStep"
        >
          <template #content-1>
            <div class="w-full">
              <p class="font-semibold text-xl mb-7 mt-1">Select Currency</p>
              <select-currency-dropdown
                :show-history="false"
                :currencies="bankCurrencies"
                @select="handleSelectCurrency"
              />
              <div class="h-10" />
            </div>
          </template> -->
        <!-- <template #content-2>
          <div class="w-full">
            <p class="font-semibold text-xl mb-7 mt-1">Select Network</p>
            <select-network-dropdown
              :networks="networks"
              @select="handleSelectNetwork"
            />
            <div class="h-10" />
          </div>
        </template>
        <template #content-3>
          <div class="w-full">
            <p class="font-semibold text-xl mb-7 mt-1">Deposit details</p>
            <deposit-crypto-detail
              :address-info="addressInfo"
              :network="network"
              :token="coin"
            />
            <div class="h-10" />
          </div>
        </template> -->
        <!-- </vertical-circle-step> -->
      </div>
    </div>
    <faq-small />
  </div>
</template>

<script setup>
// import { ref, computed, onMounted } from "vue";
// import { useStore } from "vuex";
import { useRouter } from "vue-router";
import FaqSmall from "@/common/FAQ/FaqSmall.vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
// // import VerticalCircleStep from "@/components/VerticalCircleStep/VerticalCircleStep.vue";
// // import SelectCurrencyDropdown from "@/common/SelectCurrencyDropdown/SelectCurrencyDropdown.vue";
// import axios from "@/api/axios";

const router = useRouter();
// const store = useStore();

// const bankCurrencies = computed(() =>
//   Object.values(store.getters.bankCurrencies ?? {})
// );

// const stepLength = ref(2);
// const currentActiveStep = ref(1);

const goToDepositCrypto = () => {
  router.push({ name: "asset-deposit-crypto" });
};

// const handleSelectCurrency = () => {};

// onMounted(async () => {
//   const res = await axios.post("/api/fiat", {
//     currency: "EUR",
//   });
//   console.log(res);
// });
</script>
