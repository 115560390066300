<template>
  <dropdown-select @select="handleSelect" alignment="end">
    <template #header="{ handleContentClick, isShowingMenu }">
      <div
        :class="[
          'flex flex-row items-center cursor-pointer gap-2 py-2',
          isShowingMenu ? 'text-primary-700' : 'dark:text-white text-navy-800',
        ]"
        @click="handleContentClick"
      >
        <div class="text-sm font-semibold">
          {{ selectedItem.label }}
        </div>
        <div>
          <ChevronUpIcon v-if="isShowingMenu" class="size-4 stroke-2" />
          <ChevronDownIcon v-else class="size-4 stroke-2" />
        </div>
      </div>
    </template>
    <template #menu="{ handleMenuItemClick }">
      <div
        :class="[
          'flex flex-col sm:w-max dark:bg-navy-600 bg-white rounded-2xl dark:border-navy-400 p-2 border-navy-10 max-h-72 custom-scrollbar cursor-pointer max-sm:w-[calc(100vw-16px)] gap-2 min-w-52',
        ]"
        :style="
          currentTheme === 'dark'
            ? 'box-shadow:0px 4px 24px 0px rgba(0,0,0,0.32);'
            : 'box-shadow:2px 4px 16px 0px rgba(0,0,0,0.08);'
        "
      >
        <div
          v-for="item in props.items"
          :key="item.id"
          :class="[
            'rounded-lg dark:hover:bg-navy-500 hover:bg-primary-200 flex flex-row items-center justify-stretch px-3 py-2.5 w-full',
            item.id === selectedItem.id
              ? 'dark:bg-navy-500 bg-primary-200 dark:text-white text-primary-700'
              : '',
          ]"
          @click="handleMenuItemClick(item)"
        >
          <div
            v-if="item.icon"
            class="size-10 mr-3 dark:bg-primary-700/[0.18] bg-primary-400 text-primary-600 dark:text-primary-500 flex items-center justify-center rounded-full"
          >
            <component :is="item.icon" class="size-6" />
          </div>
          <div>{{ item.label }}</div>
          <div class="grow" />
          <CheckCircleIcon
            v-if="item.id === selectedItem.id"
            class="text-primary-700 size-5"
          />
        </div>
      </div>
    </template>
  </dropdown-select>
</template>
<script setup>
import { defineProps, ref, defineEmits, watch, onBeforeMount } from "vue";
import DropdownSelect from "@/components/Dropdown/DropdownSelect.vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";
import { CheckCircleIcon } from "@heroicons/vue/24/solid";
import { isEmpty } from "@/utils/utils";

const props = defineProps(["items", "defaultSelected"]);
const emit = defineEmits(["change"]);
const selectedItem = ref(props.items[0]);

onBeforeMount(() => {
  if (isEmpty(props.defaultSelected)) {
    selectedItem.value = props.items[0];
    return;
  }
  selectedItem.value = props.items.find(
    (item) => item.id === props.defaultSelected
  );
});

watch(
  () => props.defaultSelected,
  (newVal) => {
    if (isEmpty(newVal)) {
      selectedItem.value = props.items[0];
      return;
    }
    selectedItem.value = props.items.find((item) => item.id === newVal);
  }
);

const handleSelect = (item) => {
  selectedItem.value = item;
  emit("change", item);
};
</script>
